// Dashboard
export const DASHBOARD = '/dashboard/apps';
export const POWER_STATUS = '/dashboard/powerstatus';
export const IOS_DASHBOARD = '/dashboard';

// TV Dashboard
export const TV_DASHBOARD = '/dashboard';

// PROFILE
export const POST_EDIT_PROFILE = '/user';

// Enterprise
export const ENTERPRISES = '/enterprises';
export const REGISTRATION_URL = '/registrationurl';
export const REGISTRATION = '/registrations';
export const CHROME_CONFIG = '/chromeconfig';

// Profile
export const POLICIES = '/policies';
export const POLICIES_REFRESH = '/policies/refresh';
export const DEFAULT_POLICY = '/policies/default';
export const QR_CODE = '/qrcode';
export const QR_CODE_TOKEN = '/token/qrcode';
export const CLONE = '/policies/clone';
export const POLICY_WORKFLOW = '/policyworkflow';

// Devices
export const DEVICES = '/devices';
export const REMOTE = '/devices/remote';
export const HEAD_WIND_REMOTE = '/headwinremote';
export const DEVICE_DOWNLOAD_ALL = '/devices/downloadall';
export const DEVICES_SAMPLE_DOWNLOAD = '/devices/template';
export const DEVICES_EXPORT = '/devices/download';
export const DEVICES_UPLOAD = '/devices/upload';
export const DEVICES_METRIC_COUNT = '/devices/metric';
export const DEVICE_AUTOMATION = '/deviceautomation';
export const TIME_FENCE = '/deviceautomation/timefence';
export const ES_DEVICES = '/esdevices';
export const APP_USAGE_REPORT = '/devicereports/appusage';
export const CHROME_DEVICES = '/chromedevices';
export const DEVICE_LOOKUP = '/devicelookup';

// ChromeOS Devices
export const CHROMEOS_DEVICES = '/chromedevices';

// export const ES_DEVICE_COUNT = '/esdevices2/onlinecount';
export const DEVICE_ACTIONS = '/deviceactions';

// Device Groups
export const DEVICE_GROUPS = '/groups/structured';
export const DEVICE_GROUPS_UNSTRUCTURED = '/groups';
export const DEVICE_GROUPS_CHILD = '/groups/childs';
export const DEVICE_GROUPS_SEARCH = '/groups/search';

// Device Reports
export const DEVICE_REPORTS = '/devicereports';
export const TOP_USED = '/devicereports/topfiveapps';
export const LOCATION_HISTORY_REPORT = '/location/report';
export const POWER_STATUS_REPORT = '/powerstatus/report';
export const APPUSAGE_REPORT = '/appusage/report';
export const QUERY_PAGE = '/query/page';

// Applications
export const EMBEDED_URL = '/applications/embedurl';
export const APPLICATIONS = '/applications';
export const MANAGED_CONFIGURATIONS = '/managedconfig';

// Users
export const ROLES = '/roles';
export const USERS = '/users';

// Roles
export const USER_GROUPS = '/usergroups';

// Announcement
export const ANNOUNCEMENT = '/announcements';

// Messages
export const MESSAGES = '/messages';

// Assets
export const ASSETS = '/assets';
export const ASSET_DOWNLOAD = '/assets/download';

// App Config
export const APP_CONFIG = '/config';
export const APP_CONFIG_EXTENSION = '/appconfig/extension';

// Assets
export const CALL_LOGS = '/calllog';
export const CALLLOGS_DOWNLOAD = '/calllog/download';
export const USER_ACTIVITY = '/useractivities';

// Bulk Actions
export const BULK_ACTION = '/bulkaction';
export const UPLOAD_DEVICES = '/bulkaction/upload';
// Content Management
export const CONTENT_MANAGEMENT = '/content';
// Tenant Configs
export const TENANTS = '/tenants';
// Master
export const TENANT_MASTER = '/master/tenants';
// Ticket
export const TICKETS = '/tickets';
// File
export const FILE = '/file';
// QR Code
export const GOOGLE_AUTHENTICATOR_QR = '/enrollment/qrcode';

// Configurations
export const CONFIG = '/config';

export const APP_PROTECTION = '/appprotection';
export const MS_APP_PROTECT = '/msappprotection';
export const APP_PROTECTION_APPS = '/msappprotection/android/apps';
export const APP_PROTECTION_GROUPS = '/msgroups';
export const PROTECTION = '/protection';
export const MS_APP_PROTECTION = '/msappprotection/android';
export const MS_CONDITIONAL_ACCESS = '/msconditionalaccess';
export const TT_APP_PROTECTION = '/ttappprotection/android';
export const APPLE_APP_PROTECTION = '/msappprotection/ios';
export const TT_IOS_APP_PROTECTION = '/ttappprotection/ios';
export const COND_ACCESS_LOCATION_DELETE = '/msconditionalaccess/locations';
export const MS_APP_CONFIG = '/msappprotection/config';

// TV Devices
export const TV_DEVICES = '/tvdevices';
export const TV_POLICIES = '/tvpolicies';
export const TV_APPS = '/apps';
export const TV_EXPORT = '/tvdevices/download';
export const TV_COMMANDS = '/commands';

// export const ANTIVIRUS = '/antivirus';
export const ANTI_VIRUS = '/scans';

// File uploads
export const FILES = '/files';
export const FILE_SIGNEDURL = '/files/signedurl';

export const MY_FILES = '/myfiles';

// IDM
export const BRANDING = '/branding';

export const PRIVILEGES = '/privileges';

export const TEACHER_METRICS = '/teacher/metrics';
export const TEACHER_UPLOAD = '/teacher/upload';
export const TEACHER_DOWNLOAD = '/teacher/download';
export const TV_ERRORS = '/devicereports/tverros';

export const CERTIFICATES = '/certificates';

// iOS
export const IOS_COMMANDS = '/commands';
export const ABM_CERT = '/abm/mdmserver/cert';
export const APN_GET = '/pushcert';
export const APN_CERT = '/pushcert/csr';
export const APN_CERT_UPLOAD = '/pushcert/cert';
export const MDMSERVER = '/abm/mdmserver';
export const IOSAPPS = '/assets';
export const APPCONFIG = '/managedappconfig';
export const IOSAPPS_UPLOAD = '/assets/upload';
export const IOSAPPS_SYNC = '/assets/sync';
export const IOS_DEVICES_EXPORT = '/devices/export';
export const VPP_TOKEN_UPLOAD = '/vpp/token';
export const VPP_DATA = '/vpp';
export const VPP_REGION = '/vpp';
export const ENROLLMENT_STEPS = '/dep';
export const POLICY_SYNC = '/policies/sync';
export const ACTIVATION_LOCK = '/devices';

// Auth
export const REFRESH_TOKEN = '/token/refresh';
export const LOGOUT = '/token/logout';
export const REGISTER = '/auth/signup';
export const LOGIN = '/auth/local/login';
export const FORGOT_PASSWORD = '/auth/local/password/forgot';
export const RESET_PASSWORD = '/auth/local/password/reset';
export const VALIDATE_OTP = '/auth/local/tfa';
export const TENANT_BY_CODE = '/tenants/bycode/';
export const AUTH_MODES = '/authmodes/tenant/';
export const AUTH_LOGIN = '/auth/oidc/signin';
export const AUTH_OIDC_CALLBACK = '/auth/oidc/callback';
export const LOGIN_HISTORY_DOWNLOAD = '/loginhistory/export';
export const LOGIN_HISTORY = '/loginhistory';
export const AUTH_MODES_CREATE = '/authmodes';
export const AUTH_LDAP = '/auth/ldap/login';

// Self Service Portal
export const MY_DEVICE = '/mydevice';

export const MY_DEVICES = '/mydevices';
export const MY_POLICIES = '/mypolicies';

// Windows

export const WPN_CONFIG = '/config';
export const LINK_AZURE = '/azure';
export const BYODSETUP = '/enroll/invitation';
export const WINDOWS_COMMANDS = '/commands';
export const WINDOWS_APP_UPLOAD = '/apps/upload';
export const WINDOWS_APPS = '/apps';
export const WINDOWS_DEVICES_EXPORT = '/devices/export';
export const WINDOWS_DASHBOARD = '/dashboard';
export const WINDOWS_SCRIPTS = '/commands/script';
export const SCRIPTS = '/scripts';
export const WINDOWS_ENROLL_CONFIG = '/enrollconfig';
export const WINDOWS_ASSIGNMENT = '/assignments';
export const WINDOWS_FILES = '/files';
export const WINDOWS_FILES_DOWNLOAD = '/files/download';

// Audit Log
export const AUDIT_GROUPS = '/audit/group';

// license
export const LICENSE = '/licenses';
export const LICENSE_OS = '/licenses/devices';
export const LICENSE_DEVICE = '/licenses/total';

// Enrollment Settings
export const DEVICE_RESTRICTIONS = '/device/restrictions';
export const TV_ENROLLMENT = '/enrollconfig';

export const FILE_DELETE = '/file/delete';

export const FILE_UPLOAD = '/files/signedurl/';

// Reporting
export const LOCATION_REPORT = '/location/report';
export const APP_TEMPLATE = '/template';
export const TV_APP_SIGNEDURL = '/apps/signedurl/';
