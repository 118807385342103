/* eslint-disable max-lines */
import { useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { FilePond } from 'react-filepond';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Alert, Card, CardBody, CardHeader, Col, Container, Input, Label, Row, UncontrolledTooltip } from 'reactstrap';
import * as Yup from 'yup';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import CommonModal from '../../../Components/Common/CommonModal';
import DeleteModal from '../../../Components/Common/DeleteModal';
import Loader from '../../../Components/Common/Loader';
import OffcanvasModal from '../../../Components/Common/OffcanvasModal';
import Pagination from '../../../Components/Common/Pagination/Pagination';
import TableContainer from '../../../Components/Common/TableContainer';
import { IsAuthorized, TenantConfig, Tooltip, convertUTCtoIST, getDate, getFormTypeAndRecordId } from '../../../Components/Common/Util';
import { priviliges } from '../../../Components/constants/constants';
import hardwareIcon from '../../../assets/images/common/png/devicesViewIcons/hardware.png';
import deviceSync from '../../../assets/images/common/png/devicesViewIcons/device-sync.png';
import shutdown from '../../../assets/images/common/png/devicesViewIcons/devicePower.png';
import deviceSecurity from '../../../assets/images/common/svg/devicesViewIcons/deviceSecurity.svg';
import factoryReset from '../../../assets/images/common/png/common/factoryReset.png';
import lockDevice from '../../../assets/images/common/png/common/lockDevice.png';
import rebootDevice from '../../../assets/images/common/png/common/rebootDevice.png';
import softwareIcon from '../../../assets/images/common/svg/devicesViewIcons/software.svg';
import syncDevice from '../../../assets/images/common/png/devicesViewIcons/sync.png';
import toastMessages from '../../../common/messages/toastMessages';
import { useEnv } from '../../../envContext';
import { APIClient } from '../../../helpers/api_helper';
import * as domains from '../../../helpers/domain_helper';
import * as url from '../../../helpers/url_helper';
import unenroll from '../../../assets/images/apple/png/common/unlink.png';
import { moveGroupsSchema } from '../../AndroidEnterprise/Devices/TableSchema';
import { usersTableSchema } from '../TableSchema';
import { InputComponent } from './IOSDevices';

const ViewIOSDevices = () => {
    const api = new APIClient();
    const history = useHistory();
    const urlconf = useEnv();
    const formTypeAndId = getFormTypeAndRecordId(window.location.hash);
    let recordID = formTypeAndId['recordID'];
    const tenantConfig = TenantConfig();
    const [loading, setLoading] = useState(false);
    const [iosDetails, setIosDetails] = useState('');
    const [type, setType] = useState('');
    const [actionModal, setActionModal] = useState(false);
    const [actionText, setActionText] = useState('');
    const [policy, setPolicy] = useState('');
    const [policyOptions, setPolicyOptions] = useState([]);
    const [showPolicyDropdown, setShowPolicyDropdown] = useState(false);
    const [policyBackup, setPolicyBackup] = useState('');
    const [showDeviceGroup, setShowDeviceGroup] = useState(false);
    const [deviceGroupOptions, setDeviceGroupsOptions] = useState([]);
    const [deviceGroupOptionsBackup, setDeviceGroupsOptionsBackup] = useState([]);
    const [selectedDeviceGroup, setSelectedDeviceGroup] = useState('');
    const [subBreadcrumbItems, setSubBreadcrumbItems] = useState([{ name: 'Main Group' }]);
    const [defaultGroup, setDefaultGroup] = useState('');
    const [modalData, setModalData] = useState({ message: '', image: '' });
    const [showPhoneInput, setShowPhoneInput] = useState(false);
    const [showUserInput, setShowUserInput] = useState(false);
    const [selectedUser, setSelectedUser] = useState('');
    const [defaultUser, setDefaultUser] = useState('');
    const [user, setUser] = useState({ data: [], page: 1, size: 10 });
    const [phoneNumber, setPhoneNumber] = useState('');
    const [showDeviceInput, setShowDeviceInput] = useState(false);
    const [deviceName, setDeviceName] = useState('');
    const [commandHistory, setCommandHistory] = useState([]);
    const [commandPage, setCommandPage] = useState({ page: 1, size: 20, length: 0, device: recordID });
    const [commonModal, setCommonModal] = useState({ show: false, header: '', type: '' });
    const [lostMode, setLostMode] = useState({ phone: '', message: '', footNote: '', networkTether: false, enabled: false });
    const [appsList, setAppsList] = useState([]);
    const [selectedApp, setSelectedApp] = useState();
    const [showPolicyModal, setShowPolicyModal] = useState(false);
    document.title = 'View Apple Device';

    const commands = {
        RestartDevice: { label: 'Reboot Device', icon: 'ri-creative-commons-sa-line' },
        DeviceLock: { label: 'Device Lock', icon: 'ri-phone-lock-line' },
        ShutDownDevice: { label: 'Shutdown', icon: 'ri-shut-down-line' },
        EraseDevice: { label: 'Erase Device', icon: 'ri-android-line' },
        DeviceInformation: { label: 'Device Information', icon: 'ri-information-line' },
        ProfileList: { label: 'Profile List', icon: 'ri-profile-line' },
        RemoveProfile: { label: 'Remove Profile', icon: 'ri-delete-bin-line' },
        InstallApplication: { label: 'Install Application', icon: 'ri-tablet-line' },
        ManagedApplicationList: { label: 'Managed Application List', icon: 'ri-apps-2-line' },
        DeclarativeManagement: { label: 'Declarative Management', icon: 'ri-tablet-line' },
        InstallProfile: { label: 'Install Profile', icon: 'ri-install-line' },
        RemoveApplication: { label: 'Remove Application', icon: 'ri-delete-bin-line' },
        DisableLostMode: { label: 'Disabled Lost Mode', icon: 'ri-phone-find-line' },
        EnableLostMode: { label: 'Enabled Lost Mode', icon: 'ri-phone-find-line' },
        PlayLostModeSound: { label: 'Lost Mode Sound', icon: 'ri-volume-up-line' },
        Wallpaper: { label: 'Wallpaper', icon: 'ri-image-2-line' },
        ActivationLockBypassCode: { label: 'Activation Lock Bypass Code', icon: 'ri-lock-unlock-line' },
        InstallEnterpriseApplication: { label: 'Install Enterprise Application', icon: 'ri-tablet-line' },
        DeviceName: { label: 'Device Name', icon: 'ri-smartphone-line' },
        ActivationLock: { label: 'Activation Lock', icon: 'ri-door-lock-box-line' },
        '': { label: 'NA' }
    };

    const handleLostModeClick = (key, heading) => {
        setLostMode({ phone: '', message: '', footNote: '', networkTether: false, enabled: false });
        setCommonModal({ show: true, header: heading, type: key });
        setActionText('Ok');
    };

    const handleAppClick = (key, heading) => {
        setCommonModal({ show: true, header: heading, type: key });
    };

    const handleActivationLockClick = (key, heading) => {
        setCommonModal({ show: true, header: heading, type: key });
        setActionText('Yes, Activate it');
    };

    const handleWallpaper = (key, heading) => {
        setCommonModal({ show: true, header: heading, type: key });
        setActionText('Yes, Apply it!');
    };

    useEffect(() => {
        setLoading(true);
        handlePromise();
    }, []);

    const handlePromise = () => {
        const infoPromise = new Promise((resolve, reject) => {
            api.get(url.DEVICES + '/' + recordID + '/info', '', domains.IOS_V1)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp.data) resolve(resp.data);
                    else reject('Info failed');
                })
                .catch((_err) => reject('Info failed'));
        });
        const devicePromise = new Promise((resolve, reject) => {
            api.get(url.DEVICES + '/' + recordID, '', domains.IOS_V1)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp.data) resolve(resp.data);
                    else reject('Device failed');
                })
                .catch((_err) => reject('Device failed'));
        });
        const policyPromise = new Promise((resolve, reject) => {
            let params = { page: 1, size: 100 };
            api.get(url.POLICIES, params, domains.IOS_V1)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp?.data) resolve(resp.data);
                    else reject('Policy failed.');
                })
                .catch((err) => reject('Policy failed.'));
        });

        const getDeviceGroups = new Promise((resolve, reject) => {
            let params = { page: 1, size: 100 };
            api.get(url.DEVICE_GROUPS, params, domains.IDM)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp?.data) resolve(resp.data);
                    else reject('Group failed.');
                })
                .catch((_err) => reject('Group failed.'));
        });

        const actionHistyPromise = new Promise((resolve, reject) => {
            api.get(url.IOS_COMMANDS, { device: recordID, page: 1, size: 20 }, domains.IOS_V1)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp?.data) resolve(resp);
                    else reject('History failed.');
                })
                .catch((_err) => reject('History failed'));
        });

        const getiOSApps = new Promise((resolve, reject) => {
            api.get(url.IOSAPPS, { page: 1, size: 100 }, domains.IOS_V1)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp?.data) resolve(resp);
                    else reject('Apps failed.');
                })
                .catch((_err) => reject('Apps failed'));
        });

        Promise.allSettled([devicePromise, infoPromise, policyPromise, getDeviceGroups, actionHistyPromise, getiOSApps])
            .then((result) => {
                let groupArr = [];
                let deviceData = {};
                if (result[0].status === 'fulfilled') {
                    deviceData = { ...result[0].value };
                    deviceData.platform = deviceData?.hardware?.devicetype || '—';
                    deviceData.serial = deviceData?.refs?.find((ref) => ref.type === 'SERIAL')?.value;
                    deviceData.showActions = deviceData?.enroll?.status === 'ACTIVE';
                    deviceData.showActiationLock = true;
                }
                if (result[1].status === 'fulfilled') {
                    setDeviceName(result?.[1]?.value?.report?.QueryResponses?.DeviceName);
                    deviceData.deviceInfo = { ...result[1].value };
                }
                if (result[2].status === 'fulfilled') {
                    let polObj = result?.[2]?.value?.find((pol) => pol.code === result[0]?.value?.assignment?.policy);
                    if (polObj) result[0].value.assignment.policyName = polObj?.name + ' - ' + polObj?.code;
                    setPolicy(polObj);
                    setPolicyBackup(polObj);
                    setPolicyOptions(result[2].value);
                }
                if (result[3].status === 'fulfilled') {
                    if (result[3].value?.length > 0) {
                        result[3].value.forEach((group) => {
                            groupArr.push(group);
                            group.label = group.name;
                            group.key = group.code;
                            group.value = group.code;
                            group.nameCode = group.name + ' - ' + group.code;
                            if (group.children) {
                                group.children = setChildGroups(group.children);
                                groupArr = setGroups(group.children, groupArr);
                            }
                            group.childsCount = group.children.length ? group.children.length : 0;
                        });
                        let defGroup = handleDeviceGroup(groupArr, result?.[0]?.value?.assignment?.group);
                        result[0].value.assignment.groupName = defGroup ? defGroup?.name + ' - ' + defGroup?.code : undefined;
                        setDefaultGroup(defGroup);
                        setDeviceGroupsOptions(result[3].value);
                        setDeviceGroupsOptionsBackup(result[3].value);
                    }
                }
                if (result[4].status === 'fulfilled') {
                    result[4].value?.data?.forEach((value) => {
                        value.command =
                            value?.params?.Settings?.[0]?.Item === 'Wallpaper' || value?.params?.Item === 'Wallpaper'
                                ? 'Wallpaper'
                                : value?.params?.Settings?.[0]?.Item === 'DeviceName'
                                ? 'DeviceName'
                                : value?.params?.Settings?.[0]?.MDMOptions?.ActivationLockAllowedWhileSupervised === true ||
                                  value?.params?.Settings?.[0]?.MDMOptions?.ActivationLockAllowedWhileSupervised === false
                                ? 'ActivationLock'
                                : value.command;
                        value.class = value.status === 'NEW' ? 'primary' : value.status === 'DONE' ? 'success' : 'danger';
                    });
                    setCommandPage({ ...commandPage, length: result[4]?.value?.totalRecords });
                    setCommandHistory(result[4].value?.data);
                }
                if (result[5].status === 'fulfilled') {
                    setAppsList(result[5]?.value?.data);
                }
                setIosDetails(deviceData);
                setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    const handleDeviceGroup = (groupArr, groupCode) => {
        let deviceGroupObj = groupArr.find((group) => group.code === groupCode);
        return deviceGroupObj;
    };

    const getCommandHistory = (params) => {
        api.get(url.IOS_COMMANDS, params, domains.IOS_V1)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp?.data) {
                    resp?.data?.forEach((value) => {
                        value.command =
                            value?.params?.Settings?.[0]?.Item === 'Wallpaper' || value?.params?.Item === 'Wallpaper'
                                ? 'Wallpaper'
                                : value?.params?.Settings?.[0]?.Item === 'DeviceName'
                                ? 'DeviceName'
                                : value?.params?.Settings?.[0]?.MDMOptions?.ActivationLockAllowedWhileSupervised === true ||
                                  value?.params?.Settings?.[0]?.MDMOptions?.ActivationLockAllowedWhileSupervised === false
                                ? 'ActivationLock'
                                : value.command;
                        value.class = value.status === 'NEW' ? 'primary' : value.status === 'DONE' ? 'success' : 'danger';
                    });
                    params = { ...params, length: resp?.totalRecords };
                    setCommandHistory(resp?.data);
                }
                setLoading(false);
            })
            .catch((err) => setLoading(false))
            .finally(() => {
                setCommandPage(params);
            });
    };

    const actionItems = [
        {
            id: 'reboot',
            label: 'Reboot',
            show:
                !!tenantConfig?.IOS_DEVICE_ACTIONS_MONITORING?.includes('REBOOT') &&
                ['ipad', 'iphone', 'mac']?.includes(iosDetails.platform?.toLowerCase()),
            disabled: iosDetails?.platform?.toLowerCase() === 'iphone' ? !iosDetails?.enroll?.issupervised : false,
            disabledTooltip: 'This device is not supervised.',
            icon: 'ri-creative-commons-sa-line',
            clickFunction: () => {
                setModalData({
                    message: 'Are you sure you want to reboot Device?',
                    image: rebootDevice,
                    actionBody: { command: 'RestartDevice', params: { RequestType: 'RestartDevice' } },
                    toastMessage: toastMessages.rebootDevice
                });
                setType('reboot');
                setActionModal(true);
                setActionText('Yes, Reboot it!');
            },
            condition: iosDetails.showActions
        },
        {
            id: 'lock',
            label: 'Lock',
            show:
                !!tenantConfig?.IOS_DEVICE_ACTIONS_MONITORING?.includes('LOCK') &&
                ['ipad', 'iphone', 'mac']?.includes(iosDetails.platform?.toLowerCase()),
            icon: 'ri-phone-lock-line',
            clickFunction: () => {
                setModalData({
                    message: 'Are you sure you want to lock Device?',
                    image: lockDevice,
                    actionBody: {
                        command: 'DeviceLock',
                        params:
                            iosDetails?.platform?.toLowerCase() === 'mac'
                                ? {
                                      RequestType: 'DeviceLock',
                                      Message: 'Locked by your TT Admin',
                                      PIN: JSON.stringify(validation?.values?.pin),
                                      PhoneNumber: JSON.stringify(validation?.values?.phoneNumber)
                                  }
                                : { RequestType: 'DeviceLock', Message: 'Locked by your TT Admin' }
                    },
                    toastMessage: toastMessages.lockDevice
                });
                setType('lock');
                setActionModal(true);
                setActionText('Yes, Lock it!');
            },
            condition: iosDetails.showActions
        },
        {
            id: 'shutdown',
            label: 'Shutdown',
            disabled: iosDetails?.platform?.toLowerCase() === 'iphone' ? !iosDetails?.enroll?.issupervised : false,
            disabledTooltip: 'This device is not supervised.',
            show:
                !!tenantConfig?.IOS_DEVICE_ACTIONS_MONITORING?.includes('SHUTDOWN') &&
                ['ipad', 'iphone', 'mac']?.includes(iosDetails.platform?.toLowerCase()),
            icon: 'ri-shut-down-line',
            clickFunction: () => {
                setModalData({
                    message: 'Are you sure you want to shutdown Device?',
                    image: shutdown,
                    actionBody: { command: 'ShutDownDevice', params: { RequestType: 'ShutDownDevice' } },
                    toastMessage: toastMessages.shutdownDevice
                });
                setType('shutdonw');
                setActionModal(true);
                setActionText('Yes, Shut it down!');
            },
            condition: iosDetails.showActions
        },
        {
            id: 'lost_mode_sound',
            label: 'Lost Mode Sound',
            icon: 'ri-volume-up-line',
            disabled: !iosDetails?.enroll?.issupervised,
            disabledTooltip: 'This device is not supervised.',
            clickFunction: () => handleLostModeClick('lost_mode_sound', 'Lost Mode Sound'),
            condition: iosDetails.showActions,
            show:
                !!tenantConfig?.IOS_DEVICE_ACTIONS_MONITORING?.includes('LOST_MODE_SOUND') &&
                ['ipad', 'iphone']?.includes(iosDetails.platform?.toLowerCase())
        },
        {
            id: 'lost_mode',
            label: 'Lost Mode',
            disabled: !iosDetails?.enroll?.issupervised,
            disabledTooltip: 'This device is not supervised.',
            icon: 'ri-phone-find-line',
            clickFunction: () => handleLostModeClick('lost_mode', 'Lost Mode'),
            condition: iosDetails.showActions,
            show:
                !!tenantConfig?.IOS_DEVICE_ACTIONS_MONITORING?.includes('LOST_MODE') &&
                ['ipad', 'iphone']?.includes(iosDetails.platform?.toLowerCase())
        },
        {
            id: 'set_wallpaper',
            label: 'Wallpaper',
            icon: 'ri-image-line',
            clickFunction: () => handleWallpaper('set_wallpaper', 'Wallpaper'),
            condition: iosDetails.showActions,
            show:
                !!tenantConfig?.IOS_DEVICE_ACTIONS_MONITORING?.includes('SET_WALLPAPER') &&
                ['ipad', 'iphone', 'mac']?.includes(iosDetails.platform?.toLowerCase())
        },
        {
            id: 'app_install',
            label: 'App Install',
            icon: 'ri-install-line',
            clickFunction: () => handleAppClick('app_install', 'App Install'),
            condition: iosDetails.showActions,
            show:
                !!tenantConfig?.IOS_DEVICE_ACTIONS_MONITORING?.includes('APP_INSTALL') &&
                ['ipad', 'iphone', 'mac']?.includes(iosDetails.platform?.toLowerCase())
        },
        {
            id: 'app_uninstall',
            label: 'App Uninstall',
            icon: 'ri-uninstall-line',
            clickFunction: () => handleAppClick('app_uninstall', 'App Uninstall'),
            condition: iosDetails.showActions,
            show:
                !!tenantConfig?.IOS_DEVICE_ACTIONS_MONITORING?.includes('APP_UNINSTALL') &&
                ['ipad', 'iphone', 'mac']?.includes(iosDetails.platform?.toLowerCase())
        },
        {
            id: 'activation_lock',
            label: 'Activation Lock Bypass Code',
            icon: 'ri-lock-unlock-line',
            disabled: iosDetails?.platform?.toLowerCase() === 'iphone' ? !iosDetails?.enroll?.issupervised : false,
            disabledTooltip: 'This device is not supervised.',
            clickFunction: () => handleActivationLockClick('activation_lock', 'Activation Lock Bypass Code'),
            condition: iosDetails.showActiationLock,
            show:
                !!tenantConfig?.IOS_DEVICE_ACTIONS_MONITORING?.includes('ACTIVATION_LOCK') &&
                ['ipad', 'iphone', 'mac']?.includes(iosDetails.platform?.toLowerCase())
        },
        {
            id: 'erase_data',
            label: 'Erase Data',
            show:
                !!tenantConfig?.IOS_DEVICE_ACTIONS_MONITORING?.includes('ERASE_DEVICE') &&
                ['ipad', 'iphone', 'mac']?.includes(iosDetails.platform?.toLowerCase()),
            icon: 'ri-settings-5-line',
            clickFunction: () => {
                setModalData({
                    message: 'Are you sure you want to erase data?',
                    image: factoryReset,
                    actionBody: {
                        command: 'EraseDevice',
                        params:
                            iosDetails?.platform?.toLowerCase() === 'mac'
                                ? {
                                      RequestType: 'EraseDevice',
                                      PIN: validation?.values?.pin?.toString()
                                      //   ObliterationBehavior: validation?.values?.obliterationBehavior?.value
                                  }
                                : { RequestType: 'EraseDevice' }
                    },
                    toastMessage: toastMessages.eraseDevice
                });
                setType('erase_data');
                setActionModal(true);
                setActionText('Yes, Erase Data!');
            },
            condition: iosDetails.showActions
        },
        {
            id: 'unenroll',
            icon: 'ri-link-unlink-m',
            label: 'Unenroll',
            show: !!tenantConfig?.IOS_DEVICE_ACTIONS_MONITORING?.includes('UNENROLL'),
            clickFunction: () => {
                setModalData({
                    message: 'Are you sure you want to remove profile?',
                    image: unenroll,
                    actionBody: {
                        command: 'RemoveProfile',
                        params: {
                            RequestType: 'RemoveProfile'
                        }
                    },
                    toastMessage: toastMessages.unenrollDevice
                });
                setType('unenroll');
                setActionModal(true);
                setActionText('Yes, Remove it!');
            },
            condition: iosDetails.showActions
        },
        {
            id: 'sync',
            icon: 'ri-device-recover-line',
            label: 'Sync Policy',
            show:
                !!tenantConfig?.IOS_DEVICE_ACTIONS_MONITORING?.includes('SYNC_POLICY') &&
                ['ipad', 'iphone', 'mac']?.includes(iosDetails.platform?.toLowerCase()),
            clickFunction: () => {
                setModalData({
                    message: 'Are you sure you want to sync policy on this Device?',
                    image: deviceSync,
                    actionBody: '',
                    toastMessage: toastMessages.deviceSync
                });
                setType('sync_policy');
                setActionModal(true);
                setActionText('Yes, Sync it!');
            },
            condition: iosDetails.showActions
        },
        {
            id: 'refresh',
            label: 'Refresh',
            show:
                !!tenantConfig?.IOS_DEVICE_ACTIONS_MONITORING?.includes('REFRESH') &&
                ['ipad', 'iphone', 'mac']?.includes(iosDetails.platform?.toLowerCase()),
            icon: 'ri-refresh-line',
            clickFunction: () => {
                setModalData({
                    message: 'Are you sure you want to Refresh Device?',
                    image: syncDevice,
                    actionBody: '',
                    toastMessage: toastMessages.refreshDevice
                });
                setType('refresh');
                setActionModal(true);
                setActionText('Yes, Refresh it!');
            },
            condition: iosDetails.showActions
        }
    ];

    const setChildGroups = (groupArr, groupStatus) => {
        groupArr.forEach((group) => {
            group.label = group.name;
            group.key = group.code;
            group.value = group.code;
            group.nameCode = group.name + ' - ' + group.code;
            group.deviceCount = groupStatus?.[group.code] ? groupStatus?.[group.code] : 0;
            if (group.children) group.children = setChildGroups(group.children, groupStatus);
            group.childsCount = group.children.length ? group.children.length : 0;
        });
        return groupArr;
    };

    const setGroups = (groups, arr) => {
        groups.forEach((group) => {
            arr.push(group);
            if (group.children) arr = setGroups(group.children, arr);
        });
        return arr;
    };

    const onSubmitPolicy = () => {
        setLoading(true);
        setShowPolicyDropdown(false);
        api.patch(url.DEVICES + '/' + recordID, { 'assignment.policy': policy.code }, false, domains.IOS_V1)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    setShowPolicyModal(false);
                }
                handlePromise();
            })
            .catch((_err) => setLoading(false));
    };

    const onChangeGroup = () => {
        setLoading(true);
        setDeviceGroupsOptions(deviceGroupOptionsBackup);
        setSubBreadcrumbItems([{ name: 'Main Group' }]);
        setShowDeviceGroup(false);
        api.patch(url.DEVICES + '/' + recordID, { 'assignment.group': selectedDeviceGroup.code }, false, domains.IOS_V1)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    handlePromise();
                }
                setLoading(false);
            })
            .catch((err) => setLoading(false));
    };
    const onChangeUser = () => {
        setLoading(true);
        setShowUserInput(false);
        api.patch(url.DEVICES + '/' + recordID, { 'assignment.user': selectedUser.username }, false, domains.IOS_V1)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    handlePromise();
                }
                setLoading(false);
            })
            .catch((err) => setLoading(false))
            .finally(() => setSelectedUser(''));
    };

    const onCancelPolicy = () => {
        setPolicy(policyBackup);
        setShowPolicyDropdown(false);
    };

    const toggle1 = useCallback(async () => {
        setDeviceGroupsOptions(deviceGroupOptionsBackup);
        setSubBreadcrumbItems([{ name: 'Main Group' }]);
        setSelectedDeviceGroup({ ...defaultGroup });
        setShowDeviceGroup(!showDeviceGroup);
    }, [showDeviceGroup]);

    const toggle2 = useCallback(async () => {
        setSelectedUser({ ...defaultUser });
        setShowUserInput(false);
    }, [showUserInput]);

    const handleSubBreadcrumb = (group, ind) => {
        if (ind !== subBreadcrumbItems.length - 1) {
            let crumbs = [...subBreadcrumbItems];
            crumbs.splice(ind + 1);
            setSubBreadcrumbItems(crumbs);
            if (!group.key) {
                setSelectedDeviceGroup({});
                let groupsArr = JSON.parse(JSON.stringify(deviceGroupOptionsBackup));
                groupsArr.forEach((groupObj) => delete groupObj.checked);
                setDeviceGroupsOptions(groupsArr);
            } else {
                setSelectedDeviceGroup(group);
                let groupsArr = JSON.parse(JSON.stringify(group.children));
                groupsArr.forEach((groupObj) => delete groupObj.checked);
                setDeviceGroupsOptions(groupsArr);
            }
        }
    };

    const handleMoveView = (_cell, row) => {
        setSelectedDeviceGroup(row.original);
        setSubBreadcrumbItems([...subBreadcrumbItems, row.original]);
        setDeviceGroupsOptions(row.original.children ? row.original.children : []);
    };

    const actionService = () => {
        setLoading(true);
        validation.setErrors({});

        const body = { ...modalData?.actionBody, device: recordID };
        let apiService;
        if (type === 'lock' && iosDetails?.platform?.toLowerCase() === 'mac') {
            body.params.PIN = validation.values.pin?.toString();
            body.params.PhoneNumber = validation.values?.phoneNumber?.toString();
        } else if (type === 'erase_data' && iosDetails?.platform?.toLowerCase() === 'mac') {
            body.params.PIN = validation?.values?.pin?.toString();
            // body.params.ObliterationBehavior = validation?.values?.obliterationBehavior?.value;
        }
        setActionModal(false);
        if (type === 'refresh') {
            apiService = api.patch(url.DEVICES + '/' + recordID + '/syncinfo', '', false, domains.IOS_V1);
        } else if (type === 'sync_policy') {
            apiService = api.patch(url.DEVICES + '/' + recordID + '/syncpolicy', '', false, domains.IOS_V1);
        } else {
            apiService = api.create(url.IOS_COMMANDS, body, false, domains.IOS_V1);
        }
        apiService
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    toast.success(modalData?.toastMessage);
                    handlePromise();
                } else setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    const selectComponent = (key) => {
        return (
            <React.Fragment>
                <Select
                    getOptionValue={(option) => option.code}
                    getOptionLabel={(option) => option.name}
                    id={key}
                    className="w-100"
                    name={'name'}
                    options={policyOptions}
                    value={policy}
                    isSearchable={true}
                    placeholder={`Select ${key}`}
                    classNamePrefix={'custom-select'}
                    onChange={(e) => setPolicy(e)}
                    noOptionsMessage={() => 'No data found'}
                />
                <i title="Close" className="ri-close-line ms-2 me-2 fs-18 cursor-pointer link-danger" onClick={() => onCancelPolicy()} />
                <i title="Submit" className="ri-check-line fs-18 cursor-pointer link-primary" onClick={() => setShowPolicyModal(true)} />
            </React.Fragment>
        );
    };

    const handlePhoneChange = (e) => {
        let regex = new RegExp(/^[0-9]+$/);
        let num = Number(e.target.value);
        let valid = regex.test(num);
        if (valid && e.target.value?.length <= 10) {
            setPhoneNumber(e.target.value);
        }
    };

    const onCancelPhone = () => {
        setPhoneNumber(iosDetails?.deviceSummary?.phone);
        setShowPhoneInput(false);
    };

    const onSubmitPhone = () => {
        setLoading(true);
        setShowPhoneInput(false);
    };

    const handleDeviceNameChange = (e) => {
        if (e.target.value?.length === 0) {
            setDeviceName(e.target.value);
            return;
        }
        let regex = new RegExp(/^[0-9a-zA-Z'\s]+$/);
        let valid = regex.test(e.target.value);
        if (valid && e.target.value?.length <= 50) {
            setDeviceName(e.target.value);
        }
    };

    const onCancelDeviceName = () => {
        setDeviceName(iosDetails?.deviceInfo?.report?.QueryResponses?.DeviceName);
        setShowDeviceInput(false);
    };

    const onSubmitDeviceName = () => {
        setLoading(true);
        setShowDeviceInput(false);

        let params = {
            device: recordID,
            command: 'Settings',
            params: { RequestType: 'Settings', Settings: [{ Item: 'DeviceName', DeviceName: deviceName }] }
        };

        api.create(url.IOS_COMMANDS, params, false, domains.IOS_V1)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    toast.success(toastMessages.deviceNameMsg);
                    handlePromise();
                }
                setLoading(false);
            })
            .catch((_err) => setLoading(false));
    };

    const inputComponent = (key) => {
        return (
            <React.Fragment>
                <Input
                    name={'phoneNumber'}
                    id={'phoneNumber'}
                    className="form-control"
                    placeholder={`Enter ${key}`}
                    type={'text'}
                    validate={{ required: { value: true } }}
                    onChange={(e) => handlePhoneChange(e)}
                    value={phoneNumber}
                />
                <i title="Close" className="ri-close-line ms-2 me-2 fs-18 cursor-pointer link-danger" onClick={() => onCancelPhone()} />
                <i title="Submit" className="ri-check-line fs-18 cursor-pointer link-primary" onClick={() => onSubmitPhone()} />
            </React.Fragment>
        );
    };

    const inputComponentDeviceName = (key) => {
        return (
            <React.Fragment>
                <Input
                    name={'deviceName'}
                    id={'deviceName'}
                    className="form-control"
                    placeholder={`Enter ${key}`}
                    type={'text'}
                    validate={{ required: { value: true } }}
                    onChange={(e) => handleDeviceNameChange(e)}
                    value={deviceName}
                />
                <i
                    title="Close"
                    className="ri-close-line ms-2 me-2 fs-18 cursor-pointer link-danger"
                    onClick={() => onCancelDeviceName()}
                />
                {deviceName !== '' && (
                    <i title="Submit" className="ri-check-line fs-18 cursor-pointer link-primary" onClick={() => onSubmitDeviceName()} />
                )}
            </React.Fragment>
        );
    };

    const handleUsersGet = (params) => {
        setLoading(true);
        api.get(url.USERS, params, domains.IDM)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    resp.data?.forEach((data) => {
                        if (data.username === iosDetails?.assignment?.user) setDefaultUser(data);
                    });
                    setUser(resp);
                }
                setLoading(false);
            })
            .catch((_err) => setLoading(false));
    };

    const handleUserChange = () => {
        handleUsersGet({ page: 1, size: 10 });
        setShowUserInput(true);
    };

    const returnData = (key, val, edit) => {
        return edit && key === 'Device Group' ? (
            <tr>
                <td className="fw-medium w-50 py-2">
                    <div className="d-flex align-items-center">{key}</div>
                </td>
                <td className="w-50 py-2 word-wrap-break">
                    <div className="d-flex align-items-center">
                        {val ? val : '—'}
                        <IsAuthorized
                            privRequired={priviliges.IOSDEVICE_EDITOR}
                            yes={() => (
                                <i
                                    title="Edit"
                                    className="ri-edit-box-line ps-4 fs-18 cursor-pointer link-secondary"
                                    onClick={() => setShowDeviceGroup(true)}
                                />
                            )}
                        />
                    </div>
                </td>
            </tr>
        ) : edit && key === 'Policy' ? (
            <tr>
                <td className="fw-medium w-50 py-2">
                    <div className="d-flex align-items-center">{key}</div>
                </td>
                <td className="w-50 py-2 word-wrap-break">
                    <div className="d-flex align-items-center">
                        {!showPolicyDropdown ? (
                            <>
                                {val ? val : '—'}
                                <IsAuthorized
                                    privRequired={priviliges.IOSDEVICE_EDITOR}
                                    yes={() => (
                                        <i
                                            title="Edit"
                                            className="ri-edit-box-line ps-4 fs-18 cursor-pointer link-secondary"
                                            onClick={() => setShowPolicyDropdown(true)}
                                        />
                                    )}
                                />
                            </>
                        ) : (
                            selectComponent(key)
                        )}
                    </div>
                </td>
            </tr>
        ) : edit && key === 'Mobile Number' ? (
            <tr>
                <td className="fw-medium w-50 py-2">
                    <div className="d-flex align-items-center">{key}</div>
                </td>
                <td className="w-50 py-2 word-wrap-break">
                    <div className="d-flex align-items-center">
                        {!showPhoneInput ? (
                            <>
                                {val ? val : '—'}
                                <IsAuthorized
                                    privRequired={priviliges.IOSDEVICE_EDITOR}
                                    yes={() => (
                                        <i
                                            title="Edit"
                                            className="ri-edit-box-line ps-4 fs-18 cursor-pointer link-secondary"
                                            onClick={() => setShowPhoneInput(true)}
                                        />
                                    )}
                                />
                            </>
                        ) : (
                            inputComponent(key)
                        )}
                    </div>
                </td>
            </tr>
        ) : edit && key === 'Device Name' ? (
            <tr>
                <td className="fw-medium w-50 py-2">
                    <div className="d-flex align-items-center">{key}</div>
                </td>
                <td className="w-50 py-2 word-wrap-break">
                    <div className="d-flex align-items-center">
                        {!showDeviceInput ? (
                            <>
                                {val ? val : '—'}
                                <IsAuthorized
                                    privRequired={priviliges.IOSDEVICE_EDITOR}
                                    yes={() => (
                                        <i
                                            title="Edit"
                                            className="ri-edit-box-line ps-4 fs-18 cursor-pointer link-secondary"
                                            onClick={() => setShowDeviceInput(true)}
                                        />
                                    )}
                                />
                            </>
                        ) : (
                            inputComponentDeviceName(key)
                        )}
                    </div>
                </td>
            </tr>
        ) : edit && key === 'User Name' ? (
            <tr>
                <td className="fw-medium w-50 py-2">
                    <div className="d-flex align-items-center">{key}</div>
                </td>
                <td className="w-50 py-2 word-wrap-break">
                    <div className="d-flex align-items-center">
                        {val ? val : '—'}
                        <IsAuthorized
                            privRequired={priviliges.IOSDEVICE_EDITOR}
                            yes={() => (
                                <i
                                    title="Edit"
                                    className="ri-edit-box-line ps-4 fs-18 cursor-pointer link-secondary"
                                    onClick={handleUserChange}
                                />
                            )}
                        />
                    </div>
                </td>
            </tr>
        ) : (
            <tr>
                <td className="fw-medium w-50 py-2">
                    {key === 'Total internal storage' ? <div className="d-flex align-items-center h-100">{key + ' '}</div> : key}
                </td>
                <td className="text-grey-text fw-medium w-50 py-2 word-wrap-break">
                    <div className="d-flex align-items-center">{val ? val : '—'}</div>
                </td>
            </tr>
        );
    };

    const deviceGroupBody = () => {
        return (
            <React.Fragment>
                <div className={'mt-5 mb-4 d-flex align-items-center'}>
                    {subBreadcrumbItems?.map((crumbs, ind) => (
                        <React.Fragment key={ind}>
                            {ind !== subBreadcrumbItems?.length - 1 ? (
                                <span
                                    className={`fw-medium font-size-12 text-muted ${
                                        ind !== subBreadcrumbItems?.length - 1 ? ' cursor-pointer' : ''
                                    }`}
                                    onClick={() => handleSubBreadcrumb(crumbs, ind)}
                                >
                                    {crumbs.name}
                                </span>
                            ) : (
                                <span
                                    className={`fw-medium font-size-12 ${ind !== subBreadcrumbItems?.length - 1 ? ' cursor-pointer' : ''}`}
                                    style={{ color: crumbs.avatarColor }}
                                >
                                    {crumbs.name}
                                </span>
                            )}
                            {ind !== subBreadcrumbItems?.length - 1 && <i className="ri-arrow-right-s-line ms-2" />}
                        </React.Fragment>
                    ))}
                </div>
                <TableContainer
                    loading={loading}
                    data={deviceGroupOptions}
                    columns={moveGroupsSchema}
                    handleView={handleMoveView}
                    hideAvatar={true}
                    customPageSize={10}
                    totalRecords={deviceGroupOptions.length ? deviceGroupOptions.length : 0}
                    editor={priviliges.DEVICEGROUP_EDITOR}
                    reader={priviliges.DEVICEGROUP_READER}
                    className="custom-header-css"
                    tableHeader="All Device Groups"
                    divClass="table-responsive table-card"
                    tableClass="table table-nowrap table-border table-centered align-middle"
                    theadClass="bg-light text-muted"
                />
            </React.Fragment>
        );
    };

    const handleUserSearch = (val) => {
        handleUsersGet({ page: user.page, size: user.size, username: val });
    };

    const handlePageChange = (page) => {
        handleUsersGet({ page: page, size: user.size });
    };

    const handleSelectUser = (e, row) => {
        let users = [...user.data];
        users.forEach((userObj) => {
            if (userObj._id === row.original._id) {
                userObj.checked = e.target.checked;
                if (e.target.checked) setSelectedUser(userObj);
            } else userObj.checked = false;
        });

        setUser({ ...user, data: users });
    };

    const deviceUserBody = () => {
        return (
            <TableContainer
                loading={loading}
                isGlobalFilter={true}
                tableHeader={'All Users'}
                totalRecords={user.totalRecords}
                handleGlobalSearch={handleUserSearch}
                handleRadioChange={handleSelectUser}
                columns={usersTableSchema}
                data={user.data}
                pageNum={Number(user.page)}
                customPageSize={user.size}
                onPageChange={handlePageChange}
                editor={priviliges.USER_EDITOR}
                deletor={priviliges.USER_EDITOR}
                reader={priviliges.USER_READER}
                className="custom-header-css"
                divClass="table-responsive table-card"
                tableClass="table table-nowrap table-border table-centered align-middle"
                theadClass="bg-light text-muted"
            />
        );
    };

    useEffect(() => {
        if (!actionModal) {
            validation.resetForm();
        }
    }, [actionModal]);

    const actionMessage = () => {
        return (
            <React.Fragment>
                <div className="mt-3">
                    <div className="mb-4">{modalData.message}</div>
                    <div className="mb-4 d-flex align-items-center justify-content-center">
                        <img src={modalData.image} alt="device" width={60} height={60} />
                    </div>
                    <div>
                        Serial Number: <span className="fw-semibold">{iosDetails.serial}</span>
                    </div>
                </div>
                {iosDetails?.platform?.toLowerCase() === 'mac' && (type === 'lock' || type === 'erase_data') && (
                    <InputComponent
                        field={{ label: 'Pin', value: 'pin', type: 'number', mandatory: true, placeholder: 'Enter The Pin' }}
                        validation={validation}
                    />
                )}
                {iosDetails?.platform?.toLowerCase() === 'mac' && type === 'lock' && (
                    <InputComponent
                        field={{
                            label: 'Phone Number',
                            value: 'phoneNumber',
                            mandatory: true,
                            type: 'number',
                            placeholder: 'Enter Phone Number'
                        }}
                        validation={validation}
                    />
                )}
                {/* {iosDetails?.platform?.toLowerCase() === 'mac' && type === 'erase_data' && (
                    <Row className="mt-2 flex-row align-items-center">
                        <Col xs={12} md={12} sm={5} lg={5} xl={5}>
                            <Label className="mb-0 fs-14 fw-medium d-flex jusify-content-start">ObliterationBehavior</Label>
                        </Col>
                        <Col xs={12} md={12} sm={7} lg={7} xl={7}>
                            <Select
                                getOptionValue={(option) => option.value}
                                getOptionLabel={(option) => option.label}
                                name="obliterationBehavior"
                                options={[
                                    { label: 'Default', value: 'default' },
                                    { label: 'DoNotObliterate', value: 'doNotObliterate' },
                                    { label: 'ObliterateWithWarning', value: 'obliterateWithWarning' },
                                    { label: 'Always', value: 'always' }
                                ]}
                                placeholder="Select"
                                onChange={(selectedOption) => {
                                    validation.handleChange({ target: { name: 'obliterationBehavior', value: selectedOption } });
                                }}
                                value={validation?.values['obliterationBehavior'] || ''}
                                isSearchable={true}
                                noOptionsMessage={() => 'No data found'}
                            />
                        </Col>
                    </Row>
                )} */}
            </React.Fragment>
        );
    };

    const commandReturnData = (command, ind) => {
        return (
            <tr className="w-100" key={ind}>
                <td className="fw-medium w-70">
                    <div className="d-flex align-items-center text-truncate">
                        <i
                            title={commands[command.command]?.label}
                            className={`${commands[command.command]?.icon} me-2 fs-18 cursor-pointer link-secondary`}
                        />
                        {commands[command.command]?.label}
                    </div>
                </td>
                <td className="w-30 word-wrap-break m-0">
                    <span className={'lh-sm fw-semibold text-truncate text-' + command?.class}>
                        {command.status?.substr(0, 1) + command.status?.substr(1)?.toLowerCase()}
                    </span>
                </td>
                <td className="text-info w-30 text-truncate word-wrap-break m-0" id={`reqdate-${ind}`}>
                    {command.reqdate ? getDate(command.reqdate) : '—'}
                </td>
            </tr>
        );
    };

    const handleCommandPageChange = (page) => {
        setLoading(true);
        getCommandHistory({ ...commandPage, page: page });
    };

    const toggleCommonModal = useCallback(() => {
        setCommonModal({ ...commonModal, show: !commonModal.show });
        setLostMode({ phone: '', message: '', footNote: '', networkTether: false, enabled: false });
        validation.setValues({ type: 'user' });
        setSelectedApp('');
    }, [commonModal]);

    const handleCommonModalConfirm = () => {
        setCommonModal({ show: false, header: '', type: '' });
        setLoading(true);
        let apiService;
        let params = { device: recordID };
        if (commonModal.type === 'lost_mode') {
            params = {
                ...params,
                command: lostMode.enabled ? 'EnableLostMode' : 'DisableLostMode',
                params: {
                    RequestType: lostMode.enabled ? 'EnableLostMode' : 'DisableLostMode',
                    RequestRequiresNetworkTether: !!lostMode.networkTether
                }
            };
            if (lostMode.enabled)
                params.params = { ...params.params, PhoneNumber: lostMode.phone, Message: lostMode.message, Footnote: lostMode.message };
            apiService = api.create(url.IOS_COMMANDS, params, false, domains.IOS_V1);
        } else if (commonModal.type === 'lost_mode_sound') {
            params = {
                ...params,
                command: 'PlayLostModeSound',
                params: { RequestType: 'PlayLostModeSound', RequestRequiresNetworkTether: lostMode.networkTether }
            };
            apiService = api.create(url.IOS_COMMANDS, params, false, domains.IOS_V1);
        } else if (commonModal.type === 'app_install') {
            params = {
                ...params,
                command: 'InstallApplication',
                params: {
                    RequestType: 'InstallApplication',
                    InstallAsManaged: true,
                    ManagementFlags: 5,
                    Options: { PurchaseMethod: 1 },
                    ...(selectedApp.type === 'STORE' ? { iTunesStoreID: Number(selectedApp.adamid) } : { ManifestURL: selectedApp.url })
                }
            };
            apiService = api.create(url.IOS_COMMANDS, params, false, domains.IOS_V1);
        } else if (commonModal.type === 'app_uninstall') {
            params = {
                ...params,
                command: 'RemoveApplication',
                params: { RequestType: 'RemoveApplication', Identifier: selectedApp.packagename }
            };
            apiService = api.create(url.IOS_COMMANDS, params, false, domains.IOS_V1);
        } else if (commonModal.type === 'set_wallpaper') {
            params = {
                ...params,
                command: 'Settings',
                params: {
                    RequestType: 'Settings',
                    Settings: [{ Item: 'Wallpaper', Where: 3, Image: validation.values.wallpaper }]
                }
            };
            apiService = api.create(url.IOS_COMMANDS, params, false, domains.IOS_V1);
        } else if (commonModal.type === 'activation_lock') {
            apiService = api.patch(url.ACTIVATION_LOCK + '/' + iosDetails?._id + '/activationlock', '', false, domains.IOS_V1);
        }

        apiService
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    toast.success(successToastMsg());
                    setLostMode({ phone: '', message: '', footNote: '', networkTether: false, enabled: false });
                    setSelectedApp('');
                    handlePromise();
                }
                setLoading(false);
            })
            .catch((_err) => setLoading(false));
    };

    const successToastMsg = () => {
        let message;
        switch (commonModal.type) {
            case 'lost_mode':
                if (lostMode.enabled) {
                    message = toastMessages.lostModeEnabled;
                } else {
                    message = toastMessages.lostModeDisabled;
                }
                break;
            case 'lost_mode_sound':
                message = toastMessages.lostModeSoundInitiated;
                break;
            case 'app_install':
                message = toastMessages.appInstallMsg;
                break;
            case 'app_uninstall':
                message = toastMessages.appRemoveMsg;
                break;
            case 'set_wallpaper':
                message = toastMessages.wallpaperMsg;
                break;
            case 'activation_lock':
                message = toastMessages.activationLock;
                break;
            default:
                message = toastMessages.actionSuccessMsg;
        }
        return message;
    };

    const handleLostModeDetails = (e, key) => {
        if (key === 'phone') {
            let regex = new RegExp(/[0-9]/g);
            let test = regex.test(e.target.value);
            if (!test) return;
        }
        setLostMode({ ...lostMode, [key]: e.target.value });
    };

    const handleBadge = (option) => {
        let arr = [];
        option.platforms?.forEach((platform) => {
            arr.push(<span className="fs-10 d-grid place-items-center rounded-2 px-2 bg-soft-warning">{platform}</span>);
        });
        return arr;
    };

    const handleCommonModalBody = () => {
        if (commonModal.type === 'lost_mode') {
            return (
                <React.Fragment>
                    <div className="mt-3 d-flex align-items-center justify-content-center flex-column">
                        {/* <div className="mb-4 d-flex align-items-center justify-content-center">
                        <img src={factoryModeImage} alt="factory_mode" width={60} height={60} />
                    </div> */}
                        <div className="mb-4">Are you sure you want to {lostMode.enabled ? 'enable' : 'disable'} lost mode?</div>
                        <div className="mb-4 d-flex align-items-center justify-content-center">
                            <div className="p-1 d-flex align-items-center border border-1 border-info rounded-3">
                                <div
                                    className={`${
                                        !lostMode.enabled ? 'bg-info text-light' : 'bg-light text-dark'
                                    } w-50 px-2 py-1 cursor-pointer`}
                                    onClick={() => {
                                        setLostMode({ ...lostMode, enabled: false });
                                        setActionText('Yes, Disable it!');
                                    }}
                                >
                                    Disable
                                </div>
                                <div
                                    className={`${
                                        lostMode.enabled ? 'bg-info text-light' : 'bg-light text-dark'
                                    } w-50 px-2 py-1 cursor-pointer`}
                                    onClick={() => {
                                        setLostMode({ ...lostMode, enabled: true });
                                        setActionText('Yes, Enable it!');
                                    }}
                                >
                                    Enable
                                </div>
                            </div>
                        </div>
                    </div>
                    <Row className="d-flex align-items-center justify-content-center">
                        <Col sm={12} md={lostMode.enabled ? 6 : 5}>
                            <Label className={'mb-0 fw-medium'}>Require Network Tether :</Label>
                            {/* <div className="text-muted wrap-text-word"></div> */}
                        </Col>
                        <Col sm={12} md={lostMode.enabled ? 6 : 2}>
                            <div className="form-check form-switch form-switch-md">
                                <Input
                                    className="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    checked={lostMode.networkTether || false}
                                    onChange={(e) => setLostMode({ ...lostMode, networkTether: e.target.checked })}
                                />
                            </div>
                        </Col>
                    </Row>
                    {lostMode.enabled && (
                        <>
                            <Row className="mt-2">
                                <Col sm={12} md={6} className="d-flex align-items-center">
                                    <Label className={'mb-0 fw-medium'}>Phone Number :</Label>
                                    {/* <div className="text-muted wrap-text-word"></div> */}
                                </Col>
                                <Col sm={12} md={6}>
                                    <div className="input-group">
                                        <Input
                                            className="form-control"
                                            placeholder={'Enter Phone Number'}
                                            validate={{ required: { value: true } }}
                                            maxLength={10}
                                            onChange={(e) => handleLostModeDetails(e, 'phone')}
                                            value={lostMode.phone || ''}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col sm={12} md={6} className="d-flex align-items-center">
                                    <Label className={'mb-0 fw-medium'}>Message :</Label>
                                    {/* <div className="text-muted wrap-text-word"></div> */}
                                </Col>
                                <Col sm={12} md={6}>
                                    <div className="input-group">
                                        <Input
                                            className="form-control"
                                            placeholder={'Enter Message'}
                                            validate={{ required: { value: true } }}
                                            type="textarea"
                                            onChange={(e) => handleLostModeDetails(e, 'message')}
                                            value={lostMode.message || ''}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col sm={12} md={6} className="d-flex align-items-center">
                                    <Label className={'mb-0 fw-medium'}>FootNote :</Label>
                                    {/* <div className="text-muted wrap-text-word"></div> */}
                                </Col>
                                <Col sm={12} md={6}>
                                    <div className="input-group">
                                        <Input
                                            className="form-control"
                                            placeholder={'Enter Foot Note'}
                                            validate={{ required: { value: true } }}
                                            type="textarea"
                                            onChange={(e) => handleLostModeDetails(e, 'footNote')}
                                            value={lostMode.footNote || ''}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </>
                    )}
                </React.Fragment>
            );
        }
        if (commonModal.type === 'lost_mode_sound') {
            return (
                <React.Fragment>
                    <Col>
                        <div className="mb-4">Are you sure you want to play lost mode on this device?</div>
                        <Row className="mt-2">
                            <Col sm={12} md={6}>
                                <Label className={'mb-0 fw-medium'}>Require Network Tether :</Label>
                                {/* <div className="text-muted wrap-text-word"></div> */}
                            </Col>
                            <Col sm={12} md={6}>
                                <div className="form-check form-switch form-switch-md">
                                    <Input
                                        className="form-check-input"
                                        type="checkbox"
                                        role="switch"
                                        checked={lostMode.networkTether || false}
                                        onChange={(e) => setLostMode({ ...lostMode, networkTether: e.target.checked })}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </React.Fragment>
            );
        }
        if (commonModal.type === 'app_install' || commonModal.type === 'app_uninstall') {
            return (
                <React.Fragment>
                    <Col>
                        <Alert className="alert-borderless alert-warning text-center mb-5" role="alert">
                            {commonModal.type === 'app_install'
                                ? 'You can initiate the installation of a new app on a device by selecting it from the dropdown menu.'
                                : 'To uninstall an app from a device, select it from the dropdown menu. Once uninstalled, the app will be completely removed from the device.'}
                        </Alert>
                        <Row>
                            <Col sm={4}>
                                <Label className="form-label d-flex align-items-center fw-medium">
                                    Select App <span className="red-color ps-1">*</span>
                                </Label>
                            </Col>
                            <Col sm={8}>
                                <Select
                                    getOptionValue={(option) => option.adamid || option.url}
                                    getOptionLabel={(option) => (
                                        <div className="d-flex align-items-center gap-1">
                                            {option.name}
                                            {handleBadge(option)}
                                        </div>
                                    )}
                                    classNamePrefix={'custom-select'}
                                    options={appsList}
                                    placeholder="Select App"
                                    onChange={(selectedOption) => setSelectedApp(selectedOption)}
                                    value={selectedApp || ''}
                                    isSearchable={true}
                                    noOptionsMessage={() => 'No data found'}
                                />
                            </Col>
                        </Row>
                    </Col>
                </React.Fragment>
            );
        }
        if (commonModal.type === 'activation_lock') {
            return (
                <React.Fragment>
                    <Col>
                        <Row className="mb-2">
                            <Col sm={'auto'}>
                                <Label className="mb-0 fw-medium d-flex align-items-center fs-14">Type :</Label>
                            </Col>
                            <Col xs={6} md={6} sm={6} lg={5} xl={5}>
                                <>
                                    <div className="form-check form-check-inline">
                                        <Input
                                            className="form-check-input"
                                            type="radio"
                                            id={'type'}
                                            name={'type'}
                                            value="user"
                                            onBlur={validation.handleBlur}
                                            onChange={validation.handleChange}
                                            checked={validation?.values?.type === 'user'}
                                        />
                                        <Label className="mb-0">User</Label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <Input
                                            className="form-check-input"
                                            type="radio"
                                            id={'type'}
                                            name={'type'}
                                            value="admin"
                                            onBlur={validation.handleBlur}
                                            onChange={validation.handleChange}
                                            checked={validation.values?.type === 'admin'}
                                        />
                                        <Label className="mb-0">Admin</Label>
                                    </div>
                                </>
                            </Col>
                        </Row>
                        {validation?.values?.type === 'user' && (
                            <div className="mb-2">
                                <span className="fw-medium pe-2"> Activation Lock :</span>
                                <span>{iosDetails?.deviceInfo?.report?.QueryResponses?.IsDeviceLocatorServiceEnabled ? 'ON' : 'OFF'}</span>
                            </div>
                        )}

                        <div>
                            Tectoro EMM collects an Activation Lock bypass code, which you can use to clear Activation Lock on a device to
                            prepare for a new user.
                        </div>
                        <div className="fw-semibold mt-4 fs-15 d-flex align-items-center justify-content-center">
                            {iosDetails?.bypasscode?.ActivationLockBypassCode}
                        </div>
                    </Col>
                </React.Fragment>
            );
        }
        if (commonModal.type === 'set_wallpaper') {
            return (
                <React.Fragment>
                    <Col>
                        <Alert className="alert-borderless alert-warning text-center mb-5" role="alert">
                            The maximum file size is 4MB, and the device must be supervised.
                        </Alert>
                        {uploadComponent({
                            field: {
                                label: 'Wallpaper',
                                value: 'wallpaper',
                                helpText: 'Sets the wallpaper for the Home screen and Lock screen.',
                                inputType: 'upload',
                                maxFiles: 1,
                                maxFileSize: '4MB',
                                accept: ['.png', '.jpg', '.jpeg', '.svg'],
                                placeHolder: 'Choose',
                                show: ['SA', 'MA'],
                                maxLength: 100
                            },
                            index: 1,
                            formType: 'add',
                            validation: validation
                        })}
                    </Col>
                </React.Fragment>
            );
        }
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            wallpaper: '',
            type: 'user'
        },
        validationSchema: Yup.object({
            wallpaper: Yup.string().required('Please select wallpaper')
        })
    });

    const handlePolicyShiftModalBody = () => {
        return (
            <div className="mt-3">
                <div className="mb-4 text-center">
                    Are you sure you want to shift policy from &nbsp;
                    <span className="fw-semibold">{iosDetails?.assignment?.policyName}</span> to &nbsp;
                    <span className="fw-semibold">{policy?.name + ' - ' + policy?.code}</span>
                </div>
                <Alert className="alert-borderless alert-warning text-center mt-3 mb-0" role="alert">
                    <div>It may take some time for it to reflect the updated policy.</div>
                    <div className="d-flex justify-content-center">
                        <b className="me-1">Note: </b>Device should be connected to internet
                    </div>
                </Alert>
            </div>
        );
    };

    const uploadComponent = (uploadProps) => {
        const handleFileUpload = (file, field) => {
            let valid = false;
            ['.png', '.jpg', '.jpeg', '.svg']?.forEach((accept) => {
                if (file.filename.endsWith(accept)) valid = true;
            });
            if (valid) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    let str = reader.result.split(',')[1];
                    validation.setValues({ ...validation.values, [field.value]: str });
                };
                reader.readAsDataURL(file.file);
            }
        };

        return (
            <React.Fragment>
                <Row>
                    <Col className={`d-flex ${uploadProps.field.notes ? '' : 'align-items-center'} justify-content-between`}>
                        <Label className={`mb-0 fw-medium d-flex ${uploadProps.field.notes ? '' : 'align-items-center'}`}>
                            {uploadProps.field.label}
                            {uploadProps.formType !== 'view' ? (
                                <span className="red-color ps-1">*</span>
                            ) : uploadProps.field.helpText ? (
                                <>
                                    <i
                                        className={`ri-information-line d-flex ${
                                            uploadProps.field.notes ? '' : 'align-items-center'
                                        } blue-icon ps-1 fs-15`}
                                        id={`polInputComponent-${uploadProps.field.value}`}
                                    />
                                    <UncontrolledTooltip placement="bottom" target={`polInputComponent-${uploadProps.field.value}`}>
                                        {uploadProps.field.helpText && uploadProps.field.helpText}
                                    </UncontrolledTooltip>
                                </>
                            ) : (
                                <span className="ps-1">:</span>
                            )}
                            {uploadProps.field.badge && (
                                <span className={` px-2 fs-10 badge-soft-${uploadProps.field.badgeClass}`}>
                                    {uploadProps.field.badgeText}
                                </span>
                            )}
                        </Label>
                    </Col>
                    <Row>
                        <Col>
                            {!uploadProps.field.hideHelpText && (
                                <div className="text-muted font-size-12 mb-2 word-wrap-break">
                                    {uploadProps.field.helpText && uploadProps.field.helpText}
                                </div>
                            )}
                        </Col>
                    </Row>
                </Row>
                <Row>
                    <Col>
                        <FilePond
                            acceptedFileTypes={['image/*']}
                            name={uploadProps.field.value}
                            minFileSize="5KB"
                            maxFileSize={uploadProps.field.maxFileSize}
                            maxFiles={uploadProps.field.maxFiles}
                            allowMultiple={true}
                            onaddfile={(_error, fileItems) => handleFileUpload(fileItems, uploadProps.field)}
                            className="filepond filepond-input-multiple"
                        />
                    </Col>
                </Row>
            </React.Fragment>
        );
    };

    const handleDisableAction = () => {
        let errors = { ...validation.errors };
        if (iosDetails?.platform?.toLowerCase() === 'mac') {
            if (type === 'lock') {
                if (validation?.values?.pin?.toString()?.length !== 6) errors.pin = 'Pin must be 6 digts';
                else delete errors.pin;

                if (validation?.values?.phoneNumber?.toString()?.length !== 10) errors.phoneNumber = 'Enter valid phone number';
                else delete errors.phoneNumber;
            }
            if (type === 'erase') {
                if (validation?.values?.pin?.toString()?.length !== 6) errors.pin = 'Pin must be 6 digts';
                else delete errors.pin;
            }
        }
        validation.setErrors({ ...errors });
        return (
            iosDetails?.platform?.toLowerCase() === 'mac' &&
            (type === 'lock'
                ? !validation?.values?.pin ||
                  validation?.values?.pin?.toString()?.length !== 6 ||
                  !validation.values.phoneNumber ||
                  validation.values.phoneNumber?.toString()?.length !== 10
                : type === 'erase'
                ? validation?.values?.pin || validation?.values?.pin?.toString()?.length !== 6
                : false)
        );
    };

    return (
        <React.Fragment>
            {loading && <Loader />}

            <div className={`page-content ${loading ? 'postion-relative mask' : ''}`}>
                <Container fluid>
                    <BreadCrumb
                        pageTitle={`View ${iosDetails.platform} Device`}
                        history={history}
                        homeLink="Dashboard"
                        showBack={true}
                        backLink="iosdevices"
                    />
                    <Row>
                        <Col className="col-12">
                            <Card className="pt-3 pb-3 ps-2 pe-2 w-100 d-flex align-items-center mb-0">
                                <div className="w-100 d-flex align-items-center justify-content-between">
                                    <div className="d-flex align-items-center">
                                        <div className="fs-15 px-1 fw-medium">Device ID :</div>
                                        <div className="fs-16 ps-2 text-info">{iosDetails?.serial}</div>
                                    </div>
                                    <div className="d-flex align-items-center gap-4">
                                        <IsAuthorized
                                            privRequired={priviliges.IOSDEVICE_EDITOR}
                                            yes={() => (
                                                <div className="d-flex align-items-center justify-content-center gap-1">
                                                    {actionItems?.filter((action) => action.show)?.length > 0 && (
                                                        <div className="fs-15 d-flex justify-content-center align-items-center fw-medium">
                                                            Actions :
                                                        </div>
                                                    )}
                                                    {actionItems.map((action, ind) => {
                                                        return (
                                                            action.show && (
                                                                <div
                                                                    key={ind}
                                                                    className={`width-35 height-35 d-flex align-items-center justify-content-center ${
                                                                        action.condition
                                                                            ? action.disabled
                                                                                ? 'bg-muted'
                                                                                : 'bg-447BD107 cursor-pointer'
                                                                            : ''
                                                                    }`}
                                                                    onClick={
                                                                        action.condition && !action.disabled
                                                                            ? action.clickFunction
                                                                            : undefined
                                                                    }
                                                                >
                                                                    <i
                                                                        id={action.id}
                                                                        className={`${action.icon} fs-20 ${
                                                                            action.condition ? 'action-hover link-info' : 'text-muted'
                                                                        }`}
                                                                    />
                                                                    {Tooltip(
                                                                        action.id,
                                                                        action.disabled ? action.disabledTooltip : action.label
                                                                    )}
                                                                </div>
                                                            )
                                                        );
                                                    })}
                                                </div>
                                            )}
                                        />
                                        {/* <div className="d-flex align-items-center justify-content-center gap-1">
                                        {monitoringItems?.filter((monitor) => monitor.show)?.length > 0 && (
                                            <div className="fs-15 d-flex justify-content-center align-items-center fw-medium">
                                                Monitoring :
                                            </div>
                                        )}
                                        {monitoringItems?.map((monitor, ind) => {
                                            return (
                                                monitor.show && (
                                                    <div
                                                        key={ind}
                                                        className="width-35 height-35 d-flex align-items-center justify-content-center bg-447BD107 cursor-pointer"
                                                        onClick={() => handleMonitoring(monitor)}
                                                    >
                                                        <i
                                                            id={`monitor-${ind}`}
                                                            className={monitor.icon + ' fs-20 link-info action-hover'}
                                                        />
                                                        {Tooltip(`monitor-${ind}`, monitor.label)}
                                                    </div>
                                                )
                                            );
                                        })}
                                    </div> */}
                                    </div>
                                </div>
                            </Card>
                        </Col>
                        <Col xs={12} sm={12} md={4} xl={4} lg={4} className="d-flex flex-column g-3">
                            <Card className="card-height-100 mb-0 padding-bottom-50">
                                <CardHeader className="d-flex align-items-center flex-column">
                                    <>
                                        <div className="w-100 mt-3 d-flex justify-content-center">
                                            <span className="rounded height-50 width-50 border border-2 d-flex align-items-center justify-content-center fs-2">
                                                {iosDetails?.deviceInfo?.report?.QueryResponses?.Model ? (
                                                    iosDetails?.deviceInfo?.report?.QueryResponses?.Model?.toLowerCase()?.includes(
                                                        'mac'
                                                    ) ? (
                                                        <i className="ri-macbook-line" />
                                                    ) : (
                                                        <i className="ri-tablet-line" />
                                                    )
                                                ) : (
                                                    <i className="ri-loader-2-line iconSpinner" />
                                                )}
                                            </span>
                                        </div>
                                        <div className="w-100 mt-3 margin-bottom-30 d-flex justify-content-center">
                                            <p className="ps-2 fs-18 fw-semibold mb-0">{iosDetails?.hardware?.model}</p>
                                        </div>
                                    </>
                                    <div className="w-100 d-flex justify-content-start">
                                        <h4 className="card-title mb-0 flex-grow-1 text-success">Device Summary</h4>
                                        <div className="flex-shrink-0"></div>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <div className="table-responsive table-card">
                                        <table className="table table-wrap table-borderless table-centered align-middle mb-0">
                                            <tbody>
                                                {returnData(
                                                    'Device Group',
                                                    iosDetails?.assignment?.groupName,
                                                    iosDetails?.enroll?.status !== 'DELETED'
                                                )}
                                                {returnData(
                                                    'Policy',
                                                    iosDetails?.assignment?.policyName,
                                                    iosDetails?.enroll?.status !== 'DELETED'
                                                )}
                                                {returnData(
                                                    'User Name',
                                                    iosDetails?.assignment?.user,
                                                    iosDetails?.enroll?.status !== 'DELETED'
                                                )}
                                                {/* {returnData('Mobile Number', iosDetails?.deviceSummary?.phone, false)} */}
                                                {returnData('Enrollment Time', convertUTCtoIST(iosDetails?.enroll?.enrolldate))}
                                                {returnData('Last Status Report Time', convertUTCtoIST(iosDetails?.modifieddate))}
                                                {returnData(
                                                    'Battery',
                                                    Math.floor(
                                                        (iosDetails?.deviceInfo?.report?.QueryResponses?.BatteryLevel * 100)?.toFixed(2)
                                                    ) + (iosDetails?.deviceInfo?.report?.QueryResponses?.BatteryLevel > 0 ? '%' : '')
                                                )}
                                                {returnData('Last Modified Date', convertUTCtoIST(iosDetails?.modifieddate))}
                                                {returnData('Policy Compliance', 'NON-COMPLIANT')}
                                                {returnData('Management Mode', 'DEVICE_OWNER')}
                                                {returnData('Ownership', 'COMPANY_OWNER')}
                                            </tbody>
                                        </table>
                                        <div className="w-100 d-flex justify-content-start ps-4 margin-top-30">
                                            <h4 className="card-title mb-0 flex-grow-1 text-success">Device Identity Details</h4>
                                            <div className="flex-shrink-0"></div>
                                        </div>
                                        <table className="table table-wrap table-borderless table-centered align-middle mb-0">
                                            <tbody>
                                                {returnData('Serial Number', iosDetails?.serial)}
                                                {iosDetails?.platform?.toLowerCase() !== 'mac' &&
                                                    returnData('IMEI', iosDetails?.deviceInfo?.report?.QueryResponses?.IMEI)}
                                                {returnData(
                                                    'Device Name',
                                                    iosDetails?.deviceInfo?.report?.QueryResponses?.DeviceName,
                                                    iosDetails?.enroll?.status !== 'DELETED'
                                                )}
                                                {returnData('Bluetooth MAC', iosDetails?.deviceInfo?.report?.QueryResponses?.BluetoothMAC)}
                                                {returnData('Wi-Fi MAC', iosDetails?.deviceInfo?.report?.QueryResponses?.WiFiMAC)}
                                            </tbody>
                                        </table>
                                        <div className="w-100 d-flex justify-content-start ps-4 margin-top-30">
                                            <h4 className="card-title mb-0 flex-grow-1 text-success">Device Security</h4>
                                            <div className="flex-shrink-0"></div>
                                        </div>
                                        <table className="table table-wrap table-borderless table-centered align-middle mb-0">
                                            <tbody>
                                                {returnData(
                                                    'Cloud Backup Enabled',
                                                    iosDetails?.deviceInfo?.report?.QueryResponses?.IsCloudBackupEnabled ? 'Yes' : 'No'
                                                )}
                                                {returnData(
                                                    'Device Locator Enabled',
                                                    iosDetails?.deviceInfo?.report?.QueryResponses?.IsDeviceLocatorServiceEnabled
                                                        ? 'Yes'
                                                        : 'No'
                                                )}
                                                {returnData(
                                                    'MDM Lost Mode Enabled',
                                                    iosDetails?.deviceInfo?.report?.QueryResponses?.IsMDMLostModeEnabled ? 'Yes' : 'No'
                                                )}
                                                {returnData(
                                                    'Supervised Device',
                                                    iosDetails?.deviceInfo?.report?.QueryResponses?.IsSupervised ? 'Yes' : 'No'
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xs={12} sm={12} md={4} xl={4} lg={4} className="d-flex flex-column g-3">
                            <Card className="card-height-20 mb-3 padding-bottom-50">
                                <CardHeader className="align-items-center d-flex">
                                    <img src={hardwareIcon} alt="Hardware Icon" height={20}></img>
                                    <h4 className="ps-2 card-title mb-0 flex-grow-1 text-success">Hardware Information</h4>
                                    <div className="flex-shrink-0"></div>
                                </CardHeader>
                                <CardBody>
                                    <div className="table-responsive table-card">
                                        <table className="table table-wrap table-borderless table-centered align-middle mb-0">
                                            <tbody>
                                                {returnData('Manufacturer', 'Apple')}
                                                {returnData('Model', iosDetails?.deviceInfo?.report?.QueryResponses?.Model)}
                                                {returnData('Model Name', iosDetails?.deviceInfo?.report?.QueryResponses?.ModelName)}
                                                {returnData('Model Number', iosDetails?.deviceInfo?.report?.QueryResponses?.ModelNumber)}
                                            </tbody>
                                        </table>
                                    </div>
                                </CardBody>
                            </Card>
                            <Card className="card-height-30 mb-3 padding-bottom-50">
                                <CardHeader className="align-items-center d-flex">
                                    <img src={softwareIcon} alt="Software Icon" height={20}></img>
                                    <h4 className="card-title ps-2 mb-0 flex-grow-1 text-success">Software Information</h4>
                                    <div className="flex-shrink-0"></div>
                                </CardHeader>
                                <CardBody>
                                    <div className="table-responsive table-card">
                                        <table className="table table-wrap table-borderless table-centered align-middle mb-0">
                                            <tbody>
                                                {returnData(
                                                    `${iosDetails.platform} Version`,
                                                    iosDetails?.deviceInfo?.report?.QueryResponses?.OSVersion
                                                )}
                                                {returnData(
                                                    'Software Update',
                                                    iosDetails?.deviceInfo?.report?.QueryResponses?.SoftwareUpdateDeviceID
                                                )}
                                                {iosDetails?.platform?.toLowerCase() !== 'mac' &&
                                                    returnData(
                                                        'Modem Firmware Version',
                                                        iosDetails?.deviceInfo?.report?.QueryResponses?.ModemFirmwareVersion
                                                    )}
                                                {returnData(
                                                    'Supplemental Build Version',
                                                    iosDetails?.deviceInfo?.report?.QueryResponses?.SupplementalBuildVersion
                                                )}
                                                {iosDetails?.platform?.toLowerCase() !== 'mac' &&
                                                    returnData('Time Zone', iosDetails?.deviceInfo?.report?.QueryResponses?.TimeZone)}
                                            </tbody>
                                        </table>
                                    </div>
                                </CardBody>
                            </Card>
                            <Card className="card-height-50 mb-0 padding-bottom-50">
                                <CardHeader className="align-items-center d-flex">
                                    <img src={softwareIcon} alt="Software Icon" height={20}></img>
                                    <h4 className="card-title ps-2 mb-0 flex-grow-1 text-success">Accessibility</h4>
                                    <div className="flex-shrink-0"></div>
                                </CardHeader>
                                <CardBody>
                                    <div className="table-responsive table-card">
                                        <table className="table table-wrap table-borderless table-centered align-middle mb-0">
                                            <tbody>
                                                {returnData(
                                                    'Bold Text Enabled',
                                                    iosDetails?.deviceInfo?.report?.QueryResponses?.AccessibilitySettings?.BoldTextEnabled
                                                        ? 'Yes'
                                                        : 'No'
                                                )}
                                                {returnData(
                                                    'Increase Contrast Enabled',
                                                    iosDetails?.deviceInfo?.report?.QueryResponses?.AccessibilitySettings
                                                        ?.IncreaseContrastEnabled
                                                        ? 'Yes'
                                                        : 'No'
                                                )}
                                                {returnData(
                                                    'Reduce Motion Enabled',
                                                    iosDetails?.deviceInfo?.report?.QueryResponses?.AccessibilitySettings
                                                        ?.ReduceMotionEnabled
                                                        ? 'Yes'
                                                        : 'No'
                                                )}
                                                {returnData(
                                                    'Reduce Transparency Enabled',
                                                    iosDetails?.deviceInfo?.report?.QueryResponses?.AccessibilitySettings
                                                        ?.ReduceTransparencyEnabled
                                                        ? 'Yes'
                                                        : 'No'
                                                )}
                                                {returnData(
                                                    'Font Size',
                                                    iosDetails?.deviceInfo?.report?.QueryResponses?.AccessibilitySettings?.TextSize
                                                )}
                                                {returnData(
                                                    'Touch Accommodations Enabled',
                                                    iosDetails?.deviceInfo?.report?.QueryResponses?.AccessibilitySettings
                                                        ?.TouchAccommodationsEnabled
                                                        ? 'Yes'
                                                        : 'No'
                                                )}
                                                {returnData(
                                                    'VoiceOver Enabled',
                                                    iosDetails?.deviceInfo?.report?.QueryResponses?.AccessibilitySettings?.VoiceOverEnabled
                                                        ? 'Yes'
                                                        : 'No'
                                                )}
                                                {returnData(
                                                    'Zoom Enabled',
                                                    iosDetails?.deviceInfo?.report?.QueryResponses?.AccessibilitySettings?.ZoomEnabled
                                                        ? 'Yes'
                                                        : 'No'
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xs={12} sm={12} md={4} xl={4} lg={4} className="d-flex flex-column g-3">
                            <Card className="card-height-100 mb-0 padding-bottom-50">
                                <CardHeader className="align-items-center d-flex">
                                    <img src={deviceSecurity} alt="Non Compliance Icon"></img>
                                    <h4 className="card-title ps-2 mb-0 flex-grow-1 text-success">Action History</h4>
                                    <div className="flex-shrink-0"></div>
                                </CardHeader>
                                <CardBody>
                                    <div className="max-height-850">
                                        <div className="table-responsive table-card">
                                            <table className="table table-wrap table-borderless table-centered align-middle mb-0">
                                                <tbody>
                                                    {commandHistory?.length > 0 ? (
                                                        commandHistory?.map((command, ind) => (
                                                            <React.Fragment key={ind}>{commandReturnData(command, ind)}</React.Fragment>
                                                        ))
                                                    ) : (
                                                        <tr>
                                                            <td className="pb-6 fs-13">{'No Records Found.'}</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    {commandPage?.length > 15 && (
                                        <Pagination
                                            activePage={commandPage.page}
                                            itemsCountPerPage={20}
                                            totalItemsCount={commandPage.length}
                                            pageRangeDisplayed={3}
                                            onChange={handleCommandPageChange}
                                            hideFirstLastPages={false}
                                            isColumn={true}
                                        />
                                    )}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    {/* <Row>
                        <div className="p-1">
                            <TableContainer
                                loading={loading}
                                searchFilter2={true}
                                showTooltip={true}
                                searchPlaceHolder={'Package/Name'}
                                headerImage={applicationsIcon}
                                tableLabelClass={'text-success'}
                                tableHeader={'Applications'}
                                isGlobalFilter={true}
                                columns={androidTVAppSchema}
                                data={applications}
                                customPageSize={5}
                                className="custom-header-css"
                                divClass="table-responsive table-card"
                                tableClass="table table-wrap table-border table-centered align-middle"
                                theadClass="bg-light text-muted"
                            />
                        </div>
                    </Row> */}
                </Container>
                <OffcanvasModal
                    direction="end"
                    toggle={toggle1}
                    open={showDeviceGroup}
                    handleCloseClick={toggle1}
                    saveDisabled={!selectedDeviceGroup?._id || selectedDeviceGroup?._id === defaultGroup?._id}
                    loading={loading}
                    OffcanvasModalID="deviceGroup"
                    handleOffcanvasBody={deviceGroupBody}
                    offcanvasHeader="Change Device Group"
                    modalClassName="w-40"
                    handleSaveClick={() => onChangeGroup()}
                />
                <CommonModal
                    size={'md'}
                    show={showPolicyModal}
                    modalheader={'Policy Shift'}
                    onCloseClick={() => setShowPolicyModal(false)}
                    handleClick={() => onSubmitPolicy()}
                    handleModalBody={() => handlePolicyShiftModalBody()}
                />
                <OffcanvasModal
                    direction="end"
                    toggle={toggle2}
                    open={showUserInput}
                    handleCloseClick={toggle2}
                    saveDisabled={!selectedUser?._id || selectedUser?._id === defaultUser?._id}
                    loading={loading}
                    OffcanvasModalID="deviceUser"
                    handleOffcanvasBody={deviceUserBody}
                    offcanvasHeader="Change User"
                    modalClassName="w-40"
                    handleSaveClick={() => onChangeUser()}
                />
                <CommonModal
                    size={'md'}
                    show={commonModal.show}
                    modalheader={commonModal.header}
                    onCloseClick={toggleCommonModal}
                    saveText={actionText}
                    disabled={commonModal.type === 'set_wallpaper' ? !validation.values.wallpaper : false}
                    hideSaveButton={commonModal.type === 'activation_lock' && validation?.values?.type === 'user' ? true : false}
                    handleClick={handleCommonModalConfirm}
                    handleModalBody={() => handleCommonModalBody()}
                />
                <DeleteModal
                    hideIcon={true}
                    show={actionModal}
                    hideDeleteIcon={true}
                    hideDeleteMessage={true}
                    message={actionMessage()}
                    confirmText={actionText}
                    disabled={handleDisableAction()}
                    onDeleteClick={actionService}
                    onCloseClick={() => {
                        setActionModal(false);
                        validation.setErrors({});
                    }}
                />
                {/* <OffcanvasModal
                    direction="end"
                    toggle={toggle}
                    open={offcanvasModal}
                    hideSaveButton={true}
                    handleCloseClick={toggle}
                    loading={loading}
                    OffcanvasModalID="viewiosDetailsmodal"
                    handleOffcanvasBody={selectedMonitor.type === 'app_usage' ? appUsageBody : geoViolationBody}
                    modalClassName={'w-50'}
                    offcanvasHeader={selectedMonitor.header + iosDetails.serial}
                /> */}
            </div>
        </React.Fragment>
    );
};

export default ViewIOSDevices;
