/* eslint-disable max-lines */
/* eslint-disable quotes */
/* eslint-disable multiline-comment-style */
import singleApp from '../../../assets/images/windows/svg/common/policyFully.svg';
import passcode from '../../../assets/images/windows/svg/windowsCardImages/passcode.svg';
import restrictions from '../../../assets/images/windows/svg/windowsCardImages/restrictions.svg';
import powerSettings from '../../../assets/images/windows/svg/windowsCardImages/powerSettings.svg';
import edgeBrowser from '../../../assets/images/windows/svg/windowsCardImages/edgeBrowser.svg';
import sudo from '../../../assets/images/windows/svg/windowsCardImages/sudo.svg';
import localPolicySecurityOptions from '../../../assets/images/windows/svg/windowsCardImages/localPolicySecurityOptions.svg';
import startMenu from '../../../assets/images/windows/svg/windowsCardImages/startmenu.svg';
import ctrlAltDel from '../../../assets/images/windows/svg/windowsCardImages/ctrlAltDel.svg';
import kioskBrowser from '../../../assets/images/windows/svg/windowsCardImages/kioskBrowser.svg';
import deviceHealthMonitoring from '../../../assets/images/windows/svg/windowsCardImages/deviceHealthMonitoring.svg';
import applicationGuard from '../../../assets/images/windows/svg/windowsCardImages/applicationGuards.svg';
import branding from '../../../assets/images/windows/svg/windowsCardImages/branding.svg';
import applications from '../../../assets/images/windows/svg/windowsCardImages/applications.svg';
import deliveryOptimization from '../../../assets/images/windows/svg/windowsCardImages/deliveryOptimization.svg';
import defender from '../../../assets/images/windows/svg/windowsCardImages/defender.svg';
import deviceGuard from '../../../assets/images/windows/svg/windowsCardImages/deviceguard.png';
import wifi from '../../../assets/images/windows/svg/windowsCardImages/wifi.svg';
import email from '../../../assets/images/windows/svg/windowsCardImages/email.svg';
import vpn from '../../../assets/images/windows/svg/windowsCardImages/vpn.svg';
import windowsUpdate from '../../../assets/images/windows/svg/windowsCardImages/windowsUpdate.svg';
import settings from '../../../assets/images/windows/svg/windowsCardImages/settings.svg';
import speech from '../../../assets/images/windows/svg/windowsCardImages/speech.svg';
import firewall from '../../../assets/images/windows/svg/windowsCardImages/firewall.png';

export const windowsPolicySchema = [
    {
        label: 'Single App',
        helpText: 'Single App, restricts user access to a single application, ensuring devices operate in a focused, purpose-driven mode.',
        show: ['FM', 'SA', 'MA'],
        image: singleApp,
        fields: [
            {
                label: 'Kiosk Mode',
                value: 'kioskMode',
                helpText: 'Specifies the Kiosk Mode',
                inputType: 'dropdown',
                parentId: 'AssignedAccess',
                myId: 'kioskMode',
                defaultValue: 'single',
                mandatory: true,
                options: [
                    {
                        label: 'Single App',
                        value: 'single'
                    }
                ]
            },
            {
                label: 'Username',
                value: 'userAccountName',
                helpText: 'Specifies the username with which the user is created',
                inputType: 'policyInput',
                parentId: 'AssignedAccess',
                myId: 'userAccountName',
                shouldTrim: true,
                defaultValue: '',
                mandatory: true,
                placeholder: 'Enter Username'
            },
            {
                label: 'App',
                value: 'appname',
                helpText: 'Specifies the Single App',
                inputType: 'dropdown',
                parentId: 'AssignedAccess',
                myId: 'appname',
                defaultValue: '',
                mandatory: true,
                placeholder: 'Select App',
                options: [
                    {
                        label: 'Cortana',
                        value: 'Microsoft.549981C3F5F10_8wekyb3d8bbwe!App'
                    },
                    {
                        label: 'News',
                        value: 'Microsoft.BingNews_8wekyb3d8bbwe!AppexNews'
                    },
                    {
                        label: 'Snipping Tool',
                        value: 'Microsoft.ScreenSketch_8wekyb3d8bbwe!App'
                    },
                    {
                        label: 'Photos',
                        value: 'Microsoft.Windows.Photos_8wekyb3d8bbwe!App'
                    },
                    {
                        label: 'Calculator',
                        value: 'Microsoft.WindowsCalculator_8wekyb3d8bbwe!App'
                    },
                    {
                        label: 'Microsoft To Do',
                        value: 'Microsoft.Todos_8wekyb3d8bbwe!App'
                    },
                    {
                        label: 'Weather',
                        value: 'Microsoft.BingWeather_8wekyb3d8bbwe!App'
                    },
                    {
                        label: 'Sound Recorder',
                        value: 'Microsoft.WindowsSoundRecorder_8wekyb3d8bbwe!App'
                    },
                    {
                        label: 'Camera',
                        value: 'Microsoft.WindowsCamera_8wekyb3d8bbwe!App'
                    },
                    {
                        label: 'Clock',
                        value: 'Microsoft.WindowsAlarms_8wekyb3d8bbwe!App'
                    },
                    { label: 'Paint', value: 'Microsoft.Paint_8wekyb3d8bbwe!App' },
                    {
                        label: 'Notepad',
                        value: 'Microsoft.WindowsNotepad_8wekyb3d8bbwe!App'
                    }
                ]
            }
        ]
    },
    {
        label: 'Passcode',
        helpText: 'Use this section to manage passcode complexity to mitigate the risk of unauthorized access',
        show: ['FM', 'SA', 'MA'],
        image: passcode,
        fields: [
            {
                label: 'Device Lock',
                value: 'DevicePasswordEnabled',
                helpText: 'Specifies if device lock is enabled',
                inputType: 'checkbox',
                parentId: 'DeviceLock',
                myId: 'DevicePasswordEnabled',
                defaultValue: 1,
                checkedValue: 0,
                options: [
                    { label: 'Enabled', value: 0 },
                    { label: 'Disabled.', value: 1 }
                ]
            },
            {
                label: 'Minimum Passcode Length',
                value: 'MinDevicePasswordLength',
                inputType: 'dropdown',
                placeholder: 'Select',
                helpText: 'Specifies the minimum characters required in a PIN or password',
                parentId: 'DeviceLock',
                myId: 'MinDevicePasswordLength',
                defaultValue: 4,
                enableOn: 'DevicePasswordEnabled',
                enableOnVal: [0],
                options: [
                    { label: '4', value: 4 },
                    { label: '5', value: 5 },
                    { label: '6', value: 6 },
                    { label: '7', value: 7 },
                    { label: '8', value: 8 },
                    { label: '9', value: 9 },
                    { label: '10', value: 10 },
                    { label: '11', value: 11 },
                    { label: '12', value: 12 },
                    { label: '13', value: 13 },
                    { label: '14', value: 14 },
                    { label: '15', value: 15 },
                    { label: '16', value: 16 }
                ]
            },
            {
                label: 'Minimum Passcode Age',
                value: 'MinimumPasswordAge',
                helpText:
                    'Specifies the minimum number of days a password must be used before it can be changed, ranging from 0 to 998 days',
                inputType: 'policyInput',
                regExp: '^[0-9]+$',
                valueType: 'number',
                minValue: 0,
                maxValue: 998,
                maxLength: 3,
                parentId: 'DeviceLock',
                myId: 'MinimumPasswordAge',
                defaultValue: 0,
                enableOn: 'DevicePasswordEnabled',
                enableOnVal: [0],
                placeholder: '0-998 day(s)'
            },
            {
                label: 'Maximum Passcode Age',
                value: 'MaximumPasswordAge',
                helpText:
                    'Specifies the maximum number of days a password can be used before it must be changed, ranging from 0 to 999 days',
                inputType: 'policyInput',
                regExp: '^[0-9]+$',
                valueType: 'number',
                minValue: 0,
                maxValue: 999,
                maxLength: 3,
                parentId: 'DeviceLock',
                myId: 'MaximumPasswordAge',
                defaultValue: 42,
                enableOn: 'DevicePasswordEnabled',
                enableOnVal: [0],
                placeholder: '1-999 day(s)'
            },
            // {
            //     label: 'Maximum idle time allowed before Auto Lock',
            //     value: 'MaxInactivityTimeDeviceLock',
            //     helpText: 'Specifies the maximum idle time (in minutes) before the device locks and requires a PIN or password',
            //     inputType: 'policyInput',
            //     regExp: "^[0-9]+$",
            //     valueType: 'number',
            //     minValue: 0,
            //     maxValue: 999,
            //     maxLength: 3,
            //     parentId: 'DeviceLock',
            //     myId: 'MaxInactivityTimeDeviceLock',
            //     defaultValue: 0,
            //     enableOn: 'DevicePasswordEnabled',
            //     enableOnVal: [0],
            //     placeholder: '0-999 minute(s)'
            // },
            {
                label: 'Number of passcodes to be maintained in history',
                value: 'DevicePasswordHistory',
                helpText: 'Specifies the number of previous passwords that cannot be reused',
                inputType: 'policyInput',
                regExp: '^[0-9]+$',
                valueType: 'number',
                minValue: 0,
                maxValue: 50,
                maxLength: 2,
                parentId: 'DeviceLock',
                myId: 'DevicePasswordHistory',
                defaultValue: 0,
                enableOn: 'DevicePasswordEnabled',
                enableOnVal: [0],
                placeholder: '0-50'
            },
            {
                label: 'Wipe device after specified number of failed attempts',
                value: 'MaxDevicePasswordFailedAttempts',
                helpText: 'Specifies the number of failed authentication attempts before the device is wiped',
                inputType: 'policyInput',
                regExp: '^[0-9]+$',
                valueType: 'number',
                minValue: 0,
                maxValue: 999,
                maxLength: 3,
                parentId: 'DeviceLock',
                myId: 'MaxDevicePasswordFailedAttempts',
                defaultValue: 0,
                enableOn: 'DevicePasswordEnabled',
                enableOnVal: [0],
                placeholder: '0-999'
            },
            {
                label: 'Simple Device Password',
                value: 'AllowSimpleDevicePassword',
                helpText: 'Specifies whether simple PINs (like 1111 or 1234) & picture passwords are allowed',
                inputType: 'checkbox',
                parentId: 'DeviceLock',
                myId: 'AllowSimpleDevicePassword',
                defaultValue: 1,
                checkedValue: 1,
                enableOn: 'DevicePasswordEnabled',
                enableOnVal: [0],
                options: [
                    { label: 'Not Allowed.', value: 0 },
                    { label: 'Allowed.', value: 1 }
                ]
            },
            {
                label: 'Alpha Numeric PIN',
                value: 'AlphanumericDevicePasswordRequired',
                helpText: 'Specifies the type of PIN or password required',
                inputType: 'dropdown',
                parentId: 'DeviceLock',
                myId: 'AlphanumericDevicePasswordRequired',
                defaultValue: 2,
                enableOn: 'DevicePasswordEnabled',
                enableOnVal: [0],
                row: true,
                options: [
                    { label: 'Password or Alphanumeric PIN required', value: 0 },
                    { label: 'Password or Numeric PIN required', value: 1 },
                    { label: 'Password, Numeric PIN, or Alphanumeric PIN required', value: 2 }
                ]
            }
        ]
    },
    {
        label: 'Restrictions',
        helpText: 'Use this section to impose restrictions on managed devices to prevent unauthorised access and misuse',
        show: ['FM', 'SA', 'MA'],
        image: restrictions,
        fields: [
            {
                label: 'Restrictions',
                inputType: 'restrictions',
                fields: [
                    {
                        label: 'General Settings',
                        fields: [
                            {
                                label: 'Capture Screen',
                                inputType: 'checkbox',
                                value: 'AllowScreenCapture',
                                helpText: 'Specifies whether screen capture is allowed or not',
                                parentId: 'Experience',
                                myId: 'AllowScreenCapture',
                                defaultValue: 1,
                                checkedValue: 1,
                                options: [
                                    { label: 'Not Allowed.', value: 0 },
                                    { label: 'Allowed.', value: 1 }
                                ]
                            },
                            {
                                label: 'Manual Unenrollment',
                                inputType: 'checkbox',
                                value: 'AllowManualMDMUnenrollment',
                                helpText:
                                    'Specifies whether user is allowed or restricted from deleting the workplace account via the workplace control panel',
                                parentId: 'Experience',
                                myId: 'AllowManualMDMUnenrollment',
                                defaultValue: 1,
                                checkedValue: 1,
                                options: [
                                    { label: 'Not Allowed.', value: 0 },
                                    { label: 'Allowed.', value: 1 }
                                ]
                            },
                            {
                                label: 'Allow Device Discovery',
                                inputType: 'checkbox',
                                value: 'AllowDeviceDiscovery',
                                helpText: 'Specifies if device discovery is enabled or disabled',
                                parentId: 'Experience',
                                myId: 'AllowDeviceDiscovery',
                                defaultValue: 1,
                                checkedValue: 1,
                                options: [
                                    { label: 'Disabled.', value: 0 },
                                    { label: 'Enabled.', value: 1 }
                                ]
                            },
                            {
                                label: 'Allow Find My Device',
                                inputType: 'checkbox',
                                value: 'AllowFindMyDevice',
                                helpText:
                                    'Specifies whether find my device is enabled, allowing the device & its location to be registered in the cloud for tracking',
                                parentId: 'Experience',
                                myId: 'AllowFindMyDevice',
                                defaultValue: 1,
                                checkedValue: 1,
                                options: [
                                    { label: 'Not Allowed.', value: 0 },
                                    { label: 'Allowed.', value: 1 }
                                ]
                            },
                            {
                                label: 'Allow Clipboard History',
                                inputType: 'checkbox',
                                value: 'AllowClipboardHistory',
                                helpText: 'Specifies whether the history of clipboard items can be stored in memory',
                                parentId: 'Experience',
                                myId: 'AllowClipboardHistory',
                                defaultValue: 1,
                                checkedValue: 1,
                                options: [
                                    { label: 'Not Allowed.', value: 0 },
                                    { label: 'Allowed.', value: 1 }
                                ]
                            },
                            // {
                            //     label: 'Allow Direct Memory Access',
                            //     inputType: 'checkbox',
                            //     value: 'AllowDirectMemoryAccess',
                            //     helpText:
                            //         'Specifies whether DMA is blocked for hot pluggable PCI downstream ports until a user, logs into Windows, enforced only when BitLocker device encryption is enabled',
                            //     parentId: 'DataProtection',
                            //     myId: 'AllowDirectMemoryAccess',
                            //     defaultValue: 1,
                            //     checkedValue: 1,
                            //     options: [
                            //         { label: 'Not Allowed.', value: 0 },
                            //         { label: 'Allowed.', value: 1 }
                            //     ]
                            // },
                            {
                                label: 'Allow End Task',
                                inputType: 'checkbox',
                                value: 'AllowEndTask',
                                helpText: 'Specifies whether non-administrators can use task manager to end tasks',
                                parentId: 'TaskManager',
                                myId: 'AllowEndTask',
                                defaultValue: 1,
                                checkedValue: 1,
                                row: true,
                                options: [
                                    { label: 'Disabled. EndTask functionality is blocked in TaskManager.', value: 0 },
                                    { label: 'Enabled. Users can perform EndTask in TaskManager', value: 1 }
                                ]
                            },
                            {
                                label: 'Camera',
                                inputType: 'checkbox',
                                value: 'AllowCamera',
                                helpText: 'Allows or disallows camera',
                                parentId: 'Camera',
                                myId: 'AllowCamera',
                                defaultValue: 1,
                                checkedValue: 1,
                                options: [
                                    { label: 'Not Allowed.', value: 0 },
                                    { label: 'Allowed.', value: 1 }
                                ]
                            },
                            {
                                label: 'USB Connection',
                                inputType: 'checkbox',
                                value: 'AllowStorageCard',
                                helpText: 'Specifies whether the user is allowed to use the storage card for device storage',
                                parentId: 'System',
                                myId: 'AllowStorageCard',
                                defaultValue: 1,
                                checkedValue: 1,
                                options: [
                                    { label: 'SD card use is not allowed and USB drives are disabled.', value: 0 },
                                    { label: 'Allow a Storage Card.', value: 1 }
                                ]
                            },
                            {
                                label: 'Modify device date & time',
                                inputType: 'checkbox',
                                value: 'AllowDateTime',
                                helpText: 'Specifies the user’s ability to change the date and time of the device',
                                parentId: 'Settings',
                                myId: 'AllowDateTime',
                                defaultValue: 1,
                                checkedValue: 1,
                                options: [
                                    { label: 'Not Allowed.', value: 0 },
                                    { label: 'Allowed.', value: 1 }
                                ]
                            }
                        ]
                    },
                    {
                        label: 'Network Settings',
                        fields: [
                            {
                                label: 'VPN',
                                inputType: 'checkbox',
                                value: 'AllowVPN',
                                helpText: 'Specifies if user can change VPN settings',
                                parentId: 'Settings',
                                myId: 'AllowVPN',
                                defaultValue: 1,
                                checkedValue: 1,
                                options: [
                                    { label: 'Not Allowed.', value: 0 },
                                    { label: 'Allowed.', value: 1 }
                                ]
                            },
                            {
                                label: 'VPN usage while using Cellular Data',
                                inputType: 'checkbox',
                                value: 'AllowVPNOverCellular',
                                helpText: 'Specifies what type of underlying connections VPN can use',
                                parentId: 'Connectivity',
                                myId: 'AllowVPNOverCellular',
                                defaultValue: 1,
                                checkedValue: 1,
                                row: true,
                                options: [
                                    { label: 'VPN is not allowed over cellular.', value: 0 },
                                    { label: 'VPN can use any connection, including cellular.', value: 1 }
                                ]
                            },
                            {
                                label: 'VPN roaming while using Cellular Data',
                                inputType: 'checkbox',
                                value: 'AllowVPNRoamingOverCellular',
                                helpText:
                                    'Specifies whether to prevent the device from connecting to VPN when roaming over cellular networks',
                                parentId: 'Connectivity',
                                myId: 'AllowVPNRoamingOverCellular',
                                defaultValue: 1,
                                checkedValue: 1,
                                options: [
                                    { label: 'Not Allowed.', value: 0 },
                                    { label: 'Allowed.', value: 1 }
                                ]
                            },
                            {
                                label: 'Internet Sharing',
                                inputType: 'checkbox',
                                value: 'AllowInternetSharing',
                                helpText: 'Allow or disallow internet sharing',
                                parentId: 'Wifi',
                                myId: 'AllowInternetSharing',
                                defaultValue: 1,
                                checkedValue: 1,
                                options: [
                                    { label: 'Not Allowed.', value: 0 },
                                    { label: 'Allowed.', value: 1 }
                                ]
                            },
                            {
                                label: 'Wi-Fi',
                                inputType: 'checkbox',
                                value: 'AllowWiFi',
                                helpText: 'Allows or disallows wifi connection',
                                parentId: 'Wifi',
                                myId: 'AllowWiFi',
                                defaultValue: 1,
                                checkedValue: 1,
                                options: [
                                    { label: 'Not Allowed.', value: 0 },
                                    { label: 'Allowed.', value: 1 }
                                ]
                            },
                            {
                                label: 'Wi-Fi Configuration',
                                inputType: 'checkbox',
                                value: 'AllowManualWiFiConfiguration',
                                helpText: 'Controls user’s access to add other Wi-Fi networks',
                                parentId: 'Wifi',
                                myId: 'AllowManualWiFiConfiguration',
                                defaultValue: 1,
                                checkedValue: 1,
                                row: true,
                                options: [
                                    { label: 'No Wi-Fi connection outside of MDM provisioned network is allowed.', value: 0 },
                                    { label: 'Adding new network SSIDs beyond the already MDM provisioned ones is allowed.', value: 1 }
                                ]
                            },
                            {
                                label: 'Allow Auto Connect To Wi-Fi Hotspots',
                                inputType: 'checkbox',
                                value: 'AllowAutoConnectToWiFiSenseHotspots',
                                helpText: 'Allows or disallows device to automatically connect to Wi-fi hotspots',
                                parentId: 'Wifi',
                                myId: 'AllowAutoConnectToWiFiSenseHotspots',
                                defaultValue: 1,
                                checkedValue: 1,
                                options: [
                                    { label: 'Not Allowed.', value: 0 },
                                    { label: 'Allowed.', value: 1 }
                                ]
                            },
                            {
                                label: 'Allow Connected Devices',
                                inputType: 'checkbox',
                                value: 'AllowConnectedDevices',
                                helpText:
                                    'Note This policy requires reboot to take effect.  Allows IT Admins the ability to disable the Connected Devices Platform (CDP) component. CDP enables discovery and connection to other devices (either proximally with BT/LAN or through the cloud) to support remote app launching, remote messaging, remote app sessions, and other cross-device experiences.',
                                parentId: 'Connectivity',
                                myId: 'AllowConnectedDevices',
                                defaultValue: 1,
                                checkedValue: 1,
                                options: [
                                    { label: 'Disable (CDP service not available).', value: 0 },
                                    { label: 'Allow (CDP service available)', value: 1 }
                                ]
                            }
                        ]
                    },
                    {
                        label: 'Bluetooth Settings',
                        fields: [
                            {
                                label: 'Bluetooth',
                                inputType: 'checkbox',
                                value: 'AllowBluetooth',
                                helpText: 'Allows or restricts Bluetooth Access',
                                parentId: 'Connectivity',
                                myId: 'AllowBluetooth',
                                defaultValue: 2,
                                checkedValue: 2,
                                options: [
                                    { label: 'Disallow.', value: 0 },
                                    { label: 'Allow.', value: 2 }
                                ]
                            },
                            {
                                label: 'Bluetooth Discovery',
                                inputType: 'checkbox',
                                value: 'AllowDiscoverableMode',
                                helpText: 'Allows or disallows device to be discovered by other devices',
                                parentId: 'Bluetooth',
                                myId: 'AllowDiscoverableMode',
                                defaultValue: 1,
                                checkedValue: 1,
                                enableOn: 'AllowBluetooth',
                                enableOnVal: [2],
                                notEnabledValue: 0,
                                options: [
                                    { label: 'Not Allowed.', value: 0 },
                                    { label: 'Allowed.', value: 1 }
                                ]
                            },
                            {
                                label: 'Bluetooth Services Advertising',
                                inputType: 'checkbox',
                                value: 'AllowAdvertising',
                                helpText: 'Allows device to send bluetooth low energy advertisements',
                                parentId: 'Bluetooth',
                                myId: 'AllowAdvertising',
                                defaultValue: 1,
                                checkedValue: 1,
                                enableOn: 'AllowBluetooth',
                                enableOnVal: [2],
                                notEnabledValue: 0,
                                options: [
                                    { label: 'Not Allowed.', value: 0 },
                                    { label: 'Allowed.', value: 1 }
                                ]
                            },
                            {
                                label: 'Bluetooth pre-pairing',
                                inputType: 'checkbox',
                                value: 'AllowPrepairing',
                                helpText:
                                    'Specifies whether certain Bluetooth devices that come with the host device can automatically pair with it',
                                parentId: 'Bluetooth',
                                myId: 'AllowPrepairing',
                                enableOn: 'AllowBluetooth',
                                enableOnVal: [2],
                                notEnabledValue: 0,
                                defaultValue: 1,
                                checkedValue: 1,
                                options: [
                                    { label: 'Not Allowed.', value: 0 },
                                    { label: 'Allowed.', value: 1 }
                                ]
                            }
                        ]
                    },
                    {
                        label: 'Security Settings',
                        fields: [
                            {
                                label: 'Location',
                                inputType: 'dropdown',
                                value: 'AllowLocation',
                                helpText: 'Specifies whether to allow app access to the Location',
                                parentId: 'System',
                                myId: 'AllowLocation',
                                defaultValue: 1,
                                row: true,
                                options: [
                                    {
                                        label: 'Force Location Off. All Location Privacy settings are toggled off and grayed out.',
                                        value: 0
                                    },
                                    {
                                        label: 'Location service is allowed. The user has control and can change Location Privacy settings on or off.',
                                        value: 1
                                    },
                                    { label: 'Force Location On. All Location Privacy settings are toggled on and grayed out.', value: 2 }
                                ]
                            },
                            {
                                label: 'Sync My Settings',
                                inputType: 'checkbox',
                                value: 'AllowSyncMySettings',
                                helpText: 'Specifies whether to allow or disallow all windows sync settings on the device',
                                parentId: 'Experience',
                                myId: 'AllowSyncMySettings',
                                defaultValue: 1,
                                checkedValue: 1,
                                options: [
                                    { label: 'Sync settings are not allowed.', value: 0 },
                                    { label: 'Sync settings allowed.', value: 1 }
                                ]
                            },
                            {
                                label: 'Developer Unlock',
                                inputType: 'dropdown',
                                value: 'AllowDeveloperUnlock',
                                helpText: 'Specifies whether developer unlock is allowed',
                                parentId: 'ApplicationManagement',
                                myId: 'AllowDeveloperUnlock',
                                defaultValue: 65535,
                                row: true,
                                options: [
                                    { label: 'Explicit deny.', value: 0 },
                                    { label: 'Explicit allow unlock.', value: 1 },
                                    { label: 'Not configured.', value: 65535 }
                                ]
                            },
                            {
                                label: 'Microsoft Account Connection',
                                inputType: 'checkbox',
                                value: 'AllowMicrosoftAccountConnection',
                                helpText:
                                    'Specifies whether the user is allowed to use MSA account for non-email related connection authentication and services',
                                parentId: 'Accounts',
                                myId: 'AllowMicrosoftAccountConnection',
                                defaultValue: 1,
                                checkedValue: 1,
                                options: [
                                    { label: 'Not Allowed.', value: 0 },
                                    { label: 'Allowed.', value: 1 }
                                ]
                            },
                            {
                                label: 'User To Reset Device',
                                inputType: 'checkbox',
                                value: 'AllowUserToResetPhone',
                                helpText: 'Specifies whether user can reset the device using hard key and the control panel combination',
                                parentId: 'System',
                                myId: 'AllowUserToResetPhone',
                                defaultValue: 1,
                                checkedValue: 1,
                                options: [
                                    { label: 'Not Allowed.', value: 0 },
                                    { label: 'Allowed to reset to factory default settings.', value: 1 }
                                ]
                            },
                            {
                                label: 'Add Provisioning Package',
                                inputType: 'checkbox',
                                value: 'AllowAddProvisioningPackage',
                                helpText: 'Specifies whether to allow the runtime configuration agent to install provisioning packages.',
                                parentId: 'Security',
                                myId: 'AllowAddProvisioningPackage',
                                defaultValue: 1,
                                checkedValue: 1,
                                options: [
                                    { label: 'Not Allowed.', value: 0 },
                                    { label: 'Allowed.', value: 1 }
                                ]
                            },
                            // {
                            //     label: 'Fips Algorithm Policy',
                            //     inputType: 'checkbox',
                            //     value: 'AllowFipsAlgorithmPolicy',
                            //     helpText: 'Specifies whether to allow the runtime configuration agent to install provisioning packages.',
                            //     parentId: 'Cryptography',
                            //     myId: 'AllowFipsAlgorithmPolicy',
                            //     defaultValue: 1,
                            //     checkedValue: 1,
                            //     options: [
                            //         { label: 'Not Allowed.', value: 0 },
                            //         { label: 'Allowed.', value: 1 }
                            //     ]
                            // },
                            {
                                label: 'Adding Non Microsoft Accounts Manually',
                                inputType: 'checkbox',
                                value: 'AllowAddingNonMicrosoftAccountsManually',
                                helpText: 'Specifies whether user is allowed to use non MSA email accounts',
                                parentId: 'Accounts',
                                myId: 'AllowAddingNonMicrosoftAccountsManually',
                                defaultValue: 1,
                                checkedValue: 1,
                                options: [
                                    { label: 'Not Allowed.', value: 0 },
                                    { label: 'Allowed.', value: 1 }
                                ]
                            },
                            {
                                label: 'Remove Provisioning Package',
                                inputType: 'checkbox',
                                value: 'AllowRemoveProvisioningPackage',
                                helpText: 'Specifies whether to allow the runtime configuration agent to remove provisioning packages.',
                                parentId: 'Security',
                                myId: 'AllowRemoveProvisioningPackage',
                                defaultValue: 1,
                                checkedValue: 1,
                                options: [
                                    { label: 'Not Allowed.', value: 0 },
                                    { label: 'Allowed.', value: 1 }
                                ]
                            }
                        ]
                    },
                    {
                        label: 'Applications Settings',
                        fields: [
                            {
                                label: 'Allow all Trusted Apps to Install',
                                value: 'AllowAllTrustedApps',
                                inputType: 'dropdown',
                                placeholder: 'Select',
                                helpText: 'Specifies whether Non-Microsoft Apps are allowed to be installed',
                                parentId: 'ApplicationManagement',
                                myId: 'AllowAllTrustedApps',
                                defaultValue: 65535,
                                options: [
                                    { label: 'Explicit Deny.', value: 0 },
                                    { label: 'Explicit Allow Unlock.', value: 1 },
                                    { label: 'Not Configured.', value: 65535 }
                                ]
                            },
                            {
                                label: 'Prevent users app data from being stored on non-system volumes',
                                inputType: 'checkbox',
                                value: 'RestrictAppDataToSystemVolume',
                                helpText: 'Specifies whether application data is restricted to the system drive',
                                parentId: 'ApplicationManagement',
                                myId: 'RestrictAppDataToSystemVolume',
                                defaultValue: 0,
                                checkedValue: 1,
                                options: [
                                    { label: 'Not Restricted.', value: 0 },
                                    { label: 'Restricted.', value: 1 }
                                ]
                            },
                            {
                                label: 'Disable installing Windows Apps on Non-System Volumes',
                                inputType: 'checkbox',
                                value: 'RestrictAppToSystemVolume',
                                helpText: 'Specifies whether the installation of applications is restricted to the system drive',
                                parentId: 'ApplicationManagement',
                                myId: 'RestrictAppToSystemVolume',
                                defaultValue: 0,
                                checkedValue: 1,
                                options: [
                                    { label: 'Not Restricted.', value: 0 },
                                    { label: 'Restricted.', value: 1 }
                                ]
                            },
                            {
                                label: 'Turn off Automatic Download and Install of apps updates',
                                value: 'AllowAppStoreAutoUpdate',
                                inputType: 'dropdown',
                                placeholder: 'Select',
                                helpText: 'Specifies whether automatic update of apps from Microsoft store are allowed',
                                parentId: 'ApplicationManagement',
                                myId: 'AllowAppStoreAutoUpdate',
                                defaultValue: 2,
                                options: [
                                    { label: 'Not Allowed.', value: 0 },
                                    { label: 'Allowed.', value: 1 },
                                    { label: 'Not Configured.', value: 2 }
                                ]
                            }
                        ]
                    },
                    {
                        label: 'Projections Settings',
                        fields: [
                            {
                                label: 'User input from Wireless Display',
                                inputType: 'checkbox',
                                value: 'AllowUserInputFromWirelessDisplayReceiver',
                                helpText:
                                    'Specifies whether the wireless display can transmit input back to the source device if the display supports such features',
                                parentId: 'WirelessDisplay',
                                myId: 'AllowUserInputFromWirelessDisplayReceiver',
                                defaultValue: 1,
                                checkedValue: 1,
                                options: [
                                    { label: 'Disabled.', value: 0 },
                                    { label: 'Enabled.', value: 1 }
                                ]
                            },
                            {
                                label: 'Projection to this PC',
                                inputType: 'checkbox',
                                value: 'AllowProjectionToPC',
                                helpText:
                                    'Specifies whether the user can control the projection settings to make the PC either discoverable and capable of receiving projections or hidden and not available for projections',
                                parentId: 'WirelessDisplay',
                                myId: 'AllowProjectionToPC',
                                defaultValue: 1,
                                checkedValue: 1,
                                options: [
                                    { label: 'Not allowed.', value: 0 },
                                    { label: 'Allowed.', value: 1 }
                                ]
                            },
                            {
                                label: 'Require PIN for pairing',
                                inputType: 'dropdown',
                                value: 'RequirePinForPairing',
                                helpText: 'Specifies if a PIN is required before pairing with other devices',
                                parentId: 'WirelessDisplay',
                                myId: 'RequirePinForPairing',
                                defaultValue: 0,
                                row: true,
                                options: [
                                    { label: 'PIN is not required.', value: 0 },
                                    { label: 'Pairing ceremony for new devices will always require a PIN', value: 1 },
                                    { label: 'All pairings will require PIN', value: 1 }
                                ]
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        label: 'Power Settings',
        helpText: 'Power settings on your Windows 10 or later devices can assist in reducing energy consumption and extend battery life.',
        note: 'Requires a restart to take effect.',
        show: ['FM', 'SA', 'MA'],
        image: powerSettings,
        fields: [
            {
                label: 'Battery'
            },
            {
                label: 'Energy Saver Battery Threshold',
                value: 'EnergySaverBatteryThresholdOnBattery',
                helpText: 'Specifies the battery charge level at which energy saver turns on',
                inputType: 'policyInput',
                regExp: '^[0-9]+$',
                valueType: 'number',
                minValue: 0,
                maxValue: 100,
                maxLength: 3,
                parentId: 'Power',
                myId: 'EnergySaverBatteryThresholdOnBattery',
                defaultValue: 0,
                placeholder: '0-100'
            },
            {
                label: 'Power Button Action',
                value: 'SelectPowerButtonActionOnBattery',
                inputType: 'dropdown',
                placeholder: 'Select',
                helpText: 'Specifies the action that Windows takes when a user presses the power button',
                parentId: 'Power',
                myId: 'SelectPowerButtonActionOnBattery',
                defaultValue: 1,
                options: [
                    { label: 'Take no Action', value: 0 },
                    { label: 'Sleep', value: 1 },
                    { label: 'System Hibernate Sleep State', value: 2 },
                    { label: 'System Shutdown', value: 3 }
                ]
            },
            {
                label: 'Sleep Button Action',
                value: 'SelectSleepButtonActionOnBattery',
                inputType: 'dropdown',
                placeholder: 'Select',
                helpText: 'Specifies the action that Windows takes when a user presses the sleep button',
                parentId: 'Power',
                myId: 'SelectSleepButtonActionOnBattery',
                defaultValue: 1,
                options: [
                    { label: 'Take no Action', value: 0 },
                    { label: 'Sleep', value: 1 },
                    { label: 'System Hibernate Sleep State', value: 2 },
                    { label: 'System Shutdown', value: 3 }
                ]
            },
            {
                label: 'Hybrid Sleep',
                inputType: 'checkbox',
                value: 'TurnOffHybridSleepOnBattery',
                helpText: 'Specifies if the user can turn on hybrid sleep mode',
                parentId: 'Power',
                myId: 'TurnOffHybridSleepOnBattery',
                defaultValue: 0,
                checkedValue: 1,
                options: [
                    { label: 'Not Configured.', value: 0 },
                    { label: 'Hybrid Sleep.', value: 1 }
                ]
            },
            {
                label: 'PluggedIn'
            },
            {
                label: 'Energy Saver Battery Threshold',
                value: 'EnergySaverBatteryThresholdPluggedIn',
                helpText: 'Specifies the battery charge level at which energy saver turns on',
                inputType: 'policyInput',
                regExp: '^[0-9]+$',
                valueType: 'number',
                minValue: 0,
                maxValue: 100,
                maxLength: 3,
                parentId: 'Power',
                myId: 'EnergySaverBatteryThresholdPluggedIn',
                defaultValue: 0,
                placeholder: '0-100'
            },
            {
                label: 'Power Button Action',
                value: 'SelectPowerButtonActionPluggedIn',
                inputType: 'dropdown',
                placeholder: 'Select',
                helpText: 'Specifies the action that Windows takes when a user presses the power button',
                parentId: 'Power',
                myId: 'SelectPowerButtonActionPluggedIn',
                defaultValue: 1,
                options: [
                    { label: 'Take no action', value: 0 },
                    { label: 'Sleep', value: 1 },
                    { label: 'System hibernate sleep state', value: 2 },
                    { label: 'System shutdown', value: 3 }
                ]
            },
            {
                label: 'Sleep Button Action',
                value: 'SelectSleepButtonActionPluggedIn',
                inputType: 'dropdown',
                placeholder: 'Select',
                helpText: 'Specifies the action that Windows takes when a user presses the sleep button',
                parentId: 'Power',
                myId: 'SelectSleepButtonActionPluggedIn',
                defaultValue: 1,
                options: [
                    { label: 'Take no Action', value: 0 },
                    { label: 'Sleep', value: 1 },
                    { label: 'System Hibernate Sleep State', value: 2 },
                    { label: 'System Shutdown', value: 3 }
                ]
            },
            {
                label: 'Hybrid Sleep',
                inputType: 'checkbox',
                value: 'TurnOffHybridSleepPluggedIn',
                helpText: 'Specifies if the user can turn on hybrid sleep mode',
                parentId: 'Power',
                myId: 'TurnOffHybridSleepPluggedIn',
                defaultValue: 0,
                checkedValue: 1,
                options: [
                    { label: 'Not Configured.', value: 0 },
                    { label: 'Hybrid Sleep.', value: 1 }
                ]
            }
        ]
    },
    {
        label: 'Windows Update',
        helpText: 'Use this section to configure Windows Update settings.',
        show: ['FM', 'SA', 'MA'],
        image: windowsUpdate,
        fields: [
            {
                label: 'Microsoft product updates',
                inputType: 'checkbox',
                value: 'SetDisableUXWUAccess',
                helpText: 'This setting allows you to remove access to scan windows update.',
                parentId: 'Update',
                myId: 'SetDisableUXWUAccess',
                defaultValue: 0,
                checkedValue: 1,
                options: [
                    { label: 'Disabled.', value: 0 },
                    { label: 'Enabled.', value: 1 }
                ]
            },
            {
                label: 'Windows drivers',
                inputType: 'checkbox',
                value: 'SetPolicyDrivenUpdateSourceForDriverUpdates',
                helpText:
                    'Specify whether to receive Windows Driver Updates from Windows Update endpoint, managed by Windows Update for Business policies, or through your configured Windows Server Update Service (WSUS) server.',
                parentId: 'Update',
                myId: 'SetPolicyDrivenUpdateSourceForDriverUpdates',
                defaultValue: 1,
                checkedValue: 1,
                options: [
                    { label: 'Detect, download and deploy Driver Updates from Windows Update.', value: 0 },
                    { label: 'Detect, download and deploy Driver Updates from Windows Server Update Services (WSUS).', value: 1 }
                ]
            },
            {
                label: 'Quality Updates deferral period',
                value: 'DeferQualityUpdatesPeriodInDays',
                helpText: 'Specifies the deferral period in days',
                inputType: 'policyInput',
                regExp: '^[0-9]+$',
                valueType: 'number',
                minValue: 0,
                maxValue: 30,
                maxLength: 2,
                parentId: 'Update',
                myId: 'DeferQualityUpdatesPeriodInDays',
                defaultValue: 0,
                placeholder: 'Range: [0-30]'
            },
            {
                label: 'Feature Updates deferral period',
                value: 'DeferFeatureUpdatesPeriodInDays',
                helpText: 'Specifies the deferral period in days',
                inputType: 'policyInput',
                regExp: '^[0-9]+$',
                valueType: 'number',
                minValue: 0,
                maxValue: 30,
                maxLength: 2,
                parentId: 'Update',
                myId: 'DeferFeatureUpdatesPeriodInDays',
                defaultValue: 0,
                placeholder: 'Range: [0-30]'
            },
            {
                label: 'Upgrade Windows 10 devices to Latest Windows 11 release',
                value: 'ProductVersion',
                inputType: 'dropdown',
                placeholder: 'Select',
                helpText:
                    'Specifies version as listed on the Windows Update target version page (Select the target Feature Update version)',
                parentId: 'Update',
                myId: 'ProductVersion',
                defaultValue: 'Windows 10',
                options: [
                    { label: 'Windows 10', value: 'Windows 10' },
                    { label: 'Windows 11', value: 'Windows 11' }
                ]
            },
            {
                label: 'Set feature update uninstall period',
                value: 'ConfigureFeatureUpdateUninstallPeriod',
                helpText: 'Specifies the feature update uninstall period in days',
                inputType: 'policyInput',
                regExp: '^[0-9]+$',
                valueType: 'number',
                minValue: 2,
                maxValue: 60,
                maxLength: 2,
                parentId: 'Update',
                myId: 'ConfigureFeatureUpdateUninstallPeriod',
                defaultValue: 10,
                placeholder: 'Range: [2-60]'
            },
            {
                label: 'Enable pre-release builds',
                value: 'ManagePreviewBuilds',
                inputType: 'dropdown',
                placeholder: 'Select',
                helpText: 'Specifies which updates you receive prior to the update being released to the world.',
                parentId: 'Update',
                myId: 'ManagePreviewBuilds',
                defaultValue: 3,
                options: [
                    { label: 'Disable Preview builds', value: 0 },
                    { label: 'Disable Preview builds once the next release is public', value: 1 },
                    { label: 'Enable Preview builds', value: 2 },
                    { label: 'Preview builds is left to user selection', value: 3 }
                ]
            },
            {
                label: 'User experience settings'
            },
            {
                label: 'Automatic update behavior',
                value: 'AllowAutoUpdate',
                inputType: 'dropdown',
                placeholder: 'Select',
                helpText: 'Specifies which updates you receive prior to the update being released to the world.',
                parentId: 'Update',
                myId: 'AllowAutoUpdate',
                defaultValue: 2,
                options: [
                    { label: 'Notify the user before downloading the update', value: 0 },
                    { label: 'Auto install at maintainance time', value: 1 },
                    { label: 'Auto install and restart at maintainance time ', value: 2 },
                    { label: 'Auto install and restart at a specified time', value: 3 },
                    { label: 'Auto install and restart without end-user control.', value: 4 },
                    {
                        label: 'Turn off automatic updates',
                        value: 5
                    }
                ]
            },
            {
                label: 'Active hours start',
                value: 'ActiveHoursStart',
                inputType: 'dropdown',
                placeholder: 'Select',
                helpText: 'Specifies which updates you receive prior to the update being released to the world.',
                parentId: 'Update',
                myId: 'ActiveHoursStart',
                defaultValue: 8,
                enableOn: 'AllowAutoUpdate',
                enableOnVal: [1, 2],
                options: [
                    { label: '12 AM', value: 0 },
                    { label: '1 AM', value: 1 },
                    { label: '2 AM', value: 2 },
                    { label: '3 AM', value: 3 },
                    { label: '4 AM', value: 4 },
                    {
                        label: '5 AM',
                        value: 5
                    },
                    { label: '6 AM', value: 6 },
                    { label: '7 AM', value: 7 },
                    { label: '8 AM', value: 8 },
                    { label: '9 AM', value: 9 },
                    { label: '10 AM', value: 10 },
                    { label: '11 AM', value: 11 },
                    { label: '12 PM', value: 12 },
                    { label: '1 PM', value: 13 },
                    { label: '2 PM', value: 14 },
                    { label: '3 PM', value: 15 },
                    { label: '4 PM', value: 16 },
                    { label: '5 PM', value: 17 },
                    { label: '6 PM', value: 18 },
                    { label: '7 PM', value: 19 },
                    { label: '8 PM', value: 20 },
                    { label: '9 PM', value: 21 },
                    { label: '10 PM', value: 22 },
                    { label: '11 PM', value: 23 }
                ]
            },
            {
                label: 'Active hours end',
                value: 'ActiveHoursEnd',
                inputType: 'dropdown',
                placeholder: 'Select',
                helpText: 'Specifies which updates you receive prior to the update being released to the world.',
                parentId: 'Update',
                myId: 'ActiveHoursEnd',
                defaultValue: 17,
                enableOn: 'AllowAutoUpdate',
                enableOnVal: [1, 2],
                options: [
                    { label: '12 AM', value: 0 },
                    { label: '1 AM', value: 1 },
                    { label: '2 AM', value: 2 },
                    { label: '3 AM', value: 3 },
                    { label: '4 AM', value: 4 },
                    {
                        label: '5 AM',
                        value: 5
                    },
                    { label: '6 AM', value: 6 },
                    { label: '7 AM', value: 7 },
                    { label: '8 AM', value: 8 },
                    { label: '9 AM', value: 9 },
                    { label: '10 AM', value: 10 },
                    { label: '11 AM', value: 11 },
                    { label: '12 PM', value: 12 },
                    { label: '1 PM', value: 13 },
                    { label: '2 PM', value: 14 },
                    { label: '3 PM', value: 15 },
                    { label: '4 PM', value: 16 },
                    { label: '5 PM', value: 17 },
                    { label: '6 PM', value: 18 },
                    { label: '7 PM', value: 19 },
                    { label: '8 PM', value: 20 },
                    { label: '9 PM', value: 21 },
                    { label: '10 PM', value: 22 },
                    { label: '11 PM', value: 23 }
                ]
            },
            {
                label: 'Automatic behavior frequency',
                enableOn: 'AllowAutoUpdate',
                enableOnVal: [3]
            },
            {
                label: 'Scheduled Install Every Week',
                inputType: 'checkbox',
                value: 'ScheduledInstallEveryWeek',
                helpText: 'Schedules the update installation on the every week',
                parentId: 'Update',
                myId: 'ScheduledInstallEveryWeek',
                defaultValue: 1,
                enableOn: 'AllowAutoUpdate',
                enableOnVal: [3],
                checkedValue: 1,
                options: [
                    { label: 'No update in the schedule.', value: 0 },
                    { label: 'Update is scheduled every week.', value: 1 }
                ]
            },
            {
                label: 'Scheduled Install First Week',
                inputType: 'checkbox',
                value: 'ScheduledInstallFirstWeek',
                helpText: 'Schedules the update installation on the first week of the month',
                parentId: 'Update',
                myId: 'ScheduledInstallFirstWeek',
                defaultValue: 0,
                enableOn: 'AllowAutoUpdate',
                enableOnVal: [3],
                checkedValue: 1,
                options: [
                    { label: 'No update in the schedule.', value: 0 },
                    { label: 'Update is scheduled every first week of the month.', value: 1 }
                ]
            },
            {
                label: 'Scheduled Install Second Week',
                inputType: 'checkbox',
                value: 'ScheduledInstallSecondWeek',
                helpText: 'Schedules the update installation on the second week of the month',
                parentId: 'Update',
                myId: 'ScheduledInstallSecondWeek',
                defaultValue: 0,
                enableOn: 'AllowAutoUpdate',
                enableOnVal: [3],
                checkedValue: 1,
                options: [
                    { label: 'No update in the schedule.', value: 0 },
                    { label: 'Update is scheduled every second week of the month.', value: 1 }
                ]
            },
            {
                label: 'Scheduled Install Third Week',
                inputType: 'checkbox',
                value: 'ScheduledInstallThirdWeek',
                helpText: 'Schedules the update installation on the third week of the month',
                parentId: 'Update',
                myId: 'ScheduledInstallThirdWeek',
                defaultValue: 0,
                enableOn: 'AllowAutoUpdate',
                enableOnVal: [3],
                checkedValue: 1,
                options: [
                    { label: 'No update in the schedule.', value: 0 },
                    { label: 'Update is scheduled every third week of the month.', value: 1 }
                ]
            },
            {
                label: 'Scheduled Install Fourth Week',
                inputType: 'checkbox',
                value: 'ScheduledInstallFourthWeek',
                helpText: 'Schedules the update installation on the fourth week of the month',
                parentId: 'Update',
                myId: 'ScheduledInstallFourthWeek',
                defaultValue: 0,
                enableOn: 'AllowAutoUpdate',
                enableOnVal: [3],
                checkedValue: 1,
                options: [
                    { label: 'No update in the schedule.', value: 0 },
                    { label: 'Update is scheduled every fourth week of the month.', value: 1 }
                ]
            },
            {
                label: 'Scheduled install day',
                value: 'ScheduledInstallDay',
                inputType: 'dropdown',
                placeholder: 'Select',
                helpText: 'Specifies the schedule day of the update installation',
                parentId: 'Update',
                myId: 'ScheduledInstallDay',
                defaultValue: 0,
                enableOn: 'AllowAutoUpdate',
                enableOnVal: [3],
                options: [
                    { label: 'Every day', value: 0 },
                    { label: 'Sunday', value: 1 },
                    { label: 'Monday', value: 2 },
                    { label: 'Tuesday', value: 3 },
                    { label: 'Wednesday', value: 4 },
                    { label: 'Thursday', value: 5 },
                    { label: 'Friday', value: 6 },
                    { label: 'Saturday', value: 7 }
                ]
            },
            {
                label: 'Scheduled install time',
                value: 'ScheduledInstallTime',
                inputType: 'dropdown',
                helpText: 'Specifies the schedule time of the update installation',
                parentId: 'Update',
                myId: 'ScheduledInstallTime',
                defaultValue: 3,
                enableOn: 'AllowAutoUpdate',
                enableOnVal: [3],
                options: [
                    { label: '12 AM', value: 0 },
                    { label: '1 AM', value: 1 },
                    { label: '2 AM', value: 2 },
                    { label: '3 AM', value: 3 },
                    { label: '4 AM', value: 4 },
                    { label: '5 AM', value: 5 },
                    { label: '6 AM', value: 6 },
                    { label: '7 AM', value: 7 },
                    { label: '8 AM', value: 8 },
                    { label: '9 AM', value: 9 },
                    { label: '10 AM', value: 10 },
                    { label: '11 AM', value: 11 },
                    { label: '12 PM', value: 12 },
                    { label: '1 PM', value: 13 },
                    { label: '2 PM', value: 14 },
                    { label: '3 PM', value: 15 },
                    { label: '4 PM', value: 16 },
                    { label: '5 PM', value: 17 },
                    { label: '6 PM', value: 18 },
                    { label: '7 PM', value: 19 },
                    { label: '8 PM', value: 20 },
                    { label: '9 PM', value: 21 },
                    { label: '10 PM', value: 22 },
                    { label: '11 PM', value: 23 }
                ]
            },
            {
                label: 'Pause Windows updates',
                inputType: 'checkbox',
                value: 'SetDisablePauseUXAccess',
                helpText: 'Remove access to pause updates feature',
                parentId: 'Update',
                myId: 'SetDisablePauseUXAccess',
                defaultValue: 0,
                checkedValue: 1,
                options: [
                    { label: 'Disable.', value: 0 },
                    { label: 'Enable.', value: 1 }
                ]
            },
            // {
            //     label: 'Check for Windows updates',
            //     inputType: 'checkbox',
            //     value: 'SetDisableUXWUAccess',
            //     helpText: 'Remove access to use all windows update features',
            //     parentId: 'Update',
            //     myId: 'SetDisableUXWUAccess',
            //     defaultValue: 0,
            //     checkedValue: 1,
            //     options: [
            //         { label: 'Disabled.', value: 0 },
            //         { label: 'Enabled.', value: 1 }
            //     ]
            // },
            {
                label: 'Change notification update level',
                value: 'UpdateNotificationLevel',
                inputType: 'dropdown',
                placeholder: 'Select',
                helpText: 'Display options for update notifications',
                parentId: 'Update',
                myId: 'UpdateNotificationLevel',
                defaultValue: 0,
                options: [
                    { label: 'Use the default Windows Update notifications.', value: 0 },
                    { label: 'Turn off all notifications, excluding restart warnings.', value: 1 },
                    { label: 'Turn off all notifications, including restart warnings.', value: 2 }
                ]
            },
            {
                label: 'Deadline Settings'
                // inputType: 'checkbox',
                // value: 'deadlineSettings',
                // helpText: 'Allow/disallow deadline settings',
                // parentId: 'Update',
                // myId: 'deadlineSettings',
                // defaultValue: 0,
                // checkedValue: 1,
                // options: [
                //     { label: 'Disabled.', value: 0 },
                //     { label: 'Enabled.', value: 1 }
                // ]
            },
            {
                label: 'Deadline for feature updates',
                value: 'ConfigureDeadlineForFeatureUpdates',
                helpText: 'Specifies the feature update uninstall period in days',
                inputType: 'policyInput',
                regExp: '^[0-9]+$',
                valueType: 'number',
                minValue: 0,
                maxValue: 30,
                maxLength: 2,
                parentId: 'Update',
                myId: 'ConfigureDeadlineForFeatureUpdates',
                defaultValue: 2,
                // enableOn: 'deadlineSettings',
                // enableOnVal: [1],
                placeholder: 'Range: [0-30]'
            },
            {
                label: 'Deadline for quality updates',
                value: 'ConfigureDeadlineForQualityUpdates',
                helpText: 'Specifies the number of days before quality updates are installed on devices.',
                inputType: 'policyInput',
                regExp: '^[0-9]+$',
                valueType: 'number',
                minValue: 0,
                maxValue: 30,
                maxLength: 2,
                parentId: 'Update',
                myId: 'ConfigureDeadlineForQualityUpdates',
                defaultValue: 7,
                // enableOn: 'deadlineSettings',
                // enableOnVal: [1],
                placeholder: 'Range: [0-30]'
            },
            {
                label: 'Grace period',
                value: 'ConfigureDeadlineGracePeriod',
                helpText: 'Specifies the minimum number of days from update installation until restarts occur automatically.',
                inputType: 'policyInput',
                regExp: '^[0-9]+$',
                valueType: 'number',
                minValue: 0,
                maxValue: 7,
                maxLength: 1,
                parentId: 'Update',
                myId: 'ConfigureDeadlineGracePeriod',
                defaultValue: 2,
                // enableOn: 'deadlineSettings',
                // enableOnVal: [1],
                placeholder: 'Range: [0-7]'
            },
            {
                label: 'Auto reboot before deadline',
                inputType: 'checkbox',
                value: 'ConfigureDeadlineNoAutoRebootForFeatureUpdates',
                helpText:
                    "If you disable or don't configure this policy, devices will get updates and will restart according to the default schedule",
                parentId: 'Update',
                myId: 'ConfigureDeadlineNoAutoRebootForFeatureUpdates',
                defaultValue: 0,
                checkedValue: 1,
                // enableOn: 'deadlineSettings',
                // enableOnVal: [1],
                options: [
                    { label: 'Disabled.', value: 0 },
                    { label: 'Enabled.', value: 1 }
                ]
            }
        ]
    },
    {
        label: 'Windows AI',
        helpText: 'Use this section for managing the Windows AI features.',
        note: 'This policy only applies for Windows Insider Preview builds',
        show: ['FM', 'SA', 'MA'],
        image: startMenu,
        fields: [
            {
                label: 'Disable Image Creator for Windows Paint',
                value: 'DisableImageCreator',
                helpText: 'Specifies whether Image Creator functionality is disabled in the Windows Paint app',
                inputType: 'checkbox',
                parentId: 'WindowsAI',
                myId: 'DisableImageCreator',
                defaultValue: 0,
                checkedValue: 0,
                options: [
                    { label: 'Enabled', value: 0 },
                    { label: 'Disabled', value: 1 }
                ]
            },
            {
                label: 'Allow AI Data Analysis (Windows Recall)',
                value: 'AllowRecallEnablement',
                helpText: 'Specifies whether the Recall is available',
                inputType: 'checkbox',
                parentId: 'WindowsAI',
                myId: 'AllowRecallEnablement',
                defaultValue: 0,
                checkedValue: 1,
                options: [
                    { label: "Recall isn't available.", value: 0 },
                    { label: 'Recall is available', value: 1 }
                ]
            },
            {
                label: 'Disable Windows Copilot',
                value: 'TurnOffWindowsCopilot',
                helpText: 'Specifies whether the Windows Copilot is disabled',
                inputType: 'checkbox',
                parentId: 'WindowsAI',
                myId: 'TurnOffWindowsCopilot',
                defaultValue: 1,
                checkedValue: 1,
                options: [
                    { label: 'Enable Copilot.', value: 0 },
                    { label: 'Disable Copilot', value: 1 }
                ]
            }
        ]
    },
    {
        label: 'BitLocker',
        helpText: 'Use this section to manage encryption in for devices.',
        show: ['FM', 'SA', 'MA'],
        image: startMenu,
        fields: [
            {
                label: 'Configure Encryption Methods',
                value: 'EncryptionMethodByDriveType',
                inputType: 'bitLockerDropdowns',
                helpText: 'Specifies how drivers are encrypted',
                parentId: 'BitLocker',
                myId: 'EncryptionMethodByDriveType',
                defaultValue:
                    "<enabled/><data id='EncryptionMethodWithXtsOsDropDown_Name' value='3'/><data id='EncryptionMethodWithXtsFdvDropDown_Name' value='3'/><data id='EncryptionMethodWithXtsRdvDropDown_Name' value='3'/>",
                fields: [
                    {
                        label: 'Operating System Drives',
                        value: 'EncryptionMethodWithXtsOsDropDown_Name',
                        options: [
                            { label: 'AES-CBC 128', value: '3' },
                            { label: 'AES-CBC 256', value: '4' },
                            { label: 'XTS-AES 128', value: '6' },
                            { label: 'XTS-AES 256', value: '7' }
                        ]
                    },
                    {
                        label: 'Fixed Data-Drives',
                        value: 'EncryptionMethodWithXtsFdvDropDown_Name',
                        options: [
                            { label: 'AES-CBC 128', value: '3' },
                            { label: 'AES-CBC 256', value: '4' },
                            { label: 'XTS-AES 128', value: '6' },
                            { label: 'XTS-AES 256', value: '7' }
                        ]
                    },
                    {
                        label: 'Removable Data-Drives',
                        value: 'EncryptionMethodWithXtsRdvDropDown_Name',
                        options: [
                            { label: 'AES-CBC 128', value: '3' },
                            { label: 'AES-CBC 256', value: '4' },
                            { label: 'XTS-AES 128', value: '6' },
                            { label: 'XTS-AES 256', value: '7' }
                        ]
                    }
                ]
            },
            {
                label: 'Settings for Azure AD Joined Devices'
            },
            {
                label: 'Allow Warning for Other Disk Encryption',
                inputType: 'checkbox',
                value: 'AllowWarningForOtherDiskEncryption',
                helpText: 'Specifies whether the warning prompt is allowed',
                parentId: 'BitLocker',
                myId: 'AllowWarningForOtherDiskEncryption',
                defaultValue: 0,
                checkedValue: 1,
                options: [
                    { label: 'Prompt Allowed', value: 1 },
                    { label: 'Disables Prompt', value: 0 }
                ]
            },
            {
                label: 'Allow Standard User Encryption',
                inputType: 'checkbox',
                value: 'AllowStandardUserEncryption',
                helpText: 'Specifies whether the standard user encryption is allowed',
                parentId: 'BitLocker',
                myId: 'AllowStandardUserEncryption',
                enableOn: 'AllowWarningForOtherDiskEncryption',
                enableOnVal: [0],
                defaultValue: 0,
                checkedValue: 1,
                options: [
                    { label: 'Disabled', value: 0 },
                    { label: 'Allowed', value: 1 }
                ]
            },
            {
                label: 'Configure Startup Authentication for System Drives',
                value: 'SystemDrivesRequireStartupAuthentication',
                inputType: 'bitLockerStartup',
                helpText: 'Specifies whether bitlocker has a additional authentication',
                parentId: 'BitLocker',
                myId: 'SystemDrivesRequireStartupAuthentication',
                defaultValue: '',
                fields: [
                    {
                        label: 'Enforce Additional Authentication at Startup',
                        checkedValue: '<enabled>'
                    },
                    {
                        label: 'Allow BitLocker without a compatible TPM',
                        checkedValue: "<data id='ConfigureNonTPMStartupKeyUsage_Name' value='true'>"
                    },
                    {
                        label: 'Configure TPM startup key',
                        checkedValue: "<data id='ConfigureTPMStartupKeyUsageDropDown_Name' value='2'>"
                    },
                    {
                        label: 'Configure TPM startup PIN',
                        checkedValue: "<data id='ConfigurePINUsageDropDown_Name' value='2'>"
                    },
                    {
                        label: 'Configure TPM startup key and PIN',
                        checkedValue: "<data id='ConfigureTPMPINKeyUsageDropDown_Name' value='2'>"
                    },
                    {
                        label: 'Configure TPM during startup',
                        checkedValue: "<data id='ConfigureTPMUsageDropDown_Name' value='2'>"
                    }
                ]
            },
            {
                label: 'Set minimum PIN Length for Startup PIN',
                value: 'SystemDrivesMinimumPINLength',
                inputType: 'tempInput',
                helpText: 'Specifies minimum length for a TPM startup PIN',
                parentId: 'BitLocker',
                myId: 'SystemDrivesMinimumPINLength',
                dataId: 'MinPINLength',
                placeholder: 'Range: [6-20]',
                regExp: '^[1-2]$|^[6-9]$|^[1][0-9]$|^20$',
                enableOn: 'SystemDrivesRequireStartupAuthentication',
                enableOnVal: ['<enabled>'],
                subStringCheck: true,
                defaultValue: "<enabled/> <data id='MinPINLength' value='6'/>"
            },
            {
                label: 'Recovery Options for System Drives',
                value: 'SystemDrivesRecoveryOptions',
                inputType: 'bitLockerRecovery',
                helpText:
                    'Specifies how BitLocker-protected operating system drives are recovered in the absence of the required startup key information',
                parentId: 'BitLocker',
                myId: 'SystemDrivesRecoveryOptions',
                defaultValue: '',
                fields: [
                    {
                        label: 'Configure Recovery Option for System Drives',
                        inputType: 'checkbox',
                        checkedValue: '<enabled>',
                        initialCheckedValue:
                            "<enabled><data id='OSAllowDRA_Name' value='false'/><data id='OSRecoveryKeyUsageDropDown_Name' value='0'/><data id='OSRecoveryPasswordUsageDropDown_Name' value='0'/><data id='OSActiveDirectoryBackup_Name' value='false'/><data id='OSActiveDirectoryBackupDropDown_Name' value='1'/><data id='OSRequireActiveDirectoryBackup_Name' value='false'/><data id='OSHideRecoveryPage_Name' value='false'/>"
                    },
                    {
                        label: 'Allow Certificate-Based Data Recovery Agent',
                        inputType: 'checkbox',
                        checkedValue: "<data id='OSAllowDRA_Name' value='true'/>",
                        unCheckedValue: "<data id='OSAllowDRA_Name' value='false'/>"
                    },
                    {
                        label: 'OS Recovery Key',
                        inputType: 'dropdown',
                        options: [
                            {
                                label: 'Do not allow users to generate Recovery Key',
                                value: "<data id='OSRecoveryKeyUsageDropDown_Name' value='0'/>"
                            },
                            {
                                label: 'Enforce users to generate Recovery Key',
                                value: "<data id='OSRecoveryKeyUsageDropDown_Name' value='1'/>"
                            },
                            {
                                label: 'Allow users to generate Recovery Key',
                                value: "<data id='OSRecoveryKeyUsageDropDown_Name' value='2'/>"
                            }
                        ]
                    },
                    {
                        label: 'OS Recovery Password',
                        inputType: 'dropdown',
                        options: [
                            {
                                label: 'Do not allow users to generate Recovery Password',
                                value: "<data id='OSRecoveryPasswordUsageDropDown_Name' value='0'/>"
                            },
                            {
                                label: 'Enforce users to generate Recovery Password',
                                value: "<data id='OSRecoveryPasswordUsageDropDown_Name' value='1'/>"
                            },
                            {
                                label: 'Allow users to generate Recovery Password',
                                value: "<data id='OSRecoveryPasswordUsageDropDown_Name' value='2'/>"
                            }
                        ]
                    },
                    {
                        label: 'Save BitLocker recovery information to Azure AD',
                        inputType: 'checkbox',
                        checkedValue: "<data id='OSActiveDirectoryBackup_Name' value='true'/>",
                        unCheckedValue: "<data id='OSActiveDirectoryBackup_Name' value='false'/>"
                    },
                    {
                        label: 'Select information to Sync to Azure AD Domain Services',
                        inputType: 'dropdown',
                        options: [
                            {
                                label: 'Store recovery passwords and key packages',
                                value: "<data id='OSActiveDirectoryBackupDropDown_Name' value='1'/>"
                            },
                            {
                                label: 'Store recovery passwords only',
                                value: "<data id='OSActiveDirectoryBackupDropDown_Name' value='2'/>"
                            }
                        ]
                    },
                    {
                        label: 'Disable BitLocker Until Recovery Information is synced to Azure AD DS',
                        inputType: 'checkbox',
                        checkedValue: "<data id='OSRequireActiveDirectoryBackup_Name' value='true'/>",
                        unCheckedValue: "<data id='OSRequireActiveDirectoryBackup_Name' value='false'/>"
                    },
                    {
                        label: 'Hide Recovery Options from BitLocker setup wizard',
                        inputType: 'checkbox',
                        checkedValue: "<data id='OSHideRecoveryPage_Name' value='true'/>",
                        unCheckedValue: "<data id='OSHideRecoveryPage_Name' value='false'/>"
                    }
                ]
            },
            {
                label: 'Recovery Options for Fixed Drives',
                value: 'FixedDrivesRecoveryOptions',
                inputType: 'bitLockerRecovery',
                helpText: 'Specifies how BitLocker-protected fixed data drives are recovered in the absence of the required credentials.',
                parentId: 'BitLocker',
                myId: 'FixedDrivesRecoveryOptions',
                defaultValue: '',
                fields: [
                    {
                        label: 'Configure Recovery Option for Fixed Drives',
                        inputType: 'checkbox',
                        checkedValue: '<enabled>',
                        initialCheckedValue:
                            "<enabled><data id='FDVAllowDRA_Name' value='false'/><data id='FDVRecoveryKeyUsageDropDown_Name' value='0'/><data id='FDVRecoveryPasswordUsageDropDown_Name' value='0'/><data id='FDVActiveDirectoryBackup_Name' value='false'/><data id='FDVActiveDirectoryBackupDropDown_Name' value='1'/><data id='FDVRequireActiveDirectoryBackup_Name' value='false'/><data id='FDVHideRecoveryPage_Name' value='false'/>"
                    },
                    {
                        label: 'Allow Certificate-Based Data Recovery Agent',
                        inputType: 'checkbox',
                        checkedValue: "<data id='FDVAllowDRA_Name' value='true'/>",
                        unCheckedValue: "<data id='FDVAllowDRA_Name' value='false'/>"
                    },
                    {
                        label: 'Recovery key',
                        inputType: 'dropdown',
                        options: [
                            {
                                label: 'Do not allow users to generate Recovery Key',
                                value: "<data id='FDVRecoveryKeyUsageDropDown_Name' value='0'/>"
                            },
                            {
                                label: 'Enforce users to generate Recovery Key',
                                value: "<data id='FDVRecoveryKeyUsageDropDown_Name' value='1'/>"
                            },
                            {
                                label: 'Allow users to generate Recovery Key',
                                value: "<data id='FDVRecoveryKeyUsageDropDown_Name' value='2'/>"
                            }
                        ]
                    },
                    {
                        label: 'Recovery password',
                        inputType: 'dropdown',
                        options: [
                            {
                                label: 'Do not allow users to generate Recovery password',
                                value: "<data id='FDVRecoveryPasswordUsageDropDown_Name' value='0'/>"
                            },
                            {
                                label: 'Enforce users to generate Recovery password',
                                value: "<data id='FDVRecoveryPasswordUsageDropDown_Name' value='1'/>"
                            },
                            {
                                label: 'Allow users to generate Recovery password',
                                value: "<data id='FDVRecoveryPasswordUsageDropDown_Name' value='2'/>"
                            }
                        ]
                    },
                    {
                        label: 'Save BitLocker recovery information to Azure AD',
                        inputType: 'checkbox',
                        checkedValue: "<data id='FDVActiveDirectoryBackup_Name' value='true'/>",
                        unCheckedValue: "<data id='FDVActiveDirectoryBackup_Name' value='false'/>"
                    },
                    {
                        label: 'Select information to Sync to Azure AD Domain Services',
                        inputType: 'dropdown',
                        options: [
                            {
                                label: 'Store recovery passwords and key packages',
                                value: "<data id='FDVActiveDirectoryBackupDropDown_Name' value='1'/>"
                            },
                            {
                                label: 'Store recovery passwords only',
                                value: "<data id='FDVActiveDirectoryBackupDropDown_Name' value='2'/>"
                            }
                        ]
                    },
                    {
                        label: 'Disable BitLocker Until Recovery Information is synced to Azure AD DS',
                        inputType: 'checkbox',
                        checkedValue: "<data id='FDVRequireActiveDirectoryBackup_Name' value='true'/>",
                        unCheckedValue: "<data id='FDVRequireActiveDirectoryBackup_Name' value='false'/>"
                    },
                    {
                        label: 'Hide Recovery Options from BitLocker setup wizard',
                        inputType: 'checkbox',
                        checkedValue: "<data id='FDVHideRecoveryPage_Name' value='true'/>",
                        unCheckedValue: "<data id='FDVHideRecoveryPage_Name' value='false'/>"
                    }
                ]
            },
            {
                label: 'Configure Write Access to Drives'
            },
            {
                label: 'Block Write Access to Fixed Drives until encrypted',
                inputType: 'checkbox',
                value: 'FixedDrivesRequireEncryption',
                helpText: 'Specifies whether BitLocker protection is required for fixed data drives to be writable on a computer',
                parentId: 'BitLocker',
                myId: 'FixedDrivesRequireEncryption',
                defaultValue: '<disabled/>',
                checkedValue: '<enabled/>',
                options: [
                    { label: 'Allowed', value: '<enabled/>' },
                    { label: 'Disabled', value: '<disabled/>' }
                ]
            },
            {
                label: 'Block Write Access to Removable Drives until encrypted',
                inputType: 'checkbox',
                value: 'RemovableDrivesRequireEncryption',
                helpText:
                    'Specifies whether BitLocker protection is required for a computer to be able to write data to a removable data drive',
                parentId: 'BitLocker',
                myId: 'RemovableDrivesRequireEncryption',
                defaultValue: "<enabled/><data id='RDVCrossOrg' value='false'/>",
                checkedValue: "<enabled/><data id='RDVCrossOrg' value='true'/>",
                options: [
                    { label: 'Allowed', value: "<enabled/><data id='RDVCrossOrg' value='true'/>" },
                    { label: 'Disabled', value: "<enabled/><data id='RDVCrossOrg' value='false'/>" }
                ]
            }
        ]
    },
    {
        label: 'Edge Browser',
        helpText: 'Use this section for Microsoft Edge Broswer Settings',
        show: ['FM', 'SA', 'MA'],
        image: edgeBrowser,
        note: 'These settings are for the previous version of Microsoft Edge (version 45 and earlier) and are deprecated.',
        fields: [
            {
                label: 'Allow Address bar drop-down list suggestions',
                inputType: 'checkbox',
                value: 'AllowAddressBarDropdown',
                helpText: 'Allow Address bar drop-down list suggestions',
                parentId: 'Browser',
                myId: 'AllowAddressBarDropdown',
                defaultValue: 1,
                checkedValue: 1,
                options: [
                    { label: 'Disabled', value: 0 },
                    { label: 'Allowed', value: 1 }
                ]
            },
            {
                label: 'Configure Autofill',
                inputType: 'checkbox',
                value: 'AllowAutofill',
                helpText: 'Configure Autofill',
                parentId: 'Browser',
                myId: 'AllowAutofill',
                defaultValue: 0,
                checkedValue: 1,
                options: [
                    { label: 'Prevented/Not allowed.', value: 0 },
                    { label: 'Allowed', value: 1 }
                ]
            },
            // {
            //     label: 'Allow Browser',
            //     inputType: 'checkbox',
            //     value: 'AllowBrowser',
            //     helpText: 'Allow Browser',
            //     parentId: 'Browser',
            //     myId: 'AllowBrowser',
            //     defaultValue: 1,
            //     checkedValue: 1,
            //     options: [
            //         { label: 'Prevented/Not allowed.', value: 0 },
            //         { label: 'Allowed', value: 1 }
            //     ]
            // },
            {
                label: 'Automatically update the configuration data for the Books Library',
                inputType: 'checkbox',
                value: 'AllowConfigurationUpdateForBooksLibrary',
                helpText: 'Automatically update the configuration data for the Books Library',
                parentId: 'Browser',
                myId: 'AllowConfigurationUpdateForBooksLibrary',
                defaultValue: 1,
                checkedValue: 1,
                options: [
                    { label: 'Not allowed.', value: 0 },
                    { label: 'Allowed', value: 1 }
                ]
            },
            {
                label: 'Configure cookies',
                inputType: 'dropdown',
                value: 'AllowCookies',
                helpText: 'Configure cookies',
                parentId: 'Browser',
                myId: 'AllowCookies',
                defaultValue: 2,
                options: [
                    { label: 'Block all cookies from all sites.', value: 0 },
                    { label: 'Block only cookies from third party websites.', value: 1 },
                    { label: 'Allow all cookies from all sites.', value: 2 }
                ]
            },
            {
                label: 'Allow Developer Tools',
                inputType: 'checkbox',
                value: 'AllowDeveloperTools',
                helpText: 'Allow Developer Tools',
                parentId: 'Browser',
                myId: 'AllowDeveloperTools',
                defaultValue: 1,
                checkedValue: 1,
                options: [
                    { label: 'Not allowed.', value: 0 },
                    { label: 'Allowed', value: 1 }
                ]
            },
            {
                label: 'Configure Do Not Track',
                inputType: 'checkbox',
                value: 'AllowDoNotTrack',
                helpText: 'Configure Do Not Track',
                parentId: 'Browser',
                myId: 'AllowDoNotTrack',
                defaultValue: 0,
                checkedValue: 1,
                options: [
                    { label: 'Never send tracking information.', value: 0 },
                    { label: 'Send tracking information.', value: 1 }
                ]
            },
            {
                label: 'Allow Extensions',
                inputType: 'checkbox',
                value: 'AllowExtensions',
                helpText: 'Allow Extensions',
                parentId: 'Browser',
                myId: 'AllowExtensions',
                defaultValue: 1,
                checkedValue: 1,
                options: [
                    { label: 'Never send tracking information.', value: 0 },
                    { label: 'Send tracking information.', value: 1 }
                ]
            },
            {
                label: 'Allow Adobe Flash',
                inputType: 'checkbox',
                value: 'AllowFlash',
                helpText: 'Allow Adobe Flash',
                parentId: 'Browser',
                myId: 'AllowFlash',
                defaultValue: 1,
                checkedValue: 1,
                options: [
                    { label: 'Not allowed.', value: 0 },
                    { label: 'Allowed.', value: 1 }
                ]
            },
            {
                label: 'Configure the Adobe Flash Click-to-Run setting',
                inputType: 'checkbox',
                value: 'AllowFlashClickToRun',
                helpText: 'Configure the Adobe Flash Click-to-Run setting',
                parentId: 'Browser',
                myId: 'AllowFlashClickToRun',
                defaultValue: 1,
                checkedValue: 1,
                options: [
                    { label: 'Load and run Adobe Flash content automatically.', value: 0 },
                    { label: 'Does not load or run Adobe Flash content automatically. Requires action from the user.', value: 1 }
                ]
            },
            {
                label: 'Allow FullScreen Mode',
                inputType: 'checkbox',
                value: 'AllowFullScreenMode',
                helpText: 'Allow FullScreen Mode',
                parentId: 'Browser',
                myId: 'AllowFullScreenMode',
                defaultValue: 1,
                checkedValue: 1,
                options: [
                    { label: 'Not allowed.', value: 0 },
                    { label: 'Allowed.', value: 1 }
                ]
            },
            {
                label: 'Allow InPrivate browsing',
                inputType: 'checkbox',
                value: 'AllowInPrivate',
                helpText: 'Allow InPrivate browsing',
                parentId: 'Browser',
                myId: 'AllowInPrivate',
                defaultValue: 1,
                checkedValue: 1,
                options: [
                    { label: 'Not allowed.', value: 0 },
                    { label: 'Allowed.', value: 1 }
                ]
            },
            {
                label: 'Allow Microsoft Compatibility List',
                inputType: 'checkbox',
                value: 'AllowMicrosoftCompatibilityList',
                helpText: 'Allow Microsoft Compatibility List',
                parentId: 'Browser',
                myId: 'AllowMicrosoftCompatibilityList',
                defaultValue: 1,
                checkedValue: 1,
                options: [
                    { label: 'Not allowed.', value: 0 },
                    { label: 'Allowed.', value: 1 }
                ]
            },
            {
                label: 'Configure Password Manager',
                inputType: 'checkbox',
                value: 'AllowPasswordManager',
                helpText: 'Configure Password Manager',
                parentId: 'Browser',
                myId: 'AllowPasswordManager',
                defaultValue: 1,
                checkedValue: 1,
                options: [
                    { label: 'Not allowed.', value: 0 },
                    { label: 'Allowed.', value: 1 }
                ]
            },
            {
                label: 'Configure Pop-up Blocker',
                inputType: 'checkbox',
                value: 'AllowPopups',
                helpText: 'Configure Pop-up Blocker',
                parentId: 'Browser',
                myId: 'AllowPopups',
                defaultValue: 0,
                checkedValue: 1,
                options: [
                    { label: 'Turn off Pop-up Blocker letting pop-up windows open.', value: 0 },
                    { label: 'Turn on Pop-up Blocker stopping pop-up windows from opening.', value: 1 }
                ]
            },
            {
                label: 'Allow Microsoft Edge to pre-launch at Windows startup, when the system is idle, and each time Microsoft Edge is closed',
                inputType: 'checkbox',
                value: 'AllowPrelaunch',
                helpText:
                    'Allow Microsoft Edge to pre-launch at Windows startup, when the system is idle, and each time Microsoft Edge is closed',
                parentId: 'Browser',
                myId: 'AllowPrelaunch',
                defaultValue: 1,
                checkedValue: 1,
                options: [
                    { label: 'Not allowed.', value: 0 },
                    { label: 'Allowed.', value: 1 }
                ]
            },
            {
                label: 'Allow printing',
                inputType: 'checkbox',
                value: 'AllowPrinting',
                helpText: 'Allow printing',
                parentId: 'Browser',
                myId: 'AllowPrinting',
                defaultValue: 1,
                checkedValue: 1,
                options: [
                    { label: 'Not allowed.', value: 0 },
                    { label: 'Allowed.', value: 1 }
                ]
            },
            {
                label: 'Allow Saving History',
                inputType: 'checkbox',
                value: 'AllowSavingHistory',
                helpText: 'Allow Saving History',
                parentId: 'Browser',
                myId: 'AllowSavingHistory',
                defaultValue: 1,
                checkedValue: 1,
                options: [
                    { label: 'Not allowed.', value: 0 },
                    { label: 'Allowed.', value: 1 }
                ]
            },
            {
                label: 'Allow search engine customization',
                inputType: 'checkbox',
                value: 'AllowSearchEngineCustomization',
                helpText: 'Allow search engine customization',
                parentId: 'Browser',
                myId: 'AllowSearchEngineCustomization',
                defaultValue: 1,
                checkedValue: 1,
                options: [
                    { label: 'Not allowed.', value: 0 },
                    { label: 'Allowed.', value: 1 }
                ]
            },
            {
                label: 'Configure search suggestions in Address bar',
                inputType: 'checkbox',
                value: 'AllowSearchSuggestionsinAddressBar',
                helpText: 'Configure search suggestions in Address bar',
                parentId: 'Browser',
                myId: 'AllowSearchSuggestionsinAddressBar',
                defaultValue: 1,
                checkedValue: 1,
                options: [
                    { label: 'Not allowed.', value: 0 },
                    { label: 'Allowed.', value: 1 }
                ]
            },
            {
                label: 'Allow Sideloading of extension',
                inputType: 'checkbox',
                value: 'AllowSideloadingOfExtensions',
                helpText: 'Allow Sideloading of extension',
                parentId: 'Browser',
                myId: 'AllowSideloadingOfExtensions',
                defaultValue: 1,
                checkedValue: 1,
                options: [
                    { label: 'Not allowed.', value: 0 },
                    { label: 'Allowed.', value: 1 }
                ]
            },
            {
                label: 'Configure Windows Defender SmartScreen',
                inputType: 'checkbox',
                value: 'AllowSmartScreen',
                helpText: 'Configure Windows Defender SmartScreen',
                parentId: 'Browser',
                myId: 'AllowSmartScreen',
                defaultValue: 1,
                checkedValue: 1,
                options: [
                    { label: "Don't protect users from potential threats and prevent users from turning it on.", value: 0 },
                    { label: 'Protect users from potential threats and prevent users from turning it off.', value: 1 }
                ]
            },
            {
                label: 'Allow Microsoft Edge to start and load the Start and New Tab page at Windows startup and each time Microsoft Edge is closed',
                inputType: 'checkbox',
                value: 'AllowTabPreloading',
                helpText:
                    'Allow Microsoft Edge to start and load the Start and New Tab page at Windows startup and each time Microsoft Edge is closed',
                parentId: 'Browser',
                myId: 'AllowTabPreloading',
                defaultValue: 1,
                checkedValue: 1,
                options: [
                    { label: 'Not allowed.', value: 0 },
                    { label: 'Allowed.', value: 1 }
                ]
            },
            {
                label: 'Allow web content on New Tab page',
                inputType: 'checkbox',
                value: 'AllowWebContentOnNewTabPage',
                helpText: 'Allow web content on New Tab page',
                parentId: 'Browser',
                myId: 'AllowWebContentOnNewTabPage',
                defaultValue: 1,
                checkedValue: 1,
                options: [
                    { label: 'Load a blank page instead of the default New tab page and prevent users from changing it.', value: 0 },
                    { label: 'Load the default New tab page.', value: 1 }
                ]
            },
            {
                label: 'Always show the Books Library in Microsoft Edge',
                inputType: 'checkbox',
                value: 'AlwaysEnableBooksLibrary',
                helpText: 'Always show the Books Library in Microsoft Edge',
                parentId: 'Browser',
                myId: 'AlwaysEnableBooksLibrary',
                defaultValue: 0,
                checkedValue: 1,
                options: [
                    { label: 'Show the Books Library only in countries or regions where supported.', value: 0 },
                    { label: "Show the Books Library, regardless of the device's country or region.", value: 1 }
                ]
            },
            {
                label: 'Allow clearing browsing data on exit',
                inputType: 'checkbox',
                value: 'ClearBrowsingDataOnExit',
                helpText: 'Allow clearing browsing data on exit',
                parentId: 'Browser',
                myId: 'ClearBrowsingDataOnExit',
                defaultValue: 0,
                checkedValue: 1,
                options: [
                    { label: 'Not allowed.', value: 0 },
                    { label: 'Allowed.', value: 1 }
                ]
            },
            {
                label: 'Configure Favorites Bar',
                inputType: 'checkbox',
                value: 'ConfigureFavoritesBar',
                helpText: 'Configure Favorites Bar',
                parentId: 'Browser',
                myId: 'ConfigureFavoritesBar',
                defaultValue: 0,
                checkedValue: 1,
                options: [
                    { label: 'Hide the favorites bar on all pages', value: 0 },
                    { label: 'Show the favorites bar on all pages', value: 1 }
                ]
            },
            {
                label: 'Configure Home Button',
                inputType: 'dropdown',
                value: 'ConfigureHomeButton',
                helpText: 'Configure Home Button',
                parentId: 'Browser',
                myId: 'ConfigureHomeButton',
                defaultValue: 0,
                options: [
                    { label: 'Show home button and load the Start page.', value: 0 },
                    { label: 'Show home button and load the New tab page.', value: 1 },
                    { label: 'Show home button and load the custom URL.', value: 2 },
                    { label: 'Hide home button.', value: 3 }
                ]
            },
            {
                label: 'Configure kiosk mode',
                inputType: 'checkbox',
                value: 'ConfigureKioskMode',
                helpText: 'Configure kiosk mode',
                parentId: 'Browser',
                myId: 'ConfigureKioskMode',
                defaultValue: 0,
                checkedValue: 1,
                options: [
                    { label: 'Disable.', value: 0 },
                    { label: 'Enable.', value: 1 }
                ]
            },
            {
                label: 'Configure kiosk reset after idle timeout',
                value: 'ConfigureKioskResetAfterIdleTimeout',
                helpText: 'Specifies the idle time in minutes (0-1440). The reset timer begins after the last user interaction.',
                inputType: 'policyInput',
                regExp: '^[0-9]+$',
                valueType: 'number',
                minValue: 0,
                maxValue: 1440,
                maxLength: 4,
                parentId: 'Browser',
                myId: 'ConfigureKioskResetAfterIdleTimeout',
                defaultValue: 5,
                placeholder: 'Range: [0-1440]'
            },
            {
                label: 'Configure Open Microsoft Edge With',
                inputType: 'dropdown',
                value: 'ConfigureHomeButton',
                helpText: 'Configure Open Microsoft Edge With',
                parentId: 'Browser',
                myId: 'ConfigureHomeButton',
                defaultValue: 3,
                options: [
                    { label: 'Load the Start page.', value: 0 },
                    { label: 'Load the New tab page.', value: 1 },
                    { label: 'Load the previous pages.', value: 2 },
                    { label: 'Load a specific page or pages.', value: 3 }
                ]
            },
            {
                label: 'Configure collection of browsing data for Desktop Analytics',
                inputType: 'dropdown',
                value: 'ConfigureTelemetryForMicrosoft365Analytics',
                helpText: 'Configure collection of browsing data for Desktop Analytics',
                parentId: 'Browser',
                myId: 'ConfigureTelemetryForMicrosoft365Analytics',
                defaultValue: 0,
                options: [
                    { label: 'No data collected or sent.', value: 0 },
                    { label: 'Send intranet history only.', value: 1 },
                    { label: 'Send Internet history only.', value: 2 },
                    { label: 'Send both intranet and Internet history.', value: 3 }
                ]
            },
            {
                label: 'Disable lockdown of Start pages',
                inputType: 'checkbox',
                value: 'DisableLockdownOfStartPages',
                helpText: 'Disable lockdown of Start pages',
                parentId: 'Browser',
                myId: 'DisableLockdownOfStartPages',
                defaultValue: 0,
                checkedValue: 1,
                options: [
                    {
                        label: 'Lock down Start pages configured in either the ConfigureOpenEdgeWith policy and HomePages policy.',
                        value: 0
                    },
                    { label: 'Unlocked. Users can make changes to all configured start pages.', value: 1 }
                ]
            },
            {
                label: 'Allow extended telemetry for the Books tab',
                inputType: 'checkbox',
                value: 'EnableExtendedBooksTelemetry',
                helpText: 'Allow extended telemetry for the Books tab',
                parentId: 'Browser',
                myId: 'EnableExtendedBooksTelemetry',
                defaultValue: 0,
                checkedValue: 1,
                options: [
                    { label: 'Gather and send only basic diagnostic data.', value: 0 },
                    { label: 'Gather all diagnostic data.', value: 1 }
                ]
            },
            {
                label: 'Prevent changes to Favorites on Microsoft Edge',
                inputType: 'checkbox',
                value: 'LockdownFavorites',
                helpText: 'Prevent changes to Favorites on Microsoft Edge',
                parentId: 'Browser',
                myId: 'LockdownFavorites',
                defaultValue: 0,
                checkedValue: 1,
                options: [
                    { label: 'Allowed.', value: 0 },
                    { label: 'Prevented.', value: 1 }
                ]
            },
            {
                label: 'Prevent access to the about:flags page in Microsoft Edge',
                inputType: 'checkbox',
                value: 'PreventAccessToAboutFlagsInMicrosoftEdge',
                helpText: 'Prevent access to the about:flags page in Microsoft Edge',
                parentId: 'Browser',
                myId: 'PreventAccessToAboutFlagsInMicrosoftEdge',
                defaultValue: 0,
                checkedValue: 1,
                options: [
                    { label: 'Allowed.', value: 0 },
                    { label: 'Prevents users from accessing the about:flags page.', value: 1 }
                ]
            },
            {
                label: 'Prevent certificate error overrides',
                inputType: 'checkbox',
                value: 'PreventCertErrorOverrides',
                helpText: 'Prevent certificate error overrides',
                parentId: 'Browser',
                myId: 'PreventCertErrorOverrides',
                defaultValue: 0,
                checkedValue: 1,
                options: [
                    { label: 'Allowed.', value: 0 },
                    { label: 'Prevented.', value: 1 }
                ]
            },
            {
                label: 'Prevent the First Run webpage from opening on Microsoft Edge',
                inputType: 'checkbox',
                value: 'PreventFirstRunPage',
                helpText: 'Prevent the First Run webpage from opening on Microsoft Edge',
                parentId: 'Browser',
                myId: 'PreventFirstRunPage',
                defaultValue: 0,
                checkedValue: 1,
                options: [
                    { label: 'Allowed.', value: 0 },
                    { label: 'Not allowed.', value: 1 }
                ]
            },
            {
                label: 'Prevent Microsoft Edge from gathering Live Tile information when pinning a site to Start',
                inputType: 'checkbox',
                value: 'PreventLiveTileDataCollection',
                helpText: 'Prevent Microsoft Edge from gathering Live Tile information when pinning a site to Start',
                parentId: 'Browser',
                myId: 'PreventLiveTileDataCollection',
                defaultValue: 0,
                checkedValue: 1,
                options: [
                    { label: 'Collect and send Live Tile metadata.', value: 0 },
                    { label: 'No data collected.', value: 1 }
                ]
            },
            {
                label: 'Prevent bypassing Windows Defender SmartScreen prompts for sites',
                inputType: 'checkbox',
                value: 'PreventSmartScreenPromptOverride',
                helpText: 'Prevent bypassing Windows Defender SmartScreen prompts for sites',
                parentId: 'Browser',
                myId: 'PreventSmartScreenPromptOverride',
                defaultValue: 0,
                checkedValue: 1,
                options: [
                    { label: 'Users can ignore the warning and continue to the site.', value: 0 },
                    { label: 'Turned on.', value: 1 }
                ]
            },
            {
                label: 'Prevent bypassing Windows Defender SmartScreen prompts for files',
                inputType: 'checkbox',
                value: 'PreventSmartScreenPromptOverrideForFiles',
                helpText: 'Prevent bypassing Windows Defender SmartScreen prompts for files',
                parentId: 'Browser',
                myId: 'PreventSmartScreenPromptOverrideForFiles',
                defaultValue: 0,
                checkedValue: 1,
                options: [
                    { label: 'Allowed.', value: 0 },
                    { label: 'Prevented.', value: 1 }
                ]
            },
            {
                label: 'Prevent using Localhost IP address for WebRTC',
                inputType: 'checkbox',
                value: 'PreventUsingLocalHostIPAddressForWebRTC',
                helpText: 'Prevent using Localhost IP address for WebRTC',
                parentId: 'Browser',
                myId: 'PreventUsingLocalHostIPAddressForWebRTC',
                defaultValue: 0,
                checkedValue: 1,
                options: [
                    { label: 'Allowed.', value: 0 },
                    { label: 'Not Allowed.', value: 1 }
                ]
            },
            {
                label: 'Send all intranet sites to Internet Explorer 11',
                inputType: 'checkbox',
                value: 'SendIntranetTraffictoInternetExplorer',
                helpText: 'Send all intranet sites to Internet Explorer 11',
                parentId: 'Browser',
                myId: 'SendIntranetTraffictoInternetExplorer',
                defaultValue: 0,
                checkedValue: 1,
                options: [
                    { label: 'All sites, including intranet sites, open in Microsoft Edge automatically.', value: 0 },
                    { label: 'Only intranet sites open in Internet Explorer 11 automatically.', value: 1 }
                ]
            },
            {
                label: 'Show message when opening sites in Internet Explorer',
                value: 'ShowMessageWhenOpeningSitesInInternetExplorer',
                inputType: 'dropdown',
                placeholder: 'Select',
                helpText: 'Show message when opening sites in Internet Explorer',
                parentId: 'Browser',
                myId: 'ShowMessageWhenOpeningSitesInInternetExplorer',
                defaultValue: 0,
                options: [
                    { label: 'No additional message displays.', value: 0 },
                    { label: 'Show an additional message stating that a site has opened in IE11.', value: 1 },
                    { label: 'Show an additional message with a "Keep going in Microsoft Edge" link.', value: 2 }
                ]
            },
            {
                label: 'Keep favorites in sync between Internet Explorer and Microsoft Edge',
                inputType: 'checkbox',
                value: 'SyncFavoritesBetweenIEAndMicrosoftEdge',
                helpText: 'Keep favorites in sync between Internet Explorer and Microsoft Edge',
                parentId: 'Browser',
                myId: 'SyncFavoritesBetweenIEAndMicrosoftEdge',
                defaultValue: 0,
                checkedValue: 1,
                options: [
                    { label: 'Not Syncing.', value: 0 },
                    { label: 'Syncing.', value: 1 }
                ]
            },
            {
                label: 'Unlock Home Button',
                inputType: 'checkbox',
                value: 'UnlockHomeButton',
                helpText: 'Unlock Home Button',
                parentId: 'Browser',
                myId: 'UnlockHomeButton',
                defaultValue: 0,
                checkedValue: 1,
                options: [
                    { label: 'Prevent users from making changes to the settings.', value: 0 },
                    { label: 'Let users make changes.', value: 1 }
                ]
            },
            {
                label: 'Allow a shared Books folder',
                inputType: 'checkbox',
                value: 'UseSharedFolderForBooks',
                helpText: 'Allow a shared Books folder',
                parentId: 'Browser',
                myId: 'UseSharedFolderForBooks',
                defaultValue: 0,
                checkedValue: 1,
                options: [
                    { label: 'Not Allowed.', value: 0 },
                    { label: 'Allowed.', value: 1 }
                ]
            }
        ]
    },
    // {
    //     label: 'Firewall',
    //     helpText: 'Use this section to block specific domains',
    //     show: ['FM','SA'],
    //     image: firewall,
    //     fields: [
    //         {
    //             label: 'Firewall',
    //             inputType: 'firewall',
    //             value: 'urls',
    //             parentId: 'script',
    //             myId: 'urls',
    //             defaultValue: [],
    //             isCustomConfig: true,
    //             subParentId: 'fire_wall'
    //         }
    //     ]
    // },
    // {
    //     label: 'Sudo',
    //     helpText: 'Use this section to control the use of the sudo.exe command line tool',
    //     note: 'This policy only applies to Windows 11 devices',
    //     show: ['FM','SA'],
    //     image: sudo,
    //     fields: [
    //         {
    //             label: 'Sudo',
    //             inputType: 'dropdown',
    //             placeholder: 'Select',
    //             value: 'EnableSudo',
    //             helpText: 'Controls use of the sudo.exe command line tool',
    //             parentId: 'Sudo',
    //             myId: 'EnableSudo',
    //             defaultValue: 3,
    //             options: [
    //                 { label: 'Sudo is disabled.', value: 0 },
    //                 { label: "Sudo is allowed in 'force new window' mode.", value: 1 },
    //                 { label: "Sudo is allowed in 'disable input' mode.", value: 2 },
    //                 { label: "Sudo is allowed in 'inline' mode.", value: 3 }
    //             ]
    //         }
    //     ]
    // },
    {
        label: 'Local Policies Security Options',
        helpText: 'Use this section to control local policies security options',
        note: 'This policy only applies for Windows Insider Preview builds',
        show: ['FM', 'SA', 'MA'],
        image: localPolicySecurityOptions,
        fields: [
            {
                label: 'Block Microsoft Account',
                inputType: 'dropdown',
                placeholder: 'Select',
                value: 'Accounts_BlockMicrosoftAccounts',
                helpText: 'Prevents users from adding new Microsoft accounts on this computer',
                parentId: 'LocalPoliciesSecurityOptions',
                myId: 'Accounts_BlockMicrosoftAccounts',
                defaultValue: 0,
                options: [
                    { label: 'Users will be able to use Microsoft accounts with Windows', value: 0 },
                    { label: "Users can't add Microsoft accounts", value: 1 },
                    { label: "Users can't add or log on with Microsoft accounts", value: 3 }
                ]
            },
            {
                label: 'Administrator Account',
                value: 'Accounts_EnableAdministratorAccountStatus',
                helpText: 'Determines whether the local Administrator account is enabled or disabled',
                inputType: 'checkbox',
                parentId: 'LocalPoliciesSecurityOptions',
                myId: 'Accounts_EnableAdministratorAccountStatus',
                defaultValue: 0,
                checkedValue: 1,
                options: [
                    { label: 'Disable.', value: 0 },
                    { label: 'Enable.', value: 1 }
                ]
            },
            {
                label: 'Guest account',
                value: 'Accounts_EnableGuestAccountStatus',
                helpText: 'Determines if the Guest account is enabled or disabled',
                inputType: 'checkbox',
                parentId: 'LocalPoliciesSecurityOptions',
                myId: 'Accounts_EnableGuestAccountStatus',
                defaultValue: 0,
                checkedValue: 1,
                options: [
                    { label: 'Disable.', value: 0 },
                    { label: 'Enable.', value: 1 }
                ]
            },
            {
                label: 'Blank passwords',
                value: 'Accounts_LimitLocalAccountUseOfBlankPasswordsToConsoleLogonOnly',
                helpText:
                    "Local accounts that aren't password protected can be used to log on from locations other than the physical computer console",
                inputType: 'checkbox',
                parentId: 'LocalPoliciesSecurityOptions',
                myId: 'Accounts_LimitLocalAccountUseOfBlankPasswordsToConsoleLogonOnly',
                defaultValue: 1,
                checkedValue: 1,
                options: [
                    { label: 'Disable.', value: 0 },
                    { label: 'Enable.', value: 1 }
                ]
            },
            {
                label: 'Rename administrator account',
                value: 'Accounts_RenameAdministratorAccount',
                helpText: 'Different account name is associated with the security identifier (SID) for the account Administrator.',
                inputType: 'policyInput',
                parentId: 'LocalPoliciesSecurityOptions',
                myId: 'Accounts_RenameAdministratorAccount',
                defaultValue: 'Administrator',
                placeholder: 'Administrator'
            },
            {
                label: 'Rename guest account',
                value: 'Accounts_RenameGuestAccount',
                helpText: 'Different account name is associated with the security identifier (SID) for the account "Guest"',
                inputType: 'policyInput',
                parentId: 'LocalPoliciesSecurityOptions',
                myId: 'Accounts_RenameGuestAccount',
                defaultValue: 'Guest',
                placeholder: 'Guest'
            },
            {
                label: 'Allow undock without having to log on',
                value: 'Devices_AllowUndockWithoutHavingToLogon',
                helpText: 'Portable computer can be undocked without having to log on.',
                inputType: 'checkbox',
                parentId: 'LocalPoliciesSecurityOptions',
                myId: 'Devices_AllowUndockWithoutHavingToLogon',
                defaultValue: 1,
                checkedValue: 1,
                options: [
                    { label: 'Block', value: 0 },
                    { label: 'Allow', value: 1 }
                ]
            },
            {
                label: 'Printer Drivers',
                value: 'Devices_PreventUsersFromInstallingPrinterDriversWhenConnectingToSharedPrinters',
                helpText: 'Prevent users from installing printer drivers',
                inputType: 'checkbox',
                parentId: 'LocalPoliciesSecurityOptions',
                myId: 'Devices_PreventUsersFromInstallingPrinterDriversWhenConnectingToSharedPrinters',
                defaultValue: 0,
                checkedValue: 1,
                options: [
                    { label: 'Disable', value: 0 },
                    { label: 'Enable', value: 1 }
                ]
            },
            {
                label: 'Digitally Encrypt Always',
                value: 'DomainMember_DigitallyEncryptOrSignSecureChannelDataAlways',
                helpText: 'Digitally encrypt or sign secure channel data (always)',
                inputType: 'checkbox',
                parentId: 'LocalPoliciesSecurityOptions',
                myId: 'DomainMember_DigitallyEncryptOrSignSecureChannelDataAlways',
                defaultValue: 1,
                checkedValue: 1,
                options: [
                    { label: 'Disabled', value: 0 },
                    { label: 'Enabled', value: 1 }
                ]
            },
            {
                label: 'Digitally Encrypt when possible',
                value: 'DomainMember_DigitallyEncryptSecureChannelDataWhenPossible',
                helpText: 'Digitally sign secure channel data (when possible)',
                inputType: 'checkbox',
                parentId: 'LocalPoliciesSecurityOptions',
                myId: 'DomainMember_DigitallyEncryptSecureChannelDataWhenPossible',
                defaultValue: 1,
                checkedValue: 1,
                options: [
                    { label: 'Disabled', value: 0 },
                    { label: 'Enabled', value: 1 }
                ]
            },
            {
                label: 'Account Password changes',
                value: 'DomainMember_DisableMachineAccountPasswordChanges',
                helpText: 'Disable machine account password changes',
                inputType: 'checkbox',
                parentId: 'LocalPoliciesSecurityOptions',
                myId: 'DomainMember_DisableMachineAccountPasswordChanges',
                defaultValue: 0,
                checkedValue: 1,
                options: [
                    { label: 'Disabled', value: 0 },
                    { label: 'Enabled', value: 1 }
                ]
            },
            {
                label: 'Account Password age',
                value: 'DomainMember_MaximumMachineAccountPasswordAge',
                helpText: 'Maximum machine account password age',
                inputType: 'policyInput',
                regExp: '^[0-9]+$',
                valueType: 'number',
                minValue: 0,
                maxValue: 999,
                maxLength: 3,
                parentId: 'LocalPoliciesSecurityOptions',
                myId: 'DomainMember_MaximumMachineAccountPasswordAge',
                defaultValue: 30,
                placeholder: 'Range: [0-999]'
            },
            {
                label: 'Display User information when session locked',
                inputType: 'dropdown',
                placeholder: 'Select',
                value: 'InteractiveLogon_DisplayUserInformationWhenTheSessionIsLocked',
                helpText: 'Display user information when the session is locked',
                parentId: 'LocalPoliciesSecurityOptions',
                myId: 'InteractiveLogon_DisplayUserInformationWhenTheSessionIsLocked',
                defaultValue: 1,
                options: [
                    { label: 'User display name, domain and user names.', value: 1 },
                    { label: 'User display name only.', value: 2 },
                    { label: "Don't display user information.", value: 3 },
                    { label: 'Domain and user names only.', value: 4 }
                ]
            },
            {
                label: 'Last Signed-in',
                value: 'InteractiveLogon_DoNotDisplayLastSignedIn',
                helpText: "Don't display last signed-in",
                inputType: 'checkbox',
                parentId: 'LocalPoliciesSecurityOptions',
                myId: 'InteractiveLogon_DoNotDisplayLastSignedIn',
                defaultValue: 0,
                checkedValue: 1,
                options: [
                    { label: 'Username will be shown', value: 0 },
                    { label: "Username won't be shown", value: 1 }
                ]
            },
            {
                label: 'Do not display user name',
                value: 'InteractiveLogon_DoNotDisplayUsernameAtSignIn',
                helpText: "Don't display username at sign-in",
                inputType: 'checkbox',
                parentId: 'LocalPoliciesSecurityOptions',
                myId: 'InteractiveLogon_DoNotDisplayUsernameAtSignIn',
                defaultValue: 0,
                checkedValue: 1,
                options: [
                    { label: 'Username will be shown', value: 0 },
                    { label: "Username won't be shown", value: 1 }
                ]
            },
            {
                label: "Don't require Ctrl+Alt+Del",
                value: 'InteractiveLogon_DoNotRequireCTRLALTDEL',
                helpText: "Don't require CTRL+ALT+DEL",
                inputType: 'checkbox',
                parentId: 'LocalPoliciesSecurityOptions',
                myId: 'InteractiveLogon_DoNotRequireCTRLALTDEL',
                defaultValue: 1,
                checkedValue: 1,
                options: [
                    { label: 'Disabled', value: 0 },
                    { label: "User isn't required to press CTRL+ALT+DEL to log on", value: 1 }
                ]
            },
            {
                label: 'Account Lockout Threshold',
                value: 'InteractiveLogon_MachineAccountLockoutThreshold',
                helpText: 'Machine account lockout threshold',
                inputType: 'policyInput',
                regExp: '^[0-9]+$',
                valueType: 'number',
                minValue: 0,
                maxValue: 999,
                maxLength: 3,
                parentId: 'LocalPoliciesSecurityOptions',
                myId: 'InteractiveLogon_MachineAccountLockoutThreshold',
                defaultValue: 0,
                placeholder: 'Range: [0-999]'
            },
            {
                label: 'Machine inactivity limit',
                value: 'InteractiveLogon_MachineInactivityLimit',
                helpText: 'Machine account lockout threshold',
                inputType: 'policyInput',
                regExp: '^[0-9]+$',
                valueType: 'number',
                minValue: 0,
                maxValue: 599940,
                maxLength: 6,
                parentId: 'LocalPoliciesSecurityOptions',
                myId: 'InteractiveLogon_MachineInactivityLimit',
                defaultValue: 0,
                placeholder: 'Range: [0-599940]'
            },
            {
                label: 'Change password before expiration',
                value: 'InteractiveLogon_PromptUserToChangePasswordBeforeExpiration',
                helpText: 'Machine account lockout threshold',
                inputType: 'policyInput',
                regExp: '^[0-9]+$',
                valueType: 'number',
                minValue: 0,
                maxValue: 999,
                maxLength: 3,
                parentId: 'LocalPoliciesSecurityOptions',
                myId: 'InteractiveLogon_PromptUserToChangePasswordBeforeExpiration',
                defaultValue: 5,
                placeholder: 'Range: [0-999]'
            },
            {
                label: 'Smart card removal behavior',
                inputType: 'dropdown',
                placeholder: 'Select',
                value: 'InteractiveLogon_SmartCardRemovalBehavior',
                helpText: 'Visibility of the Documents shortcut on the Start menu',
                parentId: 'LocalPoliciesSecurityOptions',
                myId: 'InteractiveLogon_SmartCardRemovalBehavior',
                defaultValue: '0',
                options: [
                    { label: 'No Action.', value: '0' },
                    { label: 'Lock Workstation.', value: '1' },
                    { label: 'Force Logoff.', value: '2' },
                    { label: 'Disconnect if a Remote Desktop Services session.', value: '3' }
                ]
            }
        ]
    },
    {
        label: 'Start Menu',
        helpText: 'Use this section for controlling Start Menu',
        show: ['FM', 'SA', 'MA'],
        image: startMenu,
        fields: [
            {
                label: 'Documents on Start',
                inputType: 'dropdown',
                placeholder: 'Select',
                value: 'AllowPinnedFolderDocuments',
                helpText: 'Visibility of the Documents shortcut on the Start menu',
                parentId: 'Start',
                myId: 'AllowPinnedFolderDocuments',
                defaultValue: 65535,
                options: [
                    { label: 'Hide', value: 0 },
                    { label: 'Show', value: 1 },
                    { label: 'Default', value: 65535 }
                ]
            },
            {
                label: 'Download on Start',
                inputType: 'dropdown',
                placeholder: 'Select',
                value: 'AllowPinnedFolderDownloads',
                helpText: 'Visibility of the Downloads shortcut on the Start menu',
                parentId: 'Start',
                myId: 'AllowPinnedFolderDownloads',
                defaultValue: 65535,
                options: [
                    { label: 'Hide', value: 0 },
                    { label: 'Show', value: 1 },
                    { label: 'Default', value: 65535 }
                ]
            },
            {
                label: 'File Explorer on Start',
                inputType: 'dropdown',
                placeholder: 'Select',
                value: 'AllowPinnedFolderFileExplorer',
                helpText: 'Visibility of the File Explorer shortcut on the Start menu',
                parentId: 'Start',
                myId: 'AllowPinnedFolderFileExplorer',
                defaultValue: 65535,
                options: [
                    { label: 'Hide', value: 0 },
                    { label: 'Show', value: 1 },
                    { label: 'Default', value: 65535 }
                ]
            },
            {
                label: 'Home Group on Start',
                inputType: 'dropdown',
                placeholder: 'Select',
                value: 'AllowPinnedFolderHomeGroup',
                helpText: 'Visibility of the HomeGroup shortcut on the Start menu',
                parentId: 'Start',
                myId: 'AllowPinnedFolderHomeGroup',
                defaultValue: 65535,
                options: [
                    { label: 'Hide', value: 0 },
                    { label: 'Show', value: 1 },
                    { label: 'Default', value: 65535 }
                ]
            },
            {
                label: 'Music on Start',
                inputType: 'dropdown',
                placeholder: 'Select',
                value: 'AllowPinnedFolderMusic',
                helpText: 'Visibility of the Music shortcut on the Start menu',
                parentId: 'Start',
                myId: 'AllowPinnedFolderMusic',
                defaultValue: 65535,
                options: [
                    { label: 'Hide', value: 0 },
                    { label: 'Show', value: 1 },
                    { label: 'Default', value: 65535 }
                ]
            },
            {
                label: 'Network on Start',
                inputType: 'dropdown',
                placeholder: 'Select',
                value: 'AllowPinnedFolderNetwork',
                helpText: 'Visibility of the Network shortcut on the Start menu',
                parentId: 'Start',
                myId: 'AllowPinnedFolderNetwork',
                defaultValue: 65535,
                options: [
                    { label: 'Hide', value: 0 },
                    { label: 'Show', value: 1 },
                    { label: 'Default', value: 65535 }
                ]
            },
            {
                label: 'Personal Folder on Start',
                inputType: 'dropdown',
                placeholder: 'Select',
                value: 'AllowPinnedFolderPersonalFolder',
                helpText: 'Visibility of the PersonalFolder shortcut on the Start menu',
                parentId: 'Start',
                myId: 'AllowPinnedFolderPersonalFolder',
                defaultValue: 65535,
                options: [
                    { label: 'Hide', value: 0 },
                    { label: 'Show', value: 1 },
                    { label: 'Default', value: 65535 }
                ]
            },
            {
                label: 'Pictures on Start',
                inputType: 'dropdown',
                placeholder: 'Select',
                value: 'AllowPinnedFolderPictures',
                helpText: 'Visibility of the Pictures shortcut on the Start menu',
                parentId: 'Start',
                myId: 'AllowPinnedFolderPictures',
                defaultValue: 65535,
                options: [
                    { label: 'Hide', value: 0 },
                    { label: 'Show', value: 1 },
                    { label: 'Default', value: 65535 }
                ]
            },
            {
                label: 'Setting on Start',
                inputType: 'dropdown',
                placeholder: 'Select',
                value: 'AllowPinnedFolderSettings',
                helpText: 'Visibility of the Settings shortcut on the Start menu',
                parentId: 'Start',
                myId: 'AllowPinnedFolderSettings',
                defaultValue: 65535,
                options: [
                    { label: 'Hide', value: 0 },
                    { label: 'Show', value: 1 },
                    { label: 'Default', value: 65535 }
                ]
            },
            {
                label: 'Video on Start',
                inputType: 'dropdown',
                placeholder: 'Select',
                value: 'AllowPinnedFolderVideos',
                helpText: 'Visibility of the Videos shortcut on the Start menu',
                parentId: 'Start',
                myId: 'AllowPinnedFolderVideos',
                defaultValue: 65535,
                options: [
                    { label: 'Hide', value: 0 },
                    { label: 'Show', value: 1 },
                    { label: 'Default', value: 65535 }
                ]
            },
            {
                label: 'Context Menus',
                inputType: 'checkbox',
                value: 'DisableContextMenus',
                helpText: 'Disable context menus in the Start Menu',
                parentId: 'Start',
                myId: 'DisableContextMenus',
                defaultValue: 0,
                checkedValue: 1,
                options: [
                    { label: "Don't Disable", value: 0 },
                    { label: 'Disable', value: 1 }
                ]
            },
            {
                label: 'Edit Quick Settings',
                inputType: 'checkbox',
                value: 'DisableEditingQuickSettings',
                helpText: 'User will be able to edit Quick Settings',
                parentId: 'Start',
                myId: 'DisableEditingQuickSettings',
                defaultValue: 0,
                checkedValue: 1,
                options: [
                    { label: 'Enable', value: 0 },
                    { label: 'Disable', value: 1 }
                ]
            },
            {
                label: 'Start Size',
                inputType: 'dropdown',
                placeholder: 'Select',
                value: 'ForceStartSize',
                helpText: 'Force Start to be either full screen size or menu size',
                parentId: 'Start',
                myId: 'ForceStartSize',
                defaultValue: 0,
                options: [
                    { label: "Don't force size of Start", value: 0 },
                    { label: 'Force non-fullscreen size of Start', value: 1 },
                    { label: 'Force a fullscreen size of Start', value: 2 }
                ]
            },
            {
                label: 'Hide App List',
                inputType: 'dropdown',
                placeholder: 'Select',
                value: 'HideAppList',
                helpText: 'Force Start to be either full screen size or menu size',
                parentId: 'Start',
                myId: 'HideAppList',
                defaultValue: 0,
                options: [
                    { label: 'None', value: 0 },
                    { label: 'Hide all apps list', value: 1 },
                    { label: 'Hide all apps list, and Disable "Show app list in Start menu" in Settings app.', value: 2 },
                    {
                        label: 'Hide all apps list, remove all apps button, and Disable "Show app list in Start menu" in Settings app.',
                        value: 3
                    }
                ]
            },
            {
                label: 'Change Account settings',
                inputType: 'checkbox',
                value: 'HideChangeAccountSettings',
                helpText: 'Hide Change Accoount Settings',
                parentId: 'Start',
                myId: 'HideChangeAccountSettings',
                defaultValue: 0,
                checkedValue: 1,
                options: [
                    { label: 'Show', value: 0 },
                    { label: 'Hide', value: 1 }
                ]
            },
            {
                label: 'Hide Frequently used apps',
                inputType: 'checkbox',
                value: 'HideFrequentlyUsedApps',
                helpText: 'Remove frequent programs list from the Start Menu',
                parentId: 'Start',
                myId: 'HideFrequentlyUsedApps',
                defaultValue: 0,
                checkedValue: 1,
                options: [
                    { label: 'Show', value: 0 },
                    { label: 'Hide', value: 1 }
                ]
            },
            {
                label: 'Hiberante on Start',
                inputType: 'checkbox',
                value: 'HideHibernate',
                helpText: 'Hides "Hibernate" from appearing in the power button in the start menu',
                parentId: 'Start',
                myId: 'HideHibernate',
                defaultValue: 0,
                checkedValue: 1,
                options: [
                    { label: 'Show', value: 0 },
                    { label: 'Hide', value: 1 }
                ]
            }
        ]
    },
    {
        label: 'Ctrl+Alt+Del',
        helpText:
            'Windows Security dialog box (Ctrl+Alt+Del) is a critical interface that provides users with several important functions related to system security and user account management.',
        show: ['FM', 'SA', 'MA'],
        image: ctrlAltDel,
        fields: [
            {
                label: 'Remove Logoff',
                inputType: 'checkbox',
                value: 'NoLogoff',
                helpText: 'Enabling, disables or removes all menu items and buttons that log the user off the system.',
                parentId: 'ADMX_CtrlAltDel',
                myId: 'NoLogoff',
                defaultValue: '<disabled/>',
                checkedValue: '<enabled/>',
                options: [
                    { label: 'Disabled', value: '<disabled/>' },
                    { label: 'Enabled', value: '<enabled/>' }
                ]
            },
            {
                label: 'Remove Lock Computer',
                inputType: 'checkbox',
                value: 'DisableLockComputer',
                helpText: "Enabling, users can't lock the computer from the keyboard using Ctrl+Alt+Del.",
                parentId: 'ADMX_CtrlAltDel',
                myId: 'DisableLockComputer',
                defaultValue: '<disabled/>',
                checkedValue: '<enabled/>',
                options: [
                    { label: 'Disabled', value: '<disabled/>' },
                    { label: 'Enabled', value: '<enabled/>' }
                ]
            },
            {
                label: 'Remove Change Password',
                inputType: 'checkbox',
                value: 'DisableChangePassword',
                helpText:
                    "Enabling, the 'Change Password' button on the Windows Security dialog box won't appear when you press Ctrl+Alt+Del.",
                parentId: 'ADMX_CtrlAltDel',
                myId: 'DisableChangePassword',
                defaultValue: '<disabled/>',
                checkedValue: '<enabled/>',
                options: [
                    { label: 'Disabled', value: '<disabled/>' },
                    { label: 'Enabled', value: '<enabled/>' }
                ]
            },
            {
                label: 'Remove Task Manager',
                inputType: 'checkbox',
                value: 'DisableTaskMgr',
                helpText: "Enabling, users won't be able to access Task Manager",
                parentId: 'ADMX_CtrlAltDel',
                myId: 'DisableTaskMgr',
                defaultValue: '<disabled/>',
                checkedValue: '<enabled/>',
                options: [
                    { label: 'Disabled', value: '<disabled/>' },
                    { label: 'Enabled', value: '<enabled/>' }
                ]
            }
        ]
    },
    {
        label: 'Chrome Configurations',
        helpText: 'Use this section for Browser Configurations',
        show: ['FM', 'SA', 'MA'],
        image: kioskBrowser,
        fields: [
            {
                label: 'Startup Settings'
            },
            {
                label: 'Home Page',
                value: 'HomepageIsNewTabPage',
                inputType: 'dropdown',
                placeholder: 'Select',
                helpText: 'Specifies the Home Page behaviour',
                parentId: 'Chrome~Policy~googlechrome~Startup',
                myId: 'HomepageIsNewTabPage',
                defaultValue: '',
                options: [
                    { label: 'User Control', value: '' },
                    { label: 'New Tab Page', value: '<enabled/>' },
                    { label: 'Launch Below URL', value: '<disabled/>' }
                ]
            },
            {
                label: 'Home Page URL',
                value: 'HomepageLocation',
                helpText: 'Specifies the Home Page URL',
                inputType: 'tempInput',
                parentId: 'Chrome~Policy~googlechrome~Startup',
                myId: 'HomepageLocation',
                enableOn: 'HomepageIsNewTabPage',
                enableOnVal: ['<disabled/>'],
                defaultValue: '',
                placeholder: 'https://www.example.com/'
            },
            {
                label: 'Home Button',
                value: 'ShowHomeButton',
                inputType: 'dropdown',
                placeholder: 'Select',
                helpText: 'Specifies the Home Button behaviour',
                parentId: 'Chrome~Policy~googlechrome~Startup',
                myId: 'ShowHomeButton',
                defaultValue: '<enabled/>',
                options: [
                    { label: 'Always Show', value: '<enabled/>' },
                    { label: 'Never Show', value: '<disabled/>' }
                ]
            },
            {
                label: 'Restore on Startup',
                value: 'RestoreOnStartup',
                inputType: 'dropdown',
                placeholder: 'Select',
                helpText: 'Specifies the browser behaviour on startup',
                parentId: 'Chrome~Policy~googlechrome~Startup',
                myId: 'RestoreOnStartup',
                defaultValue: "<enabled/> <data id='RestoreOnStartup' value='5' />",
                options: [
                    { label: 'Open New Tab Page', value: "<enabled/> <data id='RestoreOnStartup' value='5' />" },
                    { label: 'Restore the last session', value: "<enabled/> <data id='RestoreOnStartup' value='1' />" },
                    { label: 'Open a list of URLs', value: "<enabled/> <data id='RestoreOnStartup' value='4' />" },
                    {
                        label: 'Open a list of URLs and restore the last session',
                        value: "<enabled/> <data id='RestoreOnStartup' value='6' />"
                    }
                ]
            },
            {
                label: 'Restore on Startup URLs',
                value: 'RestoreOnStartupURLs',
                helpText: 'List of website URLs which open start up',
                inputType: 'tempDelimiterArray',
                delimiter: '&#xF000;',
                parentId: 'Chrome~Policy~googlechrome~Startup',
                myId: 'RestoreOnStartupURLs',
                dataId: 'RestoreOnStartupURLsDesc',
                enableOn: 'RestoreOnStartup',
                enableOnVal: ["<enabled/> <data id='RestoreOnStartup' value='4' />", "<enabled/> <data id='RestoreOnStartup' value='6' />"],
                defaultValue: '',
                placeholder: 'URL'
            },
            {
                label: 'User Experience Settings'
            },
            {
                label: 'Bookmark Bar',
                value: 'BookmarkBarEnabled',
                inputType: 'dropdown',
                placeholder: 'Select',
                helpText: 'Specifies whether Bookmark Bar is Enabled or Disabled',
                parentId: 'Chrome~Policy~googlechrome',
                myId: 'BookmarkBarEnabled',
                defaultValue: '<enabled/>',
                options: [
                    { label: 'Enable', value: '<enabled/>' },
                    { label: 'Disable', value: '<disabled/>' }
                ]
            },
            {
                label: 'Developer Tools',
                value: 'DeveloperToolsAvailability',
                inputType: 'dropdown',
                placeholder: 'Select',
                helpText: 'Specifies whether Developer Tools is Enabled or Disabled',
                parentId: 'Chrome~Policy~googlechrome',
                myId: 'DeveloperToolsAvailability',
                defaultValue: "<enabled/> <data id='DeveloperToolsAvailability' value='2' />",
                options: [
                    { label: 'Enabled', value: "<enabled/> <data id='DeveloperToolsAvailability' value='1' />" },
                    { label: 'Disabled', value: "<enabled/> <data id='DeveloperToolsAvailability' value='2' />" }
                ]
            },
            {
                label: 'AutoFill for Address',
                value: 'AutofillAddressEnabled',
                inputType: 'dropdown',
                placeholder: 'Select',
                helpText: 'Specifies whether AutoFill for address is Enabled',
                parentId: 'Chrome~Policy~googlechrome',
                myId: 'AutofillAddressEnabled',
                defaultValue: '<enabled/>',
                options: [
                    { label: 'Enabled', value: '<enabled/>' },
                    { label: 'Disabled', value: '<disabled/>' }
                ]
            },
            {
                label: 'Content Settings'
            },
            {
                label: 'Default Cookie Settings',
                value: 'DefaultCookiesSetting',
                inputType: 'dropdown',
                placeholder: 'Select',
                helpText: 'Default Cookie Settings',
                parentId: 'Chrome~Policy~googlechrome~ContentSettings',
                myId: 'DefaultCookiesSetting',
                defaultValue: "<enabled/> <data id='DefaultCookiesSetting' value='1' />",
                options: [
                    { label: 'Allow', value: "<enabled/> <data id='DefaultCookiesSetting' value='1' />" },
                    { label: 'DisAllow', value: "<enabled/> <data id='DefaultCookiesSetting' value='2' />" },
                    { label: 'Delete after Session', value: "<enabled/> <data id='DefaultCookiesSetting' value='4' />" }
                ]
            },
            {
                label: 'Google Web Search',
                value: 'ForceGoogleSafeSearch',
                inputType: 'dropdown',
                placeholder: 'Select',
                helpText: 'Google Web Safe Search',
                parentId: 'Chrome~Policy~googlechrome',
                myId: 'ForceGoogleSafeSearch',
                defaultValue: '<enabled/>',
                options: [
                    { label: 'Enforce Safe Search', value: '<enabled/>' },
                    { label: 'Donot Enforce Safe Search', value: '<disabled/>' }
                ]
            },
            {
                label: 'Youtube Restriction Mode',
                value: 'ForceYoutubeRestrict',
                inputType: 'dropdown',
                placeholder: 'Select',
                helpText: 'Enforce Youtube Restriction',
                parentId: 'Chrome~Policy~googlechrome',
                myId: 'ForceYoutubeRestrict',
                defaultValue: "<enabled/> <data id='ForceYoutubeRestrict' value='2' />",
                options: [
                    { label: 'No Restriction', value: "<enabled/> <data id='ForceYoutubeRestrict' value='0' />" },
                    { label: 'Moderate Restriction', value: "<enabled/> <data id='ForceYoutubeRestrict' value='1' />" },
                    { label: 'Strict Restriction', value: "<enabled/> <data id='ForceYoutubeRestrict' value='2' />" }
                ]
            },
            {
                label: 'Security Settings'
            },
            {
                label: 'Password Manager',
                value: 'PasswordManagerEnabled',
                inputType: 'dropdown',
                placeholder: 'Select',
                helpText: 'Specifies whether the browser can remember passwords on websites and save them',
                parentId: 'Chrome~Policy~googlechrome~PasswordManager',
                myId: 'PasswordManagerEnabled',
                defaultValue: '<enabled/>',
                options: [
                    { label: 'Enabled', value: '<enabled/>' },
                    { label: 'Disabled', value: '<disabled/>' }
                ]
            },
            {
                label: 'Incognito Mode',
                value: 'IncognitoModeAvailability',
                inputType: 'dropdown',
                placeholder: 'Select',
                helpText: 'Specifies whether the user may open pages in Incognito mode',
                parentId: 'Chrome~Policy~googlechrome',
                myId: 'IncognitoModeAvailability',
                defaultValue: "<enabled/> <data id='IncognitoModeAvailability' value='0' />",
                options: [
                    { label: 'Enabled', value: "<enabled/> <data id='IncognitoModeAvailability' value='0' />" },
                    { label: 'Disabled', value: "<enabled/> <data id='IncognitoModeAvailability' value='1' />" },
                    { label: 'Forced', value: "<enabled/> <data id='IncognitoModeAvailability' value='2' />" }
                ]
            },
            {
                label: 'Browser History',
                value: 'SavingBrowserHistoryDisabled',
                inputType: 'dropdown',
                placeholder: 'Select',
                helpText: 'Specifies whether the browser history is saved or not saved',
                parentId: 'Chrome~Policy~googlechrome',
                myId: 'SavingBrowserHistoryDisabled',
                defaultValue: '<disabled/>',
                options: [
                    { label: 'Allow Saving', value: '<disabled/>' },
                    { label: 'Disallow Saving', value: '<enabled/>' }
                ]
            },
            {
                label: 'Clear Browser History',
                value: 'AllowDeletingBrowserHistory',
                inputType: 'dropdown',
                placeholder: 'Select',
                helpText: 'Specifies whether the browser history and download history can be deleted',
                parentId: 'Chrome~Policy~googlechrome',
                myId: 'AllowDeletingBrowserHistory',
                defaultValue: '<enabled/>',
                options: [
                    { label: 'Allows Users to Clear', value: '<enabled/>' },
                    { label: 'Disallows Users to Clear', value: '<disabled/>' }
                ]
            },
            {
                label: 'Blocked URLs',
                value: 'URLBlocklist',
                helpText: "List of blocked website URLs browsers can't navigate to.",
                inputType: 'tempDelimiterArray',
                delimiter: '&#xF000;',
                parentId: 'Chrome~Policy~googlechrome',
                myId: 'URLBlocklist',
                defaultValue: '',
                dataId: 'URLBlocklistDesc',
                placeholder: 'URL'
            },
            {
                label: 'Printing Settings'
            },
            {
                label: 'Printing Settings',
                value: 'PrintingEnabled',
                inputType: 'dropdown',
                placeholder: 'Select',
                helpText: 'Enable Printing',
                parentId: 'Chrome~Policy~googlechrome~Printing',
                myId: 'PrintingEnabled',
                defaultValue: '<enabled/>',
                options: [
                    { label: 'Enable', value: '<enabled/>' },
                    { label: 'Disable', value: '<disabled/>' }
                ]
            }
        ]
    },
    {
        label: 'Edge Configurations',
        helpText: 'Use this section for Browser Configurations',
        show: ['FM', 'SA', 'MA'],
        image: edgeBrowser,
        fields: [
            {
                label: 'Startup Settings'
            },
            {
                label: 'Home Page',
                value: 'EdgeHomepageIsNewTabPage',
                inputType: 'dropdown',
                placeholder: 'Select',
                helpText: 'Specifies the Home Page behaviour',
                parentId: 'Edge~Policy~microsoft_edge~Startup',
                myId: 'HomepageIsNewTabPage',
                defaultValue: '',
                options: [
                    { label: 'User Control', value: '' },
                    { label: 'New Tab Page', value: '<enabled/>' },
                    { label: 'Launch Below URL', value: '<disabled/>' }
                ]
            },
            {
                label: 'Home Page URL',
                value: 'EdgeHomepageLocation',
                helpText: 'Specifies the Home Page URL',
                inputType: 'tempInput',
                parentId: 'Edge~Policy~microsoft_edge~Startup',
                myId: 'HomepageLocation',
                enableOn: 'EdgeHomepageIsNewTabPage',
                enableOnVal: ['<disabled/>'],
                defaultValue: '',
                placeholder: 'https://www.example.com/'
            },
            {
                label: 'Home Button',
                value: 'EdgeShowHomeButton',
                inputType: 'dropdown',
                placeholder: 'Select',
                helpText: 'Specifies the Home Button behaviour',
                parentId: 'Edge~Policy~microsoft_edge~Startup',
                myId: 'ShowHomeButton',
                defaultValue: '<enabled/>',
                options: [
                    { label: 'Always Show', value: '<enabled/>' },
                    { label: 'Never Show', value: '<disabled/>' }
                ]
            },
            {
                label: 'Restore on Startup',
                value: 'EdgeRestoreOnStartup',
                inputType: 'dropdown',
                placeholder: 'Select',
                helpText: 'Specifies the browser behaviour on startup',
                parentId: 'Edge~Policy~microsoft_edge~Startup',
                myId: 'RestoreOnStartup',
                defaultValue: "<enabled/> <data id='RestoreOnStartup' value='5' />",
                options: [
                    { label: 'Open New Tab Page', value: "<enabled/> <data id='RestoreOnStartup' value='5' />" },
                    { label: 'Restore the last session', value: "<enabled/> <data id='RestoreOnStartup' value='1' />" },
                    { label: 'Open a list of URLs', value: "<enabled/> <data id='RestoreOnStartup' value='4' />" },
                    {
                        label: 'Open a list of URLs and restore the last session',
                        value: "<enabled/> <data id='RestoreOnStartup' value='6' />"
                    }
                ]
            },
            {
                label: 'Restore on Startup URLs',
                value: 'EdgeRestoreOnStartupURLs',
                helpText: 'List of website URLs which open start up',
                inputType: 'tempDelimiterArray',
                delimiter: '&#xF000;',
                parentId: 'Edge~Policy~microsoft_edge~Startup',
                myId: 'RestoreOnStartupURLs',
                dataId: 'RestoreOnStartupURLsDesc',
                enableOn: 'EdgeRestoreOnStartup',
                enableOnVal: ["<enabled/> <data id='RestoreOnStartup' value='4' />", "<enabled/> <data id='RestoreOnStartup' value='6' />"],
                defaultValue: '',
                placeholder: 'URL'
            },
            {
                label: 'User Experience Settings'
            },
            {
                label: 'Bookmark Bar',
                value: 'EdgeBookmarkBarEnabled',
                inputType: 'dropdown',
                placeholder: 'Select',
                helpText: 'Specifies whether Bookmark Bar is Enabled or Disabled',
                parentId: 'Edge~Policy~microsoft_edge',
                myId: 'BookmarkBarEnabled',
                defaultValue: '<enabled/>',
                options: [
                    { label: 'Enable', value: '<enabled/>' },
                    { label: 'Disable', value: '<disabled/>' }
                ]
            },
            {
                label: 'Developer Tools',
                value: 'EdgeDeveloperToolsAvailability',
                inputType: 'dropdown',
                placeholder: 'Select',
                helpText: 'Specifies whether Developer Tools is Enabled or Disabled',
                parentId: 'Edge~Policy~microsoft_edge',
                myId: 'DeveloperToolsAvailability',
                defaultValue: "<enabled/> <data id='DeveloperToolsAvailability' value='2' />",
                options: [
                    { label: 'Enabled', value: "<enabled/> <data id='DeveloperToolsAvailability' value='1' />" },
                    { label: 'Disabled', value: "<enabled/> <data id='DeveloperToolsAvailability' value='2' />" }
                ]
            },
            {
                label: 'AutoFill for Address',
                value: 'EdgeAutofillAddressEnabled',
                inputType: 'dropdown',
                placeholder: 'Select',
                helpText: 'Specifies whether AutoFill for address is Enabled',
                parentId: 'Edge~Policy~microsoft_edge',
                myId: 'AutofillAddressEnabled',
                defaultValue: '<enabled/>',
                options: [
                    { label: 'Enabled', value: '<enabled/>' },
                    { label: 'Disabled', value: '<disabled/>' }
                ]
            },
            {
                label: 'Content Settings'
            },
            {
                label: 'Default Cookie Settings',
                value: 'EdgeDefaultCookiesSetting',
                inputType: 'dropdown',
                placeholder: 'Select',
                helpText: 'Default Cookie Settings',
                parentId: 'Edge~Policy~microsoft_edge~ContentSettings',
                myId: 'DefaultCookiesSetting',
                defaultValue: "<enabled/> <data id='DefaultCookiesSetting' value='1' />",
                options: [
                    { label: 'Allow', value: "<enabled/> <data id='DefaultCookiesSetting' value='1' />" },
                    { label: 'DisAllow', value: "<enabled/> <data id='DefaultCookiesSetting' value='2' />" },
                    { label: 'Delete after Session', value: "<enabled/> <data id='DefaultCookiesSetting' value='4' />" }
                ]
            },
            {
                label: 'Google Web Search',
                value: 'EdgeForceGoogleSafeSearch',
                inputType: 'dropdown',
                placeholder: 'Select',
                helpText: 'Google Web Safe Search',
                parentId: 'Edge~Policy~microsoft_edge',
                myId: 'ForceGoogleSafeSearch',
                defaultValue: '<enabled/>',
                options: [
                    { label: 'Enforce Safe Search', value: '<enabled/>' },
                    { label: 'Donot Enforce Safe Search', value: '<disabled/>' }
                ]
            },
            {
                label: 'Youtube Restriction Mode',
                value: 'EdgeForceYoutubeRestrict',
                inputType: 'dropdown',
                placeholder: 'Select',
                helpText: 'Enforce Youtube Restriction',
                parentId: 'Edge~Policy~microsoft_edge',
                myId: 'ForceYoutubeRestrict',
                defaultValue: "<enabled/> <data id='ForceYoutubeRestrict' value='2' />",
                options: [
                    { label: 'No Restriction', value: "<enabled/> <data id='ForceYoutubeRestrict' value='0' />" },
                    { label: 'Moderate Restriction', value: "<enabled/> <data id='ForceYoutubeRestrict' value='1' />" },
                    { label: 'Strict Restriction', value: "<enabled/> <data id='ForceYoutubeRestrict' value='2' />" }
                ]
            },
            {
                label: 'Security Settings'
            },
            {
                label: 'Password Manager',
                value: 'EdgePasswordManagerEnabled',
                inputType: 'dropdown',
                placeholder: 'Select',
                helpText: 'Specifies whether the browser can remember passwords on websites and save them',
                parentId: 'Edge~Policy~microsoft_edge~PasswordManager',
                myId: 'PasswordManagerEnabled',
                defaultValue: '<enabled/>',
                options: [
                    { label: 'Enabled', value: '<enabled/>' },
                    { label: 'Disabled', value: '<disabled/>' }
                ]
            },
            {
                label: 'Incognito Mode',
                value: 'EdgeIncognitoModeAvailability',
                inputType: 'dropdown',
                placeholder: 'Select',
                helpText: 'Specifies whether the user may open pages in Incognito mode',
                parentId: 'Edge~Policy~microsoft_edge',
                myId: 'IncognitoModeAvailability',
                defaultValue: "<enabled/> <data id='IncognitoModeAvailability' value='0' />",
                options: [
                    { label: 'Enabled', value: "<enabled/> <data id='IncognitoModeAvailability' value='0' />" },
                    { label: 'Disabled', value: "<enabled/> <data id='IncognitoModeAvailability' value='1' />" },
                    { label: 'Forced', value: "<enabled/> <data id='IncognitoModeAvailability' value='2' />" }
                ]
            },
            {
                label: 'Browser History',
                value: 'EdgeSavingBrowserHistoryDisabled',
                inputType: 'dropdown',
                placeholder: 'Select',
                helpText: 'Specifies whether the browser history is saved or not saved',
                parentId: 'Edge~Policy~microsoft_edge',
                myId: 'SavingBrowserHistoryDisabled',
                defaultValue: '<disabled/>',
                options: [
                    { label: 'Allow Saving', value: '<disabled/>' },
                    { label: 'Disallow Saving', value: '<enabled/>' }
                ]
            },
            {
                label: 'Clear Browser History',
                value: 'EdgeAllowDeletingBrowserHistory',
                inputType: 'dropdown',
                placeholder: 'Select',
                helpText: 'Specifies whether the browser history and download history can be deleted',
                parentId: 'Edge~Policy~microsoft_edge',
                myId: 'AllowDeletingBrowserHistory',
                defaultValue: '<enabled/>',
                options: [
                    { label: 'Allows Users to Clear', value: '<enabled/>' },
                    { label: 'Disallows Users to Clear', value: '<disabled/>' }
                ]
            },
            {
                label: 'Blocked URLs',
                value: 'EdgeURLBlocklist',
                helpText: "List of blocked website URLs browsers can't navigate to.",
                inputType: 'tempDelimiterArray',
                delimiter: '&#xF000;',
                parentId: 'Edge~Policy~microsoft_edge',
                myId: 'URLBlocklist',
                dataId: 'URLBlocklistDesc',
                defaultValue: '',
                placeholder: 'URL'
            },
            {
                label: 'Printing Settings'
            },
            {
                label: 'Printing Settings',
                value: 'PrintingEnabled',
                inputType: 'dropdown',
                placeholder: 'Select',
                helpText: 'Enable Printing',
                parentId: 'Edge~Policy~microsoft_edge~Printing',
                myId: 'PrintingEnabled',
                defaultValue: '<enabled/>',
                options: [
                    { label: 'Enable', value: '<enabled/>' },
                    { label: 'Disable', value: '<disabled/>' }
                ]
            }
        ]
    },
    {
        label: 'Kiosk Browser',
        helpText: 'Use this section for Kiosk Browser Settings',
        note: 'This policy only applies to the Kiosk Browser app in Microsoft Store',
        show: ['FM', 'SA', 'MA'],
        image: kioskBrowser,
        fields: [
            {
                label: 'End Session Button',
                inputType: 'checkbox',
                value: 'EnableEndSessionButton',
                helpText: "Enable/disable kiosk browser's end session button.",
                parentId: 'KioskBrowser',
                myId: 'EnableEndSessionButton',
                defaultValue: 0,
                checkedValue: 1,
                options: [
                    { label: 'Disable.', value: 0 },
                    { label: 'Enable.', value: 1 }
                ]
            },
            {
                label: 'Home Button',
                inputType: 'checkbox',
                value: 'EnableHomeButton',
                helpText: "Enable/disable kiosk browser's home button",
                parentId: 'KioskBrowser',
                myId: 'EnableHomeButton',
                defaultValue: 0,
                checkedValue: 1,
                options: [
                    { label: 'Disable.', value: 0 },
                    { label: 'Enable.', value: 1 }
                ]
            },
            {
                label: 'Navigation buttons',
                inputType: 'checkbox',
                value: 'EnableNavigationButtons',
                helpText: "Enable/disable kiosk browser's navigation buttons (forward/back)",
                parentId: 'KioskBrowser',
                myId: 'EnableNavigationButtons',
                defaultValue: 0,
                checkedValue: 1,
                options: [
                    { label: 'Disable.', value: 0 },
                    { label: 'Enable.', value: 1 }
                ]
            },
            {
                label: 'Session is idle until the kiosk browser restarts',
                value: 'RestartOnIdleTime',
                helpText: 'Amount of time in minutes the session is idle until the kiosk browser restarts in a fresh state.',
                inputType: 'policyInput',
                regExp: '^[0-9]+$',
                valueType: 'number',
                minValue: 0,
                maxValue: 1440,
                maxLength: 4,
                parentId: 'KioskBrowser',
                myId: 'RestartOnIdleTime',
                defaultValue: 0,
                placeholder: 'Range: [0-1440]'
            },
            {
                label: 'Default URL',
                value: 'DefaultURL',
                helpText: 'Configures the default URL for kiosk browsers to navigate on launch and restart.',
                inputType: 'policyInput',
                parentId: 'KioskBrowser',
                myId: 'DefaultURL',
                defaultValue: '',
                placeholder: 'https://www.example.com/'
            },
            {
                label: 'Blocked URLs',
                value: 'BlockedUrls',
                helpText: "List of blocked website URLs kiosk browsers can't navigate to.",
                inputType: 'delimiterArray',
                delimiter: '\uF000',
                parentId: 'KioskBrowser',
                myId: 'BlockedUrls',
                defaultValue: '',
                placeholder: 'URL'
            }
        ]
    },
    {
        label: 'Settings',
        helpText: 'Use this section for configuring Settings',
        show: ['FM', 'SA', 'MA'],
        image: settings,
        fields: [
            {
                label: 'Auto Play',
                inputType: 'checkbox',
                value: 'AllowAutoPlay',
                helpText: 'Allows the user to change Auto Play settings',
                parentId: 'Settings',
                myId: 'AllowAutoPlay',
                defaultValue: 1,
                checkedValue: 1,
                options: [
                    { label: 'Not allowed', value: 0 },
                    { label: 'Allowed', value: 1 }
                ]
            },
            {
                label: 'Data Sense',
                inputType: 'checkbox',
                value: 'AllowDataSense',
                helpText: 'Allows the user to change Data Sense settings',
                parentId: 'Settings',
                myId: 'AllowDataSense',
                defaultValue: 1,
                checkedValue: 1,
                options: [
                    { label: 'Not allowed', value: 0 },
                    { label: 'Allowed', value: 1 }
                ]
            },
            {
                label: 'Device Name',
                inputType: 'checkbox',
                value: 'AllowEditDeviceName',
                helpText: 'Allow Edit Device Name',
                parentId: 'Settings',
                myId: 'AllowEditDeviceName',
                defaultValue: 1,
                checkedValue: 1,
                options: [
                    { label: 'Not allowed', value: 0 },
                    { label: 'Allowed', value: 1 }
                ]
            },
            {
                label: 'Language',
                inputType: 'checkbox',
                value: 'AllowLanguage',
                helpText: 'Allows the user to change the language settings',
                parentId: 'Settings',
                myId: 'AllowLanguage',
                defaultValue: 1,
                checkedValue: 1,
                options: [
                    { label: 'Not allowed', value: 0 },
                    { label: 'Allowed', value: 1 }
                ]
            },
            {
                label: 'Online tips',
                inputType: 'checkbox',
                value: 'AllowOnlineTips',
                helpText: 'Enables or disables the retrieval of online tips and help for the Settings app',
                parentId: 'Settings',
                myId: 'AllowOnlineTips',
                defaultValue: 1,
                checkedValue: 1,
                options: [
                    { label: 'Not allowed', value: 0 },
                    { label: 'Allowed', value: 1 }
                ]
            },
            {
                label: 'Power and sleep',
                inputType: 'checkbox',
                value: 'AllowPowerSleep',
                helpText: 'Allows the user to change power and sleep settings',
                parentId: 'Settings',
                myId: 'AllowPowerSleep',
                defaultValue: 1,
                checkedValue: 1,
                options: [
                    { label: 'Not allowed', value: 0 },
                    { label: 'Allowed', value: 1 }
                ]
            },
            {
                label: 'Region',
                inputType: 'checkbox',
                value: 'AllowRegion',
                helpText: 'Allows the user to change the region settings',
                parentId: 'Settings',
                myId: 'AllowRegion',
                defaultValue: 1,
                checkedValue: 1,
                options: [
                    { label: 'Not allowed', value: 0 },
                    { label: 'Allowed', value: 1 }
                ]
            },
            {
                label: 'Change sign-in',
                inputType: 'checkbox',
                value: 'AllowSignInOptions',
                helpText: 'Allows the user to change sign-in options',
                parentId: 'Settings',
                myId: 'AllowSignInOptions',
                defaultValue: 1,
                checkedValue: 1,
                options: [
                    { label: 'Not allowed', value: 0 },
                    { label: 'Allowed', value: 1 }
                ]
            },
            {
                label: 'Work Place',
                inputType: 'checkbox',
                value: 'AllowWorkplace',
                helpText: 'Allows user to change workplace settings',
                parentId: 'Settings',
                myId: 'AllowWorkplace',
                defaultValue: 1,
                checkedValue: 1,
                options: [
                    { label: 'Not allowed', value: 0 },
                    { label: 'Allowed', value: 1 }
                ]
            },
            {
                label: 'Change account',
                inputType: 'checkbox',
                value: 'AllowYourAccount',
                helpText: 'Allows user to change account settings',
                parentId: 'Settings',
                myId: 'AllowYourAccount',
                defaultValue: 1,
                checkedValue: 1,
                options: [
                    { label: 'Not allowed', value: 0 },
                    { label: 'Allowed', value: 1 }
                ]
            },
            {
                label: 'Additional calendar',
                value: 'ConfigureTaskbarCalendar',
                inputType: 'dropdown',
                placeholder: 'Select',
                helpText: 'Configure Taskbar Calendar',
                parentId: 'Settings',
                myId: 'ConfigureTaskbarCalendar',
                defaultValue: 0,
                options: [
                    { label: 'User will be allowed to configure the setting', value: 0 },
                    { label: "Don't show additional calendars", value: 1 },
                    { label: 'Peering will cross NATs', value: 2 },
                    { label: 'HTTP blended with Internet Peering.', value: 3 }
                ]
            }
        ]
    },
    // {
    //     label: 'Speech',
    //     helpText: 'Speech Recognition on device allows you to control the device by voice',
    //     show: ['FM','SA'],
    //     image: speech,
    //     fields: [
    //         {
    //             label: 'Speech Model',
    //             inputType: 'checkbox',
    //             value: 'AllowSpeechModelUpdate',
    //             helpText: 'Allow Automatic Update of Speech Data',
    //             parentId: 'Settings',
    //             myId: 'AllowSpeechModelUpdate',
    //             defaultValue: 1,
    //             checkedValue: 1,
    //             options: [
    //                 { label: 'Not allowed', value: 0 },
    //                 { label: 'Allowed', value: 1 }
    //             ]
    //         }
    //     ]
    // },
    {
        label: 'Delivery Optimization',
        helpText:
            'Delivery Optimization settings on your Windows 10 or later devices can assist reduce bandwidth use when downloading applications and updates.',
        show: ['FM', 'SA', 'MA'],
        image: deliveryOptimization,
        fields: [
            {
                label: 'Download mode',
                value: 'DODownloadMode',
                inputType: 'dropdown',
                placeholder: 'Select',
                helpText:
                    'Specifies the download method that Delivery Optimization uses for downloading Windows Updates, apps and app updates',
                parentId: 'DeliveryOptimization',
                myId: 'DODownloadMode',
                defaultValue: 0,
                options: [
                    { label: 'HTTP only, no Peering.', value: 0 },
                    { label: 'HTTP blended with peering behind the same NAT.', value: 1 },
                    { label: 'Peering will cross NATs', value: 2 },
                    { label: 'HTTP blended with Internet Peering.', value: 3 },
                    {
                        label: 'Simple download mode with no peering. Delivery Optimization downloads using HTTP only and does not attempt to contact the Delivery Optimization cloud services. Added in Windows 10, version 1607.',
                        value: 99
                    },
                    {
                        label: 'Bypass mode. Windows 10: Do not use Delivery Optimization and use BITS instead. Windows 11: Deprecated, use Simple mode instead.',
                        value: 100
                    }
                ]
            },
            {
                label: 'Delay Background HTTP download',
                value: 'DODelayBackgroundDownloadFromHttp',
                helpText:
                    'Specifies how long to delay using an HTTP source for background downloads that can use peer-to-peer. After this delay, the download resumes using HTTP to complete. Note that if waiting for peer sources, the download may appear stuck. The recommended delay is one minute.',
                inputType: 'policyInput',
                regExp: '^[0-9]+$',
                valueType: 'number',
                minValue: 0,
                maxValue: 4294967295,
                maxLength: 10,
                parentId: 'DeliveryOptimization',
                myId: 'DODelayBackgroundDownloadFromHttp',
                defaultValue: 0,
                placeholder: '0-4294967295 Second(s)'
            },
            {
                label: 'Delay Foreground HTTP download',
                value: 'DODelayForegroundDownloadFromHttp',
                helpText:
                    'Allows you to delay using an HTTP source for foreground downloads that can also use peer-to-peer. After the maximum delay, the download switches to HTTP to complete. Note that if waiting for peer sources, the download may appear stuck. The recommended delay is one minute.',
                inputType: 'policyInput',
                regExp: '^[0-9]+$',
                valueType: 'number',
                minValue: 0,
                maxValue: 4294967295,
                maxLength: 10,
                parentId: 'DeliveryOptimization',
                myId: 'DODelayForegroundDownloadFromHttp',
                defaultValue: 0,
                placeholder: '0-4294967295 Second(s)'
            },
            {
                label: 'Minimum RAM required for peer caching',
                value: 'DOMinRAMAllowedToPeer',
                helpText: 'Specifies the minimum RAM size in .GB required to use peer caching.',
                inputType: 'policyInput',
                regExp: '^[0-9]+$',
                valueType: 'number',
                minValue: 0,
                maxValue: 100000,
                maxLength: 6,
                parentId: 'DeliveryOptimization',
                myId: 'DOMinRAMAllowedToPeer',
                defaultValue: 0,
                placeholder: '1-100000 GB(s)'
            },
            {
                label: 'Minimum disk size required for peer caching',
                value: 'DOMinDiskSizeAllowedToPeer',
                helpText:
                    'Specifies the minimum disk size (capacity in GB) required for the device to use peer caching. Recommended value: 64 - 256 GB ',
                inputType: 'policyInput',
                regExp: '^[0-9]+$',
                valueType: 'number',
                minValue: 0,
                maxValue: 100000,
                maxLength: 6,
                parentId: 'DeliveryOptimization',
                myId: 'DOMinDiskSizeAllowedToPeer',
                defaultValue: 0,
                placeholder: '1-100000 GB(s)'
            },
            {
                label: 'Minimum content file size required for Peer Caching',
                value: 'DOMinFileSizeToCache',
                helpText: 'Specifies the minimum content file size in MB enabled to use peer caching. Recommended value: 1 - 100,000 MB',
                inputType: 'policyInput',
                regExp: '^[0-9]+$',
                valueType: 'number',
                minValue: 0,
                maxValue: 100000,
                maxLength: 6,
                parentId: 'DeliveryOptimization',
                myId: 'DOMinFileSizeToCache',
                defaultValue: 0,
                placeholder: '1-100000 MB(s)'
            },
            {
                label: 'Minimum Battery Percentage required to upload',
                value: 'DOMinBatteryPercentageAllowedToUpload',
                helpText:
                    'Specifies a battery percentage between 1 - 100 to allow the device to upload data to LAN and group peers while running on battery power. Uploads would automatically pause if battery level falls below the minimum specified battery level. The value 0 means "not-limited". The cloud service set default value will be used.',
                inputType: 'policyInput',
                regExp: '^[0-9]+$',
                valueType: 'number',
                minValue: 0,
                maxValue: 100,
                maxLength: 3,
                parentId: 'DeliveryOptimization',
                myId: 'DOMinBatteryPercentageAllowedToUpload',
                defaultValue: 0,
                placeholder: '0-100%'
            },
            {
                label: 'Modify Cache Drive',
                value: 'DOModifyCacheDrive',
                helpText: 'Specifies the drive that delivery optimization should use for its cache',
                inputType: 'policyInput',
                parentId: 'DeliveryOptimization',
                myId: 'DOModifyCacheDrive',
                defaultValue: '%SystemDrive%',
                placeholder: 'Drive location path'
            },
            {
                label: 'Maximum Cache Age',
                value: 'DOMaxCacheAge',
                helpText:
                    'Specifies the maximum time in seconds that each file is held in delivery optimization cache after downloading successfully',
                inputType: 'policyInput',
                regExp: '^[0-9]+$',
                valueType: 'number',
                minValue: 0,
                maxValue: 4294967295,
                maxLength: 10,
                parentId: 'DeliveryOptimization',
                myId: 'DOMaxCacheAge',
                defaultValue: 0,
                placeholder: '0-4294967295 second(s)'
            },
            {
                label: 'Maximum Background Download Bandwidth (Percentage)',
                value: 'DOPercentageMaxBackgroundBandwidth',
                helpText:
                    'Specifies the maximum background download bandwidth that Delivery Optimization uses across all concurrent download activities as a percentage of available download bandwidth.',
                inputType: 'policyInput',
                regExp: '^[0-9]+$',
                valueType: 'number',
                minValue: 0,
                maxValue: 100,
                maxLength: 3,
                parentId: 'DeliveryOptimization',
                myId: 'DOPercentageMaxBackgroundBandwidth',
                defaultValue: 0,
                placeholder: '0-100%'
            },
            {
                label: 'Maximum Foreground Download Bandwidth (Percentage)',
                value: 'DOPercentageMaxForegroundBandwidth',
                helpText:
                    'Specifies the maximum foreground download bandwidth that Delivery Optimization uses across all concurrent download activities as a percentage of available download bandwidth.',
                inputType: 'policyInput',
                regExp: '^[0-9]+$',
                valueType: 'number',
                minValue: 0,
                maxValue: 100,
                maxLength: 3,
                parentId: 'DeliveryOptimization',
                myId: 'DOPercentageMaxForegroundBandwidth',
                defaultValue: 0,
                placeholder: '0-100%'
            },
            {
                label: 'Maximum Background Download Bandwidth (in KB/s)',
                value: 'DOMaxBackgroundDownloadBandwidth',
                helpText:
                    'Specifies the maximum background download bandwidth in KiloBytes/second that the device can use across all concurrent download activities using Delivery Optimization.',
                inputType: 'policyInput',
                regExp: '^[0-9]+$',
                valueType: 'number',
                minValue: 0,
                maxValue: 4294967295,
                maxLength: 10,
                parentId: 'DeliveryOptimization',
                myId: 'DOMaxBackgroundDownloadBandwidth',
                defaultValue: 0,
                placeholder: '0-4294967295'
            },
            {
                label: 'Maximum Foreground Download Bandwidth (in KB/s)',
                value: 'DOMaxForegroundDownloadBandwidth',
                helpText:
                    'Specifies the maximum foreground download bandwidth in KiloBytes/second that the device can use across all concurrent download activities using Delivery Optimization.',
                inputType: 'policyInput',
                regExp: '^[0-9]+$',
                valueType: 'number',
                minValue: 0,
                maxValue: 4294967295,
                maxLength: 10,
                parentId: 'DeliveryOptimization',
                myId: 'DOMaxForegroundDownloadBandwidth',
                defaultValue: 0,
                placeholder: '0-4294967295'
            },
            {
                label: 'Monthly Upload Data Cap',
                value: 'DOMonthlyUploadDataCap',
                helpText:
                    "Specifies the maximum total bytes in GB that Delivery Optimization is allowed to upload to Internet peers in each calendar month. The value 0 (zero) means unlimited; No monthly upload limit's applied if 0 is set.",
                inputType: 'policyInput',
                regExp: '^[0-9]+$',
                valueType: 'number',
                minValue: 0,
                maxValue: 4294967295,
                maxLength: 10,
                parentId: 'DeliveryOptimization',
                myId: 'DOMonthlyUploadDataCap',
                defaultValue: 0,
                placeholder: '0-4294967295'
            },
            {
                label: 'VPN Peer Caching',
                inputType: 'checkbox',
                value: 'DOAllowVPNPeerCaching',
                helpText: 'Specifies whether the device can participate in peer caching while connected via VPN to the domain network',
                parentId: 'DeliveryOptimization',
                myId: 'DOAllowVPNPeerCaching',
                defaultValue: 0,
                checkedValue: 1,
                options: [
                    { label: 'Not Allowed.', value: 0 },
                    { label: 'Allowed.', value: 1 }
                ]
            }
        ]
    },
    {
        label: 'Defender',
        helpText:
            'Defender Profile incorporates parameters for safeguarding your system by controlling system scans, protecting against network-level attacks, and enabling real-time monitoring.',
        show: ['FM', 'SA', 'MA'],
        image: defender,
        fields: [
            {
                label: 'Scan Archives',
                inputType: 'checkbox',
                value: 'AllowArchiveScanning',
                helpText: 'Allows or disallows scanning of archives',
                parentId: 'Defender',
                myId: 'AllowArchiveScanning',
                defaultValue: 1,
                checkedValue: 1,
                options: [
                    { label: 'Not Allowed.', value: 0 },
                    { label: 'Allowed.', value: 1 }
                ]
            },
            {
                label: 'Behaviour Monitoring',
                inputType: 'checkbox',
                value: 'AllowBehaviorMonitoring',
                helpText: 'Allows or disallows Windows Defender Behavior Monitoring functionality.',
                parentId: 'Defender',
                myId: 'AllowBehaviorMonitoring',
                defaultValue: 1,
                checkedValue: 1,
                options: [
                    { label: 'Not Allowed.', value: 0 },
                    { label: 'Allowed.', value: 1 }
                ]
            },
            {
                label: 'Cloud Protection',
                inputType: 'checkbox',
                value: 'AllowCloudProtection',
                helpText:
                    'Specifies that Windows Defender sends information to Microsoft about detected issues to enhance PC protection and provide improved solutions for users',
                parentId: 'Defender',
                myId: 'AllowCloudProtection',
                defaultValue: 1,
                checkedValue: 1,
                options: [
                    { label: 'Not Allowed.', value: 0 },
                    { label: 'Allowed.', value: 1 }
                ]
            },
            {
                label: 'Scan Email',
                inputType: 'checkbox',
                value: 'AllowEmailScanning',
                helpText: 'Allows or disallows scanning of email',
                parentId: 'Defender',
                myId: 'AllowEmailScanning',
                defaultValue: 0,
                checkedValue: 1,
                options: [
                    { label: 'Not Allowed.', value: 0 },
                    { label: 'Allowed.', value: 1 }
                ]
            },
            {
                label: 'Scan Mapped Network Drives',
                inputType: 'checkbox',
                value: 'AllowFullScanOnMappedNetworkDrives',
                helpText: 'Allows or disallows complete scanning of mapped network drives',
                parentId: 'Defender',
                myId: 'AllowFullScanOnMappedNetworkDrives',
                defaultValue: 0,
                checkedValue: 1,
                options: [
                    { label: 'Not Allowed.', value: 0 },
                    { label: 'Allowed.', value: 1 }
                ]
            },
            {
                label: 'Scan Removable Drives',
                inputType: 'checkbox',
                value: 'AllowFullScanRemovableDriveScanning',
                helpText: 'Allows or disallows scan of removable drives',
                parentId: 'Defender',
                myId: 'AllowFullScanRemovableDriveScanning',
                defaultValue: 1,
                checkedValue: 1,
                options: [
                    { label: 'Not Allowed.', value: 0 },
                    { label: 'Allowed.', value: 1 }
                ]
            },
            {
                label: 'Intrusion Prevention System',
                inputType: 'checkbox',
                value: 'AllowIntrusionPreventionSystem',
                helpText: 'Allows or disallows Windows defender intrusion prevention functionality',
                parentId: 'Defender',
                myId: 'AllowIntrusionPreventionSystem',
                defaultValue: 1,
                checkedValue: 1,
                options: [
                    { label: 'Not Allowed.', value: 0 },
                    { label: 'Allowed.', value: 1 }
                ]
            },
            {
                label: 'Input/Output File Protection',
                inputType: 'checkbox',
                value: 'AllowIOAVProtection',
                helpText: 'Allows or disallows Windows defender intrusion IOAVP protection functionality',
                parentId: 'Defender',
                myId: 'AllowIOAVProtection',
                defaultValue: 1,
                checkedValue: 1,
                options: [
                    { label: 'Not Allowed.', value: 0 },
                    { label: 'Allowed.', value: 1 }
                ]
            },
            {
                label: 'Cloud Protection Level',
                inputType: 'dropdown',
                placeholder: 'Select',
                value: 'CloudBlockLevel',
                helpText: 'Specifies how aggressive windows defender antivirus will be in blocking & scanning suspicious files',
                parentId: 'Defender',
                myId: 'CloudBlockLevel',
                defaultValue: 0,
                options: [
                    { label: 'Not Configured.', value: 0 },
                    { label: 'High.', value: 2 },
                    { label: 'HighPlus', value: 4 },
                    { label: 'ZeroTolerance', value: 6 }
                ]
            },
            {
                label: 'Allow On Access Protection',
                inputType: 'checkbox',
                value: 'AllowOnAccessProtection',
                helpText: 'Allows or disallows Windows Defender On Access Protection functionality.',
                parentId: 'Defender',
                myId: 'AllowOnAccessProtection',
                defaultValue: 1,
                checkedValue: 1,
                options: [
                    { label: 'Not Allowed.', value: 0 },
                    { label: 'Allowed.', value: 1 }
                ]
            },
            {
                label: 'Scan Network Files',
                inputType: 'checkbox',
                value: 'AllowScanningNetworkFiles',
                helpText: 'Allows or disallows a scanning of network files.',
                parentId: 'Defender',
                myId: 'AllowScanningNetworkFiles',
                defaultValue: 0,
                checkedValue: 1,
                options: [
                    { label: 'Not Allowed.', value: 0 },
                    { label: 'Allowed.', value: 1 }
                ]
            },
            {
                label: 'Real-Time Monitoring',
                inputType: 'checkbox',
                value: 'AllowRealtimeMonitoring',
                helpText: 'Allows or disallows Windows Defender Realtime Monitoring functionality.',
                parentId: 'Defender',
                myId: 'AllowRealtimeMonitoring',
                defaultValue: 1,
                checkedValue: 1,
                options: [
                    { label: 'Not Allowed.', value: 0 },
                    { label: 'Allowed.', value: 1 }
                ]
            },
            {
                label: 'PUA Protection',
                inputType: 'dropdown',
                placeholder: 'Select',
                value: 'PUAProtection',
                helpText:
                    'Specifies the level of detection for potentially unwanted applications (PUAs). Windows Defender alerts you when potentially unwanted software is being downloaded or attempts to install itself on your computer.',
                parentId: 'Defender',
                myId: 'PUAProtection',
                defaultValue: 0,
                options: [
                    { label: 'PUA Protection off.', value: 0 },
                    { label: 'PUA Protection on. Detected items are blocked.', value: 1 },
                    { label: 'Audit mode. Windows Defender will detect potentially unwanted applications, but take no action.', value: 2 }
                ]
            },
            {
                label: 'Monitor & Scan',
                inputType: 'dropdown',
                placeholder: 'Select',
                value: 'RealTimeScanDirection',
                helpText:
                    'Controls which sets of files should be monitored. Note If AllowOnAccessProtection is not allowed, then this configuration can be used to monitor specific files.',
                parentId: 'Defender',
                myId: 'RealTimeScanDirection',
                defaultValue: 0,
                options: [
                    { label: 'Monitor all files (bi-directional).', value: 0 },
                    { label: 'Monitor incoming files.', value: 1 },
                    { label: 'Monitor outgoing files.', value: 2 }
                ]
            },
            {
                label: 'Scheduled Scan Type',
                inputType: 'dropdown',
                placeholder: 'Select',
                value: 'ScanParameter',
                helpText: 'Selects whether to perform a quick scan or full scan.',
                parentId: 'Defender',
                myId: 'ScanParameter',
                defaultValue: 1,
                options: [
                    { label: 'Quick scan', value: 1 },
                    { label: 'Full scan', value: 2 }
                ]
            },
            {
                label: 'Network Protection',
                inputType: 'dropdown',
                placeholder: 'Select',
                value: 'EnableNetworkProtection',
                helpText:
                    "This policy allows you to turn network protection on (block/audit) or off. Network protection protects employees using any app from accessing phishing scams, exploit-hosting sites, and malicious content on the Internet. This includes preventing third-party browsers from connecting to dangerous sites. Value type is integer. If you enable this setting, network protection is turned on and employees can't turn it off. Its behavior can be controlled by the following options: Block and Audit. If you enable this policy with the Block option, users/apps will be blocked from connecting to dangerous domains. You will be able to see this activity in Windows Defender Security Center. If you enable this policy with the Audit option, users/apps will not be blocked from connecting to dangerous domains. However, you will still see this activity in Windows Defender Security Center. If you disable this policy, users/apps will not be blocked from connecting to dangerous domains. You will not see any network activity in Windows Defender Security Center. If you do not configure this policy, network blocking will be disabled by default.",
                parentId: 'Defender',
                myId: 'EnableNetworkProtection',
                defaultValue: 0,
                options: [
                    { label: 'Disabled', value: 0 },
                    { label: 'Enabled (block mode)', value: 1 },
                    { label: 'Enabled (audit mode)', value: 2 }
                ]
            },
            {
                label: 'User Interface Lockdown',
                inputType: 'checkbox',
                value: 'AllowUserUIAccess',
                helpText:
                    'Allows or disallows user access to the Windows Defender UI. If disallowed, all Windows Defender notifications will also be suppressed.',
                parentId: 'Defender',
                myId: 'AllowUserUIAccess',
                defaultValue: 1,
                checkedValue: 1,
                options: [
                    { label: 'Not Allowed. Prevents users from accessing UI.', value: 0 },
                    { label: 'Allowed. Lets users access UI.', value: 1 }
                ]
            },
            {
                label: 'CPU Throttle on Scheduled Scan',
                inputType: 'dropdown',
                value: 'ThrottleForScheduledScanOnly',
                helpText:
                    'A CPU usage limit can be applied to scheduled scans only, or to scheduled and custom scans. The default value applies a CPU usage limit to scheduled scans only.',
                parentId: 'Defender',
                myId: 'ThrottleForScheduledScanOnly',
                defaultValue: 1,
                options: [
                    { label: 'Only to scheduled scans.', value: 0 },
                    { label: 'Scheduled custom scans.', value: 1 }
                ]
            },
            {
                label: 'File Hash Computation',
                inputType: 'checkbox',
                value: 'EnableFileHashComputation',
                helpText:
                    'Enables or disables file hash computation feature. When this feature is enabled Windows defender will compute hashes for files it scans.',
                parentId: 'Defender',
                myId: 'EnableFileHashComputation',
                defaultValue: 0,
                checkedValue: 1,
                options: [
                    { label: 'Disable', value: 0 },
                    { label: 'Enable', value: 1 }
                ]
            },
            {
                label: 'Hide Exclusions from Local Admins',
                inputType: 'checkbox',
                value: 'HideExclusionsFromLocalAdmins',
                helpText:
                    'This policy setting controls whether or not exclusions are visible to local admins. To control local users exlcusions visibility use HideExclusionsFromLocalUsers. If HideExclusionsFromLocalAdmins is set then HideExclusionsFromLocalUsers will be implicitly set.',
                parentId: 'Defender',
                myId: 'HideExclusionsFromLocalAdmins',
                defaultValue: 0,
                checkedValue: 1,
                options: [
                    { label: 'Disable', value: 0 },
                    { label: 'Enable', value: 1 }
                ]
            },
            {
                label: 'Hide Exclusions from Local Users',
                inputType: 'checkbox',
                value: 'HideExclusionsFromLocalUsers',
                helpText:
                    'This policy setting controls whether or not exclusions are visible to local users. If HideExclusionsFromLocalAdmins is set then this policy will be implicitly set.',
                parentId: 'Defender',
                myId: 'HideExclusionsFromLocalUsers',
                defaultValue: 0,
                checkedValue: 1,
                options: [
                    { label: 'Disable', value: 0 },
                    { label: 'Enable', value: 1 }
                ]
            }
        ]
    },
    {
        label: 'Device Health Monitoring',
        helpText:
            'Microsoft can gather event data and provide recommendations to improve the performance of your Windows devices. Endpoint Analytics analyses this data to recommend software, improve startup performance, and resolve typical support concerns.',
        show: ['FM', 'SA', 'MA'],
        image: deviceHealthMonitoring,
        fields: [
            {
                label: 'Device Health Monitoring',
                value: 'AllowDeviceHealthMonitoring',
                helpText: 'Allows user to monitor the connections between device and Microsoft',
                inputType: 'checkbox',
                parentId: 'DeviceHealthMonitoring',
                myId: 'AllowDeviceHealthMonitoring',
                defaultValue: 0,
                checkedValue: 1,
                options: [
                    { label: 'Enabled', value: 1 },
                    { label: 'Disabled.', value: 0 }
                ]
            }
        ]
    },
    {
        label: 'Application Guard',
        helpText:
            'Application Guard serves as a protective barrier between an untrusted session and the host system. If a user uses Application Guard to access an untrusted site, the session is separated. All operations within this session are contained, therefore the host system is unaffected.',
        show: ['FM', 'SA', 'MA'],
        image: applicationGuard,
        fields: [
            {
                label: 'Microsoft Defender Application Guard',
                inputType: 'dropdown',
                value: 'AllowWindowsDefenderApplicationGuard',
                helpText: 'Turn on Microsoft Defender Application Guard in Enterprise Mode.',
                placeholder: 'Select',
                parentId: 'WindowsDefenderApplicationGuard/Settings',
                myId: 'AllowWindowsDefenderApplicationGuard',
                defaultValue: '',
                options: [
                    {
                        label: 'Disable',
                        value: 0
                    },
                    {
                        label: 'Enable for Microsoft Edge',
                        value: 1
                    },
                    {
                        label: 'Enable for Isolated Windows environment',
                        value: 2
                    },
                    {
                        label: 'Enable for Microsoft Edge and isolated Windows environment',
                        value: 3
                    }
                ]
            },
            {
                label: 'Clipboard Behaviour',
                inputType: 'dropdown',
                value: 'ClipboardSettings',
                helpText: 'This policy setting allows you to decide how the clipboard behaves while in Application Guard.',
                placeholder: 'Select',
                parentId: 'WindowsDefenderApplicationGuard/Settings',
                myId: 'ClipboardSettings',
                defaultValue: 0,
                enableOn: 'AllowWindowsDefenderApplicationGuard',
                enableOnVal: [1, 3],
                options: [
                    {
                        label: 'Completely turns Off the clipboard functionality for the Application Guard.',
                        value: 0
                    },
                    {
                        label: 'Turns On clipboard operation from an isolated session to the host.',
                        value: 1
                    },
                    {
                        label: 'Turns On clipboard operation from the host to an isolated session.',
                        value: 2
                    },
                    {
                        label: 'Turns On clipboard operation in both the directions.',
                        value: 3
                    }
                ]
            },
            {
                label: 'Clipboard Filetype',
                inputType: 'dropdown',
                value: 'ClipboardFileType',
                helpText:
                    'Determines the type of content that can be copied from the host to Application Guard environment and vice versa.',
                placeholder: 'Select',
                parentId: 'WindowsDefenderApplicationGuard/Settings',
                myId: 'ClipboardFileType',
                defaultValue: '',
                enableOn: 'AllowWindowsDefenderApplicationGuard',
                enableOnVal: [1, 3],
                options: [
                    {
                        label: 'Allow Text copying.',
                        value: 1
                    },
                    {
                        label: 'Allow Image copying.',
                        value: 2
                    },
                    {
                        label: 'Allow Text and Image copying.',
                        value: 3
                    }
                ]
            },
            {
                label: 'Printing Settings',
                inputType: 'dropdown',
                value: 'PrintingSettings',
                helpText: 'This policy setting allows you to decide how the print functionality behaves while in Application Guard.',
                placeholder: 'Select',
                parentId: 'WindowsDefenderApplicationGuard/Settings',
                myId: 'PrintingSettings',
                defaultValue: 0,
                enableOn: 'AllowWindowsDefenderApplicationGuard',
                enableOnVal: [1, 3],
                options: [
                    {
                        label: 'Disables all print functionality.',
                        value: 0
                    },
                    {
                        label: 'Enables only XPS Printing.',
                        value: 1
                    },
                    {
                        label: 'Enables only PDF Printing.',
                        value: 2
                    },
                    {
                        label: 'Enables both PDF and XPS Printing.',
                        value: 3
                    },
                    {
                        label: 'Enables only Local Printing.',
                        value: 4
                    },
                    {
                        label: 'Enables both Local and XPS Printing.',
                        value: 5
                    },
                    {
                        label: 'Enables both Local and PDF Printing.',
                        value: 6
                    },
                    {
                        label: 'Enables local, PDF, and XPS Printing.',
                        value: 7
                    },
                    {
                        label: 'Enables only Network printing.',
                        value: 8
                    },
                    {
                        label: 'Enables both Network and XPS Printing.',
                        value: 9
                    },
                    {
                        label: 'Enables both Network and PDF Printing.',
                        value: 10
                    },
                    {
                        label: 'Enables network, PDF, and XPS Printing.',
                        value: 11
                    },
                    {
                        label: 'Enables both Network and local Printing.',
                        value: 12
                    },
                    {
                        label: 'Enables Network, Local, and XPS Printing.',
                        value: 13
                    },
                    {
                        label: 'Enables Network, Local, and PDF Printing.',
                        value: 14
                    },
                    {
                        label: 'Enables all Printing.',
                        value: 15
                    }
                ]
            },
            {
                label: 'Collect Logs',
                value: 'AuditApplicationGuard',
                helpText: 'DeviceHealthMonitoring is an opt-in health monitoring connection between the device and Microsoft.',
                inputType: 'checkbox',
                parentId: 'WindowsDefenderApplicationGuard/Audit',
                myId: 'AuditApplicationGuard',
                defaultValue: 0,
                checkedValue: 1,
                enableOn: 'AllowWindowsDefenderApplicationGuard',
                enableOnVal: [1, 3],
                options: [
                    { label: "Audit event logs aren't collected for Application Guard.", value: 0 },
                    {
                        label: 'Application Guard inherits its auditing policies from system and starts to audit security events for Application Guard container.',
                        value: 1
                    }
                ]
            },
            {
                label: 'Graphics Accelertation',
                value: 'AllowVirtualGPU',
                helpText:
                    'This policy setting allows you to determine whether Application Guard can use the virtual Graphics Processing Unit (GPU) to process graphics. If you enable this setting, Microsoft Defender Application Guard uses Hyper-V to access supported, high-security rendering graphics hardware (GPUs). These GPUs improve rendering performance and battery life while using Microsoft Defender Application Guard, particularly for video playback and other graphics-intensive use cases. If you enable this setting without connecting any high-security rendering graphics hardware, Microsoft Defender Application Guard will automatically revert to software-based (CPU) rendering.',
                inputType: 'checkbox',
                parentId: 'WindowsDefenderApplicationGuard/Settings',
                myId: 'AllowVirtualGPU',
                defaultValue: 0,
                checkedValue: 1,
                enableOn: 'AllowWindowsDefenderApplicationGuard',
                enableOnVal: [1, 3],
                options: [
                    {
                        label: 'Cannot access the vGPU and uses the CPU to support rendering graphics. When the policy is not configured, it is the same as disabled (0).',
                        value: 0
                    },
                    {
                        label: 'Turns on the functionality to access the vGPU offloading graphics rendering from the CPU. This can create a faster experience when working with graphics intense websites or watching video within the container.',
                        value: 1
                    }
                ]
            },
            {
                label: 'Download Files to Host File System',
                value: 'SaveFilesToHost',
                helpText:
                    'This policy setting allows you to determine whether users can elect to download files from Edge in the container and persist files them from container to the host operating system.',
                inputType: 'checkbox',
                parentId: 'WindowsDefenderApplicationGuard/Settings',
                myId: 'SaveFilesToHost',
                defaultValue: 0,
                checkedValue: 1,
                enableOn: 'AllowWindowsDefenderApplicationGuard',
                enableOnVal: [1, 3],
                options: [
                    {
                        label: 'Cannot access the vGPU and uses the CPU to support rendering graphics. When the policy is not configured, it is the same as disabled (0).',
                        value: 0
                    },
                    {
                        label: 'Turns on the functionality to access the vGPU offloading graphics rendering from the CPU. This can create a faster experience when working with graphics intense websites or watching video within the container.',
                        value: 1
                    }
                ]
            },
            {
                label: 'Retain User Generated Browser Data',
                value: 'AllowPersistence',
                helpText:
                    'This policy setting allows you to decide whether data should persist across different sessions in Application Guard.',
                placeholder: 'Select',
                inputType: 'dropdown',
                parentId: 'WindowsDefenderApplicationGuard/Settings',
                myId: 'AllowPersistence',
                defaultValue: '',
                checkedValue: 1,
                enableOn: 'AllowWindowsDefenderApplicationGuard',
                enableOnVal: [1, 3],
                options: [
                    {
                        label: 'Application Guard discards user-downloaded files and other items (such as, cookies, Favorites, and so on) during machine restart or user log-off.',
                        value: 0
                    },
                    {
                        label: 'Application Guard saves user-downloaded files and other items (such as, cookies, Favorites, and so on) for use in future Application Guard sessions.',
                        value: 1
                    }
                ]
            },
            {
                label: 'Certificate Thumbprint',
                value: 'CertificateThumbprints',
                helpText:
                    "This policy setting allows certain device level Root Certificates to be shared with the Microsoft Defender Application Guard container. If you enable this setting, certificates with a thumbprint matching the ones specified will be transferred into the container. Multiple certificates can be specified by using a comma to separate the thumbprints for each certificate you want to transfer. Here's an example: b4e72779a8a362c860c36a6461f31e3aa7e58c14,1b1d49f06d2a697a544a1059bd59a7b058cda924. If you disable or don’t configure this setting, certificates are not shared with the Microsoft Defender Application Guard container.",
                inputType: 'policyInput',
                parentId: 'WindowsDefenderApplicationGuard/Settings',
                myId: 'CertificateThumbprints',
                defaultValue: '',
                placeholder: 'Specify the certificate thumbprint'
            }
        ]
    },
    {
        label: 'Device Guard',
        helpText:
            'Device Guard is a security feature for Windows devices that protects users from credential theft and exploits during system startup, as well as disabling the operating system through registry key changes that could compromise the machine.',
        show: ['FM', 'SA', 'MA'],
        image: deviceGuard,
        fields: [
            {
                label: 'Virtualization Based Security',
                value: 'EnableVirtualizationBasedSecurity',
                helpText: 'Specifies whether Virtualization Based Security(VBS) is enabled',
                inputType: 'checkbox',
                parentId: 'DeviceGuard',
                myId: 'EnableVirtualizationBasedSecurity',
                defaultValue: 0,
                checkedValue: 1,
                options: [
                    { label: 'Disable', value: 0 },
                    { label: 'Enable', value: 1 }
                ]
            },
            {
                label: 'System Guard',
                value: 'ConfigureSystemGuardLaunch',
                helpText: 'Specifies Secure Launch Configuration.',
                inputType: 'dropdown',
                parentId: 'DeviceGuard',
                myId: 'ConfigureSystemGuardLaunch',
                defaultValue: 0,
                options: [
                    { label: 'Unmanaged Configurable by Administrative user', value: 0 },
                    { label: 'Unmanaged Enables Secure Launch if supported by hardware', value: 1 },
                    { label: 'Unmanaged Disables Secure Launch', value: 2 }
                ]
            },
            {
                label: 'Credential Guard',
                value: 'LsaCfgFlags',
                helpText: 'Specifies Credential Guard Configuration.',
                inputType: 'dropdown',
                parentId: 'DeviceGuard',
                myId: 'LsaCfgFlags',
                defaultValue: 0,
                options: [
                    { label: '(Disabled) Turns off Credential Guard remotely if configured previously without UEFI Lock.', value: 0 },
                    { label: '(Enabled with UEFI lock) Turns on Credential Guard with UEFI Lock.', value: 1 },
                    { label: '(Enabled without lock) Turns on Credential Guard without UEFI Lock.', value: 2 }
                ]
            }
        ]
    },
    {
        label: 'Wi-Fi',
        helpText: 'Use this section to configure Wi-Fi Settings',
        show: ['FM', 'SA', 'MA'],
        image: wifi,
        fields: [
            {
                label: 'Wi-Fi Configuration',
                value: 'WlanXml',
                inputType: 'array',
                defaultValue: [],
                myId: 'WlanXml',
                fields: [
                    {
                        label: 'Wi-Fi Name/SSID',
                        value: 'ssidName',
                        helpText: 'Service Set Identifier(SSID) of the wireless network to connect to',
                        inputType: 'policyInput',
                        defaultValue: '',
                        placeholder: 'Enter',
                        maxLength: 20,
                        mandatory: true,
                        showInCard: true
                    },
                    {
                        label: 'Auto Connect',
                        value: 'autoConnect',
                        helpText: 'Specifies whether automatic connection to this wireless network is enabled',
                        inputType: 'checkbox',
                        defaultValue: 'auto',
                        checkedValue: 'auto',
                        options: [
                            { label: 'Enabled', value: 'auto' },
                            { label: 'Disabled', value: 'manual' }
                        ]
                    },
                    {
                        label: 'Hidden Network',
                        value: 'hiddenNetwork',
                        helpText: 'Enable if the target network is not open or actively broadcasting',
                        inputType: 'checkbox',
                        defaultValue: false,
                        checkedValue: true,
                        options: [
                            { label: 'Enabled', value: true },
                            { label: 'Disabled', value: false }
                        ]
                    },
                    {
                        label: 'Security Type',
                        value: 'securityType',
                        inputType: 'dropdown',
                        placeholder: 'Select',
                        helpText: 'Wireless network encryption to use when connecting',
                        defaultValue: 'NONE',
                        options: [
                            { label: 'None', value: 'NONE' },
                            { label: 'WPA2 PSK', value: 'WPA2PSK' }
                        ],
                        showInCard: true
                    },
                    {
                        label: 'Password',
                        value: 'password',
                        helpText: 'Password for the wireless network',
                        enableOn: 'securityType',
                        enableOnVal: ['WPA2PSK'],
                        inputType: 'policyInput',
                        type: 'password',
                        defaultValue: '',
                        placeholder: 'Enter',
                        maxLength: 20,
                        mandatory: true
                    },
                    {
                        label: 'Proxy Settings',
                        value: 'proxySettings',
                        inputType: 'dropdown',
                        placeholder: 'Select',
                        helpText:
                            'If you choose the manual proxy, you need to provide proxy server address, including its port and optionally a user name and password into the proxy server.',
                        defaultValue: 'automatic',
                        options: [
                            { label: 'Automatic', value: 'automatic' },
                            { label: 'Manual', value: 'manual' }
                        ],
                        showInCard: true
                    },
                    {
                        label: 'Proxy Server and Port',
                        value: 'proxyurl',
                        helpText: 'Proxy Server and Port number',
                        enableOn: 'proxySettings',
                        enableOnVal: ['manual'],
                        inputType: 'policyInput',
                        defaultValue: '',
                        placeholder: 'Enter',
                        maxLength: 20,
                        mandatory: true
                    }
                ]
            }
        ]
    },
    {
        label: 'Email',
        helpText: 'Use this section to configure the Incoming and Outgoing mail Server Settings',
        show: ['FM', 'SA', 'MA'],
        image: email,
        fields: [
            {
                label: 'Account Type',
                value: 'ACCOUNTTYPE',
                helpText: 'Specifies the type of account.',
                inputType: 'dropdown',
                parentId: 'EMAIL2',
                myId: 'ACCOUNTTYPE',
                defaultValue: 'Email',
                mandatory: true,
                options: [
                    { label: 'Email', value: 'Email' },
                    { label: 'Visual Voicemail (VVM)', value: 'VVM' }
                ]
            },
            {
                label: 'User Name',
                value: 'AUTHNAME',
                helpText:
                    "Specifies the name used to authorize the user to a specific email account (also known as the user's login name).",
                inputType: 'policyInput',
                parentId: 'EMAIL2',
                myId: 'AUTHNAME',
                defaultValue: '',
                placeholder: '%username%',
                mandatory: true,
                maxLength: 100
            },
            {
                label: 'Password',
                value: 'AUTHSECRET',
                helpText: "Specifies the user's password. The same password is used for SMTP authentication.",
                inputType: 'policyInput',
                parentId: 'EMAIL2',
                myId: 'AUTHSECRET',
                defaultValue: '',
                placeholder: 'Enter',
                type: 'password'
            },
            {
                label: 'Domain Name',
                value: 'DOMAIN',
                helpText: 'Specifies the incoming server credentials domain.',
                inputType: 'policyInput',
                parentId: 'EMAIL2',
                myId: 'DOMAIN',
                defaultValue: '',
                placeholder: '%domainname%',
                maxLength: 24
            },
            {
                label: 'Download e-mails received in the last',
                value: 'DWNDAY',
                helpText: 'Specifies how many days worth of email should be downloaded from the server.',
                inputType: 'dropdown',
                parentId: 'EMAIL2',
                myId: 'DWNDAY',
                defaultValue: '7',
                options: [
                    { label: '7 Days', value: '7' },
                    { label: '14 days', value: '14' },
                    { label: '30 days', value: '30' }
                ]
            },
            {
                label: 'Frequency to Sync Mails',
                value: 'LINGER',
                helpText: 'Specifies the length of time between email send/receive updates.',
                inputType: 'dropdown',
                parentId: 'EMAIL2',
                myId: 'LINGER',
                defaultValue: '15',
                options: [
                    { label: 'Manual', value: '7' },
                    { label: '15 Minute(s)', value: '15' },
                    { label: '30 Minute(s)', value: '30' },
                    { label: '1 Hour(s)', value: '60' },
                    { label: '2 Hour(s)', value: '120' }
                ]
            },
            {
                label: 'Display Name',
                value: 'NAME',
                helpText: 'Specifies the name of the sender displayed on a sent email.',
                inputType: 'policyInput',
                parentId: 'EMAIL2',
                myId: 'NAME',
                defaultValue: '',
                placeholder: 'Enter',
                maxLength: 24
            },
            {
                label: 'E-mail address to receive e-mail',
                value: 'REPLYADDR',
                helpText: 'Specifies the reply email address of the user. Sending email will fail without it.',
                inputType: 'policyInput',
                parentId: 'EMAIL2',
                myId: 'REPLYADDR',
                defaultValue: '',
                placeholder: '%email%'
            },
            {
                label: 'Account Display Name',
                value: 'SERVICENAME',
                helpText: 'Specifies the name of the email service to create or edit.',
                inputType: 'policyInput',
                parentId: 'EMAIL2',
                myId: 'SERVICENAME',
                defaultValue: '',
                placeholder: 'Enter',
                mandatory: true,
                maxLength: 32
            },
            {
                label: 'Service Type',
                value: 'SERVICETYPE',
                helpText: 'Specifies the type of email service to create or edit',
                inputType: 'dropdown',
                parentId: 'EMAIL2',
                myId: 'SERVICETYPE',
                defaultValue: 'POP3',
                mandatory: true,
                options: [
                    { label: 'IMAP4', value: 'IMAP4' },
                    { label: 'POP3', value: 'POP3' }
                ]
            },
            {
                label: 'SMTP Alternative',
                value: 'SMTPALTENABLED',
                helpText: "Specifies if the user's alternate SMTP account is enabled",
                inputType: 'checkbox',
                parentId: 'EMAIL2',
                myId: 'SMTPALTENABLED',
                defaultValue: '0',
                checkedValue: '1',
                options: [
                    { label: 'Enable', value: '1' },
                    { label: 'Disable', value: '0' }
                ]
            },
            {
                label: 'SMTP Alternative Authentication Name',
                value: 'SMTPALTAUTHNAME',
                helpText: "Specifies the display name associated with the user's alternative SMTP email account.",
                inputType: 'policyInput',
                parentId: 'EMAIL2',
                myId: 'SMTPALTAUTHNAME',
                defaultValue: '',
                enableOn: 'SMTPALTENABLED',
                enableOnVal: '1',
                placeholder: 'Enter',
                maxLength: 24
            },
            {
                label: 'SMTP Alternative Domain Name',
                value: 'SMTPALTDOMAIN',
                helpText: "Specifies the domain name for the user's alternative SMTP account.",
                inputType: 'policyInput',
                parentId: 'EMAIL2',
                myId: 'SMTPALTDOMAIN',
                defaultValue: '',
                enableOn: 'SMTPALTENABLED',
                enableOnVal: '1',
                placeholder: 'Enter',
                maxLength: 24
            },
            {
                label: 'SMTP Alternative Password',
                value: 'SMTPALTPASSWORD',
                helpText: "Specifies the password for the user's alternate SMTP account.",
                inputType: 'policyInput',
                parentId: 'EMAIL2',
                myId: 'SMTPALTPASSWORD',
                defaultValue: '',
                enableOn: 'SMTPALTENABLED',
                enableOnVal: '1',
                placeholder: 'Enter',
                type: 'password',
                maxLength: 24
            },
            {
                label: 'Incoming Mail'
            },
            {
                label: 'Server Name and Port',
                value: 'INSERVER',
                helpText: 'Specifies how many days worth of email should be downloaded from the server. server name:port number',
                inputType: 'policyInput',
                parentId: 'EMAIL2',
                myId: 'INSERVER',
                defaultValue: '',
                mandatory: true,
                placeholder: 'Enter'
            },
            {
                label: 'Use SSL for mail communication',
                value: 'TAGPROPS/8128000B',
                helpText: "Specifies if the user's alternate SMTP account is enabled",
                inputType: 'checkbox',
                parentId: 'EMAIL2',
                myId: 'TAGPROPS/8128000B',
                tooltipId: '8128000B',
                defaultValue: '0',
                checkedValue: '1',
                options: [
                    { label: 'Yes', value: '1' },
                    { label: 'No', value: '0' }
                ]
            },
            {
                label: 'Outgoing Mail'
            },
            {
                label: 'Server Name and Port',
                value: 'OUTSERVER',
                helpText:
                    "Specifies the name of the messaging service's outgoing email server. The value format is server name:port number",
                inputType: 'policyInput',
                parentId: 'EMAIL2',
                myId: 'OUTSERVER',
                defaultValue: '',
                mandatory: true,
                placeholder: 'Enter'
            },
            {
                label: 'Use SSL for mail communication',
                value: 'TAGPROPS/812C000B',
                helpText: "Specifies if the user's alternate SMTP account is enabled",
                inputType: 'checkbox',
                parentId: 'EMAIL2',
                myId: 'TAGPROPS/812C000B',
                tooltipId: '812C000B',
                defaultValue: '0',
                checkedValue: '1',
                options: [
                    { label: 'Yes', value: '1' },
                    { label: 'No', value: '0' }
                ]
            },
            {
                label: 'Authentication to send mails',
                value: 'AUTHREQUIRED',
                helpText: 'Specifies whether the outgoing server requires authentication',
                inputType: 'checkbox',
                parentId: 'EMAIL2',
                myId: 'AUTHREQUIRED',
                defaultValue: '0',
                checkedValue: '1',
                options: [
                    { label: 'Yes', value: '1' },
                    { label: 'No', value: '0' }
                ]
            }
        ]
    },
    {
        label: 'VPN',
        helpText: 'Use this section to configure the VPN settings',
        show: ['FM', 'SA', 'MA'],
        image: vpn,
        fields: [
            {
                label: 'VPN Type',
                value: 'vpnType',
                helpText: 'Specifies the type of VPN to be used.',
                inputType: 'dropdown',
                parentId: 'VPNv2',
                myId: 'vpnType',
                defaultValue: 'L2TP',
                options: [
                    { label: 'L2TP', value: 'L2TP' },
                    { label: 'PPTP', value: 'PPTP' },
                    { label: 'SONICWALL', value: 'SONICWALL' }
                ],
                mandatory: true
            },
            {
                label: 'Connection Name',
                value: 'connectionName',
                helpText: 'Specifies the name of the VPN connection.',
                inputType: 'policyInput',
                parentId: 'VPNv2',
                myId: 'connectionName',
                defaultValue: '',
                placeholder: 'Enter',
                shouldTrim: true
            },
            {
                label: 'VPN Server(IP address)',
                value: 'ServerUrl',
                helpText: 'Specifies the VPN server to be used.',
                inputType: 'policyInput',
                parentId: 'VPNv2',
                myId: 'ServerUrl',
                defaultValue: '',
                enableOn: 'vpnType',
                enableOnVal: ['SONICWALL'],
                placeholder: 'Enter',
                mandatory: true
            },
            {
                label: 'VPN Server(IP address)',
                value: 'Servers',
                helpText: 'Specifies the VPN server to be used.',
                inputType: 'policyInput',
                parentId: 'VPNv2',
                myId: 'Servers',
                defaultValue: '',
                isCustomConfig: true,
                subParentId: 'NativeProfile',
                enableOn: 'vpnType',
                enableOnVal: ['L2TP', 'PPTP'],
                placeholder: 'Enter',
                mandatory: true
            },
            {
                label: 'Remember VPN Credentials',
                value: 'RememberCredentials',
                helpText:
                    'Specifies whether the user should be prompted to enter their credentials again when the VPN connection is re-established.',
                inputType: 'checkbox',
                parentId: 'VPNv2',
                myId: 'RememberCredentials',
                defaultValue: true,
                checkedValue: true,
                options: [
                    { label: 'Yes', value: true },
                    { label: 'No', value: false }
                ]
            },
            {
                label: 'Allow packet capture',
                value: 'PacketCapture',
                helpText: 'Specifies whether the user should be allowed to capture packets.',
                inputType: 'checkbox',
                parentId: 'VPNv2',
                enableOn: 'vpnType',
                enableOnVal: ['SONICWALL'],
                isCustomConfig: true,
                subParentId: 'customConfig',
                myId: 'PacketCapture',
                defaultValue: 'false',
                checkedValue: 'true',
                options: [
                    { label: 'Yes', value: 'true' },
                    { label: 'No', value: 'false' }
                ]
            },
            {
                label: 'Use Windows Native Interface for Authentication',
                value: 'WindowsAuthUI',
                helpText: 'Specifies whether the user should be allowed to use the Windows Native Interface for Authentication.',
                inputType: 'checkbox',
                parentId: 'VPNv2',
                enableOn: 'vpnType',
                enableOnVal: ['SONICWALL'],
                isCustomConfig: true,
                subParentId: 'customConfig',
                myId: 'WindowsAuthUI',
                defaultValue: 'true',
                checkedValue: 'true',
                options: [
                    { label: 'Yes', value: 'true' },
                    { label: 'No', value: 'false' }
                ]
            },
            {
                label: 'User authentication',
                value: 'Authentication/UserMethod',
                helpText: 'Specifies the type of User Authentication.',
                inputType: 'dropdown',
                parentId: 'VPNv2',
                enableOn: 'vpnType',
                enableOnVal: ['L2TP', 'PPTP'],
                isCustomConfig: true,
                subParentId: 'NativeProfile',
                myId: 'Authentication/UserMethod',
                tooltipId: 'UserMethod',
                defaultValue: 'MSCHAPV2',
                options: [{ label: 'Password ', value: 'MSCHAPV2' }]
            },
            {
                label: 'Native Protocol Type',
                value: 'NativeProtocolType',
                helpText: 'Specifies the type Tunneling Protocol used.',
                inputType: 'dropdown',
                parentId: 'VPNv2',
                enableOn: 'vpnType',
                enableOnVal: ['L2TP', 'PPTP'],
                isCustomConfig: true,
                subParentId: 'NativeProfile',
                myId: 'NativeProtocolType',
                defaultValue: 'L2TP',
                options: [
                    { label: 'L2TP', value: 'L2TP' },
                    { label: 'PPTP', value: 'PPTP' }
                ]
            },
            {
                label: 'Apps to use this VPN',
                value: 'AppTriggerList',
                helpText: 'Specifies the apps to use this VPN.',
                inputType: 'dropdown',
                isMulti: true,
                parentId: 'VPNv2',
                myId: 'AppTriggerList',
                defaultValue: [],
                placeholder: 'Select',
                options: [
                    { label: 'Alarms and clock', value: 'Microsoft.WindowsAlarms_8wekyb3d8bbwe' },
                    { label: 'Calculator', value: 'Microsoft.WindowsCalculator_8wekyb3d8bbwe' },
                    { label: 'Camera', value: 'Microsoft.WindowsCamera_8wekyb3d8bbwe' },
                    { label: 'Contact Support', value: 'Windows.ContactSupport_cw5n1h2txyewy' },
                    { label: 'Cortana', value: 'Microsoft.Windows.Cortana_cw5n1h2txyewy' },
                    { label: 'Excel', value: 'Microsoft.Office.Excel_8wekyb3d8bbwe' },
                    { label: 'Facebook', value: 'Facebook.Facebook_8xx8rvfyw5nnt' },
                    { label: 'File Explorer', value: 'c5e2524a-ea46-4f67-841f-6a9465d9d515_cw5n1h2txyewy' },
                    { label: 'Get Started', value: 'Microsoft.Getstarted_8wekyb3d8bbwe' },
                    { label: 'Groove Music', value: 'Microsoft.ZuneMusic_8wekyb3d8bbwe' },
                    { label: 'Maps', value: 'Microsoft.WindowsMaps_8wekyb3d8bbwe' },
                    { label: 'Messaging', value: 'Microsoft.Messaging_8wekyb3d8bbwe' },
                    { label: 'Money', value: 'Microsoft.BingFinance_8wekyb3d8bbwe' },
                    { label: 'Movies and TV', value: 'Microsoft.ZuneVideo_8wekyb3d8bbwe' },
                    { label: 'Microsoft Edge', value: 'Microsoft.MicrosoftEdge_8wekyb3d8bbwe' },
                    { label: 'Outlook Calendar', value: 'Microsoft.WindowsCommunicationsApps_8wekyb3d8bbwe' },
                    { label: 'Outlook Mail', value: 'Microsoft.WindowsCommunicationsApps_8wekyb3d8bbwe' },
                    { label: 'Word', value: 'Microsoft.Office.Word_8wekyb3d8bbwe' },
                    { label: 'Xbox', value: 'Microsoft.XboxApp_8wekyb3d8bbwe' }
                ]
            },
            {
                label: 'Proxy Settings',
                value: 'proxyType',
                helpText: 'Specifies the proxy settings to be used.',
                inputType: 'dropdown',
                parentId: 'VPNv2',
                myId: 'proxyType',
                defaultValue: '',
                options: [
                    { label: 'None', value: '' },
                    { label: 'Automatic', value: 'automatic' },
                    { label: 'Manual', value: 'manual' }
                ]
            },
            {
                label: 'Proxy Server',
                value: 'proxyValue',
                helpText: 'Specifies the proxy server to be used.',
                inputType: 'policyInput',
                parentId: 'VPNv2',
                myId: 'proxyValue',
                defaultValue: '',
                enableOn: 'proxyType',
                enableOnVal: ['automatic', 'manual'],
                placeholder: 'Enter',
                mandatory: true
            }
        ]
    },
    {
        label: 'Branding',
        helpText: 'Branding involves customizing the appearance to reflect a company’s brand identity.',
        note: 'Requires license to take effect.',
        show: ['FM', 'SA', 'MA'],
        image: branding,
        fields: [
            {
                label: 'Home Screen Wallpaper',
                value: 'DesktopImageUrl',
                helpText: 'Sets the wallpaper for the Home screen.',
                inputType: 'dropdown',
                parentId: 'Personalization',
                myId: 'DesktopImageUrl',
                wallpaper: true,
                mandatory: true,
                defaultValue: ''
            },
            {
                label: 'Lock Screen Wallpaper',
                value: 'LockScreenImageUrl',
                helpText: 'Sets the wallpaper for the Lock screen.',
                inputType: 'dropdown',
                parentId: 'Personalization',
                myId: 'LockScreenImageUrl',
                wallpaper: true,
                mandatory: true,
                defaultValue: ''
            }
        ]
    },
    {
        label: 'Applications ',
        helpText: 'Select the apps to be installed',
        show: ['FM', 'SA', 'MA'],
        image: applications,
        fields: [
            {
                label: 'Applications',
                inputType: 'applications'
            }
        ]
    }
];
