import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    Container,
    Input,
    Label,
    Pagination,
    Row,
    Table,
    UncontrolledTooltip
} from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import Loader from '../../../Components/Common/Loader';
import NoRecordsFound from '../../../Components/Common/NoRecordsFound';
import {
    DownloadBunnyFile,
    TenantConfig,
    convertUTCtoIST,
    diffTwoDatesHrsMintsSeconds,
    getDateOnly,
    getFormTypeAndRecordId
} from '../../../Components/Common/Util';
import {
    aeCardDetails,
    aeEnrollmentProcess,
    aeExpiration,
    aeSecurityOptions,
    AndroidPolicyQR,
    qrSecurityOptions
} from '../../../Components/constants/constants';
import toastMessages from '../../../common/messages/toastMessages';
import { useEnv } from '../../../envContext';
import { APIClient } from '../../../helpers/api_helper';
import * as url from '../../../helpers/url_helper';
import OffcanvasModal from '../../../Components/Common/OffcanvasModal';
import EllipsisToolTip from '../../../Components/Common/Tooltip/Tooltip';
import { useFormik } from 'formik';

const EnrollmentToken = () => {
    document.title = 'Enrollment Token';
    const urlconf = useEnv();
    const api = new APIClient();
    let history = useHistory();
    let tenant = TenantConfig();
    const formTypeAndId = getFormTypeAndRecordId(window.location.hash, true);
    let policyName = localStorage.getItem('policyNameForTokens');
    let recordID = formTypeAndId['recordID'];
    let polType = formTypeAndId['queryType'];
    const [loading, setLoading] = useState(false);
    const [tokenDetails, setTokenDetails] = useState([]);
    const [qrCodeString, setQRCodeString] = useState('');
    const [mode, setMode] = useState('add');
    const [selectedRow, setSelectedRow] = useState({});
    const [showEnrollModal, setShowEnrollModal] = useState(false);
    const [showShareModal, setShowShareModal] = useState(false);
    const [offCanvasLoader, setOffCanvasLoader] = useState(false);
    const [activePage, setActivePage] = useState(1);
    const [pageSize, setPageSize] = useState(30);
    const [totalRecords, setTotalRecords] = useState(0);
    const [searchParams, setSearchParams] = useState({ page: 1, size: 30 });

    useEffect(() => {
        if (recordID) getTokens('signintokens', { ...searchParams });
    }, []);

    const getTokens = (tab, params) => {
        setLoading(true);
        api.get(url.POLICIES + '/' + recordID + `/${tab}`, params)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status?.toLowerCase() === 'success') {
                    resp.data.forEach((ele) => {
                        ele.createdDate = getDateOnly(ele?.createdTime);
                        ele.expiryDate = getDateOnly(ele?.expirationTime);
                        ele.security = aeSecurityOptions?.find((option) =>
                            option?.val === !ele.signinToken ? 'withoutLogin' : ele?.config?.pin ? 'withStatic' : 'withAuthentic'
                        )?.label;
                        const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
                        const firstDate = new Date(ele.expirationTime);
                        const secondDate = new Date();
                        const diffDays = Math.round((firstDate - secondDate) / oneDay);
                        ele.daysLeft = diffDays > 0 ? `${diffDays} ${diffDays > 1 ? 'Days ' : 'Day '}Left` : 'Expired';
                        ele.badgeClass = diffDays <= 0 ? 'danger' : diffDays <= 15 ? 'warning' : 'success';
                        ele.showQr = diffDays > 0 ? true : false;
                    });
                    setTokenDetails(resp.data);
                    setTotalRecords(resp?.totalRecords);
                    setLoading(false);
                }
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    const handleAddNew = () => {
        setMode('add');
        setShowEnrollModal(true);
    };

    const copyToClipboard = (e, index) => {
        var copyText = document.getElementById(`content${index}`)?.innerHTML;
        navigator.clipboard.writeText(copyText).then(() => {
            toast.success(toastMessages.tokenCopied);
        });
    };

    const Tooltip = (id, label) => {
        return (
            <UncontrolledTooltip placement="bottom" target={id}>
                {label}
            </UncontrolledTooltip>
        );
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            securityOptions: !selectedRow.signinToken ? 'withoutLogin' : selectedRow?.config?.pin ? 'withStatic' : 'withAuthentic',
            tokenExpiration: selectedRow.config?.noOfDays
                ? aeExpiration?.find((ele) => ele?.value === selectedRow.config?.noOfDays)
                : undefined,
            educationScreens: selectedRow?.config?.educationScreens ? selectedRow?.config?.educationScreens : false,
            systemApps: selectedRow?.config?.systemApps ? selectedRow?.config.systemApps : false,
            allowOffline: selectedRow?.config?.allowOffline ? selectedRow?.config.allowOffline : false,
            screenOn: selectedRow?.config?.screenOn ? selectedRow?.config.screenOn : false,
            wifiId: selectedRow?.config?.wifiId ? selectedRow?.config?.wifiId : undefined,
            securityType: selectedRow?.config?.securityType
                ? qrSecurityOptions?.find((ele) => ele.value === selectedRow?.config?.securityType)
                : undefined,
            password: selectedRow?.config?.password ? selectedRow?.config?.password : undefined,
            pin: selectedRow?.config?.pin ? selectedRow?.config?.pin : undefined
        },
        onSubmit: (values) => {
            let tokenBody = {
                id: recordID,
                multipleEnrollments: true,
                signIn: values.securityOptions === 'withAuthentic' || values?.securityOptions === 'withStatic' ? true : false,

                token: selectedRow?.signinToken ? selectedRow?.signinToken : selectedRow?.enrollmentToken,
                config: {
                    systemApps: !!values?.systemApps,
                    educationScreens: !!values?.educationScreens,
                    allowOffline: !!values?.allowOffline,
                    screenOn: !!values?.screenOn,
                    pin: values?.pin ? values?.pin?.toString() : undefined,
                    securityType: values?.wifiId ? values?.securityType?.value : undefined,
                    wifiId: values?.wifiId ? values?.wifiId : undefined,
                    password: values?.password ? values?.password : undefined,
                    noOfDays: values?.tokenExpiration?.value ? values?.tokenExpiration?.value : values?.tokenExpiration
                }
            };
            createAndUpdate(tokenBody);
        }
    });

    const createAndUpdate = (dataObj) => {
        let prepareUrl;
        if (mode === 'add') prepareUrl = api.create(url.POLICIES + '/signurl', dataObj);
        else if (mode === 'view') prepareUrl = api.create(url.POLICIES + '/token/qrcode', dataObj);

        prepareUrl
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status?.toLowerCase() === 'success') {
                    /*
                     * toast.success(
                     *     !tenant.ENABLE_SIGN_IN
                     *         ? toastMessages.qrGenerated
                     *         : selectedRow.token
                     *         ? toastMessages.qrGeneratedWithoutSignIn
                     *         : toastMessages.qrGeneratedWithSignIn
                     * );
                     */
                    if (mode === 'add') {
                        getTokens('signintokens', { ...searchParams });
                        toast.success(toastMessages.AccessCodeGenerate);
                        setShowEnrollModal(false);
                    } else if (mode === 'view') {
                        setQRCodeString(resp.data);
                        toast.success(toastMessages.qrGenerated);
                    }

                    setLoading(false);
                    setOffCanvasLoader(false);
                }
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    const handleKeyDown = (e) => {
        if (e.keyCode === 13) e.preventDefault();
    };

    const radioButton = (field) => {
        return (
            <Row className={'mt-2'}>
                {field?.label && (
                    <Col xs={12} sm={12} md={12} lg={10} xl={10} className={field?.label ? 'mb-2' : ''}>
                        <Label className="fw-medium fs-14">{field?.label}</Label>
                        <div>
                            {field.helpText && (
                                <div className={`text-muted fs-12 word-wrap-break${field.noMargin ? '' : 'mb-2'}`}>{field.helpText}</div>
                            )}
                        </div>
                    </Col>
                )}

                <Col xs={12} sm={12} md={12} lg={12} xl={12} className={`mx-2 ${field.noMargin ? '' : 'mb-2'}`}>
                    <Row>
                        {field?.radios?.map((ele, ind) => {
                            return (
                                <Col
                                    xs={field.customCol ? field.customCol : 12}
                                    sm={field.customCol ? field.customCol : 12}
                                    md={field.customCol ? field.customCol : 12}
                                    lg={field.customCol ? field.customCol : 12}
                                    xl={field.customCol ? field.customCol : 12}
                                    key={ind}
                                    className="form-check form-check-inline mb-2"
                                >
                                    <Input
                                        className="form-check-input"
                                        type="radio"
                                        id={field.value}
                                        name={field.value}
                                        value={ele.val}
                                        onBlur={validation.handleBlur}
                                        disabled={mode === 'view'}
                                        onChange={(e) => {
                                            validation.values?.securityOptions !== 'STATIC_PIN'
                                                ? validation.setValues({ ...validation.values, pin: undefined })
                                                : validation.values?.network !== 'specific'
                                                ? validation.setValues({ ...validation.values, ipAddress: undefined })
                                                : '';
                                            validation.handleChange(e);
                                        }}
                                        checked={validation.values[field.value] === ele.val}
                                    />
                                    <Label className="mb-0 d-flex">{ele.label}</Label>
                                    {ele.toolTip && <div className="fs-13 text-muted">{ele?.toolTip}</div>}
                                </Col>
                            );
                        })}
                    </Row>
                </Col>
            </Row>
        );
    };

    const inputComponent = (field, index) => {
        return (
            <Row>
                {field?.label && (
                    <Col
                        md={field?.customCol ? 4 : 12}
                        sm={field?.customCol ? 4 : 12}
                        lg={field?.customCol ? 5 : 12}
                        xl={field?.customCol ? 5 : 12}
                        className="d-flex align-items-center justify-content-between"
                    >
                        <Label className="form-check-label fw-medium d-flex align-items-center mb-0">
                            {field.label}
                            {field.mandatory && mode !== 'view' ? (
                                <span className="red-color ps-1">*</span>
                            ) : (
                                <span className="mx-1"> :</span>
                            )}
                        </Label>
                    </Col>
                )}

                <Col
                    md={field?.customCol ? 7 : 12}
                    lg={field?.customCol ? 7 : 12}
                    xl={field?.customCol ? 7 : 12}
                    sm={field?.customCol ? 7 : 12}
                    className={` mb-3 ${field?.label ? 'mt-2' : ''}`}
                >
                    <div className={'input-group '}>
                        <div className="d-flex align-items-center w-100">
                            <Input
                                name={field.value}
                                id={field.value}
                                className={`form-control ${field.class ? field.class : 'w-100'}`}
                                placeholder={`Enter ${field.label ? field?.label : field?.placeHolder}`}
                                type={field.type}
                                disabled={mode === 'view'}
                                maxLength={field.maxLength}
                                validate={{ required: { value: true } }}
                                onChange={validation.handleChange}
                                value={validation.values?.[field.value] || ''}
                            />
                        </div>
                    </div>
                </Col>
            </Row>
        );
    };

    const selectComponent = (field) => {
        const { totalDaysNoAbs } = selectedRow?.expirationTime
            ? diffTwoDatesHrsMintsSeconds(new Date(convertUTCtoIST(selectedRow?.expirationTime, true)), new Date())
            : 0;

        return (
            <Row className={'mb-3 pb-3 mt-3'}>
                <Col
                    md={field?.customCol ? 4 : 12}
                    xl={field?.customCol ? 5 : 12}
                    lg={field.customCol ? 5 : 12}
                    className="d-flex align-items-center"
                >
                    <Label className="form-label d-flex align-items-center fw-medium mb-2 fs-14">
                        {field.label}
                        {field?.mandatory && mode === 'add' ? <span className="red-color ps-1">*</span> : <span className="mx-1"> :</span>}
                    </Label>
                </Col>
                {field.helpText && (
                    <div>
                        <Col md={field.customCol ? 6 : 12} xl={field.customCol ? 5 : 12} lg={field.customCol ? 5 : 12}>
                            <div className="text-muted  mb-2 word-wrap-break">{field.helpText}</div>
                        </Col>
                    </div>
                )}
                <Col md={field.customCol ? 7 : 10} xl={field.customCol ? 7 : 10} lg={field.customCol ? 7 : 10}>
                    {mode === 'view' || (mode === 'edit' && field.editDisable) ? (
                        <Input
                            name={field.value}
                            id={field.value}
                            className={`form-control ${field.class ? field.class : 'w-100'}`}
                            type={field.type}
                            disabled={true}
                            value={totalDaysNoAbs >= 0 ? `${totalDaysNoAbs + 1} ${totalDaysNoAbs === 0 ? 'day' : 'days'}` : 0}
                        />
                    ) : (
                        <Select
                            getOptionValue={(option) => option.value}
                            getOptionLabel={(option) => option.label}
                            isMulti={field.isMulti ? true : false}
                            isClearable={field.isMulti ? true : false}
                            id={field.label}
                            name={field.value}
                            isDisabled={mode === 'view' && field.editDisable}
                            options={field.options}
                            placeholder={`Select ${field.label}`}
                            onBlur={() => validation.handleBlur({ target: { name: field.value } })}
                            onChange={(selectedOption) => {
                                field.value === 'securityType' && validation.setValues({ ...validation.values, password: undefined });
                                validation.handleChange({ target: { name: field.value, value: selectedOption } });
                            }}
                            value={validation?.values[field.value] || ''}
                            isSearchable={true}
                            noOptionsMessage={() => 'No data found'}
                        />
                    )}
                </Col>
            </Row>
        );
    };

    const tableInput = (field) => {
        const handleAdd = () => {
            if (validation.values[field.value]?.includes(validation.values[field.val])) {
                toast.error(field?.toastmsg);
                return;
            }
            let values = [...(validation.values[field.value] || [])];
            if (field.max ? values?.length <= field.max : true) values.push(validation.values[field.val]);
            validation.setValues({ ...validation.values, [field.value]: values, [field.val]: '' });
        };
        const handleDelete = (ind) => {
            let values = [...(validation.values[field.value] || [])];
            values.splice(ind, 1);
            validation.setValues({ ...validation.values, [field.value]: values.length > 0 ? values : '', [field.val]: '' });
        };

        return (
            <React.Fragment>
                <Row>
                    <Col sm={12} xs={12} md={10} lg={10} xl={10}>
                        <Label className="mb-0 fw-medium d-flex align-items-center">
                            {field.label}
                            {field?.mandatory && <span className="red-color ps-1">*</span>}
                        </Label>
                        {field.helpText && <div className="text-muted font-size-12 mb-2 word-wrap-break ">{field.helpText}</div>}
                        <Table bordered={validation.values?.[field.value]?.length === 1}>
                            <tbody>
                                {(field.max && validation.values[field.value]?.length
                                    ? validation.values[field.value]?.length < field.max
                                    : true) && mode !== 'view' ? (
                                    <tr>
                                        <td className={`${validation.values?.[field.value]?.length === 1 ? '' : 'border-none px-0'}`}>
                                            <div className="d-flex align-items-center gap-2">
                                                <Input
                                                    name={field?.val}
                                                    id={field?.val}
                                                    className="form-control"
                                                    autoComplete="new-password"
                                                    placeholder={`Enter ${field.placeHolder ? field.placeHolder : field.label}`}
                                                    type={field.type}
                                                    validate={{ required: { value: true } }}
                                                    onChange={validation?.handleChange}
                                                    min={field.minValue}
                                                    max={field.maxValue}
                                                    maxLength={field.maxLength}
                                                    onBlur={validation?.handleBlur}
                                                    onKeyDown={(e) => handleKeyDown(e)}
                                                    value={validation?.values[field?.val] || ''}
                                                    invalid={
                                                        validation?.touched?.[field?.val] && validation?.errors?.[field?.val] ? true : false
                                                    }
                                                />
                                                <Button
                                                    color="success"
                                                    outline
                                                    size="sm"
                                                    disabled={
                                                        !validation.values?.[field?.val] ||
                                                        validation.values?.[field?.val] === '' ||
                                                        Object.keys(validation.errors)?.length > 0
                                                    }
                                                    onClick={handleAdd}
                                                >
                                                    <i className="ri-check-line fs-16" />
                                                </Button>
                                            </div>
                                        </td>
                                    </tr>
                                ) : !validation.values[field.value]?.length ? (
                                    '–'
                                ) : (
                                    ''
                                )}
                                {(validation.values?.[field.value] || [])?.map((value, ind) => (
                                    <tr key={ind}>
                                        <td>
                                            <div className="d-flex align-items-center justify-content-between">
                                                {value}
                                                {mode !== 'view' && (
                                                    <Button color="danger" outline size="sm" onClick={() => handleDelete(ind)}>
                                                        <i className="ri-delete-bin-6-line fs-16" />
                                                    </Button>
                                                )}
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </React.Fragment>
        );
    };

    const toggleButton = (field, index) => {
        return (
            <Row>
                <Col xs={5} md={5} sm={5} lg={5} xl={5} className="d-flex align-items-center">
                    <Label className="mb-0 fw-medium d-flex align-items-center w-100">
                        {field.label}
                        {field.helpText ? (
                            <>
                                <i
                                    className="ri-information-line d-flex align-items-center blue-icon ps-1 fs-15"
                                    id={`toggleButton-${index}`}
                                />
                                <UncontrolledTooltip placement="bottom" target={`toggleButton-${index}`}>
                                    {field.helpText ? field.helpText : 'Helper Text'}
                                </UncontrolledTooltip>
                            </>
                        ) : (
                            <span className="ps-1">:</span>
                        )}
                    </Label>
                </Col>
                <Col xs={6} md={6} sm={6} lg={5} xl={6} className="d-flex">
                    <div className="form-check form-switch form-switch-lg">
                        <Input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            name={field.value}
                            id={field.value}
                            // disabled={qrDetails}
                            checked={validation.values[field.value] === true}
                            onChange={validation.handleChange}
                        />
                    </div>
                </Col>
            </Row>
        );
    };

    const dynamicComponent = (field, index) => {
        switch (field?.inputType) {
            case 'radio':
                return radioButton(field);
            case 'input':
                return inputComponent(field);
            case 'tableInput':
                return tableInput(field);
            case 'select':
                return selectComponent(field);
            case 'heading':
                return <div className="fs-14 fw-semibold my-2">{field?.label}</div>;
            case 'border':
                return <div className="my-2 border-bottom"></div>;
            case 'toggle':
                return toggleButton(field);
        }
    };

    const handleShowOn = (field) => {
        let flag = true;
        flag = field.showOn
            ? flag && validation.values?.[field.showKey]
                ? validation.values?.[field.showKey]?.value !== field.showOn
                : false
            : flag;
        flag = field.showVal ? flag && validation.values[field.showKey] === field.showVal : flag;
        return flag;
    };

    const handleTabContent = (tab) => {
        return (
            <Row>
                {tokenDetails?.length > 0 ? (
                    tokenDetails.map((item, index) => {
                        return (
                            <Col xl={6} lg={6} md={12} sm={12} xs={12} key={index}>
                                <Card className="border card-height-100">
                                    <CardHeader>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="d-flex align-items-center">
                                                <div className="text-muted">Token ID :</div>
                                                <div className="fw-medium ms-2" id={`content${index}`}>
                                                    {item.enrollmentToken}
                                                </div>
                                                <i
                                                    id={`copy-${index}`}
                                                    onClick={(e) => copyToClipboard(e, index)}
                                                    className="ri-file-copy-line ms-2 fs-18 cursor-pointer link-secondary me-2"
                                                ></i>
                                                {Tooltip(`copy-${index}`, 'Copy')}

                                                <div className="d-flex align-items-center">
                                                    <span className={'lh-lg badge badge-soft-' + item.badgeClass}>{item.daysLeft}</span>
                                                </div>
                                            </div>
                                            {/* {item.daysLeft?.toLowerCase() !== 'expired' && (
                                                <div className="d-flex align-items-center"> */}
                                            {/* <>
                                                        <i
                                                            id={`edit-${index}`}
                                                            className="ri-edit-box-line fs-15 pe-2 text-secondary cursor-pointer"
                                                            onClick={() => {
                                                                setSelectedRow(item);
                                                                setShowEnrollModal(true);
                                                                setMode('edit');
                                                            }}
                                                        />
                                                        {Tooltip(`edit-${index}`, 'Edit')}
                                                    </> */}

                                            {/* <i
                                                    id={`share-${index}`}
                                                    className="ri-share-forward-fill fs-15 cursor-pointer text-primary"
                                                    onClick={() => {
                                                        setSelectedRow(item);
                                                        setShowShareModal(true);
                                                    }}
                                                />
                                                {Tooltip(`share-${index}`, 'Share')} */}
                                            {/* </div>
                                            )} */}
                                            {/* {item.showQr && (
                                                <div>
                                                    <i
                                                        id={`qrcode-${index}`}
                                                        className="ri-qr-code-line me-2 fs-18 cursor-pointer link-secondary"
                                                        onClick={() => handleQR(item)}
                                                    />
                                                    {Tooltip(`qrcode-${index}`, 'QR Code')}
                                                </div>
                                            )} */}
                                        </div>
                                    </CardHeader>
                                    <CardBody>
                                        {aeCardDetails?.map((ele, ind) => {
                                            return (
                                                <Col sm={6} key={index}>
                                                    <div className="fs-12 text-muted ">
                                                        <EllipsisToolTip options={{ effect: 'solid', place: 'bottom' }}>
                                                            {ele?.label}
                                                        </EllipsisToolTip>
                                                    </div>
                                                    <div className="d-flex justify-content-start mt-1 mb-2 fw-medium fs-13">
                                                        <EllipsisToolTip options={{ effect: 'solid', place: 'bottom' }}>
                                                            {item?.[ele?.accessor]}
                                                            {ele?.copyIcon && (
                                                                <i
                                                                    id={`copy-${ind}`}
                                                                    onClick={(e) => copyToClipboard(item?.[ele?.accessor], ele.label)}
                                                                    className="ri-file-copy-line ms-2 fs-13 cursor-pointer link-primary"
                                                                ></i>
                                                            )}
                                                        </EllipsisToolTip>
                                                    </div>
                                                </Col>
                                            );
                                        })}
                                    </CardBody>
                                    <CardFooter className="d-flex align-items-center justify-content-between">
                                        <div className="fs-12">
                                            Valid Till : <span className="fs-12 fw-medium"> {item?.expiryDate}</span>
                                        </div>
                                        {item.daysLeft?.toLowerCase() !== 'expired' && (
                                            <div
                                                className="text-decoration-underline text-secondary fs-12 fw-medium cursor-pointer"
                                                onClick={() => {
                                                    setOffCanvasLoader(true);
                                                    setShowEnrollModal(true);
                                                    setSelectedRow(item);
                                                    setMode('view');
                                                    validation.handleSubmit();
                                                }}
                                            >
                                                View Enrollment Options
                                            </div>
                                        )}
                                    </CardFooter>
                                    <div
                                        className={`progress progress-bar animated-progess rounded-bottom rounded-0 height-3 bg-${item.badgeClass}`}
                                    ></div>
                                </Card>
                            </Col>
                        );
                    })
                ) : (
                    <NoRecordsFound text="Tokens" />
                )}
            </Row>
        );
    };

    const handleDownload = (fileUrl, fileName, type) => {
        DownloadBunnyFile(fileUrl, fileName, setLoading);
    };

    const handleEnrollModalBody = () => {
        return mode !== 'view' ? (
            <>
                <div className="mb-2">
                    <div className="d-flex align-items-center ">
                        <Label className="p-0 m-0 fw-semibold">Policy :</Label>
                        <p className="p-0 m-0 ms-2 fw-normal">{policyName}</p>
                    </div>
                </div>
                <div className="mt-2">
                    {AndroidPolicyQR?.map((field, index) => {
                        return (
                            <React.Fragment key={index}>
                                {(field?.showKey ? handleShowOn(field) : true) && dynamicComponent(field)}
                            </React.Fragment>
                        );
                    })}
                </div>
            </>
        ) : (
            <React.Fragment>
                <div className="d-flex align-items-center mb-2">
                    <Label className="p-0 m-0 fw-semibold">Policy :</Label>
                    <p className="p-0 m-0 ms-2 fw-normal">{policyName}</p>
                </div>
                {/* <div className="d-flex align-items-center mb-2">
                    <Label className="p-0 m-0 fw-semibold">Configuration Name :</Label>
                    <p className="p-0 m-0 ms-2 fw-normal">{selectedRow?.name}</p>
                </div> */}
                <div className="fs-14 fw-medium">Enrollment Options :</div>
                <div className="mt-1 fs-12 text-muted">
                    Below are the available enrollment options to simplify device setup. Users can select the method that offers the best
                    convenience.
                </div>

                <Row className="mb-2">
                    {aeCardDetails?.map((ele, ind) => {
                        return (
                            <Col sm={6} xs={6} md={6} lg={4} xl={4} key={ind} className="mt-2">
                                <div className="fw-medium fs-13">{ele?.label}</div>
                                <div className="fs-12 text-muted mt-1">{selectedRow?.[ele?.accessor]}</div>
                            </Col>
                        );
                    })}
                </Row>
                <Row className="mt-3">
                    {aeEnrollmentProcess?.map((ele, ind) => {
                        return (
                            <Col sm={12} xs={12} md={12} lg={12} xl={12} key={ind} className="border rounded-3 mb-2 p-2">
                                <div className="d-flex align-items-center">
                                    <div className="me-3">
                                        <img alt="" width={60} src={`data:image/png;base64,${qrCodeString}`} />
                                    </div>
                                    <div>
                                        <div className="fs-13 fw-medium d-flex align-items-center justify-content-between">
                                            {ele?.label}
                                            {ele?.labelCopyIcon && (
                                                <>
                                                    <i
                                                        id={`copy-${ind}`}
                                                        onClick={(e) => copyToClipboard(ele.label)}
                                                        className="ri-file-copy-line ms-2 fs-13 cursor-pointer link-primary"
                                                    ></i>
                                                    {Tooltip(`copy-${ind}`, 'Copy')}
                                                </>
                                            )}
                                            {ele?.download && (
                                                <div
                                                    onClick={() =>
                                                        handleDownload(`data:image/png;base64,${qrCodeString}`, ele?.label, 'logo')
                                                    }
                                                >
                                                    <i className="ri-download-2-line text-primary fs-16 cursor-pointer me-5"></i>
                                                </div>
                                            )}
                                        </div>
                                        <div className="mt-1 text-muted fs-12">{ele?.helptext}</div>
                                        {ele?.copyIcon && (
                                            <div className="d-flex align-items-center">
                                                <span className="fs-13 fw-medium">{selectedRow?.enrollmentcode}</span>
                                                <i
                                                    id={`tokencopy-${ind}`}
                                                    onClick={(e) => copyToClipboard(selectedRow?.enrollmentcode, ele?.label)}
                                                    className="ri-file-copy-line ms-2 fs-13 cursor-pointer link-primary"
                                                ></i>
                                                {Tooltip(`tokencopy-${ind}`, 'Copy')}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </Col>
                        );
                    })}
                </Row>
            </React.Fragment>
        );
    };

    const toggle = useCallback(async () => {
        setShowShareModal(false);
        setSelectedRow('');
    }, [showShareModal]);

    const customCancel = () => {
        setShowEnrollModal(false);
        setSelectedRow('');
        setQRCodeString('');
        setMode('add');
        validation.resetForm();
    };

    const handleEnrollPageChange = (pageNumber, pgSize) => {
        setActivePage(pageNumber);
        setSearchParams({ ...searchParams, page: pageNumber });
        getTokens('signintokens', { ...searchParams });
    };

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className={`page-content ${loading ? 'postion-relative mask' : ''}`}>
                <Container fluid>
                    <BreadCrumb
                        pageTitle={'Enrollment Token'}
                        history={history}
                        homeLink="Dashboard"
                        showBack={true}
                        backLink="aepolicies"
                    />
                    <Card className="card-height-100">
                        <CardHeader>
                            <div className="d-flex w-100 align-items-center justify-content-between">
                                <div className="d-flex align-items-center">
                                    <Label className="p-0 m-0 mt-3 fw-semibold fs-16">Policy :</Label>
                                    <p className="p-0 m-0 mt-3 ms-2 fw-normal fs-14">{policyName}</p>
                                </div>
                                <Button type="button" color="success" onClick={handleAddNew}>
                                    + Create New Token
                                </Button>
                            </div>
                        </CardHeader>
                        <CardBody>
                            {/* {tenant.ENABLE_SIGN_IN ? (
                                <TabsComponent tabsList={tabsList} defaultTabID={selectedTab} toggleTab={(tab) => handleSelectedTab(tab)} />
                            ) : ( */}
                            {handleTabContent()}
                            {/* )} */}
                            {totalRecords > 30 && (
                                <Pagination
                                    activePage={activePage}
                                    itemsCountPerPage={pageSize}
                                    totalItemsCount={totalRecords}
                                    pageRangeDisplayed={3}
                                    marginTopClass={'mt-1'}
                                    onChange={handleEnrollPageChange}
                                    hideFirstLastPages={false}
                                />
                            )}
                        </CardBody>
                    </Card>
                </Container>
                <OffcanvasModal
                    direction="end"
                    loading={offCanvasLoader}
                    toggle={showEnrollModal ? customCancel : toggle}
                    open={showEnrollModal || showShareModal}
                    hideSaveButton={showEnrollModal && mode === 'view'}
                    handleCloseClick={showEnrollModal ? customCancel : toggle}
                    OffcanvasModalID="enrollment"
                    saveDisabled={showEnrollModal && mode === 'add' && !validation.values?.tokenExpiration}
                    handleSaveClick={showEnrollModal ? validation.handleSubmit : ''}
                    saveText={showEnrollModal ? (mode === 'add' ? 'Save' : 'Update') : 'Send'}
                    handleOffcanvasBody={() => showEnrollModal && handleEnrollModalBody()}
                    modalClassName={'w-40'}
                    offcanvasHeader={`${
                        showEnrollModal ? (mode === 'edit' ? 'Edit' : mode === 'view' ? 'View' : 'Add') : 'Share'
                    } Enrollment Configurations`}
                />
            </div>
        </React.Fragment>
    );
};

export default EnrollmentToken;
