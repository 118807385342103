/* eslint-disable multiline-comment-style */
import React, { useEffect, useRef, useState } from 'react';
import Loader from '../../../Components/Common/Loader';
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    Container,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Input,
    Label,
    Row,
    UncontrolledDropdown
} from 'reactstrap';
import { useHistory, Link } from 'react-router-dom';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import NoRecordsFound from '../../../Components/Common/NoRecordsFound';
import { APIClient } from '../../../helpers/api_helper';
import WindowsImage from '../../../assets/images/windows/svg/common/windowslogo.svg';

import * as url from '../../../helpers/url_helper';
import * as domains from '../../../helpers/domain_helper';
import { useEnv } from '../../../envContext';
import EllipsisToolTip from '../../../Components/Common/Tooltip/Tooltip';
import CommonModal from '../../../Components/Common/CommonModal';
import { FilePond } from 'react-filepond';
import CryptoJS from 'crypto-js';
import SimpleBar from 'simplebar-react';
import image2 from '../../../assets/images/common/jpg/users/avatar-2.jpg';
import image3 from '../../../assets/images/common/jpg/users/avatar-3.jpg';
import image5 from '../../../assets/images/common/jpg/users/avatar-5.jpg';
import { convertBytesToWithDecimals, convertUTCtoIST, Tooltip } from '../../../Components/Common/Util';
import { toast } from 'react-toastify';
import toastMessages from '../../../common/messages/toastMessages';
import OffcanvasModal from '../../../Components/Common/OffcanvasModal';
import { AGENT_APP_NAME } from '../Policies/WindowsPolicyComponents';
import { MsiPropsReader } from 'msi-props-reader';

const WindowsApps = () => {
    document.title = 'Windows Apps';

    let history = useHistory();
    const api = new APIClient();
    const urlconf = useEnv();
    const [loading, setLoading] = useState(false);
    const [fileUploader, setFileUploader] = useState('');
    const updateFileUploader = useRef({});
    const [appsData, setAppsData] = useState([]);
    const [addCustomAppModal, setAddCustomAppModal] = useState(false);
    const [addStoreAppModal, setAddStoreAppModal] = useState(false);
    const [appName, setappName] = useState('');
    const [files, setFiles] = useState('');
    const [offcanvasCommonModal, setOffcanvasCommonModal] = useState(false);
    const [seletecdAppData, setSeletecdAppData] = useState({});

    const [value, setValue] = useState('');
    const onChangeData = (val) => {
        getEventLisners();
        getAppStoreData(val);
        setValue(val);
    };

    const getAppStoreData = (val) => {
        api.get('https://apps.microsoft.com/api/products/getAutosuggestions?prefix=whatsapp', {}, '', true).then((resp) => {
            //
        });
    };

    const getEventLisners = () => {
        var searchOptions = document.getElementById('search-close-options');
        var dropdown = document.getElementById('search-dropdown');
        var searchInput = document.getElementById('search-options');

        searchInput?.addEventListener('focus', function () {
            var inputLength = searchInput.value.length;
            if (inputLength > 0) {
                dropdown?.classList.add('show');
                searchOptions?.classList.remove('d-none');
            } else {
                dropdown?.classList.remove('show');
                searchOptions?.classList.add('d-none');
            }
        });

        searchInput?.addEventListener('keyup', function () {
            var inputLength = searchInput.value.length;
            if (inputLength > 0) {
                dropdown?.classList.add('show');
                searchOptions?.classList.remove('d-none');
            } else {
                dropdown?.classList.remove('show');
                searchOptions?.classList.add('d-none');
            }
        });

        searchOptions?.addEventListener('click', function () {
            searchInput.value = '';
            dropdown?.classList.remove('show');
            searchOptions?.classList.add('d-none');
        });

        document.body.addEventListener('click', function (e) {
            if (e.target.getAttribute('id') !== 'search-options') {
                dropdown?.classList.remove('show');
                searchOptions?.classList.add('d-none');
            }
        });
    };

    useEffect(() => {
        getApps();
    }, []);

    const getApps = () => {
        setLoading(true);
        let params = { page: 1, size: 2000 };
        api.get(url.WINDOWS_APPS, params, domains.WINDOWS_V1)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp?.data?.length > 0) {
                    setAppsData(resp.data);
                }
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    const handleRefresh = () => {
        // setLoading(true);
        // api.patch(url.IOSAPPS_SYNC, {}, false, domains.WINDOWS_V1)
        //     .then((resp) => {
        //         setLoading(false);
        //     })
        //     .catch((_err) => {
        //         setLoading(false);
        //     });
    };

    const CustomAppHeader = () => {
        return (
            <div className="d-flex align-items-center justify-content-center">
                <Label className="p-0 m-0 mt-3 fw-semibold">Add New App</Label>
            </div>
        );
    };

    const StoreAppHeader = () => {
        return (
            <div className="d-flex align-items-center justify-content-center">
                <Label className="p-0 m-0 mt-3 fw-semibold">Add Microsoft Store App</Label>
            </div>
        );
    };

    const CustomAppBody = () => {
        return (
            <Row className="d-flex align-items-center justify-content-center">
                <Label htmlFor="name" className="mb-0 ps-2">
                    App Name
                    <span className="red-color ps-1">*</span>
                </Label>
                <div className="px-2 mb-2">
                    <Input
                        className="my-2"
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Enter App Name"
                        value={appName}
                        onChange={(e) => onEnterAppName(e.target.value)}
                    />
                </div>
                <div className="filepondmarginremoval w-100">
                    <FilePond
                        name="files"
                        minFileSize="5KB"
                        maxFileSize="300MB"
                        maxFiles={1}
                        files={files}
                        className="filepond filepond-input-multiple"
                        onupdatefiles={(fileItems) => handleFiles(fileItems)}
                    />
                </div>
            </Row>
        );
    };

    const StoreAppBody = () => {
        return (
            <div className="app-search d-none d-md-block">
                <div className="position-relative">
                    <Input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        id="search-options"
                        value={value}
                        onChange={(e) => {
                            onChangeData(e.target.value);
                        }}
                    />
                    <span className="ri-search-line search-widget-icon"></span>
                    <span
                        className="mdi mdi-close-circle search-widget-icon search-widget-icon-close d-none"
                        id="search-close-options"
                    ></span>
                </div>
                <div className="dropdown-menu dropdown-menu-lg" id="search-dropdown">
                    <SimpleBar className="max-height-320">
                        <div className="notification-list">
                            <Link to="#" className="dropdown-item notify-item py-2">
                                <div className="d-flex">
                                    <img src={image2} className="me-3 rounded-circle avatar-xs" alt="user-pic" />
                                    <div className="flex-1">
                                        <h6 className="m-0">Angela Bernier</h6>
                                        <span className="fs-11 mb-0 text-muted">Manager</span>
                                    </div>
                                </div>
                            </Link>

                            <Link to="#" className="dropdown-item notify-item py-2">
                                <div className="d-flex">
                                    <img src={image3} className="me-3 rounded-circle avatar-xs" alt="user-pic" />
                                    <div className="flex-1">
                                        <h6 className="m-0">David Grasso</h6>
                                        <span className="fs-11 mb-0 text-muted">Web Designer</span>
                                    </div>
                                </div>
                            </Link>

                            <Link to="#" className="dropdown-item notify-item py-2">
                                <div className="d-flex">
                                    <img src={image5} className="me-3 rounded-circle avatar-xs" alt="user-pic" />
                                    <div className="flex-1">
                                        <h6 className="m-0">Mike Bunch</h6>
                                        <span className="fs-11 mb-0 text-muted">React Developer</span>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </SimpleBar>
                </div>
            </div>
        );
    };

    const onEnterAppName = (e) => {
        setappName(e);
    };

    const handleFiles = (fileItems) => {
        setFiles(fileItems);
        if (fileItems.length > 0) {
            let fileReader = new FileReader();
            fileReader.readAsDataURL(fileItems[0].file);
            fileReader.addEventListener('loadend', (e) => {
                if (e.target.readyState === FileReader.DONE) {
                    const hash = CryptoJS.SHA256(fileReader.result).toString(CryptoJS.enc.Hex);
                }
            });
        }
    };

    const uploadFile = () => {
        fileUploader?.click();
    };

    const handleUpload = (ev) => {
        const file = ev.target.files[0];
        if (file) {

            const fileExtension = file.name.split('.').pop().toLowerCase();
            if (fileExtension === 'msi') {
                // || fileExtension === 'msix' || fileExtension === 'exe') {
                const fileProps = getFileInfo(file);
                fileProps.onComplete = (fileProperties) => {
                    console.log('File properties', fileProperties);
                    // setLoading(true);
                    delete fileProperties.onComplete;
                    console.log('After: File properties', fileProperties);
                    api.get(url.WINDOWS_FILES, { filename: file.name }, domains.WINDOWS_V1)
                        .then((resp) => {
                            resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                            if (resp.status === 'success') {
                                fetch(resp?.data?.url, {
                                    method: 'PUT',
                                    body: file
                                })
                                    .then((response) => {
                                        if (response?.status === 200) {
                                            api.create(
                                                url.WINDOWS_APPS,
                                                {
                                                    serverFileName: resp?.data?.serverfilename,
                                                    apptype: fileExtension,
                                                    ...fileProperties
                                                },
                                                false,
                                                domains.WINDOWS_V1
                                            )
                                                .then((finalResp) => {
                                                    finalResp =
                                                        urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(finalResp) : finalResp;
                                                    if (finalResp.status === 'success') {
                                                        toast.success(toastMessages.windowsAppUpload);
                                                        getApps();
                                                    }
                                                })
                                                .catch((___err) => {
                                                    setLoading(false);
                                                });
                                        }
                                    })
                                    .catch((__err) => {
                                        setLoading(false);
                                    });
                            }
                        })
                        .catch((_err) => {
                            setLoading(false);
                        });
                }
            } else {
                toast.error('Please select a valid .msi file');
            }
        }
    };

    const handleUpdateUpload = (ev, data) => {
        const file = ev.target.files[0];
        if (file) {
            const fileExtension = file.name.split('.').pop().toLowerCase();
            if (fileExtension === 'msi') {
                //  || fileExtension === 'msix' || fileExtension === 'exe') {
                const fileProps = getFileInfo(file);
                fileProps.onComplete = (fileProperties) => {
                    if (fileProperties.name !== data.name) {
                        toast.error(`Update ${fileProperties.name} app does not match the existing app ${data.name}.`);
                    }
                    else if (fileProperties.version === data.version) {
                        toast.error(`${fileProperties.version} Version already exists.`);
                    } else {
                        setLoading(true);
                        delete fileProperties.onComplete;
                        console.log('ertyhj', fileProperties);
                        api.get(url.WINDOWS_FILES, { filename: file.name, ...completedProperties }, domains.WINDOWS_V1)
                            .then((resp) => {
                                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                                if (resp.status === 'success') {
                                    fetch(resp?.data?.url, {
                                        method: 'PUT',
                                        body: file
                                    })
                                        .then((response) => {
                                            if (response?.status === 200) {
                                                api.patch(
                                                    url.WINDOWS_APPS + '/' + data?._id,
                                                    {
                                                        serverFileName: resp?.data?.serverfilename,
                                                        apptype: fileExtension,
                                                        ...fileProperties
                                                    },
                                                    false,
                                                    domains.WINDOWS_V1
                                                )
                                                    .then((finalResp) => {
                                                        finalResp =
                                                            urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(finalResp) : finalResp;
                                                        if (finalResp.status === 'success') {
                                                            toast.success(toastMessages.windowsAppUpdate);
                                                            getApps();
                                                        }
                                                    })
                                                    .catch((___err) => {
                                                        setLoading(false);
                                                    });
                                            }
                                        })
                                        .catch((__err) => {
                                            setLoading(false);
                                        });
                                }
                            })
                            .catch((_err) => {
                                setLoading(false);
                            });
                    }
                };
            } else {
                toast.error('Please select a valid .msi file');
            }
        }
    };


    const handleAppUpdate = (data) => {
        updateFileUploader.current?.[data?._id].click();
    };

    const handleAppDelete = (data) => {
        setLoading(true);
        api.delete(url.WINDOWS_APPS + '/' + data?._id, '', domains.WINDOWS_V1)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    toast.success(toastMessages.windowsAppDelete);
                    getApps();
                }
            })
            .finally(() => setLoading(false));
    };

    const userLabel = (uname) => {
        let name = uname?.split(' ');
        if (name?.length === 1) return name?.[0]?.charAt(0)?.toUpperCase();
        else return name?.[0]?.charAt(0)?.toUpperCase() + name?.[1]?.charAt(0)?.toUpperCase();
    };

    const toggleOffcanvasCommonModal = () => {
        setOffcanvasCommonModal(!offcanvasCommonModal);
        setSeletecdAppData({});
    };

    const handleViewDetails = (appData) => {
        setSeletecdAppData(appData);
        setOffcanvasCommonModal(true);
    };

    const handleOffcanvasBody = () => {
        return (
            <Col>
                <Row className="mb-2">
                    <Col className="fw-semibold">ID :</Col>
                    <Col className="fw-normal">{seletecdAppData._id ? seletecdAppData._id : '—'}</Col>
                </Row>
                <Row className="mb-2">
                    <Col className="fw-semibold">Name :</Col>
                    <Col className="fw-normal">{seletecdAppData.name ? seletecdAppData.name : '—'}</Col>
                </Row>
                <Row className="mb-2">
                    <Col className="fw-semibold">App Type :</Col>
                    <Col className="fw-normal">{seletecdAppData.apptype ? seletecdAppData.apptype : '—'}</Col>
                </Row>
                <Row className="mb-2">
                    <Col className="fw-semibold">Product ID :</Col>
                    <Col className="fw-normal">{seletecdAppData.productid ? seletecdAppData.productid : '—'}</Col>
                </Row>
                <Row className="mb-2">
                    <Col className="fw-semibold">Version :</Col>
                    <Col className="fw-normal">{seletecdAppData.version ? seletecdAppData.version : '—'}</Col>
                </Row>
                <Row className="mb-2">
                    <Col className="fw-semibold">Size :</Col>
                    <Col className="fw-normal">{seletecdAppData.size ? convertBytesToWithDecimals(seletecdAppData.size, 2) : '—'}</Col>
                </Row>
                <Row className="mb-2">
                    <Col className="fw-semibold">Modified By :</Col>
                    <Col className="fw-normal">{seletecdAppData.modifiedby ? seletecdAppData.modifiedby : '—'}</Col>
                </Row>
                <Row className="mb-2">
                    <Col className="fw-semibold">Last Modified Date :</Col>
                    <Col className="fw-normal">{seletecdAppData.modifieddate ? convertUTCtoIST(seletecdAppData.modifieddate) : '—'}</Col>
                </Row>
            </Col>
        );
    };

    const getFileInfo = (file) => {
        const result = {
            name: null,
            version: null,
            manufacturer: null,
            filehash: null,
            size: file.size,
            productid: null,
            upgradecode: null,
            onComplete: null
        };
        const reader = new MsiPropsReader();
        reader.initialize(file).then(() => {
            const properties = reader.getProperties();
            computeSha256(file).then((hash) => {
                result.name = properties.ProductName;
                result.version = properties.ProductVersion;
                result.manufacturer = properties.Manufacturer;
                result.filehash = hash;
                result.productid = properties.ProductCode.slice(1, -1);
                result.upgradecode = properties.UpgradeCode.slice(1, -1);
                if (result.onComplete) {
                    result.onComplete(result);
                }
            });
        });

        return result;
    };

    const computeSha256 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            const crypto = window.crypto || window.msCrypto;

            reader.onload = () => {
                const buffer = reader.result;
                const hash = crypto.subtle.digest('SHA-256', buffer);
                hash.then((result) => {
                    resolve(bufferToHex(result));
                }).catch(reject);
            };

            reader.onerror = () => reject(reader.error);
            reader.readAsArrayBuffer(file);
        });
    };

    const bufferToHex = (buffer) => {
        const byteArray = new Uint8Array(buffer);
        return Array.from(byteArray)
            .map((byte) => byte.toString(16).padStart(2, '0'))
            .join('');
    };

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className={`page-content ${loading ? 'postion-relative mask' : ''}`}>
                <Container fluid>
                    <BreadCrumb title="Windows Apps" pageTitle="Windows Apps" backLink="windowsapps" history={history} />
                    <Card className="min-height-600">
                        <CardHeader>
                            <Row className="d-flex align-items-center justify-content-between">
                                {/* <Col className="col-5 d-flex align-items-center">
                                    <div className="w-50 pe-5">
                                        <SearchOption />
                                    </div>
                                    <div className="w-30">
                                        <Select options={options} />
                                    </div>
                                </Col> */}
                                <Col className="d-flex align-items-center justify-content-end">
                                    {/* <div className="text-grey fw-medium fs-12 pe-2">
                                    <i>Last Synced 5 minutes ago</i>
                                </div>
                                <div
                                    className="cursor-pointer width-30 rounded d-flex align-items-center justify-content-center bg-soft-info"
                                    onClick={handleRefresh}
                                >
                                    <i className="ri-refresh-line text-success fs-16"></i>
                                </div> */}
                                    <div className="d-flex" id="customAppUpload">
                                        <Button type="button" color="primary" onClick={() => uploadFile()}>
                                            + Upload App
                                        </Button>
                                        {Tooltip('customAppUpload', 'Upload .msi File')}
                                        {/* {Tooltip('customAppUpload', 'Upload .msi/.msix/.exe File')} */}

                                        <input
                                            hidden
                                            id="file"
                                            type="file"
                                            accept=".msi"
                                            // accept=".msi,.msix,.exe"
                                            ref={(file) => setFileUploader(file)}
                                            onChange={(ev) => handleUpload(ev)}
                                            onClick={(ev) => (ev.target.value = null)}
                                        />
                                    </div>
                                    {/* <div className="ps-2">
                                        <Button type="button" color="primary" onClick={() => setAddStoreAppModal(true)}>
                                            + Add Microsoft Store App
                                        </Button>
                                    </div> */}
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                {appsData?.length > 0 ? (
                                    appsData.map((data, index) => (
                                        <Col key={index} xs={12} sm={12} md={6} lg={4} xl={4} xxl={3}>
                                            <Card className="shadow-card border">
                                                <CardBody>
                                                    <div className="mb-4">
                                                        <div
                                                            className={
                                                                'badge-soft-success position-absolute top-0 start-0 fs-10 p-1 fw-medium'
                                                            }
                                                        >
                                                            Enterprise
                                                        </div>
                                                        <div className="position-absolute cursor-pointer top-0 end-0 pt-1 p-2">
                                                            <UncontrolledDropdown direction="start">
                                                                <DropdownToggle
                                                                    tag="button"
                                                                    className="btn btn-link text-muted p-1 mt-0 py-0 text-decoration-none fs-15"
                                                                >
                                                                    <i className="ri-more-fill" />
                                                                </DropdownToggle>

                                                                <DropdownMenu className="dropdown-menu-end">
                                                                    <DropdownItem onClick={() => handleViewDetails(data)}>
                                                                        <i className="ri-information-line align-bottom me-2 text-muted"></i>
                                                                        View Details
                                                                    </DropdownItem>
                                                                    {data.name !== AGENT_APP_NAME && (
                                                                        <>
                                                                            <DropdownItem onClick={() => handleAppUpdate(data)}>
                                                                                <input
                                                                                    hidden
                                                                                    id="file"
                                                                                    type="file"
                                                                                    accept=".msi,.msix,.exe"
                                                                                    ref={(file) =>
                                                                                    (updateFileUploader.current = {
                                                                                        ...updateFileUploader.current,
                                                                                        [data._id]: file
                                                                                    })
                                                                                    }
                                                                                    onChange={(ev) => handleUpdateUpload(ev, data)}
                                                                                    onClick={(ev) => (ev.target.value = null)}
                                                                                />
                                                                                <i className="ri-loop-right-line align-bottom me-2 text-muted"></i>
                                                                                Update
                                                                            </DropdownItem>
                                                                            <DropdownItem onClick={() => handleAppDelete(data)}>
                                                                                <i className="ri-delete-bin-6-line align-bottom me-2 text-muted"></i>
                                                                                Delete
                                                                            </DropdownItem>
                                                                        </>
                                                                    )}
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </div>
                                                    </div>
                                                    <Row className="d-flex justify-content-center align-items-center">
                                                        <Col xs={3} sm={3} md={3} lg={3} xl={2} xxl={2}>
                                                            <span className="rounded height-50 width-50 border border-2 d-flex align-items-center justify-content-center fs-15 bg-primary text-white">
                                                                {userLabel(data.name)}
                                                            </span>
                                                        </Col>
                                                        <Col xs={9} sm={9} md={9} lg={9} xl={10} xxl={10} className="ps-5">
                                                            <div className="fs-13 fw-semibold">
                                                                <EllipsisToolTip options={{ effect: 'solid', place: 'bottom' }}>
                                                                    {data.name}
                                                                </EllipsisToolTip>
                                                            </div>
                                                            <div className="fs-11 text-muted">
                                                                Version {data.version ? data.version : '1.0.0'}
                                                            </div>
                                                            <div className="fs-11 text-muted">
                                                                {convertBytesToWithDecimals(data.size, 2)}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                                <CardFooter>
                                                    {data.assignedlicenses || data.totallicenses ? (
                                                        <div className="mt-auto">
                                                            <div className="d-flex mb-1">
                                                                <div className="flex-grow-1">
                                                                    <div className="fw-medium fs-11">Licenses</div>
                                                                </div>
                                                                <div className="flex-shrink-0">
                                                                    <div className="d-flex align-items-center">
                                                                        <i className="ri-list-check align-bottom me-1 text-muted fs-11"></i>
                                                                        <span className="fw-medium fs-11">
                                                                            {data.assignedlicenses}/{data.totallicenses}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="progress progress-sm animated-progess">
                                                                <div
                                                                    className="progress-bar bg-success"
                                                                    role="progressbar"
                                                                    style={{
                                                                        width:
                                                                            (Number(data.assignedlicenses) / Number(data.totallicenses)) *
                                                                            100 +
                                                                            '%'
                                                                    }}
                                                                ></div>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className="d-flex align-items-center">
                                                            <i className="ri-information-line fs-14 pe-1" />
                                                            <i className="text-grey fw-medium fs-11 word-ellipsis">
                                                                No License is required.
                                                            </i>
                                                        </div>
                                                    )}
                                                </CardFooter>
                                            </Card>
                                        </Col>
                                    ))
                                ) : (
                                    <NoRecordsFound />
                                )}
                            </Row>
                        </CardBody>
                    </Card>
                    <CommonModal
                        size={'md'}
                        show={addCustomAppModal}
                        disabled={true}
                        saveText="Save"
                        cancelText="Cancel"
                        modalheader={CustomAppHeader()}
                        onCloseClick={() => setAddCustomAppModal(false)}
                        handleClick={() => { }}
                        handleModalBody={() => CustomAppBody()}
                    />
                    <CommonModal
                        size={'md'}
                        show={addStoreAppModal}
                        centered={false}
                        disabled={true}
                        saveText="Save"
                        cancelText="Cancel"
                        modalheader={StoreAppHeader()}
                        onCloseClick={() => setAddStoreAppModal(false)}
                        handleClick={() => { }}
                        handleModalBody={() => StoreAppBody()}
                    />
                    <OffcanvasModal
                        direction="end"
                        open={offcanvasCommonModal}
                        toggle={toggleOffcanvasCommonModal}
                        handleCloseClick={toggleOffcanvasCommonModal}
                        loading={loading}
                        OffcanvasModalID={'appDetails'}
                        closeButtonBorder={true}
                        handleOffcanvasBody={handleOffcanvasBody}
                        offcanvasHeader={seletecdAppData?.name}
                        hideSaveButton={true}
                        modalClassName={'w-30'}
                    />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default WindowsApps;
