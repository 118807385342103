/* eslint-disable max-lines */
/* eslint-disable max-depth */
import React, { forwardRef, Fragment, useEffect, useRef, useState } from 'react';
import Flatpickr from 'react-flatpickr';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import {
    useAsyncDebounce,
    useExpanded,
    useFilters,
    useGlobalFilter,
    useMountedLayoutEffect,
    usePagination,
    useRowSelect,
    useSortBy,
    useTable
} from 'react-table';
import { toast } from 'react-toastify';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Input,
    Row,
    Table,
    UncontrolledDropdown
} from 'reactstrap';
import EnrollmentToken from '../../assets/images/common/svg/common/enrollment_token.svg';
import sortasc from '../../assets/images/common/png/common/sortasc.png';
import sortdesc from '../../assets/images/common/png/common/sortdesc.png';
import toastMessages from '../../common/messages/toastMessages';
import Pagination from '../../Components/Common/Pagination/Pagination';
import { TenantConfig, Tooltip } from '../Common/Util';
import { TableSkelton } from './skelton';
import { DefaultColumnFilter } from './TableFilters';
import EllipsisToolTip from './Tooltip/Tooltip';
import { ellipsisToolTip, getDate, getDateOnly, IsAuthorized, IsAuthorizedCondition } from './Util';
import { tenantConfig } from '../constants/TenantConfig';

const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }, ref) => {
    const defaultRef = useRef();
    const resolvedRef = ref || defaultRef;

    useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
        <>
            <input className="form-check-input" type="checkbox" ref={resolvedRef} {...rest} />
        </>
    );
});

IndeterminateCheckbox.displayName = 'IndeterminateCheckbox';

const TableContainer = ({
    columns,
    data,
    isGlobalFilter,
    customPageSize,
    tableClass,
    theadClass,
    trClass,
    thClass,
    divClass,
    emptyMessage,
    tableHeader,
    onPageChange,
    totalRecords,
    pageNum,
    reader,
    editor,
    showSelectAllOption,
    onSelectedRowsChange,
    ...props
}) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        nextPage,
        pageCount,
        previousPage,
        setPageSize,
        setGlobalFilter,
        state,
        globalFilteredRows,
        selectedFlatRows,
        state: { pageIndex, pageSize, selectedRowIds }
    } = useTable(
        {
            columns,
            data,
            defaultColumn: { Filter: DefaultColumnFilter },
            initialState: {
                pageIndex: 0,
                pageSize: customPageSize ? customPageSize : 10,
                selectedRowIds: props.selectedRows ? props.selectedRows : {}
            }
        },
        useGlobalFilter,
        useFilters,
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect,
        (hooks) => {
            showSelectAllOption &&
                hooks.visibleColumns.push((cols) => [
                    // Let's make a column for selection
                    {
                        id: 'selection',
                        minWidth: 15,
                        maxWidth: 15,
                        width: 15,
                        /*
                         * The header can use the table's getToggleAllRowsSelectedProps method
                         * to render a checkbox
                         */
                        Header: ({ getToggleAllRowsSelectedProps }) => (
                            <div>
                                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} disabled={props.selectionCheckBoxDisabled} />
                            </div>
                        ),
                        /*
                         * The cell can use the individual row's getToggleRowSelectedProps method
                         * to the render a checkbox
                         */
                        Cell: ({ row }) => (
                            <div>
                                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} disabled={props.selectionCheckBoxDisabled} />
                            </div>
                        )
                    },
                    ...cols
                ]);
        }
    );

    const fp = useRef(null);
    let history = useHistory();
    const [activePage, setActivePage] = useState(pageIndex + 1);
    const [fileUploader, setFileUploader] = useState('');
    const [dateVal, setDateVal] = useState('');
    const [value, setValue] = useState(props.searchVal ? props.searchVal : props.handleGlobalSearch ? '' : state.globalFilter);
    const [dropDownValue, setDropDownValue] = useState(props.dropDownParams2?.dropdownVal || '');
    const [showPanel, setShowPanel] = useState(false);
    let tenant = TenantConfig();

    useEffect(() => {
        setValue(props.searchVal);
    }, [props.searchVal]);

    useMountedLayoutEffect(() => {
        onSelectedRowsChange && onSelectedRowsChange(selectedRowIds, selectedFlatRows);
    }, [onSelectedRowsChange, selectedRowIds]);

    const generateSortingIndicator = (column) => {
        return column.isSorted ? (
            column.isSortedDesc ? (
                <img className="sortsymbol" src={sortdesc} alt="desc" />
            ) : (
                <img className="sortsymbol" src={sortasc} alt="asc" />
            )
        ) : (
            ''
        );
    };

    const handlePageChange = (pageNumber) => {
        if (props.tableLabel === 'ANDROID' && pageNumber > tenant.MAX_ANDROID_DEVICE && !props.maxPage) {
            toast.error(toastMessages.deviceMaxPageReached);
            pageNumber = tenant.MAX_ANDROID_DEVICE / 25;
            if (activePage === tenant.MAX_ANDROID_DEVICE / 25) return;
        } else if (props.tableLabel === 'IOS' && pageNumber > tenant.MAX_IOS_DEVICE && !props.maxPage) {
            toast.error(toastMessages.deviceMaxPageReached);
            pageNumber = tenant.MAX_IOS_DEVICE / 25;
            if (activePage === tenant.MAX_IOS_DEVICE / 25) return;
        }
        setActivePage(pageNumber);
        const pg = pageNumber ? Number(pageNumber) - 1 : 0;
        gotoPage(pg);
        onPageChange && onPageChange(pageNumber, pageSize);
    };

    const onClickAction = (cell, row, action) => {
        switch (action) {
            case 'view':
                if (cell.column.view) {
                    if (cell.column.viewRoute)
                        history.push(cell.column.viewRoute + '/' + row.original[cell.column.routeKey ? cell.column.routeKey : 'id']);
                    else props.handleClickView(row, cell);
                } else {
                    props.handleClickView(row, cell);
                }
                break;
            case 'viewDetails':
                props.handleClickViewDetails(row, cell);
                break;
            case 'info':
                props.handleInfo(row, cell);
                break;
            default:
                break;
        }
    };

    const copyToClipboard = async (e, val, id, toastMsg) => {
        navigator.clipboard.writeText(val).then(() => {
            toast.success(toastMsg);
        });
    };

    const label = (names) => {
        let nameArr = names?.split(' ');
        let returnVal =
            nameArr?.length > 1
                ? nameArr?.[0]?.charAt(0)?.toUpperCase() + nameArr?.[1]?.charAt(0)?.toUpperCase()
                : names?.substring(0, 2)?.toUpperCase();
        return returnVal;
    };

    const prepareCustomBody = (cell, row, index) => {
        let body = undefined;
        switch (cell?.column?.fieldType) {
            case 'Sl-No':
                body = <div className="d-flex">{row?.index + 1}</div>;
                break;
            case 'dateFormat':
                body = (
                    <div className="d-flex">
                        {row.original[cell.column.key] !== '—'
                            ? cell.column.dateOnly
                                ? getDateOnly(row.original[cell.column.key])
                                : getDate(row.original[cell.column.key])
                            : '—'}
                    </div>
                );
                break;
            case 'customcss':
                body = (
                    <>
                        <div className={cell?.column?.customClass}>{cell.render('Cell')}</div>
                    </>
                );
                break;
            case 'clickable':
                body = cell.column.key ? (
                    row.original[cell.column.key] === cell.column.value ? (
                        <span className="fw-medium link-secondary cursor-pointer" onClick={() => onClickAction(cell, row, 'view')}>
                            {cell.render('Cell')}
                        </span>
                    ) : (
                        <span>{cell.render('Cell')}</span>
                    )
                ) : (
                    <span className="fw-medium link-secondary cursor-pointer" onClick={() => onClickAction(cell, row, 'view')}>
                        {cell.render('Cell')}
                    </span>
                );
                break;
            case 'conditionalClickable':
                body = row.original.noLink ? (
                    <span>{cell.render('Cell')}</span>
                ) : (
                    <span
                        className={`fw-medium ${cell.column.hideLink ? '' : 'link-secondary cursor-pointer'}`}
                        onClick={() => (cell.column.hideLink ? undefined : onClickAction(cell, row, 'view'))}
                    >
                        {row.original[cell.column.iconField] && (
                            <sup className="me-1 text-danger fw-bold fs-17" id={`policyChange-${index}-${cell.column.id}`}>
                                <i className="ri-checkbox-blank-circle-fill link-danger font-size-8" />
                            </sup>
                        )}
                        {row.original[cell.column.iconField] &&
                            Tooltip(
                                `policyChange-${index}-${cell.column.id}`,
                                `Policy updating from ${row.original[cell.column.startField]} to ${row.original[cell.column.endField]}`
                            )}
                        {cell.render('Cell')}
                    </span>
                );
                break;
            case 'tickandcross':
                body = (
                    <div>
                        <i
                            className={`${
                                row.original.status === 'published' ? 'ri-check-fill link-success' : 'ri-subtract-line text-muted'
                            } fw-bold fs-18`}
                        />
                    </div>
                );
                break;
            case 'deviceCount':
                body = (
                    <Row className="d-flex align-items-center">
                        {/* <Col sm={3} className="d-flex align-items-center text-truncate">
                            <span className="text-muted me-1">({row.original[cell.column.accessor1]})</span>
                        </Col> */}
                        <Col sm={'3'} className="d-flex align-items-center text-truncate">
                            <span className="d-flex align-items-center" id={`key3-${index}`}>
                                <i className="ri-checkbox-blank-circle-fill link-success me-1" />
                                {row.original[cell.column.accessor3]}
                            </span>
                            {Tooltip(`key3-${index}`, 'Latest: ' + row.original?.[cell.column.accessor3])}
                        </Col>
                        <Col sm={'3'} className="d-flex align-items-center text-truncate" id={`key2-${index}`}>
                            <span className="d-flex align-items-center">
                                <i className="ri-checkbox-blank-circle-fill link-warning me-1" />
                                {row.original[cell.column.accessor2]}
                            </span>
                            {Tooltip(`key2-${index}`, 'Not Latest: ' + row.original?.[cell.column.accessor2])}
                        </Col>
                        <Col sm={'3'} className="d-flex align-items-center text-truncate" id={`key1-${index}`}>
                            <span className="d-flex align-items-center">
                                <i className="ri-checkbox-blank-circle-fill link-danger me-1" />
                                {row.original[cell.column.accessor1]}
                            </span>
                            {Tooltip(`key1-${index}`, 'Not Installed: ' + row.original?.[cell.column.accessor1])}
                        </Col>
                    </Row>
                );
                break;
            case 'countWithText':
                body = (
                    <div className="d-flex align-items-center">
                        <span className="text-primary me-1 fw-semibold text-decoration-underline">
                            {row.original[cell.column.accessor1] > 0
                                ? row.original[cell.column.accessor1].toLocaleString('en-US', {
                                      minimumIntegerDigits: 2,
                                      useGrouping: false
                                  })
                                : row.original[cell.column.accessor1]}
                        </span>
                        {row.original[cell.column.accessor1] > 0 ? (
                            <span className="text-muted fw-medium">({row.original[cell.column.accessor2] + ' Devices'})</span>
                        ) : (
                            ''
                        )}
                    </div>
                );
                break;
            case 'avatarText':
                body = (
                    <div>
                        {row.original.hideView ? (
                            <div className="d-flex align-items-center w-fit fw-medium">
                                <span
                                    style={{ backgroundColor: row.original.avatarColor }}
                                    className="width-30 height-30 me-1 rounded-circle header-profile-user d-flex align-items-center justify-content-center fs-10 text-white"
                                >
                                    {label(row.original[cell.column.value])}
                                </span>
                                {cell.render('Cell')}
                            </div>
                        ) : (
                            <div
                                className="d-flex align-items-center w-fit link-secondary text-secondary fw-medium cursor-pointer"
                                onClick={() => props.handleView(cell, row)}
                            >
                                {!props.hideAvatar && (
                                    <span
                                        style={{ backgroundColor: row.original.avatarColor }}
                                        className="width-30 height-30 me-1 rounded-circle header-profile-user d-flex align-items-center justify-content-center fs-10 text-white"
                                    >
                                        {label(row.original[cell.column.value])}
                                    </span>
                                )}
                                {cell.render('Cell')}
                            </div>
                        )}
                    </div>
                );
                break;
            case 'iconText':
                body = (
                    <div>
                        <div className="d-flex align-items-center">
                            <img className="me-1 rounded" src={row.original[cell.column.imageURL]} alt="app" width={20} height={20} />
                            <span
                                className={`text-truncate fw-medium ${
                                    row.original[cell.column.accessor2] > 0 ? ' cursor-pointer text-secondary fw-semibold' : ''
                                }`}
                                onClick={() => {
                                    if (row.original[cell.column.accessor2] > 0) props.handleCountViewPolicy(row, index, cell);
                                }}
                                id={`iconText-${index}`}
                            >
                                {cell.render('Cell')}
                            </span>
                            {Tooltip(`iconText-${index}`, cell.render('Cell'))}
                        </div>
                    </div>
                );
                break;
            case 'iconClickable':
                body = (
                    <div
                        className="m-1 d-flex align-items-center cursor-pointer link-primary fw-medium"
                        onClick={() => onClickAction(cell, row, 'viewDetails')}
                    >
                        {row.original.icon && (
                            <>
                                <i
                                    className={`${row.original.icon ? row.original.icon : cell.column.icon} fs-18 ${
                                        row.original.iconClass ? row.original.iconClass : 'link-secondary'
                                    } me-1`}
                                    id={`icon1-${index}`}
                                />
                                {row.original.iconTooltip && Tooltip(`icon1-${index}`, row.original.iconTooltip)}
                            </>
                        )}
                        {row.original.icon2 && (
                            <>
                                <i
                                    className={`${row.original.icon2 ? row.original.icon2 : cell.column.icon2} fs-18 ${
                                        row.original.iconClass2 ? row.original.iconClass2 : 'link-secondary'
                                    } me-1`}
                                    id={`icon2-${index}`}
                                />
                                {row.original.icon2Tooltip && Tooltip(`icon2-${index}`, row.original.icon2Tooltip)}
                            </>
                        )}
                        <EllipsisToolTip options={{ effect: 'solid', place: 'bottom' }}>
                            <span className="text-secondary">{cell.render('Cell')}</span>
                        </EllipsisToolTip>
                    </div>
                );
                break;
            case 'info':
                body = (
                    <div className="d-flex align-items-center fw-medium">
                        <i
                            className={`${cell.column.icon} me-1 link-info cursor-pointer`}
                            onClick={() => onClickAction(cell, row, 'info')}
                        />
                        <span>{cell.render('Cell')}</span>
                    </div>
                );
                break;
            case 'copy':
                body = (
                    <>
                        <div className="d-flex align-items-center">
                            <span className="fw-medium text-truncate">{ellipsisToolTip(cell.render('Cell'))}</span>
                            {row.original[cell.column.fieldKey] !== '—' && (
                                <span className="me-2">
                                    <i
                                        id={`${cell.column.fieldKey}-copy-${index}`}
                                        onClick={(e) =>
                                            copyToClipboard(
                                                e,
                                                row.original[cell.column.fieldKey],
                                                `${cell.column.fieldKey}-copy-${index}`,
                                                cell.column.copyToastMsg
                                            )
                                        }
                                        className="ri-file-copy-line ms-2 fs-13 cursor-pointer link-primary"
                                    ></i>
                                    {Tooltip(`${cell.column.fieldKey}-copy-${index}`, 'Copy')}
                                </span>
                            )}
                        </div>
                    </>
                );
                break;
            case 'fieldClickable':
                body = (
                    <>
                        {row.original.rowView ? (
                            <div className="d-flex align-items-center">
                                <span
                                    className="fw-medium link-secondary cursor-pointer text-truncate"
                                    onClick={() => onClickAction(cell, row, cell.column.type ? cell.column.type : 'view')}
                                >
                                    {ellipsisToolTip(cell.render('Cell'))}
                                </span>
                                {cell.column.copy && (
                                    <span className="me-2">
                                        <i
                                            id={`${cell.column.fieldKey}-copy-${index}`}
                                            onClick={(e) =>
                                                copyToClipboard(
                                                    e,
                                                    row.original[cell.column.fieldKey],
                                                    `${cell.column.fieldKey}-copy-${index}`,
                                                    cell.column.copyToastMsg
                                                )
                                            }
                                            className="ri-file-copy-line ms-2 fs-13 cursor-pointer link-primary"
                                        ></i>
                                        {Tooltip(`${cell.column.fieldKey}-copy-${index}`, 'Copy')}
                                    </span>
                                )}
                            </div>
                        ) : (
                            <div className="d-flex align-items-center">
                                <span className="text-truncate">{ellipsisToolTip(cell.render('Cell'))}</span>
                                {cell.column.copy && (
                                    <span className="me-2">
                                        <i
                                            id={`${cell.column.fieldKey}-copy-${index}`}
                                            onClick={(e) =>
                                                copyToClipboard(
                                                    e,
                                                    row.original[cell.column.fieldKey],
                                                    `${cell.column.fieldKey}-copy-${index}`,
                                                    cell.column.copyToastMsg
                                                )
                                            }
                                            className="ri-file-copy-line ms-2 fs-13 cursor-pointer link-primary"
                                        ></i>
                                        {Tooltip(`${cell.column.fieldKey}-copy-${index}`, 'Copy')}
                                    </span>
                                )}
                            </div>
                        )}
                    </>
                );
                break;
            case 'imageLabel':
                body = (
                    <>
                        <div className="d-flex align-items-center">
                            <div className="flex-shrink-0">
                                {row.original.img ? (
                                    <img src={row.original.img} alt="" className="avatar-xxs rounded-circle" />
                                ) : (
                                    <div className="flex-shrink-0 avatar-xs me-2">
                                        <div className="avatar-title bg-soft-success text-success rounded-circle fs-13">
                                            {row.original.name?.charAt(0)}
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="flex-grow-1 ms-2 name">{cell.render('Cell')}</div>
                        </div>
                    </>
                );
                break;
            case 'iamgeANDLabel':
                body =
                    row?.original?.[cell.column.dataKey] !== '—' ? (
                        <div className="d-flex align-items-center">
                            <img src={row.original.icon} height={35} width={35} alt="" className="me-1" />
                            <div className={'flex-grow-1 name text-' + row?.original?.iconBadgeClass}>
                                <span id={`battery-${index}`} className="w-fit">
                                    {row.original?.text}
                                </span>
                                {cell.column.tooltip && Tooltip(`battery-${index}`, row.original?.[cell.column.tooltipKey])}
                            </div>
                        </div>
                    ) : (
                        <span>{cell.render('Cell')}</span>
                    );
                break;
            case 'iconLabel':
                body =
                    row?.original?.[cell.column.dataKey] !== '—' ? (
                        <div className="d-flex align-items-center">
                            <img src={row.original.icon} alt="" className="me-1" />
                            <div className={'flex-grow-1 name text-' + row?.original?.iconBadgeClass}>
                                <span id={`battery-${index}`} className="w-fit">
                                    {cell.render('Cell')}
                                </span>
                                {cell.column.tooltip && Tooltip(`battery-${index}`, row.original?.[cell.column.tooltipKey])}
                            </div>
                        </div>
                    ) : (
                        <span>{cell.render('Cell')}</span>
                    );
                break;
            case 'textColor':
                body = (
                    <span className={'text-' + (cell?.column?.textColorClass || row?.original?.textColorClass)}>{cell.render('Cell')}</span>
                );
                break;
            case 'badge':
                body = (
                    <div className="d-flex align-items-center gap-2">
                        <span className={'lh-sm badge badge-soft-' + (cell?.column?.badgeClass || row?.original?.badgeClass)}>
                            {cell.render('Cell')}
                        </span>
                        {row.original.showTooltip && (
                            <>
                                <i className="ri-information-line cursor-pointer" id={`info-${index}`} />
                                {Tooltip(`info-${index}`, row?.original?.[cell.column?.tooltipAccessor])}
                            </>
                        )}
                    </div>
                );
                break;
            case 'customBadge':
                body = (
                    <span className={'m-1 lh-sm badge badge-soft-' + (cell?.column?.badgeClass || row?.original?.badgeClass)}>
                        {cell.render('Cell')}
                    </span>
                );
                break;
            case 'coloredText':
                body = (
                    <span className={'fw-medium text-' + (cell?.column?.badgeClass || row?.original?.badgeClass)}>
                        {cell.render('Cell')}
                    </span>
                );
                break;
            case 'switch':
                body = (
                    <IsAuthorized
                        privRequired={editor}
                        yes={() => (
                            <span>
                                <div className="form-switch form-switch-md">
                                    <Input
                                        className={`form-check-input cursor-pointer ${cell.column.className ? cell.column.className : ''}`}
                                        type="checkbox"
                                        role="switch"
                                        name={'status'}
                                        id={`switch-${index}`}
                                        disabled={row.original.disabled}
                                        onChange={(e) => props.onClickSwitch(row, cell, index)}
                                        checked={row.original.status?.toLowerCase() === 'active' ? true : false}
                                    />
                                    {Tooltip(`switch-${index}`, row.original.status?.toLowerCase() === 'active' ? 'Active' : 'Inactive')}
                                </div>
                            </span>
                        )}
                        no={() => <span>{row.original.status?.toLowerCase() === 'active' ? 'Active' : 'Inactive'}</span>}
                    />
                );
                break;
            case 'progress':
                body = (
                    <div className="d-flex align-items-center">
                        <div className="flex-shrink-0 me-1 text-muted fs-13">{cell.render('Cell')}</div>
                        <div className="progress progress-sm flex-grow-1">
                            <div
                                role="progressbar"
                                aria-valuemin="0"
                                aria-valuenow="53"
                                aria-valuemax="100"
                                className={`progress-bar bg-${row.original.progressBadgeClass} rounded`}
                                style={{ width: row.original[cell.column.dataKey] }}
                            ></div>
                        </div>
                    </div>
                );
                break;
            case 'violations':
                body = (
                    <div className="d-flex align-items-center">
                        {/* <i
                            id={`sim-${index}`}
                            className={`ri-sim-card-2-line me-1 fs-15 ${
                                row.original.violations.sim?.toLowerCase() === 'y'
                                    ? 'link-danger'
                                    : row.original.violations.sim?.toLowerCase() === 'n'
                                    ? 'link-green-1'
                                    : 'link-primary'
                            }`}
                        />
                        {Tooltip(
                            `sim-${index}`,
                            row.original.violations.sim
                                ? 'SIM: ' + (row.original.violations.sim?.toLowerCase() === 'y' ? 'In Violation' : 'No Violation')
                                : 'SIM: No data available'
                        )} */}
                        {/*   <i
                            id={`memory-${index}`}
                            className={`ri-sd-card-mini-line me-1 fs-15 ${
                                row.original.violations.memory?.toLowerCase() === 'y'
                                    ? 'link-danger'
                                    : row.original.violations.memory?.toLowerCase() === 'n'
                                    ? 'link-green-1'
                                    : 'link-primary'
                            }`}
                        />
                        {Tooltip(
                            `memory-${index}`,
                            row.original.violations.memory
                                ? 'SD Card: ' + (row.original.violations.memory?.toLowerCase() === 'y' ? 'In Violation' : 'No Violation')
                                : 'SD Card: No data available'
                        )}
                         <i
                            id={`message-${index}`}
                            className={`ri-message-2-line me-1 fs-15 ${
                                row.original.violations.message?.toLowerCase() === 'y'
                                    ? 'link-danger'
                                    : row.original.violations.message?.toLowerCase() === 'n'
                                    ? 'link-green-1'
                                    : 'link-primary'
                            }`}
                        />
                        {Tooltip(
                            `message-${index}`,
                            row.original.violations.message
                                ? 'Message: ' + (row.original.violations.message?.toLowerCase() === 'y' ? 'In Violation' : 'No Violation')
                                : 'Message: No data available'
                        )}
                         <i
                            id={`wifi-${index}`}
                            className={`ri-wifi-line me-1 fs-15 ${
                                row.original.violations.wifi?.toLowerCase() === 'y'
                                    ? 'link-danger'
                                    : row.original.violations.wifi?.toLowerCase() === 'n'
                                    ? 'link-green-1'
                                    : 'link-primary'
                            }`}
                        />
                        {Tooltip(
                            `wifi-${index}`,
                            row.original.violations.wifi
                                ? 'Wi-fi: ' + (row.original.violations.wifi?.toLowerCase() === 'y' ? 'In Violation' : 'No Violation')
                                : 'Wi-fi: No data available'
                        )} */}
                        <i
                            id={`geofence-${index}`}
                            className={`ri-map-pin-line me-1 fs-15 ${
                                row.original.violations.geofence?.toLowerCase() === 'y'
                                    ? 'link-danger'
                                    : row.original.violations.geofence?.toLowerCase() === 'n'
                                    ? 'link-green-1'
                                    : 'link-primary'
                            }`}
                        />
                        {Tooltip(
                            `geofence-${index}`,
                            row.original.violations.geofence
                                ? 'Geo-fence: ' +
                                      (row.original.violations.geofence?.toLowerCase() === 'y' ? 'In Violation' : 'No Violation')
                                : 'Geo-fence: No data available'
                        )}
                        {/* <i
                            id={`unauthorized-${index}`}
                            className={`ri-apps-line me-1 fs-15 ${
                                row.original.violations.unauth?.toLowerCase() === 'y'
                                    ? 'link-danger'
                                    : row.original.violations.unauth?.toLowerCase() === 'n'
                                    ? 'link-green-1'
                                    : 'link-primary'
                            }`}
                        />
                        {Tooltip(
                            `unauthorized-${index}`,
                            row.original.violations.unauth
                                ? 'Apps: ' + (row.original.violations.unauth?.toLowerCase() === 'y' ? 'In Violation' : 'No Violation')
                                : 'Apps: No data available'
                        )} */}
                    </div>
                );
                break;
            case 'deviceActions':
                body = (
                    <UncontrolledDropdown>
                        <DropdownToggle href="#" className="btn-soft-secondary btn-sm" tag="button">
                            <i className="ri-more-fill" />
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end">
                            <DropdownItem>
                                <IsAuthorized
                                    privRequired={editor}
                                    yes={() => (
                                        <>
                                            <i
                                                className="ri-settings-5-line me-2 fs-18 cursor-pointer link-secondary"
                                                onClick={() => props.handleFactoryReset(row, cell, index)}
                                            />
                                            Factory Reset
                                        </>
                                    )}
                                />
                            </DropdownItem>
                            <DropdownItem>
                                <IsAuthorized
                                    privRequired={editor}
                                    yes={() => (
                                        <>
                                            <i
                                                className="ri-phone-lock-line me-2 fs-18 cursor-pointer link-secondary"
                                                onClick={() => props.handleDeviceLock(row, cell, index)}
                                            />
                                            Lock
                                        </>
                                    )}
                                />
                            </DropdownItem>
                            <DropdownItem>
                                <IsAuthorized
                                    privRequired={editor}
                                    yes={() => (
                                        <>
                                            <i
                                                className="ri-lock-password-line me-2 fs-18 cursor-pointer link-secondary"
                                                onClick={() => props.handleResetPassword(row, cell, index)}
                                            />
                                            Reset Password
                                        </>
                                    )}
                                />
                            </DropdownItem>
                            <DropdownItem>
                                <IsAuthorized
                                    privRequired={editor}
                                    yes={() => (
                                        <>
                                            <i
                                                className="ri-creative-commons-sa-line me-2 fs-18 cursor-pointer link-secondary"
                                                onClick={() => props.handleDeviceReboot(row, cell, index)}
                                            />
                                            Reboot
                                        </>
                                    )}
                                />
                            </DropdownItem>
                            <DropdownItem>
                                <IsAuthorized
                                    privRequired={editor}
                                    yes={() => (
                                        <>
                                            <i
                                                className="ri-draft-line me-2 fs-18 cursor-pointer link-secondary"
                                                onClick={() => props.handleNotes(row, cell, index)}
                                            />
                                            Notes
                                        </>
                                    )}
                                />
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                );
                break;
            case 'disableFilters':
                body = (
                    <UncontrolledDropdown className="position-static">
                        <DropdownToggle
                            tag="button"
                            className="btn btn-soft-secondary btn-sm dropdown "
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <i className="ri-more-2-fill align-middle"></i>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu  dropdown-menu-start">
                            {cell?.column?.notes && (
                                <IsAuthorized
                                    privRequired={editor}
                                    yes={() => (
                                        <DropdownItem className="dropdown-item" onClick={() => props.handleNotes(row, cell, index)}>
                                            <div className="d-flex justify-content-start align-items-center">
                                                <i className="ri-draft-line me-2 fs-18 cursor-pointer link-secondary" />
                                                <span className="d-flex align-items-center"> Notes </span>
                                            </div>
                                        </DropdownItem>
                                    )}
                                />
                            )}
                            {cell?.column?.reboot && (
                                <IsAuthorized
                                    privRequired={editor}
                                    yes={() => (
                                        <DropdownItem
                                            className={`dropdown-item ${
                                                row.original.showActions &&
                                                !row.original.disableReboot &&
                                                !row.original?.[cell.column.disableReboot]
                                                    ? 'cursor-pointer'
                                                    : 'cursor-default'
                                            }`}
                                            onClick={() =>
                                                row.original.showActions &&
                                                !row.original.disableReboot &&
                                                !row.original?.[cell.column.disableReboot]
                                                    ? props.handleDeviceReboot(row, cell, index)
                                                    : undefined
                                            }
                                        >
                                            <div className="d-flex justify-content-start align-items-center">
                                                <i
                                                    className={`ri-creative-commons-sa-line me-2 fs-18 ${
                                                        row.original.showActions &&
                                                        !row.original.disableReboot &&
                                                        !row.original?.[cell.column.disableReboot]
                                                            ? ' link-secondary'
                                                            : 'text-muted'
                                                    }`}
                                                />
                                                <span
                                                    className={`d-flex align-items-center ${
                                                        row.original.showActions &&
                                                        !row.original.disableReboot &&
                                                        !row.original?.[cell.column.disableReboot]
                                                            ? ''
                                                            : 'text-muted'
                                                    }`}
                                                >
                                                    Reboot
                                                </span>
                                            </div>
                                        </DropdownItem>
                                    )}
                                />
                            )}
                            {cell?.column?.lock && (
                                <IsAuthorized
                                    privRequired={editor}
                                    yes={() => (
                                        <DropdownItem
                                            className={`dropdown-item ${
                                                row.original.showActions && !row.original.disableLock ? 'cursor-pointer' : 'cursor-default'
                                            }`}
                                            onClick={() =>
                                                row.original.showActions && !row.original.disableLock
                                                    ? props.handleDeviceLock(row, cell, index)
                                                    : undefined
                                            }
                                        >
                                            <div className="d-flex justify-content-start align-items-center">
                                                <i
                                                    className={`ri-phone-lock-line me-2 fs-18 ${
                                                        row.original.showActions && !row.original.disableLock
                                                            ? ' link-secondary'
                                                            : 'text-muted'
                                                    }`}
                                                />
                                                <span
                                                    className={`d-flex align-items-center ${
                                                        row.original.showActions && !row.original.disableLock ? '' : 'text-muted'
                                                    }`}
                                                >
                                                    Lock
                                                </span>
                                            </div>
                                        </DropdownItem>
                                    )}
                                />
                            )}

                            {cell?.column?.map && (
                                <IsAuthorized
                                    privRequired={editor}
                                    yes={() => (
                                        <DropdownItem
                                            className={`dropdown-item ${row.original.hideMapView ? ' cursor-pointer' : 'cursor-default'}`}
                                            onClick={row.original.hideMapView ? undefined : () => props.handleMap(row, cell, index)}
                                        >
                                            <div className="d-flex justify-content-start align-items-center">
                                                <i
                                                    className={`ri-road-map-line me-2 fs-18 ${
                                                        row.original.hideMapView ? 'text-muted' : ' link-secondary'
                                                    }`}
                                                />
                                                <span
                                                    className={`d-flex align-items-center ${row.original.hideMapView ? 'text-muted' : ''}`}
                                                >
                                                    See Device on Map
                                                </span>
                                            </div>
                                        </DropdownItem>
                                    )}
                                />
                            )}
                            {cell?.column?.resetpassword && (
                                <IsAuthorized
                                    privRequired={editor}
                                    yes={() => (
                                        <DropdownItem
                                            className={`dropdown-item ${
                                                row.original.showActions && !row.original.disableResetPassword
                                                    ? 'cursor-pointer'
                                                    : 'cursor-default '
                                            }`}
                                            onClick={() =>
                                                row.original.showActions && !row.original.disableResetPassword
                                                    ? props.handleResetPassword(row, cell, index)
                                                    : undefined
                                            }
                                        >
                                            <div className="d-flex justify-content-start align-items-center">
                                                <i
                                                    className={`ri-lock-password-line me-2 fs-18 ${
                                                        row.original.showActions && !row.original.disableResetPassword
                                                            ? 'link-secondary'
                                                            : 'text-muted'
                                                    }`}
                                                />
                                                <span
                                                    className={`d-flex align-items-center ${
                                                        row.original.showActions && !row.original.disableResetPassword ? '' : 'text-muted'
                                                    }`}
                                                >
                                                    Reset Password
                                                </span>
                                            </div>
                                        </DropdownItem>
                                    )}
                                />
                            )}
                            {cell?.column?.scream && (
                                <IsAuthorized
                                    privRequired={editor}
                                    yes={() => (
                                        <DropdownItem
                                            className={`dropdown-item ${
                                                row.original.showActions && !row.original.disableScream
                                                    ? 'cursor-pointer'
                                                    : 'cursor-default'
                                            }`}
                                            onClick={() =>
                                                row.original.showActions && !row.original.disableScream
                                                    ? props.handleScream(row, cell, index)
                                                    : undefined
                                            }
                                        >
                                            <div className="d-flex justify-content-start align-items-center">
                                                <i
                                                    className={`me-2 fs-18  ri-volume-up-line ${
                                                        row.original.showActions && !row.original.disableScream
                                                            ? ' link-secondary'
                                                            : 'text-muted'
                                                    } `}
                                                />
                                                <span
                                                    className={`d-flex align-items-center ${
                                                        row.original.showActions && !row.original.disableScream ? '' : 'text-muted'
                                                    }`}
                                                >
                                                    Scream
                                                </span>
                                            </div>
                                        </DropdownItem>
                                    )}
                                />
                            )}
                            {cell?.column?.lostMode && (
                                <IsAuthorized
                                    privRequired={editor}
                                    yes={() => (
                                        <DropdownItem
                                            className={`dropdown-item ${!row.original.hideLostMode ? 'cursor-pointer' : 'cursor-default'} `}
                                            onClick={() =>
                                                !row.original.hideLostMode ? props.handleLostMode(row, cell, index) : undefined
                                            }
                                        >
                                            <div className="d-flex justify-content-start align-items-center">
                                                <i
                                                    className={`ri-door-lock-line me-2 fs-18 ${
                                                        !row.original.hideLostMode ? 'cursor-pointer link-secondary' : 'text-muted'
                                                    }`}
                                                />
                                                <span
                                                    className={`d-flex align-items-center ${
                                                        !row.original.hideLostMode ? '' : 'text-muted'
                                                    }`}
                                                >
                                                    Lost Mode
                                                </span>
                                            </div>
                                        </DropdownItem>
                                    )}
                                />
                            )}
                            {cell?.column?.factoryreset && (
                                <IsAuthorized
                                    privRequired={editor}
                                    yes={() => (
                                        <DropdownItem
                                            className={`dropdown-item ${
                                                !row.original.hideFactoryReset ? 'cursor-pointer' : 'cursor-default'
                                            }`}
                                            onClick={() =>
                                                !row.original.hideFactoryReset ? props.handleFactoryReset(row, cell, index) : undefined
                                            }
                                        >
                                            <div className="d-flex justify-content-start align-items-center">
                                                <i
                                                    className={`ri-settings-5-line me-2 fs-18 ${
                                                        !row.original.hideFactoryReset ? ' link-secondary' : 'text-muted'
                                                    }`}
                                                />
                                                <span
                                                    className={`d-flex align-items-center ${
                                                        !row.original.hideFactoryReset ? '' : 'text-muted'
                                                    }`}
                                                >
                                                    Factory Reset
                                                </span>
                                            </div>
                                        </DropdownItem>
                                    )}
                                />
                            )}
                            {cell.column.retiree && (
                                <IsAuthorized
                                    privRequired={editor}
                                    yes={() => (
                                        <DropdownItem
                                            className={`dropdown-item ${
                                                row?.original?.deviceStatusApplied === 'DELETED' ? 'cursor-pointer' : 'cursor-default'
                                            }`}
                                            onClick={() =>
                                                row?.original?.deviceStatusApplied === 'DELETED' && props.handleRetire(row, cell, index)
                                            }
                                        >
                                            <div className="d-flex justify-content-start align-items-center">
                                                <i
                                                    className={`ri-delete-bin-line me-2 fs-18 ${
                                                        row?.original?.deviceStatusApplied === 'DELETED' ? '  link-secondary' : 'text-muted'
                                                    }`}
                                                />
                                                <span
                                                    className={`d-flex align-items-center ${
                                                        row?.original?.deviceStatusApplied === 'DELETED' ? '' : 'text-muted'
                                                    }`}
                                                >
                                                    Retire
                                                </span>
                                            </div>
                                        </DropdownItem>
                                    )}
                                />
                            )}
                        </DropdownMenu>
                    </UncontrolledDropdown>
                );
                break;
            case 'actions':
                body = row.original.hideActions ? (
                    <>
                        {cell.column.onHideAction ? (
                            <span className={`lh-sm badge badge-soft-${row.original[cell.column.onHideClass]}`}>
                                {row.original[cell.column.onHideAction]}
                            </span>
                        ) : (
                            '—'
                        )}
                    </>
                ) : (
                    <>
                        {row.original.actionsBadge ? (
                            <span className={'lh-sm badge badge-soft-primary'}>{row.original.actionsBadgeTitle}</span>
                        ) : (
                            (cell.column.hideActions ? !row.original[cell.column.actionsHideKey] : true) && (
                                <div className="d-flex align-items-center">
                                    {row.original.rowAdd && cell?.column?.rowAdd && (
                                        <IsAuthorized
                                            privRequired={editor}
                                            yes={() => (
                                                <>
                                                    <i
                                                        id={`add-${index}`}
                                                        className="ri-add-box-line me-2 fs-18 cursor-pointer link-secondary"
                                                        onClick={() => props.handleCreate(row, cell, index)}
                                                    />
                                                    {Tooltip(`add-${index}`, 'Add')}
                                                </>
                                            )}
                                        />
                                    )}
                                    {cell?.column?.assign && (
                                        <IsAuthorized
                                            privRequired={editor}
                                            yes={() => (
                                                <>
                                                    <i
                                                        id={`assign-${index}`}
                                                        className="ri-folder-add-line me-2 fs-18 cursor-pointer link-secondary"
                                                        onClick={() => props.handleAssign(row)}
                                                    />
                                                    {Tooltip(`assign-${index}`, 'Assign Groups')}
                                                </>
                                            )}
                                        />
                                    )}
                                    {cell?.column?.clearData && (
                                        <IsAuthorized
                                            privRequired={editor}
                                            yes={() => (
                                                <>
                                                    <i
                                                        id={`clearData-${index}`}
                                                        className="ri-line-chart-line me-2 fs-18 cursor-pointer link-secondary"
                                                        onClick={() => props.handleClearData(row)}
                                                    />
                                                    {Tooltip(`clearData-${index}`, 'Clear Data')}
                                                </>
                                            )}
                                        />
                                    )}
                                    {cell.column.clearCache && (
                                        <IsAuthorized
                                            privRequired={editor}
                                            yes={() => (
                                                <>
                                                    <span className="link-primary me-1 fs-15 ">
                                                        <i
                                                            id={`clearCache-${index}`}
                                                            className="ri-eraser-fill me-2 fs-18 cursor-pointer link-secondary"
                                                            onClick={() => props.handleClearCache(row)}
                                                        />
                                                        {Tooltip(`clearCache-${index}`, 'Clear Cache')}
                                                    </span>
                                                </>
                                            )}
                                        />
                                    )}
                                    {row.original.rowEdit &&
                                        cell?.column?.rowEdit &&
                                        (props.groupAccess ? (
                                            <IsAuthorizedCondition
                                                key={props.accessingKey}
                                                value={props.accessingValue}
                                                yes={() => (
                                                    <>
                                                        <i
                                                            id={`edit-${index}`}
                                                            className="ri-edit-box-line me-2 fs-18 cursor-pointer link-secondary"
                                                            onClick={() => props.handleEdit(row, cell, index)}
                                                        />
                                                        {Tooltip(`edit-${index}`, 'Edit')}
                                                    </>
                                                )}
                                            />
                                        ) : (
                                            <IsAuthorized
                                                privRequired={editor}
                                                yes={() => (
                                                    <>
                                                        <i
                                                            id={`edit-${index}`}
                                                            className="ri-edit-box-line me-2 fs-18 cursor-pointer link-secondary"
                                                            onClick={() => props.handleEdit(row, cell, index)}
                                                        />
                                                        {Tooltip(`edit-${index}`, 'Edit')}
                                                    </>
                                                )}
                                            />
                                        ))}
                                    {cell?.column?.view && (
                                        <IsAuthorized
                                            privRequired={reader}
                                            yes={() => (
                                                <>
                                                    <i
                                                        id={`view-${index}`}
                                                        className="ri-eye-line me-2 fs-18 cursor-pointer link-secondary"
                                                        onClick={() => props.handleView(row, cell, index)}
                                                    />
                                                    {Tooltip(`view-${index}`, 'View')}
                                                </>
                                            )}
                                        />
                                    )}
                                    {cell?.column?.devicemove && (
                                        <IsAuthorized
                                            privRequired={editor}
                                            yes={() => (
                                                <>
                                                    <i
                                                        id={`devicemove-${index}`}
                                                        className="ri-folder-transfer-line me-2 fs-18 cursor-pointer link-secondary"
                                                        onClick={() => props.handleMove(row, cell, index)}
                                                    />
                                                    {Tooltip(`devicemove-${index}`, 'Move')}
                                                </>
                                            )}
                                        />
                                    )}
                                    {cell?.column?.notes && (
                                        <IsAuthorized
                                            privRequired={editor}
                                            yes={() => (
                                                <>
                                                    <i
                                                        id={`notes-${index}`}
                                                        className="ri-draft-line me-2 fs-18 cursor-pointer link-secondary"
                                                        onClick={() => props.handleNotes(row, cell, index)}
                                                    />
                                                    {Tooltip(`notes-${index}`, 'Notes')}
                                                </>
                                            )}
                                        />
                                    )}
                                    {cell.column?.power && (
                                        <IsAuthorized
                                            privRequired={editor}
                                            yes={() => (
                                                <>
                                                    <i
                                                        id={`power-${index}`}
                                                        className={`ri-shut-down-line me-2 fs-18 ${
                                                            row.original.showActions ? 'cursor-pointer link-secondary' : 'text-muted'
                                                        }`}
                                                        onClick={() => (row.original.showActions ? props.handlePower(row) : undefined)}
                                                    />
                                                    {Tooltip(`power-${index}`, 'Shutdown')}
                                                </>
                                            )}
                                        />
                                    )}
                                    {cell?.column?.reboot && (
                                        <IsAuthorized
                                            privRequired={editor}
                                            yes={() => (
                                                <>
                                                    <i
                                                        id={`reboot-${index}`}
                                                        className={`ri-creative-commons-sa-line me-2 fs-18 ${
                                                            row.original.showActions &&
                                                            !row.original.disableReboot &&
                                                            !row.original?.[cell.column.disableReboot]
                                                                ? 'cursor-pointer link-secondary'
                                                                : 'text-muted'
                                                        }`}
                                                        onClick={() =>
                                                            row.original.showActions &&
                                                            !row.original.disableReboot &&
                                                            !row.original?.[cell.column.disableReboot]
                                                                ? props.handleDeviceReboot(row, cell, index)
                                                                : undefined
                                                        }
                                                    />
                                                    {Tooltip(
                                                        `reboot-${index}`,
                                                        row.original?.[cell.column.disableReboot] ? cell.column.rebootTooltip : 'Reboot'
                                                    )}
                                                </>
                                            )}
                                        />
                                    )}
                                    {cell?.column?.shutdown && (
                                        <IsAuthorized
                                            privRequired={editor}
                                            yes={() => (
                                                <>
                                                    <i
                                                        id={`shutdown-${index}`}
                                                        className={`ri-shut-down-line me-2 fs-18 ${
                                                            row.original.showActions &&
                                                            !row.original.disableShutdown &&
                                                            !row.original?.[cell.column.disableShutdown]
                                                                ? 'cursor-pointer link-secondary'
                                                                : 'text-muted'
                                                        }`}
                                                        onClick={() =>
                                                            row.original.showActions &&
                                                            !row.original.disableShutdown &&
                                                            !row.original?.[cell.column.disableShutdown]
                                                                ? props.handleDeviceShutdown(row, cell, index)
                                                                : undefined
                                                        }
                                                    />
                                                    {Tooltip(
                                                        `shutdown-${index}`,
                                                        row.original?.[cell.column.disableShutdown]
                                                            ? cell.column.shutdownTooltip
                                                            : 'Shutdown'
                                                    )}
                                                </>
                                            )}
                                        />
                                    )}
                                    {cell?.column?.lock && (
                                        <IsAuthorized
                                            privRequired={editor}
                                            yes={() => (
                                                <>
                                                    <i
                                                        id={`lock-${index}`}
                                                        className={`ri-phone-lock-line me-2 fs-18 ${
                                                            row.original.showActions && !row.original.disableLock
                                                                ? 'cursor-pointer link-secondary'
                                                                : 'text-muted'
                                                        }`}
                                                        onClick={() =>
                                                            row.original.showActions && !row.original.disableLock
                                                                ? props.handleDeviceLock(row, cell, index)
                                                                : undefined
                                                        }
                                                    />
                                                    {Tooltip(`lock-${index}`, 'Lock')}
                                                </>
                                            )}
                                        />
                                    )}
                                    {cell?.column?.lockDevice && (
                                        <IsAuthorized
                                            privRequired={editor}
                                            yes={() => (
                                                <>
                                                    <i
                                                        id={`lock-${index}`}
                                                        className={`ri-phone-lock-line me-2 fs-18 ${
                                                            !row.original.disableLock ? 'cursor-pointer link-secondary' : 'text-muted'
                                                        }`}
                                                        onClick={() =>
                                                            !row.original.disableLock ? props.handleDeviceLock(row, cell, index) : undefined
                                                        }
                                                    />
                                                    {Tooltip(
                                                        `lock-${index}`,
                                                        row.original?.[cell.column.disableLock] ? cell.column.lockTooltip : 'Lock'
                                                    )}
                                                </>
                                            )}
                                        />
                                    )}
                                    {cell?.column?.map && (
                                        <IsAuthorized
                                            privRequired={reader}
                                            yes={() => (
                                                <>
                                                    <i
                                                        id={`mapView-${index}`}
                                                        className={`ri-road-map-line me-2 fs-18 ${
                                                            row.original.hideMapView ? 'text-muted' : 'cursor-pointer link-secondary'
                                                        }`}
                                                        onClick={
                                                            row.original.hideMapView ? undefined : () => props.handleMap(row, cell, index)
                                                        }
                                                    />
                                                    {Tooltip(`mapView-${index}`, 'See Device on Map')}
                                                </>
                                            )}
                                        />
                                    )}
                                    {cell?.column?.resetpassword && (
                                        <IsAuthorized
                                            privRequired={editor}
                                            yes={() => (
                                                <>
                                                    <i
                                                        id={`resetPassword-${index}`}
                                                        className={`ri-lock-password-line me-2 fs-18 ${
                                                            row.original.showActions && !row.original.disableResetPassword
                                                                ? 'cursor-pointer link-secondary'
                                                                : 'text-muted'
                                                        }`}
                                                        onClick={() =>
                                                            row.original.showActions && !row.original.disableResetPassword
                                                                ? props.handleResetPassword(row, cell, index)
                                                                : undefined
                                                        }
                                                    />
                                                    {Tooltip(`resetPassword-${index}`, 'Reset Password')}
                                                </>
                                            )}
                                        />
                                    )}
                                    {cell?.column?.scream && (
                                        <IsAuthorized
                                            privRequired={reader}
                                            yes={() => (
                                                <>
                                                    <i
                                                        id={`scream-${index}`}
                                                        className={`me-2 fs-18 ${
                                                            row.original.showActions && !row.original.disableScream
                                                                ? 'cursor-pointer link-secondary'
                                                                : 'text-muted'
                                                        } ${
                                                            // row.original.screamerOn ? ' ri-alarm-warning-fill mask' :
                                                            ' ri-volume-up-line'
                                                        }`}
                                                        onClick={() =>
                                                            row.original.showActions && !row.original.disableScream
                                                                ? props.handleScream(row, cell, index)
                                                                : undefined
                                                        }
                                                    />
                                                    {Tooltip(`scream-${index}`, 'Scream')}
                                                </>
                                            )}
                                        />
                                    )}
                                    {cell?.column?.lostMode && (
                                        <IsAuthorized
                                            privRequired={editor}
                                            yes={() => (
                                                <>
                                                    <i
                                                        id={`lostMode-${index}`}
                                                        className={`ri-door-lock-line me-2 fs-18 ${
                                                            !row.original.hideLostMode ? 'cursor-pointer link-secondary' : 'text-muted'
                                                        }`}
                                                        onClick={() =>
                                                            !row.original.hideLostMode ? props.handleLostMode(row, cell, index) : undefined
                                                        }
                                                    />
                                                    {Tooltip(
                                                        `lostMode-${index}`,
                                                        'Lost Mode : A device must be company-owned; work profile is supported on Android 13 and above, and fully managed devices on Android 11 and above.'
                                                    )}
                                                </>
                                            )}
                                        />
                                    )}
                                    {cell?.column?.factoryreset && (
                                        <IsAuthorized
                                            privRequired={editor}
                                            yes={() => (
                                                <>
                                                    <i
                                                        id={`factoryReset-${index}`}
                                                        className={`ri-settings-5-line me-2 fs-18 ${
                                                            !row.original.hideFactoryReset ? 'cursor-pointer link-secondary' : 'text-muted'
                                                        }`}
                                                        onClick={() =>
                                                            !row.original.hideFactoryReset
                                                                ? props.handleFactoryReset(row, cell, index)
                                                                : undefined
                                                        }
                                                    />
                                                    {Tooltip(
                                                        `factoryReset-${index}`,
                                                        cell.column.factoryResetTooltip ? cell.column.factoryResetTooltip : 'Factory Reset'
                                                    )}
                                                </>
                                            )}
                                        />
                                    )}
                                    {cell?.column?.deprovision && (
                                        <IsAuthorized
                                            privRequired={editor}
                                            yes={() => (
                                                <>
                                                    <i
                                                        id={`deprovision-${index}`}
                                                        className={`ri-link-unlink-m me-2 fs-18 ${
                                                            !row.original.hidedeoprovision ? 'cursor-pointer link-secondary' : 'text-muted'
                                                        }`}
                                                        onClick={() =>
                                                            !row.original.hidedeoprovision
                                                                ? props.handleDeprovision(row, cell, index)
                                                                : undefined
                                                        }
                                                    />
                                                    {Tooltip(
                                                        `deprovision-${index}`,
                                                        cell?.column?.deprovisionTooltip ? cell?.column?.deprovisionTooltip : 'Deprovision'
                                                    )}
                                                </>
                                            )}
                                        />
                                    )}
                                    {cell?.column?.edit && (
                                        <IsAuthorized
                                            privRequired={editor}
                                            yes={() => (
                                                <>
                                                    <i
                                                        id={`edit-${index}`}
                                                        className="ri-edit-box-line me-2 fs-18 cursor-pointer link-secondary"
                                                        onClick={() => props.handleEdit(row, cell, index)}
                                                    />
                                                    {Tooltip(`edit-${index}`, 'Edit')}
                                                </>
                                            )}
                                        />
                                    )}
                                    {cell?.column?.editFadable && (
                                        <IsAuthorized
                                            privRequired={editor}
                                            yes={() => (
                                                <>
                                                    <i
                                                        id={`editFadable-${index}`}
                                                        className={`ri-edit-box-line me-1 fs-18 ${
                                                            row.original[cell.column.fadeEdit]
                                                                ? 'text-muted'
                                                                : ' cursor-pointer link-secondary'
                                                        }`}
                                                        onClick={() => {
                                                            if (!row.original[cell.column.fadeEdit]) props.handleEdit(row, cell, index);
                                                        }}
                                                    />
                                                    {Tooltip(
                                                        `editFadable-${index}`,
                                                        row.original[cell.column.fadeEdit]
                                                            ? cell.column.fadeTooltipKeyEdit
                                                                ? row.original?.[cell.column?.fadeTooltipKeyEdit]
                                                                : cell.column.fadeTooltip
                                                                ? cell.column.fadeTooltip
                                                                : 'Edit'
                                                            : 'Edit'
                                                    )}
                                                </>
                                            )}
                                        />
                                    )}
                                    {row.original?.editFadableConditionally && cell?.column?.editFadableConditionally ? (
                                        <IsAuthorized
                                            privRequired={editor}
                                            yes={() => (
                                                <>
                                                    <i
                                                        id={`editFadable-${index}`}
                                                        className={`ri-edit-box-line me-1 fs-18 ${
                                                            row.original[cell.column.fadeEdit]
                                                                ? 'text-muted'
                                                                : ' cursor-pointer link-secondary'
                                                        }`}
                                                        onClick={() => {
                                                            if (!row.original[cell.column.fadeEdit]) props.handleEdit(row, cell, index);
                                                        }}
                                                    />
                                                    {Tooltip(
                                                        `editFadable-${index}`,
                                                        row.original[cell.column.fadeEdit]
                                                            ? cell.column.fadeTooltipKeyEdit
                                                                ? row.original?.[cell.column?.fadeTooltipKeyEdit]
                                                                : cell.column.fadeTooltip
                                                                ? cell.column.fadeTooltip
                                                                : 'Edit'
                                                            : 'Edit'
                                                    )}
                                                </>
                                            )}
                                        />
                                    ) : (
                                        ''
                                    )}
                                    {cell?.column?.enrollmentToken && !row.original.hideToken && (
                                        <IsAuthorized
                                            privRequired={editor}
                                            yes={() => (
                                                <>
                                                    <img
                                                        alt=""
                                                        src={EnrollmentToken}
                                                        id={`enrollmentToken-${index}`}
                                                        className="me-2 cursor-pointer height-18"
                                                        onClick={() => props.handleEnrollmentToken(row, cell, index)}
                                                    />
                                                    {Tooltip(`enrollmentToken-${index}`, 'Enrollment Token')}
                                                </>
                                            )}
                                        />
                                    )}
                                    {cell?.column?.enrollmentConfig && !row.original.hideConfig && (
                                        <IsAuthorized
                                            privRequired={editor}
                                            yes={() => (
                                                <>
                                                    <img
                                                        alt=""
                                                        src={EnrollmentToken}
                                                        id={`enrollmentConfig-${index}`}
                                                        className="me-2 cursor-pointer height-18"
                                                        onClick={() => props.handleEnrollmentConfig(row, cell, index)}
                                                    />
                                                    {Tooltip(`enrollmentConfig-${index}`, 'Enrollment Config')}
                                                </>
                                            )}
                                        />
                                    )}
                                    {cell?.column?.assignment && (
                                        <IsAuthorized
                                            privRequired={editor}
                                            yes={() => (
                                                <>
                                                    <i
                                                        id={`assignment-${index}`}
                                                        className="ri-share-forward-line me-2 fs-18 cursor-pointer link-secondary"
                                                        onClick={() => props.handleAssign(row, cell, index)}
                                                    />
                                                    {Tooltip(`assignment-${index}`, 'Assign')}
                                                </>
                                            )}
                                        />
                                    )}
                                    {cell?.column?.qrCode && !row.original.hideQR && (
                                        <IsAuthorized
                                            privRequired={editor}
                                            yes={() => (
                                                <>
                                                    <i
                                                        id={`qrcode-${index}`}
                                                        className={`ri-qr-code-line me-2 fs-18 ${
                                                            row.original?.[cell.column.disableQr]
                                                                ? 'text-muted'
                                                                : 'cursor-pointer link-secondary'
                                                        }`}
                                                        onClick={() =>
                                                            row.original?.[cell.column.disableQr]
                                                                ? undefined
                                                                : props.handleQR(row, cell, index)
                                                        }
                                                    />
                                                    {Tooltip(
                                                        `qrcode-${index}`,
                                                        row.original?.[cell.column.disableQr] ? cell.column.disableQRTooltip : 'QR Code'
                                                    )}
                                                </>
                                            )}
                                        />
                                    )}
                                    {cell?.column?.link && (
                                        <IsAuthorized
                                            privRequired={editor}
                                            yes={() => (
                                                <>
                                                    <i
                                                        id={`link-${index}`}
                                                        className="ri-links-line me-2 fs-18 cursor-pointer link-secondary"
                                                        onClick={() => props.handleLink(row, cell, index)}
                                                    />
                                                    {Tooltip(`link-${index}`, 'Enrollment Link')}
                                                </>
                                            )}
                                        />
                                    )}
                                    {cell?.column?.clone && (
                                        <IsAuthorized
                                            privRequired={editor}
                                            yes={() => (
                                                <>
                                                    <i
                                                        id={`clone-${index}`}
                                                        className="ri-file-copy-2-line me-2 fs-18 cursor-pointer link-secondary"
                                                        onClick={() => props.handleClone(row, cell, index)}
                                                    />
                                                    {Tooltip(`clone-${index}`, 'Clone')}
                                                </>
                                            )}
                                        />
                                    )}
                                    {cell?.column?.clone1 && (
                                        <IsAuthorized
                                            privRequired={editor}
                                            yes={() => (
                                                <>
                                                    <i
                                                        id={`clone-${index}`}
                                                        className="ri-file-copy-line me-2 fs-18 cursor-pointer link-secondary"
                                                        onClick={() => props.handleClone(row, cell, index)}
                                                    />
                                                    {Tooltip(`clone-${index}`, 'Clone')}
                                                </>
                                            )}
                                        />
                                    )}
                                    {cell?.column?.urlDownload && (
                                        <IsAuthorized
                                            privRequired={reader}
                                            yes={() => (
                                                <>
                                                    <i
                                                        id={`urlExport-${index}`}
                                                        className="ri-file-upload-line link-secondary fs-18 cursor-pointer me-2"
                                                        onClick={() => props.handleDownloadFirewall(row)}
                                                    />
                                                    {Tooltip(`urlExport-${index}`, 'Export as CSV File')}
                                                </>
                                            )}
                                        />
                                    )}
                                    {cell.column.rename && !row.original.hideRename && (
                                        <IsAuthorized
                                            privRequired={editor}
                                            yes={() => (
                                                <>
                                                    <i
                                                        id={`rename-${index}`}
                                                        onClick={() => props.handleRename(row, cell, index)}
                                                        className="ri-edit-line me-2 fs-18 cursor-pointer link-secondary"
                                                    />
                                                    {Tooltip(`rename-${index}`, 'Rename')}
                                                </>
                                            )}
                                        />
                                    )}
                                    {cell.column.move && !row.original.hideMove && (
                                        <IsAuthorized
                                            privRequired={props.type === 'adminAccess' ? props.adminAccess : editor}
                                            yes={() => (
                                                <>
                                                    <i
                                                        id={`move-${index}`}
                                                        onClick={() => props.handleMove(row, cell, index)}
                                                        className={`ri-folder-transfer-line me-2 fs-18 cursor-pointer ${
                                                            row.original.delete ? 'link-danger' : 'link-secondary'
                                                        }`}
                                                    />
                                                    {Tooltip(`move-${index}`, cell.column.moveTooltip)}
                                                </>
                                            )}
                                        />
                                    )}
                                    {cell?.column?.auditlog && (
                                        <IsAuthorized
                                            privRequired={editor}
                                            yes={() => (
                                                <>
                                                    <i
                                                        id={`audit-${index}`}
                                                        className="ri-bar-chart-box-line me-2 fs-18 cursor-pointer link-secondary"
                                                        onClick={() => props.handleAuditLog(row, cell, index)}
                                                    />
                                                    {Tooltip(`audit-${index}`, 'Audit')}
                                                </>
                                            )}
                                        />
                                    )}
                                    {cell.column.treeView && (
                                        <>
                                            <i
                                                className="ri-node-tree link-secondary cursor-pointer fs-18 me-2"
                                                id={`tree-${index}`}
                                                onClick={() => props.handleTreeView(row, cell)}
                                            />
                                            {Tooltip(`tree-${index}`, 'Tree View')}
                                        </>
                                    )}
                                    {cell.column.deleteOnCount && !row.original.hideDelete && (
                                        <IsAuthorized
                                            privRequired={props.type === 'adminAccess' ? props.adminAccess : editor}
                                            yes={() => (
                                                <>
                                                    <i
                                                        id={`delete-${index}`}
                                                        onClick={() => props.handleDelete(row, cell, index)}
                                                        className={`ri-delete-bin-line me-2 fs-18 cursor-pointer ${
                                                            row.original.delete ? 'link-danger' : 'link-secondary'
                                                        }`}
                                                    />
                                                    {Tooltip(`delete-${index}`, 'Delete')}
                                                </>
                                            )}
                                        />
                                    )}
                                    {cell?.column?.config && (
                                        <IsAuthorized
                                            privRequired={editor}
                                            yes={() => (
                                                <>
                                                    <i
                                                        id={`config-${index}`}
                                                        className={`ri-file-settings-line me-1 fs-18 ${
                                                            row.original[cell.column.configFade]
                                                                ? 'text-muted'
                                                                : ' cursor-pointer link-secondary'
                                                        }`}
                                                        onClick={() => props.handleIconView(row, cell)}
                                                    />
                                                    {Tooltip(
                                                        `config-${index}`,
                                                        row.original[cell.column.configFade]
                                                            ? cell.column.configTooltip
                                                            : 'Add Managed Configuration'
                                                    )}
                                                </>
                                            )}
                                        />
                                    )}
                                    {cell?.column?.refresh && (
                                        <IsAuthorized
                                            privRequired={editor}
                                            yes={() => (
                                                <>
                                                    <i
                                                        id={`refresh-${index}`}
                                                        className={`ri-compass-discover-line me-2 fs-18 ${
                                                            row.original[cell.column.noUpdate]
                                                                ? 'text-muted'
                                                                : ' cursor-pointer link-secondary'
                                                        }`}
                                                        onClick={() => {
                                                            if (!row.original[cell.column.noUpdate]) props.handleRefresh(row, cell, index);
                                                        }}
                                                    />
                                                    {Tooltip(
                                                        `refresh-${index}`,
                                                        row.original[cell.column.noUpdate] ? 'No Apps Installed' : 'Update'
                                                    )}
                                                </>
                                            )}
                                        />
                                    )}
                                    {cell?.column?.sync && (
                                        <IsAuthorized
                                            privRequired={reader}
                                            yes={() => (
                                                <>
                                                    <i
                                                        id={`sync-${index}`}
                                                        className={`ri-refresh-line fs-18 me-1 ${
                                                            row.original[cell.column.disableSync]
                                                                ? 'link-muted'
                                                                : 'link-secondary cursor-pointer'
                                                        }`}
                                                        onClick={() => {
                                                            if (!row.original[cell.column.disableSync]) props.handleSync(row);
                                                        }}
                                                    />
                                                    {Tooltip(`sync-${index}`, 'Force Sync')}
                                                </>
                                            )}
                                        />
                                    )}
                                    {cell?.column?.deleteFadable && (
                                        <IsAuthorized
                                            privRequired={editor}
                                            yes={() => (
                                                <>
                                                    <i
                                                        id={`deleteFadable-${index}`}
                                                        className={`ri-delete-bin-line me-1 fs-18 ${
                                                            row.original[cell.column.fade] ? 'text-muted' : ' cursor-pointer link-secondary'
                                                        }`}
                                                        onClick={() => {
                                                            if (!row.original[cell.column.fade]) props.handleDelete(row, cell, index);
                                                        }}
                                                    />
                                                    {Tooltip(
                                                        `deleteFadable-${index}`,
                                                        row.original[cell.column.fade]
                                                            ? cell.column.fadeTooltip
                                                                ? cell.column.fadeTooltip
                                                                : cell.column.fadeTooltipKeyDelete
                                                                ? row.original?.[cell.column?.fadeTooltipKeyDelete]
                                                                : 'Delete'
                                                            : 'Delete'
                                                    )}
                                                </>
                                            )}
                                        />
                                    )}
                                    {cell?.column?.share && (
                                        <IsAuthorized
                                            privRequired={editor}
                                            yes={() => (
                                                <>
                                                    <i
                                                        id={`share-${index}`}
                                                        className="ri-share-line fs-18 me-1 cursor-pointer link-secondary"
                                                        onClick={() => props.handleShare(row)}
                                                    />
                                                    {Tooltip(`share-${index}`, 'Share')}
                                                </>
                                            )}
                                        />
                                    )}
                                    {cell?.column?.delete && (
                                        <IsAuthorized
                                            privRequired={editor}
                                            yes={() => (
                                                <>
                                                    <i
                                                        id={`delete-${index}`}
                                                        className="ri-delete-bin-line fs-18 cursor-pointer link-secondary"
                                                        onClick={() => props.handleDelete(row, cell, index)}
                                                    />
                                                    {Tooltip(`delete-${index}`, 'Delete')}
                                                </>
                                            )}
                                        />
                                    )}
                                    {cell?.column?.retire && (
                                        <IsAuthorized
                                            privRequired={editor}
                                            yes={() => (
                                                <>
                                                    <i
                                                        id={`delete-${index}`}
                                                        className={`ri-delete-bin-line fs-18 ${
                                                            row?.original?.deviceStatusApplied === 'DELETED'
                                                                ? ' cursor-pointer link-secondary'
                                                                : 'text-muted'
                                                        }`}
                                                        onClick={() =>
                                                            row?.original?.deviceStatusApplied === 'DELETED'
                                                                ? props.handleRetire(row, cell, index)
                                                                : undefined
                                                        }
                                                    />
                                                    {Tooltip(`delete-${index}`, 'Retire')}
                                                </>
                                            )}
                                        />
                                    )}
                                    {row.original.rowDelete && cell?.column?.rowDelete && (
                                        <IsAuthorized
                                            privRequired={editor}
                                            yes={() => (
                                                <>
                                                    <i
                                                        id={`delete-${index}`}
                                                        className="ri-delete-bin-line me-2 fs-18 cursor-pointer link-secondary"
                                                        onClick={() => props.handleDelete(row, cell, index)}
                                                    />
                                                    {Tooltip(`delete-${index}`, 'Delete')}
                                                </>
                                            )}
                                        />
                                    )}
                                    {cell?.column?.download && (
                                        <IsAuthorized
                                            privRequired={reader}
                                            yes={() => (
                                                <>
                                                    <i
                                                        id={`downloadbulk-${index}`}
                                                        className="ri-file-upload-line me-2 fs-18 cursor-pointer link-secondary"
                                                        onClick={() => props.handleBulkDownload(row, cell, index)}
                                                    />
                                                    {Tooltip(
                                                        `downloadbulk-${index}`,
                                                        cell.column.downloadTooltip ? cell.column.downloadTooltip : 'Export'
                                                    )}
                                                </>
                                            )}
                                        />
                                    )}
                                    {cell?.column?.shareWithText && (
                                        <IsAuthorized
                                            privRequired={editor}
                                            yes={() => (
                                                <span
                                                    className="d-flex align-items-center cursor-pointer"
                                                    onClick={() => props.handleShare(row, cell, index)}
                                                >
                                                    <span className="text-secondary fs-14 fw-semibold me-2">
                                                        {cell.render('Cell')} {cell.column.text ? cell.column.text : ''}
                                                    </span>
                                                    <i id={`share-${index}`} className="ri-share-fill me-2 fs-18 link-secondary" />
                                                    {Tooltip(`share-${index}`, 'Share')}
                                                </span>
                                            )}
                                        />
                                    )}
                                    {row.original.recall && cell?.column?.recall && (
                                        <IsAuthorized
                                            privRequired={editor}
                                            yes={() => (
                                                <>
                                                    <i
                                                        id={`Recall-${index}`}
                                                        className="ri-repeat-line me-2 fs-18 cursor-pointer link-secondary"
                                                        onClick={() => props.handleRecall(row, cell, index)}
                                                    />
                                                    {Tooltip(`Recall-${index}`, 'Recall')}
                                                </>
                                            )}
                                        />
                                    )}
                                </div>
                            )
                        )}
                    </>
                );
                break;
            case 'takeAction':
                body = (
                    <div>
                        {row.original?.[cell.column.hideTakeAction] ? (
                            <></>
                        ) : (
                            <Button
                                size="sm"
                                type="button"
                                id="takeAction"
                                color={cell.column.takeActionClass ? cell.column.takeActionClass : 'success'}
                                onClick={() => props.handleTakeAction(row, cell, index)}
                            >
                                <span className="d-flex align-items-center">
                                    {row.original.takeActionText ? row.original.takeActionText : 'Take Action'}
                                </span>
                            </Button>
                        )}
                    </div>
                );
                break;
            case 'statusDots':
                body = (
                    <div className="d-flex align-items-center justify-content-center fw-medium">
                        <i
                            className={`ri-checkbox-blank-circle-fill link-${row.original.onlineStatusClass} me-3`}
                            id={`onlineStatus-${index}`}
                        />
                        {!cell.column.hideTooltip && Tooltip(`onlineStatus-${index}`, row.original?.[cell.column?.onlineStatusTooltip])}
                        {/* <i
                            className={`ri-checkbox-blank-circle-fill link-${row.original.enrollmentStatusClass} link-info`}
                            id={`registration-${index}`}
                        />
                        {Tooltip(`registration-${index}`, row.original?.[cell.column?.enrollmentStatusTooltip])} */}
                    </div>
                );
                break;
            case 'checkbox':
                body = (
                    <div className={`d-flex align-items-center justify-content-center ${cell.column.className}`}>
                        {(cell.column.disable ? (row.original[cell.column.disable] ? true : false) : true) ? (
                            <input
                                type="checkbox"
                                checked={cell.column.checkedAccessor ? row.original?.[cell.column.checkedAccessor] : row.original.checked}
                                disabled={props.mode === 'view' || (cell.column.disable ? !row.original[cell.column.disable] : false)}
                                className={
                                    'form-check-input ' +
                                    (cell.column.disable
                                        ? row.original[cell.column.disable]
                                            ? 'border border-primary'
                                            : 'border border-danger'
                                        : 'border border-primary')
                                }
                                onChange={(e) => props.handleCheckboxChange(e, index, row, cell)}
                            />
                        ) : (
                            'NA'
                        )}
                    </div>
                );
                break;
            case 'radio':
                body = (
                    <div className={`d-flex align-items-center justify-content-center ${cell.column.className}`}>
                        <div>
                            <input
                                type="radio"
                                checked={!!row.original.checked}
                                className="form-check-input"
                                onClick={(e) => props.handleRadioChange(e, row, cell)}
                            />
                        </div>
                    </div>
                );
                break;
            case 'tooltip':
                body = (
                    <div className="d-flex align-items-center justify-content-center">
                        <span>{row.original[cell.column.id]}</span>
                    </div>
                );
                break;
            case 'status':
                body = (
                    <Row
                        className={`d-flex align-items-center ${row.original[cell.column.accessor2] > 0 ? ' cursor-pointer' : ''}`}
                        onClick={() => {
                            if (row.original[cell.column.accessor2] > 0) props.handleCountViewPolicy(row, index, cell);
                        }}
                    >
                        <Col sm={'3'} className="d-flex align-items-center text-truncate">
                            <div className="d-flex align-items-center text-truncate" id={`key3-${index}`}>
                                <i className="ri-checkbox-blank-circle-fill link-success me-1" />
                                <span>{row.original?.[cell.column.key3]}</span>
                            </div>
                            {Tooltip(`key3-${index}`, 'Latest: ' + row.original?.[cell.column.key3])}
                        </Col>
                        <Col sm={'3'} className="d-flex align-items-center justify-content-center text-truncate">
                            <div className="d-flex align-items-center text-truncate" id={`key2-${index}`}>
                                <i className="ri-checkbox-blank-circle-fill link-warning me-1" />
                                <span>{row.original?.[cell.column.key2]}</span>
                            </div>
                            {Tooltip(`key2-${index}`, 'Not Latest: ' + row.original?.[cell.column.key2])}
                        </Col>
                        <Col sm={'3'} className="d-flex align-items-center justify-content-end text-truncate">
                            <div className="d-flex align-items-center text-truncate" id={`key1-${index}`}>
                                <i className="ri-checkbox-blank-circle-fill link-danger me-1" />
                                <span>{row.original?.[cell.column.key1]}</span>
                            </div>
                            {Tooltip(`key1-${index}`, 'Not Installed: ' + row.original?.[cell.column.key1])}
                        </Col>
                    </Row>
                );
                break;
            case 'download':
                body = (
                    <div className="d-flex align-items-center">
                        {/* <i
                            className="ri-file-upload-line cursor-pointer link-success fs-18 me-2"
                            onClick={() => props.handleDownload(row, '3')}
                            id={`downloadLatestDevices-${index}`}
                        />
                        {Tooltip(`downloadLatestDevices-${index}`, 'Export as CSV File')} */}
                        <i
                            className="ri-file-upload-line cursor-pointer link-warning fs-18 me-2"
                            onClick={() => props.handleDownload(row, '2')}
                            id={`downloadNotLatestDevices-${index}`}
                        />
                        {Tooltip(`downloadNotLatestDevices-${index}`, 'Export as CSV File')}
                        <i
                            className="ri-file-upload-line cursor-pointer link-danger fs-18"
                            onClick={() => props.handleDownload(row, '1')}
                            id={`downloadNotInstalledDevices-${index}`}
                        />
                        {Tooltip(`downloadNotInstalledDevices-${index}`, 'Export as CSV File')}
                    </div>
                );
                break;
            case 'coloredTextWithLink':
                body = (
                    <>
                        <span
                            id={`coloredTextWithLink-${index}-${cell.column.keyVal}`}
                            className={`text-${cell.column?.textClass} text-decoration-underline cursor-pointer`}
                            onClick={() => props.handleCountClick(row, cell)}
                        >
                            {cell.render('Cell')}
                        </span>
                        {Tooltip(`coloredTextWithLink-${index}-${cell.column.keyVal}`, cell.column.tooltip)}
                    </>
                );
                break;
            case 'priviliges':
                body = (
                    <div className="text-info fw-medium">
                        <span className="me-2 cursor-pointer" onClick={() => props.handlePriviligeView(row)}>
                            View ({cell.render('Cell')})
                        </span>
                    </div>
                );
                break;
            case 'infoText':
                body = (
                    <div className="d-flex align-items-center gap-2">
                        <span>{cell.render('Cell')}</span>
                        {row.original?.[cell.column.showTooltipKey] && (
                            <>
                                <i className="ri-information-line link-primary" id={`infoText-${index}`} />
                                {Tooltip(`infoText-${index}`, row.original?.[cell.column.infoTooltip])}
                            </>
                        )}
                    </div>
                );
                break;
            case 'policyCompliance':
                body = (
                    <div className="d-flex align-items-center gap-2">
                        <i className={`ri-checkbox-blank-circle-fill ${row.original?.[cell.column.circleClass]}`} />
                        {cell.render('Cell')}
                    </div>
                );
                break;
            case 'chromeActions':
                body = row.original.hideActions ? (
                    <>
                        {cell.column.onHideAction ? (
                            <span className={`lh-sm badge badge-soft-${row.original[cell.column.onHideClass]}`}>
                                {row.original[cell.column.onHideAction]}
                            </span>
                        ) : (
                            '—'
                        )}
                    </>
                ) : (
                    <div className="d-flex align-items-center">
                        {cell?.column?.devicemove && (
                            <IsAuthorized
                                privRequired={editor}
                                yes={() => (
                                    <>
                                        <i
                                            id={`devicemove-${index}`}
                                            className={`ri-folder-transfer-line me-2 fs-18 ${
                                                !row.original.hideMove ? 'cursor-pointer link-secondary' : 'text-muted'
                                            }`}
                                            onClick={() => (!row.original.hideMove ? props.handleMove(row, cell, index) : undefined)}
                                        />
                                        {Tooltip(`devicemove-${index}`, 'Move')}
                                    </>
                                )}
                            />
                        )}
                        {cell?.column?.screenshot && (
                            <IsAuthorized
                                privRequired={editor}
                                yes={() => (
                                    <>
                                        <i
                                            id={`screenshot-${index}`}
                                            className={`ri-screenshot-2-line me-2 fs-18 ${
                                                !row.original.hideScreenshot ? 'cursor-pointer link-secondary' : 'text-muted'
                                            }`}
                                            onClick={() =>
                                                !row.original.hideScreenshot ? props.handleScreenshot(row, cell, index) : undefined
                                            }
                                        />
                                        {Tooltip(`screenshot-${index}`, 'Take Screenshot')}
                                    </>
                                )}
                            />
                        )}
                        {cell.column?.power && (
                            <IsAuthorized
                                privRequired={editor}
                                yes={() => (
                                    <>
                                        <i
                                            id={`power-${index}`}
                                            className={`ri-shut-down-line me-2 fs-18 ${
                                                row.original.showActions ? 'cursor-pointer link-secondary' : 'text-muted'
                                            }`}
                                            onClick={() => (row.original.showActions ? props.handlePower(row) : undefined)}
                                        />
                                        {Tooltip(`power-${index}`, 'Shutdown')}
                                    </>
                                )}
                            />
                        )}
                        {cell?.column?.reboot && (
                            <IsAuthorized
                                privRequired={editor}
                                yes={() => (
                                    <>
                                        <i
                                            id={`reboot-${index}`}
                                            className={`ri-creative-commons-sa-line me-2 fs-18 ${
                                                row.original.showActions ? 'cursor-pointer link-secondary' : 'text-muted'
                                            }`}
                                            onClick={() =>
                                                row.original.showActions ? props.handleDeviceReboot(row, cell, index) : undefined
                                            }
                                        />
                                        {Tooltip(`reboot-${index}`, 'Reboot')}
                                    </>
                                )}
                            />
                        )}
                        {cell?.column?.volume && (
                            <IsAuthorized
                                privRequired={reader}
                                yes={() => (
                                    <>
                                        <i
                                            id={`volume-${index}`}
                                            className={`me-2 fs-18 ${
                                                row.original.showActions ? 'cursor-pointer link-secondary' : 'text-muted'
                                            } ${
                                                // row.original.screamerOn ? ' ri-alarm-warning-fill mask' :
                                                ' ri-volume-up-line'
                                            }`}
                                            onClick={() =>
                                                row.original.showActions ? props.handleVolume(row, cell, index, 'START') : undefined
                                            }
                                        />
                                        {Tooltip(`volume-${index}`, 'Volume')}
                                    </>
                                )}
                            />
                        )}
                        {cell?.column?.deprovision && (
                            <IsAuthorized
                                privRequired={editor}
                                yes={() => (
                                    <>
                                        <i
                                            id={`deprovision-${index}`}
                                            className={`ri-link-unlink-m me-2 fs-18 ${
                                                !row.original.hidedeoprovision ? 'cursor-pointer link-secondary' : 'text-muted'
                                            }`}
                                            onClick={() =>
                                                !row.original.hidedeoprovision ? props.handleDeprovision(row, cell, index) : undefined
                                            }
                                        />
                                        {Tooltip(
                                            `deprovision-${index}`,
                                            cell?.column?.deprovisionTooltip ? cell?.column?.deprovisionTooltip : 'Deprovision'
                                        )}
                                    </>
                                )}
                            />
                        )}
                        {cell?.column?.wipeUser && (
                            <IsAuthorized
                                privRequired={editor}
                                yes={() => (
                                    <>
                                        <i
                                            id={`wipeuser-${index}`}
                                            className={`ri-user-settings-line me-2 fs-18 ${
                                                !row.original.hideWipeUser ? 'cursor-pointer link-secondary' : 'text-muted'
                                            }`}
                                            onClick={() =>
                                                !row.original.hideWipeUser ? props.handleWipeUser(row, cell, index) : undefined
                                            }
                                        />
                                        {Tooltip(`wipeuser-${index}`, 'Wipe User')}
                                    </>
                                )}
                            />
                        )}
                        {cell?.column?.factoryreset && (
                            <IsAuthorized
                                privRequired={editor}
                                yes={() => (
                                    <>
                                        <i
                                            id={`factoryReset-${index}`}
                                            className={`ri-settings-5-line me-2 fs-18 ${
                                                !row.original.hideFactoryReset ? 'cursor-pointer link-secondary' : 'text-muted'
                                            }`}
                                            onClick={() =>
                                                !row.original.hideFactoryReset ? props.handleFactoryReset(row, cell, index) : undefined
                                            }
                                        />
                                        {Tooltip(`factoryReset-${index}`, 'Factory Reset')}
                                    </>
                                )}
                            />
                        )}
                    </div>
                );
                break;
            default:
                break;
        }
        return body;
    };

    const prepareCustomHeader = (column) => {
        let body = undefined;
        switch (column.headerType) {
            case 'checkbox':
                body = (
                    <>
                        {column.showHeader && <div className={`d-flex justify-content-center mb-1 ${column.thClass}`}>{column.Header}</div>}
                        <div className={`d-flex justify-content-center ${column.thClass}`}>
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="checkbox"
                                disabled={props.mode === 'view'}
                                checked={column.headerCheckAll ? props[column.headerCheckAll] : props.checkAll}
                                onChange={(e) => props.handleCheckAll(e, column)}
                            />
                            {!props.hideCheckBoxTooltip &&
                                Tooltip(
                                    'checkbox',
                                    `${
                                        column?.checkALlTooltip
                                            ? column.checkALlTooltip
                                            : 'It will select all the records of this page only.'
                                    } `
                                )}
                        </div>
                    </>
                );
                break;
            default:
                break;
        }
        return body;
    };

    const uploadDoc = () => {
        fileUploader.click();
    };

    const handleUpload = (val, type) => {
        const file = props.multipleUpload ? val.target.files : val.target.files[0];
        props.handleUpload && props.handleUpload(file, type);
    };

    const handleSelectChange = (val) => {
        props.handleSelectChange(val || undefined);
    };

    const onChangePicker = useAsyncDebounce((val) => {
        props.handleDateSearch(val || undefined);
    }, 200);

    const onChange = useAsyncDebounce((val) => {
        if (props.handleGlobalSearch) props.handleGlobalSearch(val?.trim() || undefined, setValue);
        else setGlobalFilter(val?.trim() || undefined);
    }, 200);

    const onChangeInSelect = (event) => {
        setPageSize(Number(event.target.value));
        onPageChange && onPageChange(pageIndex + 1, Number(event.target.value));
    };

    function on() {
        document.getElementById('overlay').style.display = 'block';
    }

    function off() {
        document.getElementById('overlay').style.display = 'none';
    }

    const TopFilterPanel = () => {
        return (
            <Col sm="auto" className={`d-flex align-items-center ${props.doNotJustify ? '' : 'justify-content-between'}`}>
                <Row className="g-2 align-items-center">
                    {tableHeader && (
                        <Col className="d-flex justify-content-start">
                            {props.headerImage && <img src={props.headerImage} alt=""></img>}
                            <h4
                                className={`card-title mb-0 ${props.headerImage ? 'ps-2' : ''} ${
                                    props.tableLabelClass ? props.tableLabelClass : ''
                                }`}
                            >
                                {tableHeader}{' '}
                                {!props.hideDisplayTotalCount && `(${totalRecords ? totalRecords : globalFilteredRows?.length})`}
                            </h4>
                        </Col>
                    )}
                    {props.searchFilter1 && (
                        <Col sm="auto">
                            <Row className="align-items-center">
                                <div className="col-sm-auto">
                                    <div className={`form-icon ${props.largeSearchFilter ? 'width-380' : ''}`}>
                                        <input
                                            autoComplete="off"
                                            type="text"
                                            maxLength={props.filter1CharLimit ? props.filter1CharLimit : 30}
                                            id="search-bar-0"
                                            value={value || ''}
                                            className={
                                                (props.showTooltip ? 'holder' : '') + ' form-control form-control-icon dash-filter-picker'
                                            }
                                            placeholder={`Search ${props.searchPlaceHolder ? 'by ' + props.searchPlaceHolder : ''}`}
                                            onChange={(e) => {
                                                setValue(e.target.value?.replace(/[/\\*?()[]/g, '')?.trimStart());
                                                if (
                                                    e.target.value?.replace(/[/\\*?()[]/g, '')?.trimStart()?.length > 0 ||
                                                    e.target.value?.length === 0
                                                )
                                                    onChange(e.target.value?.replace(/[/\\*?()[]/g, ''));
                                            }}
                                        />
                                        <i className="ri-search-line link-info"></i>
                                    </div>
                                </div>
                            </Row>
                        </Col>
                    )}
                    {props.dropDownFilter && (
                        <Col sm="auto">
                            <div className="d-flex">
                                <Select
                                    getOptionValue={(option) => option.value}
                                    getOptionLabel={(option) => option.label}
                                    autoFocus={true}
                                    isMulti={props?.dropDownParams?.isMulti ? true : false}
                                    isClearable={props?.dropDownParams?.isMulti ? true : false}
                                    id={props?.dropDownParams?.label}
                                    name={props?.dropDownParams?.value}
                                    options={props?.dropDownParams?.options}
                                    placeholder={`Select ${props?.dropDownParams?.label}`}
                                    onChange={(e) => {
                                        setDropDownValue(e);
                                        handleSelectChange(e, props?.dropDownParams);
                                    }}
                                    value={dropDownValue || ''}
                                    isSearchable={true}
                                    className="dropdown-filter-picker"
                                    classNamePrefix={'custom-select'}
                                    noOptionsMessage={() => 'No data found'}
                                />
                            </div>
                        </Col>
                    )}
                    {props.refresh && (
                        <Col sm="auto">
                            <div className="d-flex">
                                <Button
                                    type="button"
                                    color="primary"
                                    outline
                                    id="refresh"
                                    className="ps-2 pe-2 pt-1 pb-1"
                                    onClick={props.handleFilter}
                                >
                                    <span className="d-flex align-items-center fs-18">
                                        <i className="ri-refresh-line align-bottom"></i>
                                    </span>
                                </Button>
                                {Tooltip('refresh', 'Refresh')}
                            </div>
                        </Col>
                    )}
                    {props.pageOptionsFilter && (
                        <Col sm="auto" className="d-flex justify-content-end">
                            <select className="form-select" value={pageSize} onChange={onChangeInSelect}>
                                {[10, 20, 30, 40, 50].map((pg) => (
                                    <option key={pg} value={pg}>
                                        Show {pg}
                                    </option>
                                ))}
                            </select>
                        </Col>
                    )}
                </Row>
                <Row className="g-2 align-items-center">
                    {props.dateFilter && (
                        <Col sm="auto">
                            <Row className="align-items-center">
                                <div className="col-sm-auto">
                                    <div className="form-icon flex-nowrap">
                                        <Flatpickr
                                            ref={fp}
                                            value={dateVal}
                                            placeholder={`Search by ${props.datePlaceHolder}`}
                                            className={'form-control form-control-icon w-100 date-filter-picker'}
                                            options={{ mode: 'single', dateFormat: 'd M, Y' }}
                                            onChange={(e) => {
                                                setDateVal(e);
                                                onChangePicker(e);
                                            }}
                                        />
                                        <i className="ri-calendar-2-line" />
                                    </div>
                                </div>
                            </Row>
                        </Col>
                    )}
                    {props.dropDownFilter2 && (
                        <Col sm="auto">
                            <div className="d-flex">
                                <Select
                                    getOptionValue={(option) => option.value}
                                    getOptionLabel={(option) => option.label}
                                    isMulti={props?.dropDownParams2?.isMulti ? true : false}
                                    isClearable={props?.dropDownParams2?.isMulti || props?.dropDownParams2?.clearable ? true : false}
                                    id={props?.dropDownParams2?.label}
                                    name={props?.dropDownParams2?.value}
                                    options={props?.dropDownParams2?.options}
                                    placeholder={`Select ${props?.dropDownParams2?.label}`}
                                    onChange={(e) => {
                                        setDropDownValue(e);
                                        props.handleSelectChange2(e);
                                    }}
                                    value={dropDownValue || ''}
                                    isSearchable={true}
                                    className={`dropdown-filter-picker ${props.dropDownParams2?.className}`}
                                    classNamePrefix={'custom-select'}
                                    noOptionsMessage={() => 'No data found'}
                                />
                            </div>
                        </Col>
                    )}
                    {props.dropDownFilter3 && (
                        <Col sm="auto">
                            <div className="d-flex">
                                <Select
                                    getOptionValue={(option) => option.value}
                                    getOptionLabel={(option) => option.label}
                                    isMulti={props?.dropDownParams3?.isMulti ? true : false}
                                    isClearable={props?.dropDownParams3?.isMulti ? true : false}
                                    id={props?.dropDownParams3?.label}
                                    name={props?.dropDownParams3?.value}
                                    options={props?.dropDownParams3?.options}
                                    placeholder={`Select ${props?.dropDownParams3?.label}`}
                                    onChange={(e) => {
                                        setDropDownValue(e);
                                        props.handleSelectChange2(e);
                                    }}
                                    value={dropDownValue || ''}
                                    isSearchable={true}
                                    className="dropdown-filter-picker"
                                    classNamePrefix={'custom-select'}
                                    noOptionsMessage={() => 'No data found'}
                                />
                            </div>
                        </Col>
                    )}
                    {props.searchFilter2 && (
                        <Col sm="auto">
                            <Row className="align-items-center">
                                <div className="col-sm-auto">
                                    <div
                                        className={`form-icon ${
                                            props.largeSearchFilter ? (props.SearchFilterWidth ? props.SearchFilterWidth : 'width-460') : ''
                                        }`}
                                    >
                                        <input
                                            autoComplete="off"
                                            type="text"
                                            id={props.changeID ? 'search-bar-1-' + props.tabName : 'search-bar-1'}
                                            value={value || ''}
                                            autoFocus={true}
                                            className={
                                                (props.showTooltip ? 'holder' : '') + ' form-control form-control-icon dash-filter-picker'
                                            }
                                            placeholder={`Search ${props.searchPlaceHolder ? 'by ' + props.searchPlaceHolder : ''}`}
                                            maxLength={30}
                                            onChange={(e) => {
                                                setValue(e.target.value?.replace(/[/\\*?()[]/g, '')?.trimStart());
                                                if (
                                                    e.target.value?.replace(/[/\\*?()[]/g, '')?.trimStart()?.length > 0 ||
                                                    e.target.value?.length === 0
                                                )
                                                    onChange(e.target.value?.replace(/[/\\*?()[]/g, ''));
                                            }}
                                        />
                                        {props.showTooltip &&
                                            Tooltip(
                                                props.changeID ? 'search-bar-1-' + props.tabName : 'search-bar-1',
                                                value && value !== '' ? value : `Search by ${props.searchPlaceHolder}`
                                            )}
                                        <i className="ri-search-line link-info"></i>
                                    </div>
                                </div>
                            </Row>
                        </Col>
                    )}
                    {props.searchFilter3 && (
                        <Col sm="auto">
                            <Row className="align-items-center">
                                <div className="col-sm-auto">
                                    <div
                                        className={`form-icon ${
                                            props.largeSearchFilter ? (props.SearchFilterWidth ? props.SearchFilterWidth : 'width-460') : ''
                                        }`}
                                    >
                                        <input
                                            autoComplete="off"
                                            type="text"
                                            id={props.changeID ? 'search-bar-1-' + props.tabName : 'search-bar-1'}
                                            value={props.value || ''}
                                            autoFocus={true}
                                            maxLength={30}
                                            className={
                                                (props.showTooltip ? 'holder' : '') + ' form-control form-control-icon dash-filter-picker'
                                            }
                                            placeholder={`Search ${props.searchPlaceHolder ? 'by ' + props.searchPlaceHolder : ''}`}
                                            onChange={(e) => props.onChange(e.target.value?.replace(/[/\\*?()[]/g, '')?.trimStart())}
                                        />
                                        {props.showTooltip &&
                                            Tooltip(
                                                props.changeID ? 'search-bar-1-' + props.tabName : 'search-bar-1',
                                                props.value && props.value !== '' ? props.value : `Search by ${props.searchPlaceHolder}`
                                            )}
                                        <i className="ri-search-line link-info"></i>
                                    </div>
                                </div>
                            </Row>
                        </Col>
                    )}
                    {props.dateRangeFilter && (
                        <Col sm="auto">
                            <Row className="align-items-center">
                                <div className="col-sm-auto">
                                    <div className="form-icon flex-nowrap">
                                        <Flatpickr
                                            ref={fp}
                                            value={dateVal}
                                            placeholder={`Search by ${props.datePlaceHolder}`}
                                            className={
                                                (props.showTooltip ? 'holder' : '') +
                                                (props.dateClass ? ' ' + props.dateClass : '') +
                                                ' form-control form-control-icon w-100 date-filter-picker'
                                            }
                                            options={{ mode: 'range', dateFormat: 'd M, Y' }}
                                            onChange={(e) => {
                                                setDateVal(e);
                                                onChangePicker(e);
                                            }}
                                        />
                                        <i className="ri-calendar-2-line" />
                                    </div>
                                </div>
                            </Row>
                        </Col>
                    )}
                    {props.moreFilters && (
                        <Col sm="auto">
                            <div className="d-flex">
                                <Button
                                    type="button"
                                    id="moreFilter"
                                    className="padding-x-9"
                                    color="primary"
                                    onClick={props.handleMoreFilters}
                                >
                                    <span className="d-flex align-items-center gap-2">
                                        <i className="ri-filter-line" />
                                        More Filters
                                    </span>
                                </Button>
                            </div>
                        </Col>
                    )}
                    {props.exportFile && (
                        <Col sm="auto">
                            <div className="d-flex">
                                <Button
                                    type="button"
                                    id="export"
                                    color="primary"
                                    className="padding-x-9"
                                    onClick={() => props.handleIsExport()}
                                >
                                    <span className="d-flex align-items-center">
                                        <i className="ri-upload-2-line fs-14 align-bottom me-1"></i> Export
                                    </span>
                                </Button>
                                {Tooltip('export', 'Export Devices in CSV format (up to 3 Lakhs).')}
                            </div>
                        </Col>
                    )}
                    {props.bulkAction && (
                        <Col sm="auto">
                            <div className="d-flex" id={'bulkActionDiv'}>
                                <Button
                                    type="button"
                                    id="bulkAction"
                                    className="padding-x-9"
                                    disabled={props.disableBulkAction}
                                    color="primary"
                                    onClick={props.handleBulkAction}
                                >
                                    <span className="d-flex align-items-center">Bulk Action</span>
                                </Button>
                            </div>
                            {props.disableBulkAction && Tooltip('bulkActionDiv', 'Please select atleast one device to perform action')}
                        </Col>
                    )}
                    {props.searchFilterWithPanel && (
                        <Col sm="auto">
                            <Row className="align-items-center">
                                <div className="col-sm-auto gf">
                                    <div className={`form-icon ${props.largeSearchFilter ? 'width-380' : ''}`}>
                                        <input
                                            autoComplete="off"
                                            type="text"
                                            maxLength={props.filter1CharLimit ? props.filter1CharLimit : 30}
                                            id="search-bar-0"
                                            onFocus={() => {
                                                on();
                                                setShowPanel(true);
                                            }}
                                            value={value || ''}
                                            className={
                                                (props.showTooltip ? 'holder' : '') + ' form-control form-control-icon dash-filter-picker'
                                            }
                                            placeholder={`Search ${props.searchPlaceHolder ? 'by ' + props.searchPlaceHolder : ''}`}
                                            onChange={(e) => {
                                                if (!props.loading) setValue(e.target.value?.replace(/[/\\*?()[]/g, '')?.replace(' ', ' '));
                                                onChange(e.target.value);
                                            }}
                                        />
                                        <i className="ri-search-line link-info"></i>
                                    </div>
                                    <div id="overlay">
                                        {value && value !== '' && showPanel && (
                                            <ul className="dropdown-menu show width-375 max-height-500 mt-1 border border-success position-absolute zindex-99 overflow-auto custom-max-height">
                                                {props.searchResults?.length > 0 ? (
                                                    props.searchResults.map((result, ind) => (
                                                        <li
                                                            key={ind}
                                                            className="dropdown-item d-flex justify-content-between align-items-center cursor-pointer border-bottom"
                                                            onClick={() => {
                                                                props.handleResultSelect(result);
                                                                off();
                                                            }}
                                                        >
                                                            <span className="text-primary me-5 fw-semibold">{result.name}</span>
                                                            <span className="text-muted max-width-150 width-150">
                                                                <EllipsisToolTip options={{ effect: 'solid', place: 'top' }}>
                                                                    {result.path.map((path, index) => (
                                                                        <span key={index}>
                                                                            {path + (index !== result.path.length - 1 ? ' > ' : '')}
                                                                        </span>
                                                                    ))}
                                                                </EllipsisToolTip>
                                                            </span>
                                                        </li>
                                                    ))
                                                ) : (
                                                    <div className="dropdown-item text-center text-muted">No Results Found.</div>
                                                )}
                                            </ul>
                                        )}
                                    </div>
                                </div>
                            </Row>
                        </Col>
                    )}
                    {props.dateRangeFilter2 && (
                        <Col sm="auto">
                            <Row className="align-items-center">
                                <div className="col-sm-auto">
                                    <div className="form-icon flex-nowrap">
                                        <Flatpickr
                                            ref={fp}
                                            value={dateVal}
                                            placeholder={`Search by ${props.datePlaceHolder}`}
                                            className="form-control form-control-icon date-filter-picker"
                                            options={{ mode: 'range', dateFormat: 'd M, Y' }}
                                            onChange={(e) => {
                                                setDateVal(e);
                                                onChangePicker(e);
                                            }}
                                        />
                                        <i className="ri-calendar-2-line" />
                                    </div>
                                </div>
                            </Row>
                        </Col>
                    )}
                    {props.showMove && (
                        <IsAuthorized
                            privRequired={editor}
                            yes={() => (
                                <Col className="d-flex justify-content-end">
                                    <div>
                                        <Button
                                            type="button"
                                            color="success"
                                            className="d-flex align-items-center py-1"
                                            disabled={props.disableMove}
                                            onClick={() => props.handleMove()}
                                        >
                                            <i className="ri-folder-shared-line me-2 fs-18" /> Move
                                        </Button>
                                    </div>
                                </Col>
                            )}
                        />
                    )}
                    {props.addButton && (
                        <IsAuthorized
                            privRequired={props.type === 'adminAccess' ? props.adminAccess : editor}
                            yes={() => (
                                <Col sm="auto" className="d-flex justify-content-end">
                                    <div>
                                        <Button type="button" color="primary" onClick={() => props.handleAddNew()}>
                                            + {props.addButtonText ? props.addButtonText : 'Add New'}
                                        </Button>
                                    </div>
                                </Col>
                            )}
                        />
                    )}
                    {props.searchFiltersButton && (
                        <Col className="d-flex justify-content-end">
                            <button type="button" className="btn btn-primary w-100" onClick={() => props.handleSearchFilters()}>
                                <i className="ri-equalizer-fill me-1 align-bottom"></i>
                                Search
                            </button>
                        </Col>
                    )}
                    {props.exportLogs && (
                        <Col className="d-flex justify-content-end">
                            <button
                                type="button"
                                id={'exportLogs'}
                                className="btn btn-primary w-100 padding-y-9"
                                onClick={(e) => props.handleExportLogs(e)}
                                disabled={props.isExportBtnDisabled}
                            >
                                <i className="ri-upload-2-line me-1 align-bottom"></i>
                                Export
                            </button>
                            {Tooltip('exportLogs', props.exportLogTooltip ? props.exportLogTooltip : 'Export as CSV File')}
                        </Col>
                    )}
                    {props.clearFiltersButton && (
                        <Col className="d-flex justify-content-end">
                            <button className="btn btn-light w-100" onClick={() => props.handleClearFilters()}>
                                Clear Filters
                            </button>
                        </Col>
                    )}
                    {props.actionsDownload && (
                        <Col sm="auto">
                            <div className="d-flex">
                                <Button
                                    type="button"
                                    color="primary"
                                    outline
                                    id="actionsDownload"
                                    className="ps-2 pe-2 pt-1 pb-1"
                                    onClick={() => props.handleDownload('actions')}
                                >
                                    <span className="d-flex align-items-center fs-18">
                                        <i className="ri-file-download-line align-bottom"></i>
                                    </span>
                                </Button>
                                {Tooltip('actionsDownload', 'Sample Actions Template')}
                            </div>
                        </Col>
                    )}
                    {props.actionsUpload && (
                        <IsAuthorized
                            privRequired={editor}
                            yes={() => (
                                <Col sm="auto">
                                    <div className="d-flex">
                                        <Button
                                            type="button"
                                            color="primary"
                                            outline
                                            className="ps-2 pe-2 pt-1 pb-1"
                                            id="bulkupload"
                                            onClick={uploadDoc}
                                        >
                                            <span className="d-flex align-items-center fs-18">
                                                <i className="ri-file-download-line align-button" id="bulkupload"></i>
                                            </span>
                                        </Button>
                                        {Tooltip('bulkupload', 'Bulk Import Actions')}
                                        <input
                                            hidden
                                            id="file"
                                            type="file"
                                            accept={props.acceptFiles}
                                            ref={(file) => setFileUploader(file)}
                                            onChange={(ev) => handleUpload(ev, 'actions')}
                                            onClick={(ev) => (ev.target.value = null)}
                                        />
                                    </div>
                                </Col>
                            )}
                        />
                    )}
                    {props.devicesUpload && (
                        <IsAuthorized
                            privRequired={editor}
                            yes={() => (
                                <Col sm="auto">
                                    <div className="d-flex">
                                        <Button
                                            type="button"
                                            color="primary"
                                            outline
                                            className="ps-2 pe-2 pt-1 pb-1"
                                            id="upload"
                                            onClick={props.handleDeviceUpload}
                                        >
                                            <span className="d-flex align-items-center fs-18">
                                                <i className="ri-upload-2-line align-bottom" id="upload"></i>
                                            </span>
                                        </Button>
                                        {Tooltip('upload', props.uploadTooltip)}
                                    </div>
                                </Col>
                            )}
                        />
                    )}
                    {props.popUpButton && (
                        <IsAuthorized
                            privRequired={editor}
                            yes={() => (
                                <Col className="d-flex justify-content-end">
                                    <div>
                                        <Button type="button" color="primary" className="py-1 my-0" onClick={() => props.handlePopUp()}>
                                            <span className="d-flex align-items-center">
                                                <span className="fs-14">{props.popUpButtonLabel}</span>
                                                <i className="ri-upload-2-line ms-2 fs-18" />
                                            </span>
                                        </Button>
                                    </div>
                                </Col>
                            )}
                        />
                    )}
                    {props.download && (
                        <Col sm="auto">
                            <div className="d-flex">
                                <Button
                                    type="button"
                                    color="primary"
                                    outline
                                    id="download"
                                    className="ps-2 pe-2 pt-1 pb-1"
                                    onClick={props.handleDownload}
                                >
                                    <span className="d-flex align-items-center fs-18">
                                        <i className="ri-download-2-line align-bottom"></i>
                                    </span>
                                </Button>
                                {Tooltip('download', 'Sample Template')}
                            </div>
                        </Col>
                    )}
                    {props.upload && (
                        <IsAuthorized
                            privRequired={editor}
                            yes={() => (
                                <Col sm="auto">
                                    <div className="d-flex">
                                        <Button
                                            type="button"
                                            color="primary"
                                            outline
                                            className="ps-2 pe-2 pt-1 pb-1"
                                            id="upload"
                                            onClick={uploadDoc}
                                        >
                                            <span className="d-flex align-items-center fs-18">
                                                <i className="ri-upload-2-line align-bottom" id="upload"></i>
                                            </span>
                                        </Button>
                                        {Tooltip('upload', props.uploadTooltip)}
                                        <input
                                            hidden
                                            id="file"
                                            type="file"
                                            accept={props.acceptFiles}
                                            ref={(file) => setFileUploader(file)}
                                            onChange={(ev) => handleUpload(ev, 'file')}
                                            onClick={(ev) => (ev.target.value = null)}
                                        />
                                    </div>
                                </Col>
                            )}
                        />
                    )}
                    {props.refresh2 && (
                        <Col sm="auto">
                            <div className="d-flex">
                                <Button
                                    type="button"
                                    color="primary"
                                    outline
                                    id="refresh"
                                    className="ps-2 pe-2 pt-1 pb-1"
                                    onClick={props.handleFilter}
                                >
                                    <span className="d-flex align-items-center fs-18">
                                        <i className="ri-refresh-line align-bottom"></i>
                                    </span>
                                </Button>
                                {Tooltip('refresh', 'Refresh')}
                            </div>
                        </Col>
                    )}
                    {props.uploadButtonWithNoHandling && !props.hideupload && (
                        <IsAuthorized
                            privRequired={reader}
                            yes={() => (
                                <Col sm="auto">
                                    <div className="d-flex">
                                        <Button
                                            type="button"
                                            color="primary"
                                            className="py-1 my-0"
                                            id="fileImport"
                                            onClick={props.handleUpload}
                                        >
                                            <span className="d-flex align-items-center">
                                                <span className="fs-14">Import</span>
                                                <i className="ri-download-2-line ms-2 fs-18" />
                                            </span>
                                        </Button>
                                        {Tooltip('fileImport', 'File Import')}
                                    </div>
                                </Col>
                            )}
                        />
                    )}
                    {props.fileUploadButton && (
                        <IsAuthorized
                            privRequired={editor}
                            yes={() => (
                                <Col sm="auto">
                                    <div className="d-flex">
                                        <Button
                                            type="button"
                                            id="fileImport"
                                            color="primary"
                                            className="padding-x-9"
                                            disabled={props.hideupload}
                                            onClick={props.handleUploadClick}
                                        >
                                            <span className="d-flex align-items-center">
                                                <i className="ri-download-2-line align-bottom fs-14 me-1"></i>
                                                Import
                                            </span>
                                        </Button>
                                        {Tooltip('fileImport', ' File Import')}
                                    </div>
                                </Col>
                            )}
                        />
                    )}
                    {props.uploadButton && !props.hideupload && (
                        <IsAuthorized
                            privRequired={editor}
                            yes={() => (
                                <Col sm="auto">
                                    <div className="d-flex">
                                        <Button type="button" color="primary" className="py-1 my-0" id="fileImport" onClick={uploadDoc}>
                                            <span className="d-flex align-items-center">
                                                <i className="ri-download-2-line me-1 fs-18" />
                                                <span className="fs-14">Import</span>
                                            </span>
                                        </Button>
                                        {Tooltip('fileImport', ' File Import')}
                                        <input
                                            hidden
                                            id="file"
                                            type="file"
                                            accept={props.acceptFiles && props.acceptFiles !== '' ? props.acceptFiles : undefined}
                                            ref={(file) => setFileUploader(file)}
                                            onChange={(ev) => handleUpload(ev, 'file')}
                                            onClick={(ev) => (ev.target.value = null)}
                                            multiple={props.multipleUpload}
                                        />
                                    </div>
                                </Col>
                            )}
                        />
                    )}
                    {props.exportFile5l && (
                        <Col sm="auto">
                            <div className="d-flex">
                                <Button
                                    type="button"
                                    id="exportAll"
                                    color="primary"
                                    className="padding-x-9"
                                    onClick={() => props.handleIsExportAll()}
                                >
                                    <span className="d-flex align-items-center">
                                        <i className="ri-upload-2-line align-bottom fs-14 me-1"></i>
                                        {props.exportAllLabel ? props.exportAllLabel : 'Export All'}
                                    </span>
                                </Button>
                            </div>
                        </Col>
                    )}
                    {props.selectedCount && (
                        <Col className="d-flex justify-content-end">
                            <div className="fw-medium text-muted">
                                Selected Count : <b>{selectedFlatRows?.length > 0 ? selectedFlatRows?.length : 0}</b>
                            </div>
                        </Col>
                    )}
                    {props.bulkActions && (
                        <Col className="d-flex justify-content-end">
                            <UncontrolledDropdown>
                                <DropdownToggle href="#" className="btn-soft-info btn btn-info d-flex align-items-center" tag="button">
                                    {props.bulkActionsBtnLabel} <i className="ps-2 ri-more-fill"></i>
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-end">
                                    {props.bulkActionItems?.length > 0 &&
                                        props.bulkActionItems.map((ele, index) => {
                                            return (
                                                <DropdownItem key={index} className="dropdown-item d-flex align-items-center" href="#">
                                                    <i className={`me-2 fs-18 cursor-pointer link-secondary ${ele.icon}`}></i> {ele.label}
                                                </DropdownItem>
                                            );
                                        })}
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </Col>
                    )}
                    {props.multiTypeUpload && (
                        <IsAuthorized
                            privRequired={editor}
                            yes={() =>
                                (tenant.ANDROID_ENTERPRISE_ENABLED || tenant.ANDROID_TV_ENABLED) && (
                                    <Col sm="auto">
                                        <div className="d-flex">
                                            <Button
                                                type="button"
                                                color="primary"
                                                id="bulkFile"
                                                className="padding-x-9"
                                                onClick={() => props.handleMultiUpload()}
                                            >
                                                <span className="d-flex align-items-center">
                                                    <i className="ri-download-2-line me-1 fs-14" /> Import
                                                </span>
                                            </Button>
                                            {Tooltip('bulkFile', 'Import')}
                                        </div>
                                    </Col>
                                )
                            }
                        />
                        /*
                         * <Col className="d-flex justify-content-end">
                         *     <UncontrolledDropdown>
                         *         <DropdownToggle
                         *             href="#"
                         *             className="px-2 py-1 btn-soft-info btn btn-info d-flex align-items-center"
                         *             tag="button"
                         *         >
                         *             {props.multiUploadButton} <i className={`fs-18 ${props.multiUploadIcon}`}></i>
                         *         </DropdownToggle>
                         *         <DropdownMenu className="dropdown-menu-end">
                         *             {props.multiUploadItems?.length > 0 &&
                         *                 props.multiUploadItems.map((ele, index) => {
                         *                     return (
                         *                         <DropdownItem
                         *                             key={index}
                         *                             className="dropdown-item d-flex align-items-center"
                         *                             onClick={() => props.handleMultiUpload(ele)}
                         *                         >
                         *                             <i className={`me-2 fs-18 cursor-pointer link-secondary ${ele.icon}`}></i> {ele.label}
                         *                         </DropdownItem>
                         *                     );
                         *                 })}
                         *         </DropdownMenu>
                         *     </UncontrolledDropdown>
                         * </Col>
                         */
                    )}
                    {props.multiTypeDownload && (
                        <IsAuthorized
                            privRequired={reader}
                            yes={() =>
                                (tenant.ANDROID_ENTERPRISE_ENABLED || tenant.ANDROID_TV_ENABLED) && (
                                    <Col sm="auto">
                                        <div className="d-flex">
                                            <Button
                                                type="button"
                                                id="fileDownload"
                                                color="primary"
                                                className="padding-x-9"
                                                onClick={() => props.handleMultiDownload()}
                                            >
                                                <span className="d-flex align-items-center">
                                                    <i className="ri-upload-2-line fs-14 align-bottom me-1"></i>Export
                                                </span>
                                            </Button>
                                            {Tooltip('fileDownload', 'Export')}
                                        </div>
                                    </Col>
                                )
                            }
                        />
                        /*
                         * <Col className="d-flex justify-content-end">
                         *     <UncontrolledDropdown>
                         *         <DropdownToggle
                         *             href="#"
                         *             className="px-2 py-1 btn-soft-info btn btn-info d-flex align-items-center"
                         *             tag="button"
                         *         >
                         *             {props.multiDownloadButton} <i className={`fs-18 ${props.multiDownloadIcon}`}></i>
                         *         </DropdownToggle>
                         *         <DropdownMenu className="dropdown-menu-end">
                         *             {props.multiDownloadItems?.length > 0 &&
                         *                 props.multiDownloadItems.map((ele, index) => {
                         *                     return (
                         *                         <DropdownItem
                         *                             key={index}
                         *                             className="dropdown-item d-flex align-items-center"
                         *                             onClick={() => props.handleMultiDownload(ele)}
                         *                         >
                         *                             <i className={`me-2 fs-18 cursor-pointer link-secondary ${ele.icon}`}></i> {ele.label}
                         *                         </DropdownItem>
                         *                     );
                         *                 })}
                         *         </DropdownMenu>
                         *     </UncontrolledDropdown>
                         * </Col>
                         */
                    )}
                </Row>
            </Col>
        );
    };

    let tabProps = { ...getTableProps() };
    delete tabProps.key;

    let tableProps = { ...getTableBodyProps() };
    delete tableProps.key;

    const getCellProps = (cell) => {
        let cellProps = cell.getCellProps({
            style: {
                width: cell.column.width,
                maxWidth: cell.column.maxWidth,
                minWidth: cell.column.minWidth
            }
        });
        delete cellProps.key;
        return cellProps;
    };
    let headerGroupProps = (headerGroup) => {
        let groupProps = { ...headerGroup.getHeaderGroupProps() };
        delete groupProps.key;
        return groupProps;
    };

    return (
        <Fragment>
            <Card className={`${props.tableCardClass ? props.tableCardClass : ''} ${props.noCardHeight ? 'p-0' : 'card-height-100 p-0'}`}>
                {isGlobalFilter && <CardHeader className={props.hideHeaderPadding ? 'px-0' : ''}>{TopFilterPanel()}</CardHeader>}
                <CardBody className={props.loading ? 'p-0' : ''}>
                    {props.loading ? (
                        <TableSkelton />
                    ) : (
                        <React.Fragment>
                            <div className={divClass}>
                                <Table
                                    hover={data?.length > 0}
                                    key={getTableProps().key}
                                    className={tableClass + ' overflow-x-hidden'}
                                    {...tabProps}
                                >
                                    <thead className={theadClass}>
                                        {headerGroups.map((headerGroup, index) => (
                                            <tr className={trClass} key={index} {...headerGroupProps(headerGroup)}>
                                                {headerGroup.headers.map((column, ind) => {
                                                    let sortByProps = { ...column.getSortByToggleProps() };
                                                    delete sortByProps.key;
                                                    return column.customHeader ? (
                                                        <th
                                                            key={ind}
                                                            className={
                                                                (thClass ? thClass : '') + ' ' + (column.thClass ? column.thClass : '')
                                                            }
                                                        >
                                                            {prepareCustomHeader(column)}
                                                        </th>
                                                    ) : column.sortable ? (
                                                        <th
                                                            key={ind}
                                                            className={
                                                                (thClass ? thClass : '') + ' ' + (column?.thClass ? column?.thClass : '')
                                                            }
                                                            {...sortByProps}
                                                        >
                                                            {column.render('Header')}
                                                            {generateSortingIndicator(column)}
                                                            {/* <Filter column={column} /> */}
                                                        </th>
                                                    ) : (
                                                        <th
                                                            key={ind}
                                                            className={
                                                                (thClass ? thClass : '') + ' ' + (column?.thClass ? column?.thClass : '')
                                                            }
                                                            // {...column.getSortByToggleProps()}
                                                        >
                                                            {column.render('Header')}
                                                            {/* {generateSortingIndicator(column)} */}
                                                            {/* <Filter column={column} /> */}
                                                        </th>
                                                    );
                                                })}
                                            </tr>
                                        ))}
                                    </thead>

                                    <tbody {...tableProps}>
                                        {page?.length > 0 ? (
                                            page.map((row, index) => {
                                                prepareRow(row);
                                                return (
                                                    <Fragment key={row.getRowProps().key}>
                                                        <tr className={row.original.bgClassGroup ? row.original.bgClassGroup : ''}>
                                                            {row.cells.map((cell, ind) => {
                                                                return (
                                                                    <td
                                                                        className={cell.column.className ? cell.column.className : ''}
                                                                        key={ind}
                                                                        {...getCellProps(cell)}
                                                                    >
                                                                        {cell.column.customCell ? (
                                                                            prepareCustomBody(cell, row, index)
                                                                        ) : (
                                                                            <EllipsisToolTip options={{ effect: 'solid', place: 'bottom' }}>
                                                                                {cell.render('Cell')}
                                                                            </EllipsisToolTip>
                                                                        )}
                                                                    </td>
                                                                );
                                                            })}
                                                        </tr>
                                                    </Fragment>
                                                );
                                            })
                                        ) : (
                                            <tr>
                                                <td className="fs-14" colSpan={columns?.length}>
                                                    {emptyMessage ? emptyMessage : 'No Records Found.'}
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </div>
                            {!props.hidePagination && (
                                <>
                                    {(totalRecords !== undefined ? totalRecords > 5 : globalFilteredRows?.length > 5) && (
                                        <Pagination
                                            activePage={onPageChange ? (pageNum ? pageNum : activePage) : pageIndex + 1}
                                            itemsCountPerPage={customPageSize ? customPageSize : pageSize}
                                            totalItemsCount={totalRecords ? totalRecords : globalFilteredRows?.length}
                                            pageRangeDisplayed={3}
                                            marginTopClass={props.marginTopClass}
                                            onChange={handlePageChange}
                                            hideFirstLastPages={false}
                                        />
                                    )}
                                </>
                            )}
                            {props.tokenPagination && (
                                <>
                                    <div className="d-flex align-items-center justify-content-end gap-2 mt-6">
                                        <div
                                            className={`d-flex align-items-center justify-content-center px-2 py-1 border border-1 rounded-0_25 page-font-size ${
                                                props.disablePrevious ? 'pe-none opacity-50 text-muted' : 'text-primary cursor-pointer'
                                            }`}
                                            onClick={props.handlePrevious}
                                        >
                                            Previous
                                        </div>
                                        <div
                                            className={`d-flex align-items-center justify-content-center px-2 py-1 border border-1 rounded-0_25 page-font-size ${
                                                props.disableNext ? 'pe-none opacity-50 text-muted' : 'text-primary cursor-pointer'
                                            }`}
                                            onClick={props.handleNext}
                                        >
                                            Next
                                        </div>
                                    </div>
                                </>
                            )}
                        </React.Fragment>
                    )}
                </CardBody>
            </Card>
        </Fragment>
    );
};

export default TableContainer;
