import React, { useState, useEffect, useCallback } from 'react';
import classnames from 'classnames';
import { Card, CardBody, CardHeader, Col, Collapse, Container, Input, Label, Row } from 'reactstrap';
import { useHistory, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Select from 'react-select';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import {
    IsAuthorized,
    TenantConfig,
    Tooltip,
    convertUTCtoIST,
    getDate,
    getFormTypeAndRecordId,
    windowsConvertBytesTo
} from '../../../Components/Common/Util';
import { priviliges } from '../../../Components/constants/constants';
import windowslogo from '../../../assets/images/windows/svg/common/windowslogo.svg';
import hardwareIcon from '../../../assets/images/common/svg/devicesViewIcons/hardware.svg';
import softwareIcon from '../../../assets/images/common/svg/devicesViewIcons/software.svg';
import applicationsIcon from '../../../assets/images/common/svg/devicesViewIcons/applications.svg';
import deviceSecurity from '../../../assets/images/common/svg/devicesViewIcons/deviceSecurity.svg';
import factoryReset from '../../../assets/images/common/png/common/factoryReset.png';
import rebootDevice from '../../../assets/images/common/png/common/rebootDevice.png';
import lockDevice from '../../../assets/images/common/png/common/lockDevice.png';
import scream from '../../../assets/images/common/png/common/scream.png';
import shutdown from '../../../assets/images/common/png/devicesViewIcons/devicePower.png';
import syncDevice from '../../../assets/images/common/png/common/sync.png';
import Loader from '../../../Components/Common/Loader';
import { useEnv } from '../../../envContext';
import { APIClient } from '../../../helpers/api_helper';

import * as domains from '../../../helpers/domain_helper';
import * as url from '../../../helpers/url_helper';
import toastMessages from '../../../common/messages/toastMessages';
import DeleteModal from '../../../Components/Common/DeleteModal';
import EllipsisToolTip from '../../../Components/Common/Tooltip/Tooltip';
import OffcanvasModal from '../../../Components/Common/OffcanvasModal';
import TableContainer from '../../../Components/Common/TableContainer';
import { moveGroupsSchema } from '../../AndroidEnterprise/Devices/TableSchema';
import Pagination from '../../../Components/Common/Pagination/Pagination';
import CommonModal from '../../../Components/Common/CommonModal';
import { Circle, GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import greenMarker from '../../../assets/images/common/svg/common/greenmarker.svg';
import NoRecordsFound from '../../../Components/Common/NoRecordsFound';
import Geocode from 'react-geocode';
import {
    AGENTAPP_STATUS,
    ANTISPYWARE_STATUS,
    ANTIVIRUS_STATUS,
    BITLOCKER_STATUS,
    deviceAppsTableSchema,
    FIREWALL_STATUS
} from './WindowsDevicesConstants';

const ViewWindowsDevices = () => {
    document.title = 'View Windows Device';
    const api = new APIClient();
    const history = useHistory();
    const urlconf = useEnv();
    const formTypeAndId = getFormTypeAndRecordId(window.location.hash);
    let recordID = formTypeAndId['recordID'];

    const tenantConfig = TenantConfig();

    const [loading, setLoading] = useState(false);

    const [windowsDetails, setWindowsDetails] = useState({});
    const [type, setType] = useState('');
    const [policy, setPolicy] = useState('');
    const [policyOptions, setPolicyOptions] = useState([]);
    const [showPolicyDropdown, setShowPolicyDropdown] = useState(false);
    const [policyBackup, setPolicyBackup] = useState('');
    const [actionModal, setActionModal] = useState(false);
    const [actionText, setActionText] = useState('');
    const [modalData, setModalData] = useState({ message: '', image: '' });
    const [commandHistory, setCommandHistory] = useState([]);
    const [commandPage, setCommandPage] = useState({ page: 1, size: 20, length: 0, device: recordID });
    const [showDeviceGroup, setShowDeviceGroup] = useState(false);
    const [selectedDeviceGroup, setSelectedDeviceGroup] = useState('');
    const [defaultGroup, setDefaultGroup] = useState('');
    const [deviceGroupOptions, setDeviceGroupsOptions] = useState([]);
    const [deviceGroupOptionsBackup, setDeviceGroupsOptionsBackup] = useState([]);
    const [subBreadcrumbItems, setSubBreadcrumbItems] = useState([{ name: 'Main Group' }]);
    const [showModal, setShowModal] = useState(false);
    const [userObj, setUserObj] = useState({ username: '', password: '' });
    const [deviceName, setDeviceName] = useState('');
    const [removeUsername, setRemoveUsername] = useState('');
    const [offcanvasModal, setOffcanvasModal] = useState(false);
    const [col, setCol] = useState({});
    const [locationsHistory, setLocationsHistory] = useState([]);
    const [locationsHistoryTotalRecords, setLocationHistoryTotalRecords] = useState(0);
    const [activePage, setActivePage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [localUsers, setLocalUsers] = useState([]);
    const [applications, setApplications] = useState([]);

    const actionItems = [
        {
            id: 'map',
            label: 'Show on Map',
            show: !!tenantConfig?.WINDOWS_DEVICE_ACTIONS_MONITORING?.includes('MAP_VIEW'),
            disabled: !windowsDetails?.enroll?.issupervised,
            disabledTooltip: 'This device is not supervised.',
            icon: 'ri-road-map-line',
            clickFunction: () => {
                setShowModal(true);
            },
            condition: windowsDetails.showActions
        },
        // eslint-disable-next-line multiline-comment-style
        // {
        //     id: 'add_user',
        //     label: 'Add User',
        //     show: !!tenantConfig?.WINDOWS_DEVICE_ACTIONS_MONITORING?.includes('ADD_USER'),
        //     disabled: !windowsDetails?.enroll?.issupervised,
        //     icon: 'ri-user-add-line',
        //     clickFunction: () => {
        //         setModalData({
        //             message: 'Are you sure you want to add user for device?',
        //             image: lockDevice,
        //             actionBody: {
        //                 command: 'addLocalAccount',
        //                 cmdtype: 'WINDOWS'
        //             },
        //             toastMessage: toastMessages.addUser
        //         });
        //         setType('add_user');
        //         setActionModal(true);
        //         setActionText('Submit');
        //     },
        //     condition: windowsDetails.showActions
        // },
        // {
        //     id: 'remove_device_user',
        //     label: 'Remove User',
        //     show: !!tenantConfig?.WINDOWS_DEVICE_ACTIONS_MONITORING?.includes('REMOVE_DEVICE_USER'),
        //     disabled: !windowsDetails?.enroll?.issupervised,
        //     icon: 'ri-user-minus-line',
        //     clickFunction: () => {
        //         setModalData({
        //             message: 'Are you sure you want to remove device user?',
        //             image: lockDevice,
        //             actionBody: {
        //                 script: 'remove_device_user',
        //                 cmdtype: 'AGENT'
        //             },
        //             toastMessage: toastMessages.removeDeviceUser
        //         });
        //         setType('remove_device_user');
        //         setActionModal(true);
        //         setActionText('Submit');
        //     },
        //     condition: windowsDetails.showActions
        // },
        // {
        //     id: 'change_password',
        //     label: 'Change Password',
        //     show: !!tenantConfig?.WINDOWS_DEVICE_ACTIONS_MONITORING?.includes('CHANGE_PASSWORD'),
        //     disabled: !windowsDetails?.enroll?.issupervised,
        //     icon: 'ri-lock-password-line',
        //     clickFunction: () => {
        //         setModalData({
        //             message: 'Are you sure you want to change local user password?',
        //             image: lockDevice,
        //             actionBody: {
        //                 script: 'change_password',
        //                 cmdtype: 'AGENT'
        //             },
        //             toastMessage: toastMessages.changePassword
        //         });
        //         setType('change_password');
        //         setActionModal(true);
        //         setActionText('Submit');
        //     },
        //     condition: windowsDetails.showActions
        // },
        {
            id: 'change_device_name',
            label: 'Change Device Name',
            show: !!tenantConfig?.WINDOWS_DEVICE_ACTIONS_MONITORING?.includes('CHANGE_DEVICE_NAME'),
            disabled: !windowsDetails?.enroll?.issupervised,
            icon: 'ri-computer-line',
            clickFunction: () => {
                setModalData({
                    message: 'Are you sure you want to change device name?',
                    image: lockDevice,
                    actionBody: {
                        script: 'change_device_name',
                        cmdtype: 'AGENT'
                    },
                    toastMessage: toastMessages.changeDeviceName
                });
                setType('change_device_name');
                setActionModal(true);
                setActionText('Submit');
            },
            condition: windowsDetails.showActions
        },
        {
            id: 'lock_device',
            label: 'Lock',
            show: !!tenantConfig?.WINDOWS_DEVICE_ACTIONS_MONITORING?.includes('LOCK'),
            disabled: !windowsDetails?.enroll?.issupervised,
            icon: 'ri-phone-lock-line',
            clickFunction: () => {
                setModalData({
                    message: 'Are you sure you want to lock device?',
                    image: lockDevice,
                    actionBody: {
                        script: 'lock_device',
                        cmdtype: 'AGENT'
                    },
                    toastMessage: toastMessages.lockDevice
                });
                setType('lock_device');
                setActionModal(true);
                setActionText('Yes, Lock it!');
            },
            condition: windowsDetails.showActions
        },
        {
            id: 'scream',
            label: 'Scream',
            show: !!tenantConfig?.WINDOWS_DEVICE_ACTIONS_MONITORING?.includes('SCREAM'),
            disabled: !windowsDetails?.enroll?.issupervised,
            icon: 'ri-volume-up-line',
            clickFunction: () => {
                setModalData({
                    message: 'Are you sure you want to activate scream?',
                    image: scream,
                    actionBody: {
                        script: 'play_buzzer',
                        cmdtype: 'AGENT',
                        params: {
                            frequency: '700',
                            time: '2500'
                        }
                    },
                    toastMessage: toastMessages.screamRequested
                });
                setType('scream');
                setActionModal(true);
                setActionText('Yes, Scream!');
            },
            condition: windowsDetails.showActions
        },
        {
            id: 'shutdown',
            label: 'Shutdown',
            show: !!tenantConfig?.WINDOWS_DEVICE_ACTIONS_MONITORING?.includes('SHUTDOWN'),
            disabled: !windowsDetails?.enroll?.issupervised,
            icon: 'ri-shut-down-line',
            clickFunction: () => {
                setModalData({
                    message: 'Are you sure you want to shutdown device?',
                    image: shutdown,
                    actionBody: {
                        script: 'shutdown_device',
                        cmdtype: 'AGENT'
                    },
                    toastMessage: toastMessages.shutdownDevice
                });
                setType('scream');
                setActionModal(true);
                setActionText('Yes, Shutdown it!');
            },
            condition: windowsDetails.showActions
        },
        {
            id: 'reboot',
            label: 'Reboot',
            show: !!tenantConfig?.WINDOWS_DEVICE_ACTIONS_MONITORING?.includes('REBOOT'),
            disabled: !windowsDetails?.enroll?.issupervised,
            disabledTooltip: 'This device is not supervised.',
            icon: 'ri-creative-commons-sa-line',
            clickFunction: () => {
                setModalData({
                    message: 'Are you sure you want to reboot device?',
                    image: rebootDevice,
                    actionBody: {
                        command: 'Reboot',
                        cmdtype: 'WINDOWS',
                        params: {
                            Exec: {
                                Item: {
                                    Target: {
                                        LocURI: './Device/Vendor/MSFT/Reboot/RebootNow'
                                    }
                                }
                            }
                        }
                    },
                    toastMessage: toastMessages.rebootDevice
                });
                setType('reboot');
                setActionModal(true);
                setActionText('Yes, Reboot it!');
            },
            condition: windowsDetails.showActions
        },
        {
            id: 'erase_data',
            label: 'Erase Data',
            show: !!tenantConfig?.WINDOWS_DEVICE_ACTIONS_MONITORING?.includes('ERASE_DEVICE'),
            icon: 'ri-settings-5-line',
            clickFunction: () => {
                setModalData({
                    message: 'Are you sure you want to erase data?',
                    image: factoryReset,
                    actionBody: {
                        command: 'Erase',
                        cmdtype: 'WINDOWS',
                        params: {
                            Exec: {
                                Item: {
                                    Target: {
                                        LocURI: './Device/Vendor/MSFT/RemoteWipe/doWipe'
                                    },
                                    Data: 1
                                }
                            }
                        }
                    },
                    toastMessage: toastMessages.eraseDevice
                });
                setType('erase_data');
                setActionModal(true);
                setActionText('Yes, Erase Data!');
            },
            condition: windowsDetails.showActions
        },
        {
            id: 'sync',
            label: 'Sync',
            show: !!tenantConfig?.WINDOWS_DEVICE_ACTIONS_MONITORING?.includes('SYNC'),
            disabled: !windowsDetails?.enroll?.issupervised,
            disabledTooltip: 'This device is not supervised.',
            icon: 'ri-device-recover-line',
            clickFunction: () => {
                setModalData({
                    message: 'Are you sure you want to Sync this Device?',
                    image: syncDevice,
                    actionBody: {
                        command: 'Sync'
                    },
                    toastMessage: toastMessages.syncDevice
                });
                setType('sync');
                setActionModal(true);
                setActionText('Yes, Sync it!');
            },
            condition: windowsDetails.showActions
        },
        {
            id: 'refresh',
            label: 'Refresh',
            show: !!tenantConfig?.WINDOWS_DEVICE_ACTIONS_MONITORING?.includes('REFRESH'),
            disabled: !windowsDetails?.enroll?.issupervised,
            disabledTooltip: 'This device is not supervised.',
            icon: 'ri-refresh-line',
            clickFunction: () => {
                setModalData({
                    message: 'Are you sure you want to Refresh Device?',
                    image: syncDevice,
                    actionBody: {
                        command: 'GetReport',
                        cmdtype: 'WINDOWS'
                    },
                    toastMessage: toastMessages.refreshDevice
                });
                setType('refresh');
                setActionModal(true);
                setActionText('Yes, Refresh it!');
            },
            condition: windowsDetails.showActions
        },
        {
            id: 'unenroll_device',
            label: 'Unenroll Device',
            show: !!tenantConfig?.WINDOWS_DEVICE_ACTIONS_MONITORING?.includes('UNENROLL_DEVICE'),
            icon: 'ri-link-unlink',
            clickFunction: () => {
                setModalData({
                    message: 'Are you sure you want to Unenroll Device?',
                    image: factoryReset,
                    actionBody: {
                        command: 'UnenrolDevice',
                        cmdtype: 'WINDOWS',
                        params: {
                            Exec: {
                                Item: {
                                    Target: {
                                        LocURI: './Vendor/MSFT/DMClient/Provider/Tectoro/Unenroll'
                                    }
                                }
                            }
                        }
                    },
                    toastMessage: toastMessages.unenrollDevice
                });
                setType('unenroll_device');
                setActionModal(true);
                setActionText('Yes, Unenroll it!');
            },
            condition: windowsDetails.showActions
        }
    ];

    const monitoringItems = [
        {
            label: 'Location History',
            icon: 'ri-map-pin-line',
            show: !!tenantConfig?.WINDOWS_DEVICE_ACTIONS_MONITORING?.includes('LOCATION_HISTORY'),
            clickFunction: () => {
                setOffcanvasModal(true);
                getLocationHistory({ page: 1, size: 20 });
            },
            condition: windowsDetails.showActions
        }
    ];

    const commands = {
        InstallProfile: { label: 'Install Profile', icon: 'ri-install-line' },
        RemoveProfile: { label: 'Remove Profile', icon: 'ri-delete-bin-line' },
        GetReport: { label: 'Report', icon: 'ri-information-line' },
        AddWiFi: { label: 'Add Wifi', icon: 'ri-wifi-line' },
        RemoveWiFi: { label: 'Remove Wifi', icon: 'ri-wifi-off-line' },
        InstallApplication: { label: 'Install Application', icon: 'ri-tablet-line' },
        InstallApplicationExec: { label: 'Application Execution', icon: 'ri-apps-2-line' },
        UninstallApplication: { label: 'Uninstall Application', icon: 'ri-delete-bin-line' },
        UnenrolDevice: { label: 'Unenroll Device', icon: 'ri-link-unlink' },
        Reboot: { label: 'Reboot Device', icon: 'ri-creative-commons-sa-line' },
        Erase: { label: 'Erase Device', icon: 'ri-android-line' },
        addLocalAccount: { label: 'Add User', icon: 'ri-user-add-line' }
    };

    const localUsersSchema = [
        {
            Header: 'Username',
            accessor: 'Name',
            sortable: true
        },
        {
            Header: 'Last Login',
            accessor: 'LastLogon',
            sortable: true
        },
        {
            Header: 'Actions',
            accessor: 'html',
            width: 30,
            customCell: true,
            fieldType: 'actions',
            sortable: false,
            deleteFadable: !!tenantConfig?.WINDOWS_DEVICE_ACTIONS_MONITORING?.includes('REMOVE_DEVICE_USER'),
            fadeTooltipKeyDelete: 'deleteTooltip',
            fade: 'doNotDelete'
        }
    ];

    const onCloseClick = () => {
        setShowModal(false);
    };

    const handleModalBody = () => {
        const config = useEnv();
        const mapStyles = { width: '100%', height: '100%' };
        const [mapObj, setMapObj] = useState(null);
        const [address, setAddress] = useState('');

        Geocode.setApiKey(config.REACT_APP_MAP_TOKEN);
        Geocode.setLanguage('en');

        useEffect(() => {
            if (showModal) {
                getData();
            }
        }, [showModal]);

        const getData = async () => {
            const lat = windowsDetails?.reportInfo?.cspreport?.['_/Vendor/MSFT/RemoteFind/Location/Latitude'];
            const lng = windowsDetails?.reportInfo?.cspreport?.['_/Vendor/MSFT/RemoteFind/Location/Longitude'];
            const center =
                lat && lng
                    ? {
                          lat: parseFloat(lat),
                          lng: parseFloat(lng)
                      }
                    : null;
            if (center) {
                let place = await getAddress(center);
                setAddress(place);
            }
        };

        const getAddress = async (latLng) => {
            let addressString = '';
            await Geocode.fromLatLng(latLng.lat, latLng.lng)
                .then((resp) => {
                    resp = config.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp.results) {
                        resp.results[0].address_components.forEach((addressObj, ind) => {
                            addressString = addressString + (ind !== 0 ? ', ' : '') + addressObj.long_name?.toString();
                        });
                    }
                })
                .catch((_err) => {
                    toast.error(toastMessages.addressNotFound);
                });

            return addressString;
        };
        const onLoad = useCallback(async function callback(map) {
            setMapObj(map);
        }, []);

        const onUnmount = useCallback(function callback(map) {
            setMapObj(null);
        }, []);

        const { isLoaded } = useJsApiLoader({
            id: 'google-map-script',
            googleMapsApiKey: config.REACT_APP_MAP_TOKEN
        });

        const lat = windowsDetails?.reportInfo?.cspreport?.['_/Vendor/MSFT/RemoteFind/Location/Latitude'];
        const lng = windowsDetails?.reportInfo?.cspreport?.['_/Vendor/MSFT/RemoteFind/Location/Longitude'];
        const center =
            lat && lng
                ? {
                      lat: parseFloat(lat),
                      lng: parseFloat(lng)
                  }
                : null;

        if (!center) {
            return <NoRecordsFound />;
        }

        return (
            <>
                <div className="text-grey-text">Location</div>
                <div className="fw-medium">{address}</div>
                <div className="heightVh-60">
                    {isLoaded && (
                        <GoogleMap mapContainerStyle={mapStyles} center={center} zoom={16} onLoad={onLoad} onUnmount={onUnmount}>
                            <Marker
                                position={center}
                                icon={{
                                    url: greenMarker,
                                    scaledSize: new window.google.maps.Size(64, 64)
                                }}
                            />
                            <Circle
                                center={center}
                                options={{
                                    fillColor: '#59AF5067',
                                    color: '#0D807B',
                                    strokeColor: '#0D807B',
                                    strokeOpacity: 0.8,
                                    strokeWeight: 2,
                                    fillOpacity: 0.35
                                }}
                            />
                        </GoogleMap>
                    )}
                </div>
            </>
        );
    };

    const onCancelPolicy = () => {
        setPolicy(policyBackup);
        setShowPolicyDropdown(false);
    };

    const onSubmitPolicy = () => {
        setLoading(true);
        setShowPolicyDropdown(false);
        api.patch(url.DEVICES + '/' + recordID, { 'assignment.policy': policy.code }, false, domains.WINDOWS_V1)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    handlePromise();
                }
                setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    const selectComponent = (key) => {
        return (
            <React.Fragment>
                <Select
                    getOptionValue={(option) => option.code}
                    getOptionLabel={(option) => option.name}
                    id={key}
                    className="w-100"
                    name={'name'}
                    options={policyOptions}
                    value={policy}
                    isSearchable={true}
                    placeholder={`Select ${key}`}
                    classNamePrefix={'custom-select'}
                    onChange={(e) => setPolicy(e)}
                    noOptionsMessage={() => 'No data found'}
                />
                <i title="Close" className="ri-close-line ms-2 me-2 fs-18 cursor-pointer link-danger" onClick={() => onCancelPolicy()} />
                <i title="Submit" className="ri-check-line fs-18 cursor-pointer link-primary" onClick={() => onSubmitPolicy()} />
            </React.Fragment>
        );
    };

    const toggleGroupCanvas = useCallback(async () => {
        setDeviceGroupsOptions(deviceGroupOptionsBackup);
        setSubBreadcrumbItems([{ name: 'Main Group' }]);
        setSelectedDeviceGroup({ ...defaultGroup });
        setShowDeviceGroup(!showDeviceGroup);
    }, [showDeviceGroup]);

    const handleSubBreadcrumb = (group, ind) => {
        if (ind !== subBreadcrumbItems.length - 1) {
            let crumbs = [...subBreadcrumbItems];
            crumbs.splice(ind + 1);
            setSubBreadcrumbItems(crumbs);
            if (!group.key) {
                setSelectedDeviceGroup({});
                let groupsArr = JSON.parse(JSON.stringify(deviceGroupOptionsBackup));
                groupsArr.forEach((groupObj) => delete groupObj.checked);
                setDeviceGroupsOptions(groupsArr);
            } else {
                setSelectedDeviceGroup(group);
                let groupsArr = JSON.parse(JSON.stringify(group.children));
                groupsArr.forEach((groupObj) => delete groupObj.checked);
                setDeviceGroupsOptions(groupsArr);
            }
        }
    };

    const handleMoveView = (_cell, row) => {
        setSelectedDeviceGroup(row.original);
        setSubBreadcrumbItems([...subBreadcrumbItems, row.original]);
        setDeviceGroupsOptions(row.original.children ? row.original.children : []);
    };

    const onChangeGroup = () => {
        setLoading(true);
        setDeviceGroupsOptions(deviceGroupOptionsBackup);
        setSubBreadcrumbItems([{ name: 'Main Group' }]);
        setShowDeviceGroup(false);
        api.patch(url.DEVICES + '/' + recordID, { 'assignment.group': selectedDeviceGroup.code }, false, domains.WINDOWS_V1)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    handlePromise();
                }
                setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    const deviceGroupBody = () => {
        return (
            <React.Fragment>
                <div className={'mt-5 mb-4 d-flex align-items-center'}>
                    {subBreadcrumbItems?.map((crumbs, ind) => (
                        <React.Fragment key={ind}>
                            {ind !== subBreadcrumbItems?.length - 1 ? (
                                <span
                                    className={`fw-medium font-size-12 text-muted ${
                                        ind !== subBreadcrumbItems?.length - 1 ? ' cursor-pointer' : ''
                                    }`}
                                    onClick={() => handleSubBreadcrumb(crumbs, ind)}
                                >
                                    {crumbs.name}
                                </span>
                            ) : (
                                <span
                                    className={`fw-medium font-size-12 ${ind !== subBreadcrumbItems?.length - 1 ? ' cursor-pointer' : ''}`}
                                    style={{ color: crumbs.avatarColor }}
                                >
                                    {crumbs.name}
                                </span>
                            )}
                            {ind !== subBreadcrumbItems?.length - 1 && <i className="ri-arrow-right-s-line ms-2" />}
                        </React.Fragment>
                    ))}
                </div>
                <TableContainer
                    loading={loading}
                    data={deviceGroupOptions}
                    columns={moveGroupsSchema}
                    handleView={handleMoveView}
                    hideAvatar={true}
                    customPageSize={10}
                    totalRecords={deviceGroupOptions.length ? deviceGroupOptions.length : 0}
                    editor={priviliges.DEVICEGROUP_EDITOR}
                    reader={priviliges.DEVICEGROUP_READER}
                    className="custom-header-css"
                    tableHeader="All Device Groups"
                    divClass="table-responsive table-card"
                    tableClass="table table-nowrap table-border table-centered align-middle"
                    theadClass="bg-light text-muted"
                />
            </React.Fragment>
        );
    };

    const handleDeviceGroup = (groupArr, groupCode) => {
        let deviceGroupObj = groupArr.find((group) => group.code === groupCode);
        return deviceGroupObj ? deviceGroupObj?.name + ' - ' + deviceGroupObj?.code : undefined;
    };

    const setChildGroups = (groupArr, groupStatus) => {
        groupArr.forEach((group) => {
            group.label = group.name;
            group.key = group.code;
            group.value = group.code;
            group.nameCode = group.name + ' - ' + group.code;
            group.deviceCount = groupStatus?.[group.code] ? groupStatus?.[group.code] : 0;
            if (group.children) group.children = setChildGroups(group.children, groupStatus);
            group.childsCount = group.children.length ? group.children.length : 0;
        });
        return groupArr;
    };

    const setGroups = (groups, arr) => {
        groups.forEach((group) => {
            arr.push(group);
            if (group.children) arr = setGroups(group.children, arr);
        });
        return arr;
    };

    const returnData = (key, val, edit) => {
        return edit && key === 'Device Group' ? (
            <tr className="row w-100">
                <td className="fw-medium w-50 col-6 ps-5 py-2 d-flex align-items-center">{key}</td>
                <td className="w-50 py-2 col-6 word-wrap-break">
                    <div className="d-flex align-items-center">
                        {val ? val : '—'}
                        <IsAuthorized
                            privRequired={priviliges.WINDOWSDEVICE_EDITOR}
                            yes={() => (
                                <i
                                    title="Edit"
                                    className="ri-edit-box-line ps-4 fs-18 cursor-pointer link-secondary"
                                    onClick={() => setShowDeviceGroup(true)}
                                />
                            )}
                        />
                    </div>
                </td>
            </tr>
        ) : edit && key === 'Policy' ? (
            <tr className="row w-100">
                <td className="fw-medium w-50 col-6 ps-5 py-2 d-flex align-items-center">{key}</td>
                <td className="w-50 py-2 col-6 word-wrap-break">
                    <div className="d-flex align-items-center">
                        {!showPolicyDropdown ? (
                            <>
                                {val ? val : '—'}
                                <IsAuthorized
                                    privRequired={priviliges.WINDOWSDEVICE_EDITOR}
                                    yes={() => (
                                        <i
                                            title="Edit"
                                            className="ri-edit-box-line ps-4 fs-18 cursor-pointer link-secondary"
                                            onClick={() => setShowPolicyDropdown(true)}
                                        />
                                    )}
                                />
                            </>
                        ) : (
                            selectComponent(key)
                        )}
                    </div>
                </td>
            </tr>
        ) : (
            <tr className="row w-100">
                <td className="fw-medium w-50 py-2 ps-5 col-6">{key}</td>
                <td className="text-grey-text fw-medium w-50 py-2 col-6">
                    <div className="d-flex align-items-center">
                        <EllipsisToolTip options={{ effect: 'solid', place: 'bottom' }}>{val ? val : '—'}</EllipsisToolTip>
                    </div>
                </td>
            </tr>
        );
    };

    useEffect(() => {
        setLoading(true);
        handlePromise();
    }, []);

    const handlePromise = () => {
        const devicePromise = new Promise((resolve, reject) => {
            api.get(url.DEVICES + '/' + recordID, '', domains.WINDOWS_V1)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp.data) resolve(resp.data);
                    else reject('Device failed');
                })
                .catch((err) => reject('Device failed'));
        });
        const reportPromise = new Promise((resolve, reject) => {
            api.get(url.DEVICES + '/' + recordID + '/report', '', domains.WINDOWS_V1)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp.data) resolve(resp.data);
                    else reject('Info failed');
                })
                .catch((err) => reject('Info failed'));
        });
        const policyPromise = new Promise((resolve, reject) => {
            let params = { page: 1, size: 100 };
            api.get(url.POLICIES, params, domains.WINDOWS_V1)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp?.data) resolve(resp.data);
                    else reject('Policy failed.');
                })
                .catch((err) => reject('Policy failed.'));
        });
        const getDeviceGroups = new Promise((resolve, reject) => {
            let params = { page: 1, size: 100 };
            api.get(url.DEVICE_GROUPS, params, domains.IDM)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp?.data) resolve(resp.data);
                    else reject('Group failed.');
                })
                .catch((_err) => reject('Group failed.'));
        });
        const actionHistoryPromise = new Promise((resolve, reject) => {
            api.get(url.WINDOWS_COMMANDS, { device: recordID, page: 1, size: 20 }, domains.WINDOWS_V1)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp?.data) resolve(resp);
                    else reject('History failed.');
                })
                .catch((_err) => reject('History failed'));
        });
        Promise.allSettled([devicePromise, reportPromise, policyPromise, getDeviceGroups, actionHistoryPromise])
            .then((result) => {
                let groupArr = [];
                let deviceData = {};
                if (result[0].status === 'fulfilled') {
                    deviceData = { ...result[0].value };
                    deviceData.serial = deviceData?.refs?.find((ref) => ref.type === 'SERIAL')?.value || '—';
                    deviceData.showActions = deviceData.enroll?.status === 'ACTIVE';
                }
                if (result[1].status === 'fulfilled') {
                    deviceData.reportInfo = { ...result[1].value };
                    setDeviceName(deviceData.reportInfo?.cspreport?.['_/DevDetail/Ext/Microsoft/DeviceName']);
                    if (
                        'agentreport' in result[1].value &&
                        result[1].value.agentreport &&
                        'DeviceUsers' in result[1].value.agentreport &&
                        result[1].value.agentreport.DeviceUsers &&
                        result[1].value.agentreport.DeviceUsers?.length > 0
                    ) {
                        result[1].value.agentreport.DeviceUsers.forEach((data) => {
                            data.LastLogon = data.LastLogon ? convertUTCtoIST(data.LastLogon) : '—';
                            data.doNotDelete = data.Enabled;
                            data.deleteTooltip = 'You cannot delete the Administrator.';
                        });
                        setLocalUsers(result[1].value.agentreport.DeviceUsers);
                    }
                    if (
                        'agentreport' in result[1].value &&
                        result[1].value.agentreport &&
                        'InstalledApps' in result[1].value.agentreport &&
                        result[1].value.agentreport.InstalledApps &&
                        result[1].value.agentreport.InstalledApps?.length > 0
                    ) {
                        setApplications(result[1].value.agentreport.InstalledApps);
                    }
                }
                if (result[2].status === 'fulfilled') {
                    let polObj = result?.[2]?.value?.find((pol) => pol.code === result[0]?.value?.assignment?.policy);
                    if (polObj) result[0].value.assignment.policyName = polObj?.name + ' - ' + polObj?.code;
                    setPolicy(polObj);
                    setPolicyBackup(polObj);
                    setPolicyOptions(result[2].value);
                }
                if (result[3].status === 'fulfilled') {
                    if (result[3].value?.length > 0) {
                        result[3].value.forEach((group) => {
                            groupArr.push(group);
                            group.label = group.name;
                            group.key = group.code;
                            group.value = group.code;
                            group.nameCode = group.name + ' - ' + group.code;
                            if (group.children) {
                                group.children = setChildGroups(group.children);
                                groupArr = setGroups(group.children, groupArr);
                            }
                            group.childsCount = group.children.length ? group.children.length : 0;
                        });
                        result[0].value.assignment.groupName = handleDeviceGroup(groupArr, result?.[0]?.value?.assignment?.group);
                        setDeviceGroupsOptions(result[3].value);
                        setDeviceGroupsOptionsBackup(result[3].value);
                    }
                }
                if (result[4].status === 'fulfilled') {
                    result[4].value?.data?.forEach((value) => {
                        value.class = value.status === 'NEW' ? 'primary' : value.status === 'DONE' ? 'success' : 'danger';
                    });
                    setCommandPage({ ...commandPage, length: result[4]?.value?.totalRecords });
                    setCommandHistory(result[4].value?.data);
                }
                setWindowsDetails(deviceData);
                setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    const actionMessage = () => {
        return type === 'add_user' || type === 'change_password' ? (
            <React.Fragment>
                <div className="mt-3">
                    <div className="text-primary fw-semibold mb-3">
                        {type === 'add_user' && 'Add New User'}
                        {type === 'change_password' && 'Change Local User Password'}
                    </div>
                    <Row>
                        <Col xs={6} md={6} sm={6} lg={5} xl={5} className="d-flex align-items-center">
                            <Label className="mb-0 fw-medium d-flex align-items-center w-100">Username</Label>
                        </Col>
                        <Col xs={6} md={6} sm={6} lg={7} xl={7} className="mb-2">
                            <div className="form-check ">
                                <Input
                                    name={'username'}
                                    id={'username'}
                                    className={'form-control'}
                                    placeholder={'Enter username'}
                                    type={'text'}
                                    disabled={type === 'change_password'}
                                    onChange={(e) => setUserObj({ ...userObj, username: e.target.value.trim() })}
                                    value={userObj.username}
                                />
                            </div>
                        </Col>
                        <Col xs={6} md={6} sm={6} lg={5} xl={5} className="d-flex align-items-center">
                            <Label className="mb-0 fw-medium d-flex align-items-center w-100">Password</Label>
                        </Col>
                        <Col xs={6} md={6} sm={6} lg={7} xl={7} className="mb-2">
                            <div className="form-check ">
                                <Input
                                    name={'password'}
                                    id={'password'}
                                    className={'form-control'}
                                    placeholder={'Enter password'}
                                    type={'text'}
                                    onChange={(e) => setUserObj({ ...userObj, password: e.target.value })}
                                    value={userObj.password}
                                />
                            </div>
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        ) : type === 'change_device_name' ? (
            <React.Fragment>
                <div className="mt-3">
                    <div className="text-primary fw-semibold mb-3">Change Device Name</div>
                    <Row>
                        <Col xs={6} md={6} sm={6} lg={5} xl={5} className="d-flex align-items-center">
                            <Label className="mb-0 fw-medium d-flex align-items-center w-100">Device Name</Label>
                        </Col>
                        <Col xs={6} md={6} sm={6} lg={7} xl={7} className="mb-2">
                            <div className="form-check ">
                                <Input
                                    name={'devicename'}
                                    id={'devicename'}
                                    className={'form-control'}
                                    placeholder={'Enter devicename'}
                                    type={'text'}
                                    onChange={(e) => setDeviceName(e.target.value.trim())}
                                    value={deviceName}
                                    maxLength={15}
                                />
                            </div>
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        ) : type === 'remove_device_user' ? (
            <React.Fragment>
                <div className="mt-2">
                    <div className="text-primary fw-semibold mb-3">Are you sure you want to Remove User</div>
                    <Row>
                        <Col xs={6} md={6} sm={6} lg={5} xl={5} className="d-flex align-items-center">
                            <Label className="mb-0 fw-medium d-flex align-items-center w-100">Username</Label>
                        </Col>
                        <Col xs={6} md={6} sm={6} lg={7} xl={7} className="mb-2">
                            <div className="form-check ">
                                <Input
                                    name={'removeusername'}
                                    id={'removeusername'}
                                    className={'form-control'}
                                    placeholder={'Enter username'}
                                    type={'text'}
                                    disabled={type === 'remove_device_user'}
                                    onChange={(e) => setRemoveUsername(e.target.value.trim())}
                                    value={removeUsername}
                                />
                            </div>
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        ) : (
            <React.Fragment>
                <div className="mt-3">
                    <div className="mb-4">{modalData.message}</div>
                    <div className="mb-4 d-flex align-items-center justify-content-center">
                        <img src={modalData.image} alt="device" width={60} height={60} />
                    </div>
                    <div>
                        Serial: <span className="fw-semibold">{windowsDetails?.serial}</span>
                    </div>
                </div>
            </React.Fragment>
        );
    };

    const actionService = () => {
        setLoading(true);
        setActionModal(false);
        let actionsUrl = url.WINDOWS_COMMANDS;
        let body = { ...modalData?.actionBody, device: recordID };
        if (modalData?.actionBody?.command === 'Sync') {
            actionsUrl = actionsUrl + '/trigger';
            body = { device: recordID };
        }
        if (modalData?.actionBody.command === 'addLocalAccount') {
            body = { ...body, params: userObj };
        }
        if ('script' in modalData.actionBody) {
            actionsUrl = url.WINDOWS_SCRIPTS + `/${recordID}`;
            body = { ...modalData.actionBody };
            if (modalData.actionBody.script === 'change_password') {
                body = { ...body, params: userObj };
            } else if (modalData.actionBody.script === 'change_device_name') {
                body = {
                    ...body,
                    params: {
                        new_computer_name: deviceName
                    }
                };
            } else if (modalData.actionBody.script === 'remove_device_user') {
                body = {
                    ...body,
                    params: { username: removeUsername }
                };
            }
        }

        api.create(actionsUrl, body, false, domains.WINDOWS_V1)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    toast.success(modalData?.toastMessage);
                    handlePromise();
                } else setLoading(false);
            })
            .catch((err) => setLoading(false))
            .finally(() => {
                setUserObj({ username: '', password: '' });
                setDeviceName('');
                setRemoveUsername('');
            });
    };

    const commandReturnData = (command, ind) => {
        return (
            <tr className="w-100" key={ind}>
                <td className="fw-medium w-70">
                    <div className="d-flex align-items-center text-truncate">
                        <i
                            title={commands[command.command]?.label}
                            className={`${
                                commands[command.command]?.icon ? commands[command.command]?.icon : 'ri-file-unknow-line'
                            } me-2 fs-18 cursor-pointer link-secondary`}
                        />
                        {commands[command.command]?.label ? commands[command.command]?.label : command?.command}
                    </div>
                </td>
                <td className="w-30 word-wrap-break m-0">
                    <span className={'lh-sm fw-semibold text-truncate text-' + command?.class}>
                        {command.status?.substr(0, 1) + command.status?.substr(1)?.toLowerCase()}
                    </span>
                </td>
                <td className="text-info w-30 text-truncate word-wrap-break m-0" id={`reqdate-${ind}`}>
                    {command.reqdate ? getDate(command.reqdate) : '—'}
                </td>
            </tr>
        );
    };

    const handleCommandPageChange = (page) => {
        setLoading(true);
        getCommandHistory({ ...commandPage, page: page });
    };

    const getCommandHistory = (params) => {
        api.get(url.WINDOWS_COMMANDS, params, domains.WINDOWS_V1)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp?.data) {
                    resp?.data?.forEach((value) => {
                        value.class = value.status === 'NEW' ? 'primary' : value.status === 'DONE' ? 'success' : 'danger';
                    });
                    params = { ...params, length: resp?.totalRecords };
                    setCommandHistory(resp?.data);
                }
                setLoading(false);
            })
            .catch((err) => setLoading(false))
            .finally(() => {
                setCommandPage(params);
            });
    };

    const getLocationHistory = (paramsObj) => {
        setLoading(true);
        let params = {
            ...paramsObj,
            filter: {
                devices: [recordID]
            }
        };
        api.create(url.LOCATION_REPORT, params, false, domains.REPORTING)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.data?.length > 0) {
                    resp.data.forEach((ele) => {
                        ele.dateListed = getDate(ele.submitteddate);
                        ele.addressLine = `${location.address1} ${location.address2} ${location.city} ${location.state} ${location.country}`;
                    });
                    setLocationsHistory(resp.data);
                    setLocationHistoryTotalRecords(resp.totalRecords);
                }
                setLoading(false);
            })
            .catch((_err) => {
                setLoading(false);
            });
    };

    const toggle = useCallback(async () => {
        if (offcanvasModal) setOffcanvasModal(false);
        else setOffcanvasModal(true);
    }, [offcanvasModal]);

    const togglecol = (key) => {
        setCol({ ...col, [key]: !col[key] });
    };

    const handleLocationPageChange = (pageNumber) => {
        setActivePage(pageNumber);
        getLocationHistory({ page: pageNumber, size: 20 });
    };

    const locationHistoryBody = () => {
        return (
            <div>
                <div>
                    <div className="profile-timeline">
                        <div className="accordion accordion-flush" id="accordionFlushExample">
                            {locationsHistory?.length > 0 ? (
                                locationsHistory?.map((location, ind) => {
                                    return (
                                        <div className="accordion-item border-0" key={ind}>
                                            <div className="accordion-header" id="headingOne" onClick={() => togglecol(`col${ind}`)}>
                                                <Link
                                                    to="#"
                                                    className={classnames('accordion-button', 'p-2', 'shadow-none', {
                                                        collapsed: col[`col${ind}`]
                                                    })}
                                                >
                                                    <div className="d-flex align-items-center w-100">
                                                        <div className="flex-shrink-0 avatar-xxs">
                                                            <div className={'avatar-title rounded-circle bg-primary'}>
                                                                <i className="ri-map-pin-line"></i>
                                                            </div>
                                                        </div>
                                                        <div className="flex-grow-1 ms-3">
                                                            <Row className="fs-13 mb-0 fw-semibold">
                                                                <Col sm={6} className="text-truncate" id={`location-${ind}`}>
                                                                    {location.address1}
                                                                </Col>
                                                                {location.address1?.length > 20 &&
                                                                    Tooltip(`location-${ind}`, location.address1)}
                                                                {location.submitteddate && (
                                                                    <Col sm={6} className="text-truncate text-end">
                                                                        <span className="fw-normal text-end" id={`date-${ind}`}>
                                                                            {location.dateListed}
                                                                        </span>
                                                                        {Tooltip(`date-${ind}`, location.dateListed)}
                                                                    </Col>
                                                                )}
                                                            </Row>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                            <Collapse id="collapseOne" className="accordion-collapse" isOpen={!col[`col${ind}`]}>
                                                <div className="accordion-body ms-10 ps-1 pt-0">
                                                    <h6 className="mb-2">{location?.addressLine}</h6>
                                                </div>
                                            </Collapse>
                                        </div>
                                    );
                                })
                            ) : (
                                <div className="fs-13 d-flex align-items-center justify-content-center h-100">{'No Records Found.'}</div>
                            )}
                        </div>
                        {locationsHistoryTotalRecords > 20 && (
                            <Pagination
                                activePage={activePage}
                                itemsCountPerPage={pageSize}
                                totalItemsCount={locationsHistoryTotalRecords}
                                pageRangeDisplayed={3}
                                marginTopClass={'mt-1'}
                                onChange={handleLocationPageChange}
                                hideFirstLastPages={false}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    };

    const handleAddUser = () => {
        setModalData({
            message: 'Are you sure you want to add user for device?',
            image: lockDevice,
            actionBody: {
                command: 'addLocalAccount',
                cmdtype: 'WINDOWS'
            },
            toastMessage: toastMessages.addUser
        });
        setType('add_user');
        setActionModal(true);
        setActionText('Submit');
    };
    const handleDeleteUser = (row) => {
        setModalData({
            message: 'Are you sure you want to remove device user?',
            image: lockDevice,
            actionBody: {
                script: 'remove_device_user',
                cmdtype: 'AGENT'
            },
            toastMessage: toastMessages.removeDeviceUser
        });
        setType('remove_device_user');
        setActionModal(true);
        setActionText('Yes');
        setRemoveUsername(row.original.Name);
    };

    return (
        <React.Fragment>
            {loading && <Loader />}

            <div className={`page-content ${loading ? 'postion-relative mask' : ''}`}>
                <Container fluid>
                    <BreadCrumb
                        pageTitle="View Windows Device"
                        history={history}
                        homeLink="Dashboard"
                        showBack={true}
                        backLink="windowsdevices"
                    />
                    <Row>
                        <Col className="col-12">
                            <Card className="pt-3 pb-3 ps-2 pe-2 w-100 d-flex align-items-center mb-0">
                                <div className="w-100 d-flex align-items-center justify-content-between">
                                    <div className="d-flex align-items-center">
                                        <div className="fs-15 px-1 fw-medium">Serial Number :</div>
                                        <div className="fs-16 ps-2 text-info">{windowsDetails?.serial}</div>
                                    </div>
                                    <div className="d-flex align-items-center gap-4">
                                        <IsAuthorized
                                            privRequired={priviliges.WINDOWSDEVICE_EDITOR}
                                            yes={() => (
                                                <>
                                                    <div className="d-flex align-items-center justify-content-center gap-1">
                                                        {actionItems?.filter((action) => action.show)?.length > 0 && (
                                                            <div className="fs-15 d-flex justify-content-center align-items-center fw-medium">
                                                                Actions :
                                                            </div>
                                                        )}
                                                        {actionItems.map((action, ind) => {
                                                            return (
                                                                action.show && (
                                                                    <div
                                                                        key={ind}
                                                                        className={`width-35 height-35 d-flex align-items-center justify-content-center ${
                                                                            action.condition
                                                                                ? action.disabled
                                                                                    ? 'bg-muted'
                                                                                    : 'bg-447BD107 cursor-pointer'
                                                                                : ''
                                                                        }`}
                                                                        onClick={
                                                                            action.condition && !action.disabled
                                                                                ? action.clickFunction
                                                                                : undefined
                                                                        }
                                                                    >
                                                                        <i
                                                                            id={action.id}
                                                                            className={`${action.icon} fs-20 ${
                                                                                action.condition ? 'action-hover link-info' : 'text-muted'
                                                                            }`}
                                                                        />
                                                                        {Tooltip(
                                                                            action.id,
                                                                            action.disabled ? action.disabledTooltip : action.label
                                                                        )}
                                                                    </div>
                                                                )
                                                            );
                                                        })}
                                                    </div>
                                                    <div className="d-flex align-items-center justify-content-center gap-1">
                                                        {monitoringItems?.filter((action) => action.show)?.length > 0 && (
                                                            <div className="fs-15 d-flex justify-content-center align-items-center fw-medium">
                                                                Monitoring :
                                                            </div>
                                                        )}
                                                        {monitoringItems.map((monitor, ind) => {
                                                            return (
                                                                monitor.show && (
                                                                    <div
                                                                        key={ind}
                                                                        className={`width-35 height-35 d-flex align-items-center justify-content-center ${
                                                                            monitor.condition ? 'bg-447BD107 cursor-pointer' : ''
                                                                        }`}
                                                                        onClick={monitor.condition ? monitor.clickFunction : undefined}
                                                                    >
                                                                        <i
                                                                            id={monitor.icon}
                                                                            className={`${monitor.icon} fs-20 ${
                                                                                monitor.condition ? 'action-hover link-info' : 'text-muted'
                                                                            }`}
                                                                        />
                                                                        {Tooltip(monitor.icon, monitor.label)}
                                                                    </div>
                                                                )
                                                            );
                                                        })}
                                                    </div>
                                                </>
                                            )}
                                        />
                                    </div>
                                </div>
                            </Card>
                        </Col>
                        <Col xs={12} sm={12} md={4} xl={4} lg={4} className="d-flex flex-column g-3">
                            <Card className="card-height-100 mb-0 padding-bottom-50">
                                <CardHeader className="d-flex align-items-center flex-column">
                                    <>
                                        <div className="w-100 mt-3 d-flex justify-content-center">
                                            <img src={windowslogo} alt="" width={69} height={69} />
                                        </div>
                                        <div className="w-100 mt-3 margin-bottom-30 d-flex justify-content-center">
                                            <p className="ps-2 fs-18 fw-semibold mb-0">
                                                {windowsDetails?.reportInfo?.cspreport?.['_/DevDetail/DevTyp']}
                                            </p>
                                        </div>
                                    </>
                                    <div className="w-100 d-flex justify-content-start">
                                        <h4 className="card-title mb-0 flex-grow-1 text-success">Device Summary</h4>
                                        <div className="flex-shrink-0"></div>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <div className="table-responsive table-card pt-2">
                                        <table className="table table-wrap table-borderless table-centered align-middle mb-0">
                                            <tbody>
                                                {returnData('Device Group', windowsDetails?.assignment?.groupName, true)}
                                                {returnData('Policy', windowsDetails?.assignment?.policyName, true)}
                                                {returnData('User Name', windowsDetails?.assignment?.user, false)}
                                                {returnData('Mobile Number', windowsDetails?.deviceSummary?.phone, false)}
                                                {returnData(
                                                    'Enrollment Time',
                                                    windowsDetails?.enroll?.enrolldate
                                                        ? convertUTCtoIST(windowsDetails?.enroll?.enrolldate)
                                                        : '—'
                                                )}
                                                {returnData(
                                                    'Last Status Report Time',
                                                    windowsDetails?.lastsynctime ? convertUTCtoIST(windowsDetails?.lastsynctime) : '—'
                                                )}
                                                {returnData(
                                                    'Last Modified Date',
                                                    windowsDetails?.modifieddate ? convertUTCtoIST(windowsDetails?.modifieddate) : '—'
                                                )}
                                            </tbody>
                                        </table>
                                        <div className="w-100 d-flex justify-content-start ps-4 margin-top-30 mb-2">
                                            <h4 className="card-title mb-0 flex-grow-1 text-success">Device Identity Details</h4>
                                            <div className="flex-shrink-0"></div>
                                        </div>
                                        <table className="table table-wrap table-borderless table-centered align-middle mb-0">
                                            <tbody>
                                                {returnData('Serial', windowsDetails?.serial)}
                                                {returnData(
                                                    'Device Name',
                                                    windowsDetails?.reportInfo?.cspreport?.['_/DevDetail/Ext/Microsoft/DeviceName']
                                                )}
                                                {returnData(
                                                    'SMBIOS Serial',
                                                    windowsDetails?.reportInfo?.cspreport?.['_/DevDetail/Ext/Microsoft/SMBIOSSerialNumber']
                                                )}
                                                {returnData(
                                                    'Wi-Fi MAC',
                                                    windowsDetails?.reportInfo?.cspreport?.['_/DevDetail/Ext/WlanIPv4Address']
                                                )}
                                                {returnData(
                                                    'Battery',
                                                    windowsDetails?.reportInfo?.cspreport?.[
                                                        '_/Vendor/MSFT/DeviceStatus/Battery/EstimatedChargeRemaining'
                                                    ] === '255'
                                                        ? 'AC'
                                                        : windowsDetails?.reportInfo?.cspreport?.[
                                                              '_/Vendor/MSFT/DeviceStatus/Battery/EstimatedChargeRemaining'
                                                          ]
                                                        ? `${windowsDetails?.reportInfo?.cspreport?.['_/Vendor/MSFT/DeviceStatus/Battery/EstimatedChargeRemaining']}%`
                                                        : '—'
                                                )}
                                            </tbody>
                                        </table>
                                        <div className="w-100 d-flex justify-content-start ps-4 margin-top-30 mb-2">
                                            <h4 className="card-title mb-0 flex-grow-1 text-success">Device Security</h4>
                                            <div className="flex-shrink-0"></div>
                                        </div>
                                        {returnData(
                                            'Antivirus Status',
                                            windowsDetails?.reportInfo?.cspreport?.['_/Vendor/MSFT/DeviceStatus/Antivirus/Status']
                                                ? ANTIVIRUS_STATUS[
                                                      windowsDetails?.reportInfo?.cspreport?.['_/Vendor/MSFT/DeviceStatus/Antivirus/Status']
                                                  ]
                                                : '—'
                                        )}
                                        {returnData(
                                            'Bitlocker Status',
                                            windowsDetails?.reportInfo?.cspreport?.[
                                                '_/Device/Vendor/MSFT/BitLocker/Status/DeviceEncryptionStatus'
                                            ]
                                                ? BITLOCKER_STATUS[
                                                      windowsDetails?.reportInfo?.cspreport?.[
                                                          '_/Device/Vendor/MSFT/BitLocker/Status/DeviceEncryptionStatus'
                                                      ]
                                                  ]
                                                : '—'
                                        )}
                                        {returnData(
                                            'Antispyware Status',
                                            windowsDetails?.reportInfo?.cspreport?.['_/Vendor/MSFT/DeviceStatus/Antispyware/Status']
                                                ? ANTISPYWARE_STATUS[
                                                      windowsDetails?.reportInfo?.cspreport?.[
                                                          '_/Vendor/MSFT/DeviceStatus/Antispyware/Status'
                                                      ]
                                                  ]
                                                : '—'
                                        )}
                                        {returnData(
                                            'Firewall Status',
                                            windowsDetails?.reportInfo?.cspreport?.['_/Vendor/MSFT/DeviceStatus/Firewall/Status']
                                                ? FIREWALL_STATUS[
                                                      windowsDetails?.reportInfo?.cspreport?.['_/Vendor/MSFT/DeviceStatus/Firewall/Status']
                                                  ]
                                                : '—'
                                        )}
                                        {returnData(
                                            'Agent App Status',
                                            windowsDetails?.reportInfo?.cspreport?.[
                                                '_/Device/Vendor/MSFT/EnterpriseDesktopAppManagement/MSI/%7BABCDDCBA-86C7-4D14-AEC0-86416A69ABDE%7D/Status'
                                            ]
                                                ? AGENTAPP_STATUS[
                                                      windowsDetails?.reportInfo?.cspreport?.[
                                                          '_/Device/Vendor/MSFT/EnterpriseDesktopAppManagement/MSI/%7BABCDDCBA-86C7-4D14-AEC0-86416A69ABDE%7D/Status'
                                                      ]
                                                  ]
                                                : '—'
                                        )}
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xs={12} sm={12} md={4} xl={4} lg={4} className="d-flex flex-column g-3">
                            <Card className="mb-3 card-height-42 padding-bottom-15">
                                <CardHeader className="align-items-center d-flex">
                                    <img src={softwareIcon} alt="Software Icon" height={20}></img>
                                    <h4 className="card-title ps-2 mb-0 flex-grow-1 text-success">Software Information</h4>
                                    <div className="flex-shrink-0"></div>
                                </CardHeader>
                                <CardBody>
                                    <div className="table-responsive table-card pt-2">
                                        <table className="table table-wrap table-borderless table-centered align-middle mb-0">
                                            <tbody>
                                                {returnData('OS', windowsDetails?.software?.os)}
                                                {returnData('OS Version', windowsDetails?.software?.osversion)}
                                                {returnData('OS Manufacturer', 'Microsoft')}
                                                {/* {returnData(
                                                    'OS Platform',
                                                    windowsDetails?.reportInfo?.cspreport?.['_/DevDetail/Ext/Microsoft/OSPlatform']
                                                )} */}
                                                {returnData('OS Platform', windowsDetails?.reportInfo?.agentreport?.SystemDetails?.OSName)}
                                                {returnData(
                                                    'OS Architecture',
                                                    windowsDetails?.reportInfo?.agentreport?.SystemDetails?.OSArchitecture
                                                )}
                                                {returnData('Edition', windowsDetails?.reportInfo?.agentreport?.SystemDetails?.Edition)}
                                                {returnData('Processor', windowsDetails?.reportInfo?.agentreport?.SystemDetails?.Processor)}
                                                {returnData(
                                                    'Processor Architecture',
                                                    windowsDetails?.reportInfo?.cspreport?.[
                                                        '_/DevDetail/Ext/Microsoft/ProcessorArchitecture'
                                                    ]
                                                )}
                                                {/* {returnData(
                                                    'Processor Type',
                                                    windowsDetails?.reportInfo?.cspreport?.['_/DevDetail/Ext/Microsoft/ProcessorType']
                                                )} */}
                                                {returnData(
                                                    'Build Number',
                                                    windowsDetails?.reportInfo?.agentreport?.SystemDetails?.BuildNumber
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </CardBody>
                            </Card>
                            <Card className="mb-3 card-height-28 padding-bottom-15">
                                <CardHeader className="align-items-center d-flex">
                                    <img src={hardwareIcon} alt="Hardware Icon" height={20}></img>
                                    <h4 className="ps-2 card-title mb-0 flex-grow-1 text-success">Hardware Information</h4>
                                    <div className="flex-shrink-0"></div>
                                </CardHeader>
                                <CardBody>
                                    <div className="table-responsive table-card pt-2">
                                        <table className="table table-wrap table-borderless table-centered align-middle mb-0">
                                            <tbody>
                                                {returnData(
                                                    'System Manufacturer',
                                                    windowsDetails?.reportInfo?.cspreport?.['_/DevDetail/OEM']
                                                )}
                                                {returnData('Model', windowsDetails?.reportInfo?.cspreport?.['_/DevDetail/DevTyp'])}
                                                {returnData(
                                                    'Device Type',
                                                    windowsDetails?.reportInfo?.cspreport?.[
                                                        '_/Vendor/MSFT/DeviceStatus/Battery/EstimatedChargeRemaining'
                                                    ]
                                                        ? windowsDetails?.reportInfo?.cspreport?.[
                                                              '_/Vendor/MSFT/DeviceStatus/Battery/EstimatedChargeRemaining'
                                                          ] === '255'
                                                            ? 'Desktop'
                                                            : 'Laptop'
                                                        : '—'
                                                )}
                                                {/* {returnData('Color', windowsDetails?.hardware?.color)} */}
                                                {returnData(
                                                    'Total RAM Memory',
                                                    windowsDetails?.reportInfo?.cspreport?.['_/DevDetail/Ext/Microsoft/TotalRAM'] &&
                                                        windowsConvertBytesTo(
                                                            windowsDetails?.reportInfo?.cspreport?.['_/DevDetail/Ext/Microsoft/TotalRAM'] *
                                                                1024 *
                                                                1024
                                                        )
                                                )}
                                                {returnData(
                                                    'Device Capacity',
                                                    windowsDetails?.reportInfo?.cspreport?.['_/DevDetail/Ext/Microsoft/TotalStorage'] &&
                                                        windowsConvertBytesTo(
                                                            windowsDetails?.reportInfo?.cspreport?.[
                                                                '_/DevDetail/Ext/Microsoft/TotalStorage'
                                                            ] *
                                                                1024 *
                                                                1024
                                                        )
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </CardBody>
                            </Card>
                            <Card className="mb-3 card-height-30">
                                <TableContainer
                                    loading={loading}
                                    addButton={!!tenantConfig?.WINDOWS_DEVICE_ACTIONS_MONITORING?.includes('ADD_USER')}
                                    searchFilter2={false}
                                    showTooltip={true}
                                    headerImage={hardwareIcon}
                                    marginTopClass={'mt-0'}
                                    tableCardClass={'m-0 shadow-none'}
                                    tableLabelClass={'text-success align-self-center'}
                                    tableHeader={'Users'}
                                    editor={priviliges.DEVICE_EDITOR}
                                    isGlobalFilter={true}
                                    columns={localUsersSchema}
                                    data={localUsers}
                                    handleAddNew={handleAddUser}
                                    handleDelete={handleDeleteUser}
                                    customPageSize={5}
                                    className="custom-header-css"
                                    divClass="table-responsive table-card"
                                    tableClass="table table-wrap table-border table-centered align-middle"
                                    theadClass="bg-light text-muted"
                                />
                            </Card>
                        </Col>
                        <Col xs={12} sm={12} md={4} xl={4} lg={4} className="d-flex flex-column g-3">
                            <Card className="card-height-100 mb-0 padding-bottom-50">
                                <CardHeader className="align-items-center d-flex">
                                    <img src={deviceSecurity} alt="Non Compliance Icon"></img>
                                    <h4 className="card-title ps-2 mb-0 flex-grow-1 text-success">Action History</h4>
                                    <div className="flex-shrink-0"></div>
                                </CardHeader>
                                <CardBody>
                                    <div className="max-height-850">
                                        <div className="table-responsive table-card">
                                            <table className="table table-wrap table-borderless table-centered align-middle mb-0">
                                                <tbody>
                                                    {commandHistory?.length > 0 ? (
                                                        commandHistory?.map((command, ind) => (
                                                            <React.Fragment key={ind}>{commandReturnData(command, ind)}</React.Fragment>
                                                        ))
                                                    ) : (
                                                        <tr>
                                                            <td className="pb-6 fs-13">{'No Records Found.'}</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    {commandPage?.length > 10 && (
                                        <Pagination
                                            activePage={commandPage.page}
                                            itemsCountPerPage={20}
                                            totalItemsCount={commandPage.length}
                                            pageRangeDisplayed={3}
                                            onChange={handleCommandPageChange}
                                            hideFirstLastPages={false}
                                            isColumn={true}
                                        />
                                    )}
                                </CardBody>
                            </Card>
                        </Col>
                        <Col className="col-12">
                            <TableContainer
                                loading={loading}
                                searchFilter2={true}
                                showTooltip={true}
                                searchPlaceHolder={'App Name/Publisher'}
                                headerImage={applicationsIcon}
                                tableLabelClass={'text-success'}
                                tableHeader={'Applications'}
                                isGlobalFilter={true}
                                columns={deviceAppsTableSchema}
                                data={applications}
                                customPageSize={5}
                                className="custom-header-css"
                                divClass="table-responsive table-card"
                                tableClass="table table-wrap table-border table-centered align-middle"
                                theadClass="bg-light text-muted"
                            />
                        </Col>
                    </Row>
                </Container>
                <OffcanvasModal
                    direction="end"
                    toggle={toggleGroupCanvas}
                    open={showDeviceGroup}
                    handleCloseClick={toggleGroupCanvas}
                    saveDisabled={!selectedDeviceGroup?._id || selectedDeviceGroup?._id === defaultGroup?._id}
                    loading={loading}
                    OffcanvasModalID="deviceGroup"
                    handleOffcanvasBody={deviceGroupBody}
                    offcanvasHeader="Change Device Group"
                    modalClassName="w-40"
                    handleSaveClick={() => onChangeGroup()}
                />
                <OffcanvasModal
                    direction="end"
                    toggle={toggle}
                    open={offcanvasModal}
                    hideSaveButton={true}
                    handleCloseClick={toggle}
                    loading={loading}
                    OffcanvasModalID="locationHistory"
                    handleOffcanvasBody={locationHistoryBody}
                    modalClassName={'w-30'}
                    offcanvasHeader={'Location History'}
                />
                <DeleteModal
                    hideIcon={true}
                    show={actionModal}
                    hideDeleteIcon={true}
                    hideDeleteMessage={true}
                    message={actionMessage()}
                    confirmation={
                        type === 'add_user' || type === 'change_password' || type === 'change_device_name' || type === 'remove_device_user'
                    }
                    disabled={
                        type === 'add_user' || type === 'change_password'
                            ? userObj.username === '' || userObj.password === ''
                            : type === 'change_device_name'
                            ? deviceName === ''
                            : type === 'remove_device_user'
                            ? removeUsername === ''
                            : false
                    }
                    confirmText={actionText}
                    onDeleteClick={actionService}
                    onCloseClick={() => {
                        setUserObj({ username: '', password: '' });
                        setDeviceName('');
                        setRemoveUsername('');
                        setActionModal(false);
                    }}
                />
                <CommonModal
                    show={showModal}
                    onCloseClick={onCloseClick}
                    handleModalBody={handleModalBody}
                    hideFooter={true}
                    hideHeader={true}
                />
            </div>
        </React.Fragment>
    );
};

export default ViewWindowsDevices;
