/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable max-depth */
/* eslint-disable max-nested-callbacks */
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import DeleteModal from '../../../Components/Common/DeleteModal';
import Loader from '../../../Components/Common/Loader';
import { getFormTypeAndRecordId, guid } from '../../../Components/Common/Util';
import toastMessages from '../../../common/messages/toastMessages';
import { useEnv } from '../../../envContext';
import { APIClient } from '../../../helpers/api_helper';
import * as domains from '../../../helpers/domain_helper';
import * as url from '../../../helpers/url_helper';
import { DynamicComponents, InputComponent } from './IOSPolicyComponents';
import { PoliciesSchema } from './PoliciesSchema';
import IOS_DEFAULT_APPS from './iOSDefaultApps';

const AddIOSPolicy = () => {
    document.title = 'Add IOS Policy';
    const api = new APIClient();
    const urlconf = useEnv();
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    const formTypeAndId = getFormTypeAndRecordId(window.location.hash, true);
    let formType = formTypeAndId['formType'];
    let polType = formTypeAndId['queryType'];
    let recordID = formTypeAndId['recordID'];
    let policyType = polType?.split('-')?.[0];
    let platform = polType?.split('-')?.[1];

    const [PoliciesSchemaArr, setPoliciesSchemaArr] = useState(JSON.parse(JSON.stringify(PoliciesSchema)));
    const [profileDetails, setProfileDetails] = useState(PoliciesSchemaArr);
    const [selected, setSelected] = useState('');
    const [formValues, setFormValues] = useState({});
    const [isShowCardData, setIsShowCardData] = useState(false);
    const [isConfigured, setIsConfigured] = useState({});
    const [applications, setApplications] = useState([]);
    const [applicationsConfirmation, setApplicationsConfirmation] = useState([]);
    const [isApplication, setIsApplication] = useState(true);
    const [selectedApp, setSelectedApp] = useState('');
    const [initialAssetValues, setInitialAssetValues] = useState({});
    const [code, setCode] = useState('');
    const [singleApps, setSingleApps] = useState(IOS_DEFAULT_APPS);
    const [systemApps, setSystemApps] = useState(IOS_DEFAULT_APPS?.[platform]);
    const [deletingSchema, setDeletingSchema] = useState('');
    const [deleteModal, setDeleteModal] = useState(false);
    const [isArray, setIsArray] = useState(true);
    const [arrayMode, setArrayMode] = useState('add');
    const [arrayIndex, setArrayIndex] = useState(-1);
    const [policy, setPolicy] = useState([]);
    const [managedConfigs, setManagedConfigs] = useState([]);
    const [configParams, setConfigParams] = useState({ page: 1, size: 100 });

    useEffect(() => {
        setLoading(true);
        defineSchema();
    }, []);

    const defineSchema = async () => {
        let schemaArr = JSON.parse(JSON.stringify(PoliciesSchemaArr));
        schemaArr = schemaArr.filter((schema) => schema.show?.includes(policyType) && schema.platforms?.includes(platform));
        let schema = await schemaFilter(JSON.parse(JSON.stringify(schemaArr)));
        let finalArr = schema;
        // ['localhost', 'portal.mdmdev.tectoro.com']?.includes(window.location.hostname) ? schema : schema.sort((a, b) => a.underDev - b.underDev);
        setPoliciesSchemaArr([...finalArr]);
        setProfileDetails([...finalArr]);
        handlePromise([...finalArr]);
    };

    const schemaFilter = async (schemaArr) => {
        const filteredSchemaArr = await Promise.all(
            schemaArr.map(async (schemaObj) => {
                if (schemaObj.show?.includes(policyType) && schemaObj.platforms?.includes(platform)) {
                    const fieldTypes = ['fields', 'arrayFields', 'jsonFields'];
                    for (const fieldType of fieldTypes) {
                        if (schemaObj[fieldType]) {
                            const filteredFields = filterSchema(schemaObj[fieldType]);
                            schemaObj[fieldType] = await schemaFilter(filteredFields);
                        }
                    }
                }
                return schemaObj;
            })
        );
        return JSON.parse(JSON.stringify(filteredSchemaArr));
    };

    const filterSchema = (arr) => {
        return arr.filter((obj) => obj.show?.includes(policyType) && obj.platforms?.includes(platform));
    };

    const handlePromise = (finalArr) => {
        const policyPromise = new Promise((resolve, reject) => {
            if (recordID) {
                api.get(url.POLICIES + '/' + recordID, '', domains.IOS_V1)
                    .then((resp) => {
                        resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                        if (resp.data) {
                            resolve(resp.data);
                        }
                        reject('Policy Failed.');
                    })
                    .catch((_err) => reject('Policy Failed'));
            } else resolve({});
        });
        const appsPromise = new Promise((resolve, reject) => {
            api.get(url.IOSAPPS, { page: 1, size: 100 }, domains.IOS_V1)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp?.status === 'success') resolve(resp.data);
                    else reject('Applicatoins failed');
                })
                .catch((_err) => reject('Applications failed'));
        });
        const managedConfigPromise = new Promise((resolve, reject) => {
            api.get(url.APPCONFIG, configParams, domains.IOS_V1)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp?.status === 'success') {
                        resolve(resp);
                    } else reject('Config failed.');
                })
                .catch((err) => reject(err));
        });
        Promise.allSettled([policyPromise, appsPromise, managedConfigPromise])
            .then(async (result) => {
                let initValues = {};
                let initAssetValues = {};
                let apps = JSON.parse(JSON.stringify(systemApps));
                // result[1].value = result[1].status === 'fulfilled' ? (result[1].value || [])?.filter((app) => app.packagename) : [];
                if (result[0].status === 'fulfilled') {
                    setPolicy(result[0].value?.policy || {});
                    let singleAppsArr = result[0].value.assets
                        ? result[0].value.assets?.map((asset) => (asset.iTunesStoreID ? asset.iTunesStoreID : asset.ManifestURL))
                        : [];
                    singleAppsArr = (result[1].value || [])?.filter((app) => {
                        if (singleAppsArr.includes(app.adamid ? Number(app.adamid) : app.url)) return app;
                        return '';
                    });
                    singleAppsArr = singleAppsArr.filter((app) => app !== '');
                    // let appsArr = result[1].status === 'fulfilled' ? singleAppsArr.concat(singleApps) : singleApps;
                    let appsArr = result[1].status === 'fulfilled' ? result[1].value || [] : singleApps;
                    setSingleApps(appsArr);
                    const data = recordID
                        ? JSON.parse(JSON.stringify(result[0].value.policy))
                        : JSON.parse(JSON.stringify(result[0].value));
                    initValues = { ...(data?.PayloadContent ? data?.PayloadContent : { USER: [], DEVICE: [] }) };
                    if (recordID) {
                        let restrictions = JSON.parse(JSON.stringify(initValues['DEVICE']))?.find(
                            (value) => value.PayloadType === 'com.apple.applicationaccess'
                        );
                        if (restrictions) {
                            apps.forEach((app) => (app.checked = restrictions.blockedAppBundleIDs?.includes(app.packagename)));
                            setSystemApps(apps);
                        }
                    }
                    let values = await generateValues(initValues, result[1].status === 'fulfilled' ? appsArr : []);
                    initAssetValues = await generateAssetValues(
                        result[0]?.value?.assets?.length > 0 ? result[0]?.value?.assets : [],
                        result[1].value || [],
                        result[2]?.value?.data || []
                    );
                    let arraySchema = PoliciesSchemaArr.filter((schema) => schema.schemaType === 'array');
                    let arrayValues = {};
                    arraySchema?.map((schema) => {
                        arrayValues[schema.schemaValue] = (
                            data?.PayloadContent?.[platform === 'ios' ? 'DEVICE' : schema.channel]
                                ? data?.PayloadContent?.[platform === 'ios' ? 'DEVICE' : schema.channel]
                                : []
                        )?.filter((content) => content.PayloadIdentifier?.includes(schema.payloadIdentifier));
                        return schema;
                    });
                    if (Object.keys(arrayValues)?.length > 0) {
                        values = await generateArrayValues(values, arraySchema, arrayValues);
                    }
                    let schemaObj = PoliciesSchemaArr.find((schema) => schema.payloadType === 'Configuration');
                    values = await defineSubFields(schemaObj.fields, values, data, result[1].status === 'fulfilled' ? appsArr : [], true);
                    let brandingSchema = PoliciesSchemaArr.find((schema) => schema.payloadType === 'com.apple.wallpaper');
                    values =
                        result?.[0]?.value?.others && Object.keys(result?.[0]?.value?.others)?.length > 0
                            ? await defineOtherObj(brandingSchema, values, result?.[0]?.value?.others)
                            : values;
                    initValues = { ...values, policyName: result?.[0]?.value?.name || '' };
                }
                if (result[1].status === 'fulfilled') {
                    (result[1].value || [])?.forEach((value) => {
                        value.checked = !!initAssetValues?.[value.adamid ? value.adamid : value.url]?.ManagementFlags;
                        value.appType = value.adamid ? 'epa' : 'vpp';
                    });
                    setApplications(result[1].value || []);
                    setApplicationsConfirmation(result[1].value || []);
                }
                if (result[2].status === 'fulfilled') {
                    setManagedConfigs(result[2].value?.data || []);
                }
                if (result[0]?.value?.code) setCode(result[0]?.value?.code);
                else if (recordID) {
                    toast.error('Code not foud');
                    history.push('/iospolicies');
                }
                defineSchemaConfigured(initValues, result[1].value || [], apps, finalArr);
                setInitialAssetValues(initAssetValues);
                setFormValues(initValues);
                setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    const getManagedConfigs = () => {
        setLoading(true);
        let params = JSON.parse(JSON.stringify(configParams));
        params.page += 1;
        setConfigParams(params);
        api.get(url.APPCONFIG, params, domains.IOS_V1)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp?.status === 'success') {
                    let configs = managedConfigs || [];
                    configs = configs.concat(resp?.data || []);
                    setManagedConfigs(configs);
                }
                setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    const generateArrayValues = async (values, arraySchema, arrayValues) => {
        let data = JSON.parse(JSON.stringify(values));
        await Promise.all(
            arraySchema.map(async (schema) => {
                let val = await defineArrayFieldValue(schema, arrayValues[schema.schemaValue]);
                data[schema.schemaValue] = val;
            })
        );
        return data;
    };

    const defineFields = async (fields, value, obj) => {
        await fields.map(async (field) => {
            if (field.inputType === 'checkbox') obj[field.value] = !!value[field.saveKey ? field.saveKey : field.value];
            else if (field.inputType === 'policyInput') obj[field.value] = value[field.saveKey ? field.saveKey : field.value];
            else if (field.inputType === 'multiInput') obj = await defineFields(field.inputs, value, obj);
            else if (field.inputType === 'dropdown') {
                obj[field.value] = field.options?.find(
                    (option) => option[field.optionValue ? field.optionValue : 'value'] === value[field.saveKey ? field.saveKey : 'value']
                );

                field.options?.map(async (option) => {
                    if (field[option.value]?.length > 0) {
                        obj[field.value] = await defineFields(field[option[field.optionValue ? field.optionValue : 'value']?.value], value);
                    }
                });
            }
            return field;
        });
        return obj;
    };

    const defineArrayFieldValue = async (schema, arrayValue) => {
        let valArr = [];
        await arrayValue.forEach(async (value) => {
            let obj = {};
            await schema.fields?.[0]?.arrayFields?.forEach((field) => {
                if (field.inputType === 'checkbox') obj[field.value] = !!value[field.saveKey ? field.saveKey : field.value];
                else if (field.inputType === 'policyInput' || field.inputType === 'upload')
                    obj[field.value] = value[field.saveKey ? field.saveKey : field.value];
                else if (field.inputType === 'dropdown') {
                    obj[field.value] = field.options?.find(
                        (option) =>
                            option[field.optionValue ? field.optionValue : 'value'] === value[field.saveKey ? field.saveKey : field.value]
                    );

                    field.options?.forEach(async (option) => {
                        if (field[option.value]?.length > 0) {
                            obj = await defineFields(field[option.value], value, obj);
                        }
                    });
                }
            });
            valArr.push(obj);
        });
        return valArr;
    };

    const followPath = async (schema, otherObj) => {
        let obj = { ...otherObj };
        await schema.path?.forEach((path) => {
            obj = obj[path];
        });
        return obj;
    };

    const defineOtherObj = async (schema, values, otherObj) => {
        await schema.fields?.forEach(async (field) => {
            values[field.value] = await followPath(field, otherObj);
        });
        return values;
    };

    const generateAppVal = async (polSchema, asset, polAssets, managedConfigArr) => {
        let obj = {};
        let polAsset = polAssets.find((assetObj) =>
            assetObj.iTunesStoreID ? assetObj.iTunesStoreID === Number(asset.adamid) : assetObj.ManifestURL === asset.url
        );
        await polSchema.forEach((schema) => {
            if (schema.value === 'managedconfigid') {
                obj[schema.value] = managedConfigArr?.find((configObj) => configObj?.managedconfigid === polAsset?.managedconfigid);
            } else if (schema.inputType === 'dropdown')
                obj[schema.value] = schema.options?.find((option) => option.value === polAsset?.[schema.value]);
            else if (schema.inputType === 'checkbox') obj[schema.value] = !!polAsset?.[schema.value];
            else if (schema.inputType === 'policyInput') obj[schema.value] = polAsset?.[schema.value];
        });
        return obj;
    };

    const generateAssetValues = async (polAssets, totalAssets, managedConfigArr) => {
        let obj = {};
        let polSchema = JSON.parse(JSON.stringify(PoliciesSchemaArr));
        polSchema = polSchema.filter((schema) => schema.label === 'Applications');
        await totalAssets.forEach(async (asset) => {
            let val = await generateAppVal(polSchema?.[0]?.fields?.[0]?.fields || [], asset, polAssets, managedConfigArr);
            obj[asset.adamid ? asset.adamid : asset.url] = { ...val, appType: asset.adamid ? 'epa' : 'vpp' };
        });
        return obj;
    };

    const defineSchemaConfigured = (initValues, apps, systemAppsArr, finalArr) => {
        let polSchema = JSON.parse(JSON.stringify(finalArr));
        let schemaArr = polSchema.filter((schema) => schema.label !== 'Applications' && schema.label !== 'General Settings');
        const configured = {};
        let generalSettings = polSchema.find((schema) => schema.label === 'General Settings');
        configured['General Settings'] = generalSettings?.fields.filter((field) => !!initValues[field.value])?.length > 0;
        configured.Applications = !!apps.find((app) => app.checked) || systemAppsArr.filter((app) => app.checked)?.length > 0;

        schemaArr.forEach((schema) => {
            let flag = false;
            if (schema.schemaType === 'array') {
                configured[schema.label] = initValues[schema.schemaValue]?.length > 0;
            } else {
                let schemaObj = schema.fields;
                schemaObj?.forEach((field) => {
                    if (flag) return;
                    let condition = !field.noDefaultVal;
                    if (field.inputType === 'array' || field.inputType === 'tableObject' || field.inputType === 'tableInput') {
                        flag = !!initValues[field.value]?.length > 0;
                    } else if (field.inputType === 'dropdown') {
                        flag = condition
                            ? initValues[field.value]?.[field.optionValue ? field.optionValue : 'value'] !== field.defaultVal
                            : !!initValues[field.value];

                        if (field[initValues[field.value]?.[field.optionValue ? field.optionValue : 'value']]?.length > 0)
                            flag = defineSubFieldFlag(
                                field[initValues[field.value]?.[field.optionValue ? field.optionValue : 'value']],
                                initValues,
                                flag
                            );
                    } else if (field.inputType === 'checkbox') {
                        flag = condition ? !!initValues[field.value] !== !!field.defaultVal : !!initValues[field.value];
                    } else if (field.inputType === 'policyInput') {
                        flag = condition ? initValues[field.value] !== field.defaultVal : !!initValues[field.value];
                    } else if (field.inputType === 'upload') {
                        flag = condition ? initValues[field.value] !== field.defaultVal : !!initValues[field.value];
                    } else if (field.inputType === 'multiInput') flag = defineSubFieldFlag(field.inputs, initValues, flag);
                    if (flag) return;
                    if (field.fields) flag = defineSubFieldFlag(field.fields, initValues, flag);
                    if (flag) return;
                    if (field.jsonFields) flag = defineSubFieldFlag(field.jsonFields, initValues, flag);
                    if (flag) return;
                });
                if (flag) {
                    configured[schema.label] = flag;
                    return;
                }
            }
        });
        configured['Restrictions'] = true;
        setIsConfigured(configured);
    };

    const defineSubFieldFlag = (fields, initValues, flag) => {
        fields.forEach((field) => {
            if (flag) return;
            let condition = !field.noDefaultVal;
            if (field.inputType === 'array' || field.inputType === 'tableObject' || field.inputType === 'tableInput') {
                flag = !!initValues[field.value]?.length > 0;
            } else if (field.inputType === 'dropdown') {
                flag = condition
                    ? initValues[field.value]?.[field.optionValue ? field.optionValue : 'value'] !== field.defaultVal
                    : !!initValues[field.value];

                if (field[initValues[field.value]?.[field.optionValue ? field.optionValue : 'value']]?.length > 0)
                    flag = defineSubFieldFlag(
                        field[initValues[field.value]?.[field.optionValue ? field.optionValue : 'value']],
                        initValues,
                        flag
                    );
            } else if (field.inputType === 'checkbox')
                flag = condition ? !!initValues[field.value] !== !!field.defaultVal : !!initValues[field.value];
            else if (field.inputType === 'policyInput') {
                flag = condition ? initValues[field.value] !== field.defaultVal : !!initValues[field.value];
            } else if (field.inputType === 'multiInput') flag = defineSubFieldFlag(field.inputs, initValues, flag);

            if (flag) return;
            if (field.fields) flag = defineSubFieldFlag(field.fields, initValues, flag);
            if (flag) return;
            if (field.jsonFields) flag = defineSubFieldFlag(field.jsonFields, initValues, flag);
            if (flag) return;
        });
        return flag;
    };

    const defineTableObject = async (values, fields) => {
        await values?.forEach((value) => {
            fields.forEach((field) => {
                if (field.inputType === 'checkbox') value[field.value] = !!value[field.value];
            });
        });
        return values;
    };

    const defineSubFields = async (fieldArr, obj, valuesObj, apps) => {
        await fieldArr?.forEach(async (field) => {
            let condition = !field.noDefaultVal;
            let options =
                field.inputType === 'dropdown' && field.customOptions ? (field.optionsKey === 'singleApps' ? apps : []) : field.options;
            if (field.inputType === 'input' || field?.inputType === 'policyInput' || field.inputType === 'upload')
                obj[field.value] = condition
                    ? valuesObj?.[field.saveKey ? field.saveKey : field.value] ||
                      valuesObj?.[field.saveKey ? field.saveKey : field.value] === ''
                        ? valuesObj?.[field.saveKey ? field.saveKey : field.value]
                        : field.defaultVal
                    : valuesObj?.[field.saveKey ? field.saveKey : field.value] || '';
            else if (field.inputType === 'dropdown' && field.isMulti)
                obj[field.value] = options?.filter((option) =>
                    (condition
                        ? !!valuesObj?.[field.saveKey ? field.saveKey : field.value] ||
                          valuesObj?.[field.saveKey ? field.saveKey : field.value] === 0
                            ? valuesObj?.[field.saveKey ? field.saveKey : field.value]
                            : field.defaultVal
                        : valuesObj?.[field.saveKey ? field.saveKey : field.value]
                    )?.includes(option.value)
                );
            else if (field.inputType === 'dropdown' && !field.isMulti) {
                obj[field.value] = options?.find(
                    (option) =>
                        (condition
                            ? !!valuesObj?.[field.saveKey ? field.saveKey : field.value] ||
                              valuesObj?.[field.saveKey ? field.saveKey : field.value] === 0
                                ? valuesObj?.[field.saveKey ? field.saveKey : field.value]
                                : field.defaultVal
                            : valuesObj?.[field.saveKey ? field.saveKey : field.value]) ===
                            option?.[field?.optionValue ? field?.optionValue : 'value'] &&
                        (option.checkFields ? option.checkFields?.some((checkField) => !!valuesObj[checkField]) : true)
                );
                options.forEach(async (option) => {
                    if (field[option.optionsField ? option.optionsField : option.value]?.length > 0) {
                        obj = await defineSubFields(field[option.optionsField ? option.optionsField : option.value], obj, valuesObj, apps);
                    }
                });
            } else if (field?.inputType === 'checkbox' || field?.inputType === 'switch') {
                obj[field.value] = condition
                    ? valuesObj?.[field.saveKey ? field.saveKey : field.value] === true ||
                      valuesObj?.[field.saveKey ? field.saveKey : field.value] === false
                        ? valuesObj?.[field.saveKey ? field.saveKey : field.value]
                        : !!field.defaultVal
                    : !!valuesObj?.[field.saveKey ? field.saveKey : field.value];

                if (field[obj[field.optionsField ? field.optionsField : field.value]]?.length > 0) {
                    obj = await defineSubFields(field[obj[field.optionsField ? field.optionsField : field.value]], obj, valuesObj, apps);
                }
            } else if (field?.inputType === 'json')
                obj = await defineSubFields(field.jsonFields, obj, valuesObj?.[field.saveKey ? field.saveKey : field.value], apps);
            else if (field?.inputType === 'tableInput') obj[field.value] = valuesObj?.[field.saveKey ? field.saveKey : field.value];
            else if (field.inputType === 'tableObject') {
                obj[field.value] = await defineTableObject(valuesObj?.[field.saveKey ? field.saveKey : field.value], field.tableContent);
            } else if (field.inputType === 'array') {
                obj[field.value] = await defineSubArrayFields(
                    valuesObj?.[field.saveKey ? field.saveKey : field.value],
                    field.arrayFields,
                    apps
                );
            } else if (field.inputType === 'multiInput') {
                obj = await defineSubFields(field.inputs, obj, valuesObj, apps);
            } else if (field.inputType === 'timePicker' && valuesObj?.[field.saveKey ? field.saveKey : field.value]) {
                let date = new Date();
                date.setMinutes(0);
                date.setHours(0);
                date.setMinutes(valuesObj[field.saveKey ? field.saveKey : field.value]);
                obj[field.value] = date;
            }

            if (field?.fields) {
                obj = await defineSubFields(field?.fields, obj, valuesObj, apps);
            }
        });
        return obj;
    };

    const defineSubArrayFields = async (values, fields, apps) => {
        let arr = [];
        await values?.forEach((value) => {
            let obj = {};
            fields?.forEach(async (field) => {
                let condition = !field.noDefaultVal;
                let options =
                    field.inputType === 'dropdown' && field.customOptions ? (field.optionsKey === 'singleApps' ? apps : []) : field.options;
                if (field.inputType === 'input' || field?.inputType === 'policyInput' || field.inputType === 'upload') {
                    obj[field.value] = condition
                        ? value?.[field.saveKey ? field.saveKey : field.value] ||
                          value?.[field.saveKey ? field.saveKey : field.value] === ''
                            ? value?.[field.saveKey ? field.saveKey : field.value]
                            : field.defaultVal
                        : value?.[field.saveKey ? field.saveKey : field.value] || '';
                } else if (field.inputType === 'dropdown' && field.isMulti)
                    obj[field.value] = options?.filter((option) =>
                        (condition
                            ? value?.[field.saveKey ? field.saveKey : field.value] ||
                              value?.[field.saveKey ? field.saveKey : field.value] === 0
                                ? value?.[field.saveKey ? field.saveKey : field.value]
                                : field.defaultVal
                            : value?.[field.saveKey ? field.saveKey : field.value]
                        )?.includes(option.value)
                    );
                else if (field.inputType === 'dropdown' && !field.isMulti) {
                    obj[field.value] = options?.find(
                        (option) =>
                            (condition
                                ? value?.[field.saveKey ? field.saveKey : field.value] ||
                                  value?.[field.saveKey ? field.saveKey : field.value] === 0
                                    ? value?.[field.saveKey ? field.saveKey : field.value]
                                    : field.defaultVal
                                : value?.[field.saveKey ? field.saveKey : field.value]) ===
                            option?.[field?.optionValue ? field?.optionValue : 'value']
                    );
                    options.forEach(async (option) => {
                        if (field[option.optionsField ? option.optionsField : option.value]?.length > 0) {
                            obj = await defineSubFields(field[option.optionsField ? option.optionsField : option.value], obj, value, apps);
                        }
                    });
                } else if (field?.inputType === 'checkbox' || field?.inputType === 'switch') {
                    obj[field.value] = condition
                        ? value?.[field.saveKey ? field.saveKey : field.value] === true ||
                          value?.[field.saveKey ? field.saveKey : field.value] === false
                            ? value?.[field.saveKey ? field.saveKey : field.value]
                            : !!field.defaultVal
                        : !!value?.[field.saveKey ? field.saveKey : field.value];

                    if (field[obj[field.optionsField ? field.optionsField : field.value]]?.length > 0) {
                        obj = await defineSubFields(field[obj[field.optionsField ? field.optionsField : field.value]], obj, value, apps);
                    }
                } else if (field?.inputType === 'json')
                    obj = await defineSubFields(field.jsonFields, obj, value?.[field.saveKey ? field.saveKey : field.value], apps);
                else if (field?.inputType === 'tableInput') obj[field.value] = value?.[field.saveKey ? field.saveKey : field.value];
                else if (field.inputType === 'tableObject') {
                    obj[field.value] = await defineTableObject(value?.[field.saveKey ? field.saveKey : field.value], field.tableContent);
                } else if (field.inputType === 'array')
                    obj[field.value] = await defineSubArrayFields(value?.[field.saveKey ? field.saveKey : field.value], field.arrayFields);
                else if (field.inputType === 'multiInput') obj = await defineSubFields(field.iputs, obj, value, apps);

                if (Array.isArray(field.fields) ? field?.fields : field?.fields?.fields)
                    obj = await defineSubFields(Array.isArray(field.fields) ? field?.fields : field?.fields?.fields, obj, value, apps);
            });
            arr.push(obj);
        });
        return arr;
    };

    const generateValues = async (initValues, apps) => {
        let obj = {};
        let policySchema = JSON.parse(JSON.stringify(PoliciesSchemaArr));
        policySchema = policySchema.filter((schema) => schema.payloadType !== 'Configuration' && schema.schemaType !== 'array');
        await policySchema?.forEach(async (schema) => {
            const valuesObj = initValues[platform === 'ios' ? 'DEVICE' : schema.channel]?.find((val) =>
                val.PayloadIdentifier?.includes(schema.payloadIdentifier)
            );
            obj = await defineSubFields(Array.isArray(schema.fields) ? schema.fields : schema.fields.fields, obj, valuesObj, apps);
        });
        return obj;
    };

    const defineDataObj = async (fieldArr, obj) => {
        fieldArr?.forEach(async (field) => {
            if (field?.inputType === 'input' || field?.inputType === 'policyInput' || field.inputType === 'upload')
                obj[field?.saveKey ? field?.saveKey : field?.value] =
                    field?.type === 'number' ? Number(confirmValidation.values?.[field?.value]) : confirmValidation.values?.[field?.value];
            else if (field?.inputType === 'dropdown') {
                obj[field?.saveKey ? field?.saveKey : field?.value] =
                    confirmValidation.values?.[field?.value]?.[field?.optionValue ? field.optionValue : 'value'];
                field.options?.forEach(async (option) => {
                    if (
                        !field.isMulti &&
                        confirmValidation.values[field.value]?.[
                            field.optionValue ? field.optionValue : option.optionsField ? 'optionsField' : 'value'
                        ] === option[field.optionValue ? field.optionValue : option.optionsField ? 'optionsField' : 'value'] &&
                        field[
                            confirmValidation.values[field.value]?.[
                                field.optionValue ? field.optionValue : option.optionsField ? 'optionsField' : 'value'
                            ]
                        ]?.length > 0
                    )
                        obj = await defineDataObj(
                            field[
                                confirmValidation.values[field.value]?.[
                                    field.optionValue ? field.optionValue : option.optionsField ? 'optionsField' : 'value'
                                ]
                            ],
                            obj
                        );
                });
            } else if (field?.inputType === 'checkbox' || field?.inputType === 'switch') {
                obj[field?.saveKey ? field?.saveKey : field?.value] = !!confirmValidation.values?.[field?.value];

                if (field[!!confirmValidation.values?.[field?.optionsField ? field?.optionsField : field.value]]?.length > 0) {
                    obj = await defineDataObj(
                        field[!!confirmValidation.values?.[field?.optionsField ? field?.optionsField : field.value]],
                        obj
                    );
                }
            } else if (field?.inputType === 'json') {
                let val = await defineDataObj(field.jsonFields, {});
                let flag = [];
                field.jsonFields.forEach((jsonField) => {
                    flag.push(!!confirmValidation?.values?.[jsonField?.value]);
                });
                if (flag.some((fl) => fl)) obj[field?.saveKey ? field?.saveKey : field?.value] = val;
            } else if (field?.inputType === 'tableInput' || field.inputType === 'tableObject')
                obj[field.saveKey ? field.saveKey : field.value] =
                    confirmValidation.values[field.value]?.length > 0 ? confirmValidation.values[field.value] : undefined;
            else if (field.inputType === 'array') {
                obj[field.value] = await defineSubArray(field);
            } else if (field.inputType === 'multiInput') obj = await defineDataObj(field.inputs, obj);
            else if (field.inputType === 'timePicker' && confirmValidation.values[field.value]) {
                let date = new Date(confirmValidation.values[field.value]);
                if (field.saveType === 'minutes')
                    obj[field.saveKey ? field.saveKey : field.value] = date.getHours() * 60 + date.getMinutes();
                else obj[field.saveKey ? field.saveKey : field.value] = confirmValidation.values[field.value];
            }

            if (Array.isArray(field.fields) ? field?.fields : field?.fields?.fields)
                obj = await defineDataObj(Array.isArray(field.fields) ? field?.fields : field?.fields?.fields, obj);
        });
        return obj;
    };

    const defineSubArray = async (schema) => {
        let arr = [];
        await confirmValidation.values?.[schema.value]?.forEach(async (value) => {
            let obj = {};
            schema.arrayFields?.forEach(async (field) => {
                if (field?.inputType === 'input' || field?.inputType === 'policyInput' || field.inputType === 'upload')
                    obj[field?.saveKey ? field?.saveKey : field?.value] =
                        field?.type === 'number' ? Number(value?.[field?.value]) : value?.[field?.value];
                else if (field?.inputType === 'dropdown') {
                    obj[field?.saveKey ? field?.saveKey : field?.value] =
                        value?.[field?.value]?.[field?.optionValue ? field.optionValue : 'value'];
                    field.options.forEach(async (option) => {
                        if (
                            !field.isMulti &&
                            value[field.value]?.[field.optionValue ? field.optionValue : option.optionsField ? 'optionsField' : 'value'] ===
                                option[field.optionValue ? field.optionValue : option.optionsField ? 'optionsField' : 'value'] &&
                            field[
                                value[field.value]?.[field.optionValue ? field.optionValue : option.optionsField ? 'optionsField' : 'value']
                            ]?.length > 0
                        )
                            obj = await defineDataObj(
                                field[
                                    value[field.value]?.[
                                        field.optionValue ? field.optionValue : option.optionsField ? 'optionsField' : 'value'
                                    ]
                                ],
                                obj
                            );
                    });
                } else if (field?.inputType === 'checkbox' || field?.inputType === 'switch') {
                    obj[field?.saveKey ? field?.saveKey : field?.value] = !!value?.[field?.value];

                    if (field[!!value?.[field?.optionsField ? field?.optionsField : field.value]]?.length > 0) {
                        obj = await defineDataObj(field[!!value?.[field?.optionsField ? field?.optionsField : field.value]], obj);
                    }
                } else if (field?.inputType === 'json')
                    obj[field?.saveKey ? field?.saveKey : field?.value] = await defineDataObj(field.jsonFields, {});
                else if (field?.inputType === 'tableInput' || field.inputType === 'tableObject')
                    obj[field.saveKey ? field.saveKey : field.value] = value[field.value]?.length > 0 ? value[field.value] : undefined;
                else if (field.inputType === 'array') {
                    obj[field.value] = await defineSubArray(field);
                } else if (field.inputType === 'multiInput') obj = await defineSubArray(field.inputs, obj);

                if (Array.isArray(field.fields) ? field?.fields : field?.fields?.fields)
                    obj = await defineDataObj(Array.isArray(field.fields) ? field?.fields : field?.fields?.fields, obj);
            });
            arr.push(obj);
        });
        return arr;
    };

    const defineFieldVal = async (fields, value, obj) => {
        await fields.map(async (field) => {
            if (field.inputType === 'dropdown') {
                obj[field.saveKey ? field.saveKey : field.value] = value[obj.field.value]?.value;

                if (field[value[field.value]?.value] && field[value[field.value]?.value]?.length > 0) {
                    obj = await defineFieldVal(field[value[field.value]?.value].value, obj);
                }
            } else if (field.inputType === 'multiInput') {
                obj = await defineDataObj(field.inputs, obj);
            } else {
                obj[field.saveKey ? field.saveKey : field.value] = value[field.value];
            }
        });
        return obj;
    };

    const defineArrayObj = async (schema, contentArr) => {
        let arr = [];
        await confirmValidation.values?.[schema.schemaValue]?.map(async (value) => {
            let obj = {
                PayloadVersion: 1,
                PayloadUUID: guid(),
                PayloadType: schema.payloadType,
                PayloadIdentifier: schema.payloadIdentifier + '.' + guid()
            };
            await schema?.fields?.[0]?.arrayFields?.map(async (arrayField) => {
                if (arrayField.inputType === 'dropdown') {
                    obj[arrayField.saveKey ? arrayField.saveKey : arrayField.value] = value[arrayField.value]?.value;
                    if (arrayField[value[arrayField.value]?.value] && arrayField[value[arrayField.value]?.value]?.length > 0) {
                        obj = await defineFieldVal(arrayField[value[arrayField.value]?.value], value, obj);
                    }
                } else if (arrayField.inputType === 'multiInput') {
                    obj = await defineDataObj(arrayField.inputs, obj);
                } else {
                    obj[arrayField.saveKey ? arrayField.saveKey : arrayField.value] = value[arrayField.value];
                }
            });
            contentArr.push(obj);
        });
        return contentArr;
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: { ...formValues },
        validationSchema: Yup.object({
            policyName: Yup.string()
                .required('Please enter your policy name')
                .max(30, 'policy name should not exceed 30 characters')
                .min(3, 'policy name should be atleast 3 characters')
        }),
        onSubmit: async (values) => {
            setLoading(true);
            let contentObj = { USER: [], DEVICE: [] };
            const schemaArr = JSON.parse(JSON.stringify(PoliciesSchemaArr));
            const dataSchema = schemaArr?.filter(
                (schema) =>
                    schema.payloadType !== 'Configuration' && schema.payloadType !== 'com.apple.wallpaper' && schema.schemaType !== 'array'
            );
            await dataSchema.forEach(async (schema) => {
                if (
                    isConfigured[schema.label] ||
                    (schema.payloadType === 'com.apple.applicationaccess' && systemApps.filter((app) => app.checked)?.length > 0)
                ) {
                    if (schema.payloadType === 'apps') return;
                    let obj = {
                        PayloadVersion: 1,
                        PayloadType: schema.payloadType,
                        PayloadDisplayName: schema.displayName,
                        PayloadDescription: schema?.description,
                        PayloadIdentifier: schema.payloadIdentifier + '.' + guid(),
                        PayloadUUID: schema?.payloadType + '.' + guid()
                    };
                    obj = await defineDataObj(schema.fields, obj);
                    if (schema.payloadType === 'com.apple.applicationaccess')
                        obj.blockedAppBundleIDs =
                            systemApps.filter((app) => app.checked)?.map((app) => app.packagename)?.length > 0
                                ? systemApps.filter((app) => app.checked)?.map((app) => app.packagename)
                                : undefined;
                    contentObj?.[platform === 'ios' ? 'DEVICE' : schema.channel].push(obj);
                }
            });
            let arrayTypes = schemaArr.filter((schema) => schema.schemaType === 'array');
            await Promise.all(
                arrayTypes.map(async (schema) => {
                    contentObj[platform === 'ios' ? 'DEVICE' : schema.channel] = await defineArrayObj(
                        schema,
                        contentObj[platform === 'ios' ? 'DEVICE' : schema.channel]
                    );
                })
            );
            let wallpaperArr = schemaArr.find((content) => content.payloadType === 'com.apple.wallpaper');
            let wallpapers = {};
            if (wallpaperArr) {
                wallpaperArr.fields?.forEach((field) => {
                    if (confirmValidation.values[field.value])
                        wallpapers[field.value] = {
                            RequestType: 'Settings',
                            Settings: [{ Image: confirmValidation.values[field.value], Where: field.Where, Item: field.Item }]
                        };
                });
            }
            const dataObj = {
                name: values?.policyName,
                policytype: policyType,
                platform: platform,
                code: recordID ? code : undefined,
                policy: {
                    PayloadVersion: 1,
                    PayloadType: 'Configuration',
                    PayloadUUID: policy?.PayloadUUID,
                    PayloadIdentifier: policy?.PayloadIdentifier,
                    PayloadDisplayName: values?.PayloadDisplayName || 'Test Name',
                    PayloadDescription: values?.PayloadDescription || 'Test Description',
                    PayloadRemovalDisallowed: !!values?.PayloadRemovalDisallowed,
                    PayloadContent: contentObj
                },
                assets: []
            };
            if (Object.keys(wallpapers)?.length > 0) dataObj.others = wallpapers;
            else if (formType === 'edit') dataObj.others = {};

            Object.entries(assetValidationConfirmation.values).forEach((value) => {
                let valObj = value?.[1];
                if (valObj.ManagementFlags?.value)
                    dataObj.assets.push({
                        RequestType: valObj?.RequestType?.value,
                        iTunesStoreID: valObj?.appType === 'epa' ? Number(value[0]) : undefined,
                        InstallAsManaged: !!valObj?.InstallAsManaged,
                        ManagementFlags: valObj?.ManagementFlags?.value,
                        Options: { PurchaseMethod: 1 },
                        ManifestURL: valObj.appType === 'vpp' ? value[0] : undefined,
                        managedconfigid: valObj?.managedconfigid?.managedconfigid
                        /*
                         * iOSApp: !!valObj?.iOSApp,
                         * Identifier: app.packagename
                         * RequestRequiresNetworkTether: !!valObj?.RequestRequiresNetworkTether,
                         * ManagedAppsOnly: !!valObj?.ManagedAppsOnly
                         */
                        /*
                         * Attributes: {
                         *     AssociatedDomains: valObj?.AssociatedDomains,
                         *     AssociatedDomainsEnableDirectDownloads: !!valObj?.AssociatedDomainsEnableDirectDownloads,
                         *     CellularSliceUUID: valObj?.CellularSliceUUID,
                         *     ContentFilterUUID: valObj?.ContentFilterUUID,
                         *     DNSProxyUUID: valObj?.DNSProxyUUID,
                         *     RelayUUID: valObj?.RelayUUID,
                         *     Removable: !!valObj?.Removable,
                         *     TapToPayScreenLock: !!valObj?.TapToPayScreenLock,
                         *     VPNUUID: valObj?.VPNUUID
                         * }
                         */
                    });
            });
            createNdUpdate(dataObj);
        }
    });

    const handleConfigured = (fields, flag) => {
        fields.forEach((field) => {
            if (!!validation.values[field.value]) {
                flag = !!validation.values[field.value];
                return;
            }
            if (Array.isArray(field)) flag = handleConfigured(field, flag);
            if (field.jsonFields) flag = handleConfigured(field.jsonFields, flag);
            if (field.fields) flag = handleConfigured(field.fields, flag);
            if (flag) return;
        });
        return flag;
    };

    const confirmValidation = useFormik({
        enableReinitialize: true,
        initialValues: { ...formValues },
        onSubmit: () => {
            if (selected.label === 'Applications' && isApplication) {
                setApplicationsConfirmation(applications);
            }
            const configured = JSON.parse(JSON.stringify(isConfigured));
            window.scrollTo({ top: 0, behavior: 'smooth' });
            if (!isApplication && selected.label === 'Applications') {
                let assetVals = JSON.parse(JSON.stringify(assetValidation.values));
                assetValidationConfirmation.setValues(assetVals);
                setIsApplication(true);
            } else {
                let vals = JSON.parse(JSON.stringify(validation.values));
                confirmValidation.setValues(vals);
                setSelected('');
            }
            setSelectedApp('');
            let polSchema = JSON.parse(JSON.stringify(profileDetails));
            let polObj = polSchema.find((schema) => selected.label === schema.label);
            if (selected.schemaType === 'array') {
                configured[polObj.label] = validation.values[polObj.schemaValue]?.length > 0;
            } else if (selected.label !== 'Applications')
                (polObj?.fields || [])?.forEach(async (field) => {
                    let flag = false;
                    if (field.inputType === 'array' || field.inputType === 'tableObject' || field.inputType === 'tableInput') {
                        flag = !!validation.values[field.value]?.length > 0;
                    } else if (field.inputType === 'dropdown') {
                        flag = field.noDefaultVal
                            ? !!validation.values[field.value]
                            : validation.values[field.value]?.[field.optionValue ? field.optionValue : 'value'] !== field.defaultVal;
                    } else if (field.inputType === 'checkbox') {
                        flag = field.noDefaultVal
                            ? !!validation.values[field.value]
                            : !!validation.values[field.value] !== !!field.defaultVal;
                    } else if (field.inputType === 'upload') {
                        flag = field.noDefaultVal ? !!validation.values[field.value] : validation.values[field.value] !== field.defaultVal;
                    } else if (field.inputType === 'policyInput') {
                        flag = field.noDefaultVal ? !!validation.values[field.value] : validation.values[field.value] !== field.defaultVal;
                    } else if (field.inputType === 'multiInput') flag = defineSubFieldFlag(field.inputs, validation.values, flag);
                    if (flag) {
                        configured[polObj?.label] = flag;
                        return;
                    }
                    if (field.fields) flag = defineSubFieldFlag(field.fields, validation.values, flag);
                    if (flag) {
                        configured[polObj?.label] = flag;
                        return;
                    }
                    if (field.jsonFields) {
                        flag = defineSubFieldFlag(field.jsonFields, validation.values, flag);
                    }
                    if (flag) {
                        configured[polObj?.label] = flag;
                        return;
                    }
                });
            else if (isApplication) {
                configured[polObj?.label] =
                    applications.filter((app) => app.checked)?.length > 0 || systemApps.filter((app) => app.checked)?.length > 0;
            }
            setIsApplication(true);
            setIsConfigured(configured);
            setIsShowCardData(false);
        }
    });
    const assetValidation = useFormik({
        enableReinitialize: true,
        initialValues: initialAssetValues
    });

    const assetValidationConfirmation = useFormik({
        enableReinitialize: true,
        initialValues: initialAssetValues
    });

    useEffect(() => {
        confirmValidation.setValues({ ...confirmValidation.values, policyName: validation.values.policyName });
    }, [validation.values.policyName]);

    const createNdUpdate = (obj) => {
        let apiService;
        if (recordID && !code) {
            toast.error('Code not found');
            history.push('/iospolicies');
        }
        if (formType === 'edit') apiService = api.patch(url.POLICIES + '/' + recordID, obj, false, domains.IOS_V1);
        else apiService = api.create(url.POLICIES, obj, false, domains.IOS_V1);

        apiService
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    toast.success(
                        formType === 'edit' ? toastMessages.iosPolicyUpdated[platform] : toastMessages.iosPolicyCreated[platform]
                    );
                    history.push('/iospolicies');
                }
            })
            .catch((_err) => setLoading(false));
    };

    const handleKeyDown = (e) => {
        if (e.keyCode === 13) e.preventDefault();
    };

    const handleProfileDetail = (profileObj, index) => {
        if (profileObj.label === 'Applications' && applications?.length === 0) {
            toast.error('No applications found.');
            return;
        }
        setIsShowCardData(true);
        profileObj = { ...profileObj, active: true };
        let profileDetailsArr = [];
        PoliciesSchemaArr.map((detail) => {
            if (PoliciesSchemaArr.indexOf(detail) === index) return profileDetailsArr.push(profileObj);
            else return profileDetailsArr.push({ ...detail, active: false });
        });
        setSelected(profileObj);
        setProfileDetails(profileDetailsArr);
    };

    const handleFieldData = async (fields, obj) => {
        await fields.forEach((field) => {
            if (field.inputType === 'checkbox') obj[field.value] = false;
            else obj[field.value] = '';

            if (field.inputType === 'dropdown') {
                field.options.forEach(async (option) => {
                    if (field[option.value]?.length > 0) {
                        obj = await handleFieldData(field[option.value], obj);
                    }
                });
            }
        });
        return obj;
    };

    const getEmptyObj = async (fields) => {
        let obj = {};
        await fields?.forEach((field) => {
            if (field.inputType === 'checkbox') obj[field.value] = false;
            else obj[field.value] = '';

            if (field.inputType === 'dropdown') {
                field.options.forEach(async (option) => {
                    if (field[option.value]?.length > 0) {
                        obj = await handleFieldData(field[option.value], obj);
                    }
                });
            }
        });
        return obj;
    };

    const handleBack = async () => {
        if (!isArray) {
            setIsArray(true);
            let obj = await getEmptyObj(selected.fields?.[0]?.arrayFields);
            validation.setValues({ ...validation.values, ...obj });
            setArrayMode('add');
            setArrayIndex(-1);
            return;
        }
        setIsShowCardData(false);
        setSelected('');
        validation.setValues({ ...confirmValidation.values });
    };

    const addApplication = (app, e) => {
        const apps = JSON.parse(JSON.stringify(applications));
        apps.forEach((appObj) => {
            if (appObj.url === app.url) appObj.checked = !appObj.checked;
        });
        if (!e.target.checked) delete assetValidation.values[app.adamid ? app.adamid : app.url];
        setApplications(apps);
    };

    const handleSelectApp = (app) => {
        setSelectedApp(app);
        setIsApplication(false);
    };

    const handleSubFields = (fields, flag) => {
        if (flag) return flag;
        fields?.forEach((field) => {
            if (selected?.label !== 'Applications' && (field?.inputType === 'policyInput' || field?.inputType === 'dropdown')) {
                flag = field.notMandatory ? false : !validation.values[field?.value];
                return;
            } else if (field?.inputType === 'multiInput') {
                if (field?.inputs?.length > 0) {
                    if (flag) return;
                    field?.inputs?.forEach((childInput) => {
                        if (flag) return;
                        if (
                            childInput?.inputType === 'policyInput' &&
                            (field.notMandatory ? false : !validation.values[childInput?.value])
                        ) {
                            flag = true;
                            return;
                        }
                    });
                }
                return flag;
            } else if (field?.inputType === 'json') {
                field?.jsonFields?.forEach((json) => {
                    if (
                        (json?.inputType === 'policyInput' || json?.inputType === 'dropdown') &&
                        selected.label !== 'Applications' &&
                        (field.notMandatory ? false : !validation.values[json.value])
                    ) {
                        flag = true;
                        return;
                    }
                });
                if (flag) return;
            }
            if (field?.fields) flag = handleSubFields(field.fields, flag);
            if (flag) return;
        });

        return flag;
    };

    const checkDropDownFields = (fields, flag) => {
        if (flag) return flag;
        fields.forEach((field) => {
            if (
                (field?.inputType === 'policyInput' || field?.inputType === 'upload') &&
                (field.notMandatory ? false : !validation.values[field?.value])
            ) {
                flag = true;
                return;
            } else if (field?.inputType === 'dropdown') {
                if (field.notMandatory ? false : !validation.values[field.value]) {
                    flag = true;
                    return;
                }
                if (field[validation.values?.[field.value]?.[field.optionValue ? field.optionValue : 'value']]?.length > 0 && !flag) {
                    flag = checkDropDownFields(
                        field[validation.values?.[field.value]?.[field.optionValue ? field.optionValue : 'value']],
                        flag
                    );
                }
            } else if (field?.inputType === 'multiInput') {
                if (field?.inputs?.length > 0) {
                    if (flag) return;
                    field?.inputs?.forEach((childInput) => {
                        if (flag) return;
                        if (
                            childInput?.inputType === 'policyInput' &&
                            (field.notMandatory ? false : !validation.values[childInput?.value])
                        ) {
                            flag = true;
                            return;
                        }
                    });
                }
                return flag;
            } else if (field?.inputType === 'json') {
                field?.jsonFields?.forEach((json) => {
                    if (json?.jsonFields) {
                        json?.jsonFields?.forEach((jsonObj) => {
                            if (
                                (jsonObj?.inputType === 'policyInput' || jsonObj?.inputType === 'dropdown') &&
                                (field.notMandatory ? false : !validation.values[jsonObj?.value])
                            ) {
                                flag = true;
                                return;
                            }
                        });
                    }
                    if (
                        (json?.inputType === 'policyInput' || json?.inputType === 'dropdown') &&
                        (field.notMandatory ? false : !validation.values[json?.value])
                    ) {
                        flag = true;
                        return;
                    }
                });
                if (flag) return;
            }
            if (field?.fields) flag = handleSubFields(field?.fields, flag);
            if (flag) return;
        });
        return flag;
    };

    const handleSubmitDisable = () => {
        let configured = JSON.parse(JSON.stringify(isConfigured));
        delete configured['General Settings'];
        return (
            Object.entries(configured)?.filter((conf) => conf[1])?.length === 0 ||
            validation.values?.policyName?.length < 3 ||
            validation.values.policyName === '' ||
            !isConfigured['Restrictions']
        );
    };

    const checkConfirmDisabled = () => {
        let flag = false;
        if (selected.label === 'Applications' && isApplication) {
            applications.forEach((app) => {
                if (app.checked) {
                    selected.fields?.fields?.forEach((field) => {
                        if (
                            (field.inputType === 'policyInput' || field.inputType === 'dropdown') &&
                            (field.notMandatory
                                ? false
                                : !assetValidationConfirmation?.values?.[app.adamid ? app.adamid : app.url]?.[field.value])
                        ) {
                            flag = true;
                            return;
                        }
                    });
                }
                if (flag) return;
            });
        } else if (selected.schemaType === 'array') {
            flag = !validation.values[selected.schemaValue] || validation.values[selected.schemaValue]?.length === 0;
        } else if (selected.label === 'Applications' && !isApplication) {
            selected.fields?.[0]?.fields?.forEach((field) => {
                if (
                    (field.inputType === 'policyInput' || field.inputType === 'dropdown') &&
                    (field.notMandatory
                        ? false
                        : !assetValidation?.values?.[selectedApp?.adamid ? selectedApp?.adamid : selectedApp?.url]?.[field.value])
                ) {
                    flag = true;
                    return;
                }
            });
        } else if (selected && selected?.schemaType === 'array') {
            flag = false;
        } else if (selected && selected.fields) {
            selected.fields.forEach((field) => {
                if (
                    (field?.inputType === 'policyInput' || field?.inputType === 'upload') &&
                    (field.notMandatory ? false : !validation.values[field?.value])
                ) {
                    flag = true;
                    return;
                } else if (field?.inputType === 'multiInput') {
                    if (field?.inputs?.length > 0) {
                        field?.inputs?.forEach((childInput) => {
                            if (flag) return;
                            if (
                                childInput?.inputType === 'policyInput' &&
                                (field.notMandatory ? false : !validation.values[childInput?.value])
                            ) {
                                flag = true;
                                return;
                            }
                        });
                    }
                    return flag;
                } else if (field?.inputType === 'dropdown') {
                    if (field.notMandatory ? false : !validation.values[field.value]) {
                        flag = true;
                        return;
                    }
                    if (field[validation.values?.[field.value]?.[field.optionValue ? field.optionValue : 'value']]?.length > 0 && !flag) {
                        flag = checkDropDownFields(
                            field[validation.values?.[field.value]?.[field.optionValue ? field.optionValue : 'value']],
                            flag
                        );
                    }
                } else if (field?.inputType === 'json') {
                    field?.jsonFields?.forEach((json) => {
                        if (json?.jsonFields) {
                            json?.jsonFields?.forEach((jsonObj) => {
                                if (
                                    (jsonObj?.inputType === 'policyInput' || jsonObj?.inputType === 'dropdown') &&
                                    (jsonObj.notMandatory ? false : !validation.values[jsonObj?.value])
                                ) {
                                    flag = true;
                                    return;
                                }
                            });
                        }
                        if (
                            (json?.inputType === 'policyInput' || json?.inputType === 'dropdown') &&
                            (json.notMandatory ? false : !validation.values[json?.value])
                        ) {
                            flag = true;
                            return;
                        }
                    });
                    if (flag) return;
                } else if (field.inputType === 'checkbox') {
                    flag = handleSubFields(field[!!validation.values[field.value]], flag);
                    if (flag) return;
                }
                if (field?.fields) flag = handleSubFields(field?.fields, flag);
                if (flag) return;
            });
        }
        return flag;
    };

    const addSystemApplication = (app) => {
        let apps = JSON.parse(JSON.stringify(systemApps));
        apps.forEach((appObj) => {
            if (appObj.packagename === app.packagename) appObj.checked = !appObj.checked;
        });
        setSystemApps(apps);
    };

    const handleField = (fields) => {
        return (
            <Col>
                {fields?.map((field, index) => (
                    <Row className={'p-1'} key={index}>
                        <DynamicComponents
                            field={field}
                            setSelected={setSelected}
                            setIsShowCardData={setIsShowCardData}
                            isApplication={isApplication}
                            setIsApplication={setIsApplication}
                            index={index}
                            formType={formType}
                            applications={applications}
                            selected={selected}
                            systemApps={systemApps}
                            addSystemApplication={addSystemApplication}
                            validation={validation}
                            handleKeyDown={handleKeyDown}
                            addApplication={addApplication}
                            selectedApp={selectedApp}
                            assetsValidation={assetValidation}
                            handleSelectApp={handleSelectApp}
                            singleApps={singleApps}
                            isArray={isArray}
                            setIsArray={setIsArray}
                            arrayMode={arrayMode}
                            setArrayMode={setArrayMode}
                            arrayIndex={arrayIndex}
                            setArrayIndex={setArrayIndex}
                            managedConfigs={managedConfigs}
                            getManagedConfigs={getManagedConfigs}
                        />
                    </Row>
                ))}
            </Col>
        );
    };

    const clearFields = (fields, validVals, confirmValidVals) => {
        fields?.forEach((field) => {
            if (field.inputType === 'checkbox') {
                if (field[!!validVals?.[field.val]]) {
                    let { validValues, confirmValidValues } = clearFields(field[validVals[field.value]], validVals, confirmValidVals);
                    validVals = { ...validValues };
                    confirmValidVals = { ...confirmValidValues };
                }
                confirmValidVals[field.value] = field.defaultVal === true || field.defaultVal === false ? field.defaultVal : false;
                validVals[field.value] = false;
            } else if (field.inputType === 'dropdown') {
                if (field.defaultVal || field.defaultVal === 0)
                    validVals[field.value] = (field.options || [])?.find(
                        (option) => option[field.optionValue ? field.optionValue : 'value'] === field.defaultVal
                    );
                else delete validVals[field.value];
                (field.options || [])?.forEach((option) => {
                    if (field[option[field.optionValue ? field.optionValue : 'value']]) {
                        let { validValues, confirValidValues } = clearFields(
                            field[option[field.optionValue ? field.optionValue : 'value']],
                            validVals,
                            confirmValidVals
                        );
                        validVals = { ...validValues };
                        confirmValidVals = { ...confirValidValues };
                    }
                });
            } else if (field.inputType === 'policyInput' || field.inputType === 'input') {
                if (field.defaultVal) validVals[field.value] = field.defaultVal;
                else {
                    delete confirmValidVals[field.value];
                    delete validVals[field.value];
                }
            } else if (field.inputType === 'multiInput') {
                let { validValues, confirValidValues } = clearFields(field.inputs, validVals, confirmValidVals);
                validVals = { ...validValues };
                confirmValidVals = { ...confirValidValues };
            } else {
                delete confirmValidVals[field.value];
                delete validVals[field.value];
            }
            if (field.fields) clearFields(field.fields, validVals, confirmValidVals);
            if (field.jsonFields) clearFields(field.jsonFields, validVals, confirmValidVals);
        });
        validation.setValues({ ...validVals });
        confirmValidation.setValues({ ...confirmValidVals });
        return { validValues: validVals, confirValidValues: confirmValidVals };
    };

    const handleDeleteConfirm = () => {
        let schema = deletingSchema;
        if (schema.label === 'Applications') {
            let values = { ...assetValidation.values };
            (schema.fields?.[0]?.fields || []).forEach((field) => {
                applications.forEach((app) => {
                    app.checked = false;
                    if (field.inputType === 'checkbox') values[app.adamid ? app.adamid : app.url] = false;
                    else delete values[app.adamid ? app.adamid : app.url]?.[field.value];
                });
            });
            let sysApps = JSON.parse(JSON.stringify(systemApps));
            sysApps.forEach((app) => (app.checked = false));
            setSystemApps(sysApps);
            setApplications(applications);
            assetValidation.setValues({ ...values });
            assetValidationConfirmation.setValues({ ...values });
        } else if (selected.schemaType === 'array') {
            validation.setValues({ ...validation.values, [selected.schemaValue]: [] });
        } else {
            let validVals = { ...validation.values };
            let confirmValidVals = { ...confirmValidation.values };
            (schema.fields || []).forEach((field) => {
                if (field.inputType === 'checkbox') {
                    if (field[!!validVals?.[field.val]]) {
                        let { validValues, confirmValidValues } = clearFields(field[validVals[field.value]], validVals, confirmValidVals);
                        validVals = { ...validValues };
                        confirmValidVals = { ...confirmValidValues };
                    }
                    confirmValidVals[field.value] = field.defaultVal === true || field.defaultVal === false ? field.defaultVal : false;
                    validVals[field.value] = false;
                } else if (field.inputType === 'dropdown') {
                    if (field.defaultVal || field.defaultVal === 0)
                        validVals[field.value] = (field.options || [])?.find(
                            (option) => option[field.optionValue ? field.optionValue : 'value'] === field.defaultVal
                        );
                    else delete validVals[field.value];
                    (field.options || [])?.forEach((option) => {
                        if (field[option[field.optionValue ? field.optionValue : 'value']]) {
                            let { validValues, confirValidValues } = clearFields(
                                field[option[field.optionValue ? field.optionValue : 'value']],
                                validVals,
                                confirmValidVals
                            );
                            validVals = { ...validValues };
                            confirmValidVals = { ...confirValidValues };
                        }
                    });
                } else if (field.inputType === 'policyInput' || field.inputType === 'input') {
                    if (field.defaultVal) validVals[field.value] = field.defaultVal;
                    else {
                        delete confirmValidVals[field.value];
                        delete validVals[field.value];
                    }
                } else if (field.inputType === 'multiInput') {
                    let { validValues, confirValidValues } = clearFields(field.inputs, validVals, confirmValidVals);
                    validVals = { ...validValues };
                    confirmValidVals = { ...confirValidValues };
                } else {
                    delete confirmValidVals[field.value];
                    delete validVals[field.value];
                }
                if (field.fields) clearFields(field.fields, validVals, confirmValidVals);
                if (field.jsonFields) clearFields(field.jsonFields, validVals, confirmValidVals);
            });
            validation.setValues({ ...validVals });
            confirmValidation.setValues({ ...validVals });
        }
        setDeletingSchema('');
        setDeleteModal(false);
        setIsConfigured({ ...isConfigured, [schema.label]: false });
    };

    const message = () => {
        return (
            <div className="mt-3">
                <div className="mb-2">Are you sure you want to clear the configuration for {deletingSchema.label}?</div>
                <div className="mb-2 d-flex align-items-center justify-content-center">
                    <img src={deletingSchema.image} alt="deleteGroup" width={60} height={60} />
                </div>
            </div>
        );
    };

    const handleDeleteConfig = (e, schema) => {
        e.stopPropagation();
        setDeletingSchema(schema);
        setDeleteModal(true);
    };

    const underDevCondition = (schema) => {
        return false;
        // window.location.hostname === 'portal.mdmdev.tectoro.com' || window.location.hostname === 'localhost' ? false : schema.underDev;
    };

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className={`page-content ${loading ? 'postion-relative mask' : ''}`}>
                <Container fluid>
                    <BreadCrumb
                        pageTitle={isShowCardData ? selected.label : `${formType?.charAt(0)?.toUpperCase() + formType?.slice(1)} Policy`}
                        history={history}
                        homeLink="Dashboard"
                        showBack={true}
                        customBack={isShowCardData ? true : false}
                        backLink={!isShowCardData ? 'iospolicies' : handleBack}
                        isApplication={isApplication}
                        setIsApplication={setIsApplication}
                        label={selected.label}
                    />
                    <Card>
                        <CardHeader>
                            <Row>
                                {selected === '' ? (
                                    <Col lg={6} className="d-flex flex-column gap-2">
                                        <Row>
                                            <InputComponent
                                                field={{
                                                    label: 'Policy Name',
                                                    value: 'policyName',
                                                    helpText: 'Enter policy name',
                                                    type: 'text',
                                                    exclude: true,
                                                    mandatory: true,
                                                    codeBased: false,
                                                    maxLength: 30
                                                }}
                                                formType={formType}
                                                handleKeyDown={handleKeyDown}
                                                index={'index'}
                                                validation={validation}
                                            />
                                        </Row>
                                    </Col>
                                ) : (
                                    <div>
                                        <div className="fw-semibold fs-16">{selected.label}</div>
                                        <div className="text-muted">{selected.helpText}</div>
                                    </div>
                                )}
                            </Row>
                        </CardHeader>
                        <CardBody className={`${validation.values?.policyName?.length < 3 ? 'mask pe-none' : ''}`}>
                            {!selected && (
                                <Row>
                                    {profileDetails?.map((schema, index) => (
                                        <Col xs={6} sm={3} md={4} lg={''} xl={''} className="min-width-per-20 flex-grow-0" key={index}>
                                            <Card
                                                className={`min-height-150 shadow-card ${
                                                    underDevCondition(schema) ? 'mask pe-none' : 'cursor-pointer border border-1'
                                                }`}
                                                onClick={() => {
                                                    if (!underDevCondition(schema)) handleProfileDetail(schema, index);
                                                }}
                                            >
                                                <CardBody className="d-flex align-items-center justify-content-center flex-column">
                                                    {isConfigured[schema.label] &&
                                                        formType !== 'view' &&
                                                        schema.label !== 'Restrictions' && (
                                                            <span
                                                                className="text-danger fs-12 text-decoration-underline curosr-pointer position-absolute end-10 top-5 fw-medium"
                                                                onClick={(e) => handleDeleteConfig(e, schema)}
                                                            >
                                                                Clear
                                                            </span>
                                                        )}
                                                    <div className="d-flex align-items-center justify-content-center flex-column gap-2">
                                                        <img src={schema.image} alt={schema.label} height={30} width={30} />
                                                        <div className="fs-14 fw-medium text-center d-flex align-items-center gap-1">
                                                            {schema.label}
                                                            {(schema.mandatory || (schema.mandatoryWhileMac && platform === 'macos')) && (
                                                                <span className="text-danger">*</span>
                                                            )}
                                                        </div>
                                                        <div
                                                            className={`${
                                                                underDevCondition(schema)
                                                                    ? 'text-info fw-medium'
                                                                    : isConfigured[schema.label]
                                                                    ? 'text-success'
                                                                    : 'text-muted'
                                                            } d-flex align-items-center gap-2`}
                                                        >
                                                            <i
                                                                className={`${
                                                                    isConfigured[schema.label]
                                                                        ? 'ri-checkbox-circle-fill'
                                                                        : 'ri-information-line'
                                                                }`}
                                                            />
                                                            <span className="fs-12 text-center">
                                                                {underDevCondition(schema)
                                                                    ? 'Coming Soon'
                                                                    : isConfigured[schema.label]
                                                                    ? 'Configured'
                                                                    : 'Not Configured'}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    ))}
                                </Row>
                            )}
                            {selected && selected !== '' && (
                                <Row sm={12} className="px-0 gap-3">
                                    {handleField(selected.fields)}
                                </Row>
                            )}
                        </CardBody>
                        <CardFooter className={`${!formType === 'view' || formType === 'add' ? 'border-top-0 padding-bottom-50' : ''}`}>
                            {!selected ? (
                                <div className="gap-2 d-flex justify-content-end">
                                    <Button type="button" className="btn btn-light" onClick={() => history.push('/iospolicies')}>
                                        Cancel
                                    </Button>
                                    {formType !== 'view' && (
                                        <Button
                                            size="md"
                                            type="button"
                                            color="primary"
                                            disabled={handleSubmitDisable()}
                                            onClick={validation.handleSubmit}
                                        >
                                            {formType === 'edit' ? 'Update' : 'Save'}
                                        </Button>
                                    )}
                                </div>
                            ) : (
                                isArray && (
                                    <div className="gap-2 d-flex justify-content-end">
                                        <Button
                                            type="button"
                                            className="btn btn-light"
                                            onClick={() => {
                                                validation.setValues({ ...confirmValidation.values });
                                                window.scrollTo({ top: 0, behavior: 'smooth' });
                                                if (selected?.label === 'Applications' && !isApplication) {
                                                    setIsApplication(true);
                                                } else if (selected?.label === 'Applications' && isApplication) {
                                                    setApplications(applicationsConfirmation);
                                                    setSelected('');
                                                } else setSelected('');
                                                setSelectedApp('');
                                                setIsShowCardData(false);
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                        {formType !== 'view' && (
                                            <Button
                                                size="md"
                                                disabled={checkConfirmDisabled()}
                                                type="button"
                                                color="primary"
                                                onClick={confirmValidation.handleSubmit}
                                            >
                                                Save
                                            </Button>
                                        )}
                                    </div>
                                )
                            )}
                        </CardFooter>
                    </Card>
                </Container>
                <DeleteModal
                    hideIcon={true}
                    show={deleteModal}
                    hideDeleteMessage={true}
                    message={message()}
                    confirmText={'Yes, Delete it!'}
                    onDeleteClick={handleDeleteConfirm}
                    onCloseClick={() => {
                        setDeletingSchema('');
                        setDeleteModal(false);
                    }}
                    deleteMessage={'Are you sure you want to delete this configuration ?'}
                />
            </div>
        </React.Fragment>
    );
};

export default AddIOSPolicy;
