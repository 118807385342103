import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Loader from '../../../Components/Common/Loader';
import { Card, CardBody, Container, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import TableContainer from '../../../Components/Common/TableContainer';
import { priviliges } from '../../../Components/constants/constants';
import { APIClient } from '../../../helpers/api_helper';
import { useEnv } from '../../../envContext';
import * as url from '../../../helpers/url_helper';
import * as domains from '../../../helpers/domain_helper';
import CommonModal from '../../../Components/Common/CommonModal';
import { windowsScriptsTableSchema } from '../TableSchema';
import { convertUTCtoIST } from '../../../Components/Common/Util';
import uploadScript from '../../../assets/images/windows/svg/common/scriptsUpload.svg';
import DeleteModal from '../../../Components/Common/DeleteModal';
import ClearData from '../../../assets/images/common/png/delete/DataLoss.png';
import { toast } from 'react-toastify';
import toastMessages from '../../../common/messages/toastMessages';

const WindowsScripts = () => {
    document.title = 'Windows Scripts';
    const api = new APIClient();
    const urlconf = useEnv();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [searchVal, setSearchVal] = useState('');
    const [totalRecords, setTotalRecords] = useState(0);
    const [scriptsTableSchema, setScriptsTableSchema] = useState([...windowsScriptsTableSchema]);
    const [scripts, setScripts] = useState([]);
    const [scriptsBackup, setScriptsBackup] = useState([]);
    const [showScriptsModal, setShowScriptsModal] = useState(false);
    const [scripType, setScripType] = useState('');
    const [selectedRow, setSelectedRow] = useState('');
    const [deleteModal, setDeleteModal] = useState(false);

    useEffect(() => {
        getScripts();
    }, []);

    const getScripts = () => {
        setLoading(true);
        api.get(url.SCRIPTS, { page: 1, size: 100 }, domains.WINDOWS_V1)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.data) {
                    resp.data.forEach((data) => {
                        data.ModifiedDate = data.ModifiedDate ? convertUTCtoIST(data.ModifiedDate) : '—';
                    });
                    setScripts(resp.data);
                    setScriptsBackup(resp.data);
                    setTotalRecords(resp.totalRecords);
                } else {
                    setScripts([]);
                    setScriptsBackup([]);
                    setTotalRecords(0);
                }
            })
            .catch(() => {
                setLoading(false)
            })
            .finally((err) => setLoading(false));
    };

    const handleGlobalSearch = (val) => {
        let filteredScripts = [...scriptsBackup];
        if (val?.length > 0) filteredScripts = scriptsBackup.filter((script) => script?.Name?.toLowerCase()?.includes(val?.toLowerCase()));
        setSearchVal(val);
        setTotalRecords(filteredScripts.length);
        setScripts(filteredScripts);
    };

    const handleAddNew = () => {
        setShowScriptsModal(true);
    };

    const handleDelete = (row) => {
        setSelectedRow(row.original);
        setDeleteModal(true);
    };

    const handlePolicyModalBody = () => {
        return (
            <div>
                <Row className="align-items-center justify-content-evenly">
                    {[{}]?.length > 0 &&
                        [
                            {
                                img: uploadScript,
                                label: 'Upload Script',
                                id: 'US',
                                desc: 'You can execute custom scripts on Windows machines, create script files with custom configurations and upload the file.'
                            }
                        ].map((ele, index) => {
                            return (
                                <Card
                                    key={index}
                                    className={`d-flex align-items-center justify-content-center mb-0 p-0 border shadow width-250 height-250 cursor-pointer ${ele.id === scripType.id ? 'border-2 border-primary' : ''
                                        }`}
                                    onClick={() => setScripType(ele)}
                                >
                                    <CardBody className="d-flex flex-column align-items-center justify-content-center position-relative">
                                        {ele.id === scripType.id && (
                                            <i className="position-absolute end-10 top-5 link-primary fs-18 ri-check-double-fill"></i>
                                        )}
                                        <div>
                                            <img src={ele.img} alt="" height={60} width={60} />
                                        </div>
                                        <p className="text-uppercase fw-semibold text-info text-truncate mt-3 mb-3">{ele.label}</p>
                                        <span className="text-wrap text-center fw-normal fs-12">{ele.desc}</span>
                                    </CardBody>
                                </Card>
                            );
                        })}
                </Row>
            </div>
        );
    };

    const message = () => {
        return (
            <div className="mt-3">
                <div className="mb-2">Are you sure you want to Delete Script?</div>
                <div className="mb-2 d-flex align-items-center justify-content-center">
                    <img src={ClearData} alt="deleteGroup" width={60} height={60} />
                </div>
                <div>
                    Script Name: <span className="fw-semibold">{selectedRow.Name}</span>
                </div>
            </div>
        );
    };

    const handleDeleteConfirmation = () => {
        setDeleteModal(false);
        setSelectedRow('');
        api.delete(url.SCRIPTS + '/' + selectedRow.Id, '', domains.WINDOWS_V1)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status?.toLowerCase() === 'success') {
                    toast.success(toastMessages.scriptDeleted);
                    setSearchVal('');
                    getScripts();
                }
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className={`page-content h-100 ${loading ? 'postion-relative mask' : ''}`}>
                <Container fluid>
                    <BreadCrumb pageTitle="Windows Scripts" history={history} homeLink="Dashboard" />
                    <TableContainer
                        loading={loading}
                        tableHeader={'All Scripts'}
                        isGlobalFilter={true}
                        addButton={true}
                        largeSearchFilter={true}
                        SearchFilterWidth={'width-280'}
                        searchFilter2={true}
                        searchVal={searchVal}
                        searchPlaceHolder={'Script Name'}
                        columns={scriptsTableSchema}
                        handleGlobalSearch={handleGlobalSearch}
                        handleDelete={handleDelete}
                        handleAddNew={handleAddNew}
                        handleClickView={(row) => history.push(`/windowsscripts/view/${row.original.Id}/US`)}
                        totalRecords={totalRecords}
                        data={scripts}
                        editor={priviliges.WINDOWSDEVICE_READER}
                        reader={priviliges.WINDOWSDEVICE_READER}
                        className="custom-header-css"
                        divClass="table-responsive table-card"
                        tableClass="table table-nowrap table-border table-centered align-middle"
                        theadClass="bg-light text-muted"
                    />
                    <DeleteModal
                        hideIcon={true}
                        show={deleteModal}
                        hideDeleteMessage={true}
                        message={message()}
                        onDeleteClick={handleDeleteConfirmation}
                        onCloseClick={() => setDeleteModal(false)}
                    />
                    <CommonModal
                        size={'md'}
                        disabled={!scripType?.id || scripType?.id === ''}
                        cancelText={'Cancel'}
                        saveText={'Proceed'}
                        show={showScriptsModal}
                        modalheader={'Create New Script'}
                        onCloseClick={() => {
                            setShowScriptsModal(false);
                            setScripType('');
                        }}
                        handleClick={() => {
                            history.push('/windowsscripts/add/' + scripType.id);
                            setShowScriptsModal(false);
                        }}
                        handleModalBody={() => handlePolicyModalBody()}
                    />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default WindowsScripts;
