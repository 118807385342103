import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, CardHeader, Col, Input, Row } from 'reactstrap';
import CNameImage from '../../../assets/images/windows/svg/common/CName.svg';
import { convertUTCtoIST, Tooltip } from '../../../Components/Common/Util';
import { APIClient } from '../../../helpers/api_helper';
import { useEnv } from '../../../envContext';
import * as domains from '../../../helpers/domain_helper';
import * as url from '../../../helpers/url_helper';
import axios from 'axios';

const CNameValidation = ({ setLoading }) => {
    const api = new APIClient();
    const urlconf = useEnv();
    const [CName, setCName] = useState(null);
    const [isVerified, setIsVerified] = useState(false);
    const [verifiedCName, setVerifiedCName] = useState('');
    const [winConfig, setWinConfig] = useState({});
    const [configDefined, setConfigDefined] = useState(false);

    const copyToClipboard = async (val, toastmsg) => {
        navigator.clipboard.writeText(val).then(() => {
            toast.success(`${toastmsg} Copied Successfully.`);
        });
    };

    useEffect(() => {
        getWPNData();
    }, []);


    const getWPNData = () => {
        setLoading(true);
        api.get(url.WPN_CONFIG, {}, domains.WINDOWS_V1)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.data && resp.data?.length > 0) {
                    setWinConfig(resp.data[0]);
                    setIsVerified(true);
                    setVerifiedCName('enterpriseenrollment.' + resp.data[0].CName);
                    setConfigDefined(true);
                }
            })
            .catch((err) => {
                console.log('err')
            })
            .finally(() => setLoading(false));
    };


    const onValidate = () => {
        setLoading(true);
        const endpoint = configDefined ? `${url.WPN_CONFIG}/${winConfig?._id}` : url.WPN_CONFIG;
        const method = configDefined ? 'patch' : 'create';
        const domain = CName.replace("enterpriseenrollment.", "");
        api[method](endpoint, { domain: domain, authmode: 'Federated' })
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    toast.success('Validated Successfully.');
                    setVerifiedCName(CName);
                    setIsVerified(false);
                    getWPNData();
                }
            })
            .catch((err) => {
                console.log('error', err);
                setIsVerified(true);
            })
            .finally(() => setLoading(false));
    };

    const onEditClick = (domain) => {
        setIsVerified(false);
        setCName(domain);
    };

    return (
        <Card className="card-height-100">
            <CardHeader>
                <div className="d-flex justify-content-between align-items-center fs-13 fw-semibold">
                    <div className="fs-14 ">CNAME Validation</div>
                    <div
                        className={`d-flex justify-content-center align-items-center fs-12 ${verifiedCName === '' ? 'text-danger' : 'text-primary'
                            }`}
                    >
                        {verifiedCName === '' ? (
                            <i className="ri-error-warning-fill fs-16 px-1 text-danger"></i>
                        ) : (
                            <i className="ri-checkbox-circle-fill fs-16 px-1 text-primary"></i>
                        )}
                        {verifiedCName === '' ? 'Not Configured' : 'Configured'}
                    </div>
                </div>
                <div className="padding-top-10 font-size-12 fw-medium text-muted">
                    CNAME Validation ensures the domain's authenticity by configuring a CNAME record in DNS settings. This verifies domain
                    ownership, enabling secure and trusted communication with external services.
                </div>
            </CardHeader>
            <CardBody>
                <div className="text-center py-2">
                    <img height={65} src={CNameImage} alt="img"></img>
                    <div className="fw-medium fs-14 py-2">CNAME</div>
                </div>
                <Card className="border">
                    <CardBody>
                        <Row>
                            <Col>
                                <div className="d-flex justify-content-start mt-1 mb-2 fw-medium fs-13">Type</div>
                                <div className="fs-13 text-muted">CNAME</div>
                            </Col>
                            <Col>
                                <div className="d-flex justify-content-start mt-1 mb-2 fw-medium fs-13">Host</div>
                                <div className="d-flex align-items-center">
                                    <span className="fs-13 text-muted">enterpriseenrollment</span>
                                    <i
                                        id={'hostcopy'}
                                        onClick={(e) => copyToClipboard('enterpriseenrollment', 'Host')}
                                        className="ri-file-copy-line ms-2 fs-13 cursor-pointer link-primary"
                                    ></i>
                                    {Tooltip('hostcopy', 'Copy')}
                                </div>
                            </Col>
                            <Col>
                                <div className="d-flex justify-content-start mt-1 mb-2 fw-medium fs-13">Points to</div>
                                <div className="d-flex align-items-center">
                                    <span className="fs-13 text-muted">registry.mdm.tectoro.com</span>
                                    <i
                                        id={'pointstocopy'}
                                        onClick={(e) => copyToClipboard('registry.mdm.tectoro.com', 'Points to')}
                                        className="ri-file-copy-line ms-2 fs-13 cursor-pointer link-primary"
                                    ></i>
                                    {Tooltip('pointstocopy', 'Copy')}
                                </div>
                            </Col>
                            <Col>
                                <div className="d-flex justify-content-start mt-1 mb-2 fw-medium fs-13">TTL</div>
                                <div className="fs-13 text-muted">600 sec</div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                <Row className="py-2">
                    {isVerified ? (
                        <>
                            <div className="d-flex align-items-center">
                                <span className="fw-medium fs-13">Domain Name :</span>
                                <span className="fs-13 ms-2">{'enterpriseenrollment.' + winConfig.domain}</span>
                                <i onClick={() => onEditClick('enterpriseenrollment.' + winConfig.domain)} className="ri-edit-box-line ms-2 fs-15 cursor-pointer link-primary"></i>
                            </div>
                            <div className="d-flex align-items-center mt-2">
                                <span className="fw-medium fs-13">Verified on :</span>
                                <span className="fs-13 ms-2">{convertUTCtoIST(winConfig.modifieddate)}</span>
                                <i className="ri-checkbox-circle-fill fs-16 ms-2 text-primary"></i>
                            </div>
                        </>
                    ) : (
                        <>
                            <Col>
                                <Input
                                    className="form-control"
                                    placeholder={'Enter Domain Name'}
                                    onChange={(e) => setCName(e.target.value)}
                                    value={CName}
                                />
                            </Col>
                            <Col sm="auto">
                                <Button className="bg-primary border-none" onClick={() => onValidate()} disabled={CName === ''}>
                                    <span className="d-flex align-items-center">Validate</span>
                                </Button>
                            </Col>
                        </>
                    )}
                </Row>
            </CardBody>
        </Card>
    );
};

export default CNameValidation;
