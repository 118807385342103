/* eslint-disable multiline-comment-style */
import React, { useEffect, useState } from 'react';
import Loader from '../../../Components/Common/Loader';
import { Col, Container, Row } from 'reactstrap';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import DashboardWidgets from '../../Dashboard/DashboardWidgets';
import { TenantConfig } from '../../../Components/Common/Util';
import DashboardCharts from '../../Dashboard/DashboardCharts';
import { APIClient } from '../../../helpers/api_helper';
import * as url from '../../../helpers/url_helper';
import * as domains from '../../../helpers/domain_helper';
import { useEnv } from '../../../envContext';

const WindowsDashboard = () => {
    document.title = 'Windows Dashboard';
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const api = new APIClient();
    const [devices, setDevices] = useState({ total: 0, active: 0, deleted: 0 });
    const tenant = TenantConfig();
    const urlconf = useEnv();
    const [deviceTypes, setDeviceTypes] = useState([]);
    const [osVersion, setOSVersion] = useState([]);
    const dashboardsData = [
        {
            id: 'device_type',
            label: 'Manufacturer',
            dataColors:
                '["--EMM-supplier-count-1","--EMM-supplier-count-2","--EMM-supplier-count-3","--EMM-supplier-count-4","--EMM-supplier-count-5"]',
            dataColorsArr: ['supplier-count-1', 'supplier-count-2', 'supplier-count-3', 'supplier-count-4', 'supplier-count-5'],
            details: deviceTypes
        },
        {
            id: 'dashboard_os_distribution',
            label: 'OS Distribution',
            dataColors: '["--EMM-androidv0", "--EMM-androidv1", "--EMM-androidv2","--EMM-androidv3","--EMM-androidv4","--EMM-androidv5"]',
            dataColorsArr: ['androidv0', 'androidv1', 'androidv2', 'androidv3', 'androidv4', 'androidv5'],
            details: osVersion
        }
    ];

    useEffect(() => {
        handlePromise();
    }, []);

    const handlePromise = () => {
        setLoading(true);
        let dashboardPromise = new Promise((resolve, reject) => {
            api.get(url.WINDOWS_DASHBOARD, '', domains.WINDOWS_V1)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp.status === 'success') resolve(resp.data);
                    else reject('Dashboard failed');
                })
                .catch((err) => reject('Dashboard Failed'));
        });

        Promise.allSettled([dashboardPromise])
            .then((result) => {
                if (result[0].status === 'fulfilled') {
                    let devicesObj = { ...devices };
                    let obj = {};
                    result[0].value?.statusCount?.forEach((device) => (obj[device?.Status?.toLowerCase()] = device.Count));
                    devicesObj.active = obj.active || 0;
                    devicesObj.deleted = obj.deleted || 0;
                    devicesObj.total = devicesObj.active + devicesObj.deleted || 0;

                    result[0].value?.devicetypeCount?.forEach((superObj) => {
                        superObj.label = superObj.Model || '';
                        superObj.count = superObj.Count;
                    });
                    result[0].value?.osCount?.forEach((os) => {
                        os.label = os.OsVersion || '';
                        os.count = os.Count;
                    });
                    setDeviceTypes(result[0].value?.devicetypeCount || []);
                    setOSVersion(result[0]?.value?.osCount || []);
                    setDevices(devicesObj);
                }
                setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    const handleExport = () => {
        const file = [];
        setLoading(true);
        if (tenant.WINDOWS_WIDGETS?.includes('dashboard_devices')) {
            file.push(['Total', 'Enrolled', 'UnEnrolled'], [devices.total, devices.active, devices.deleted]);
        }
        if (tenant.WINDOWS_WIDGETS?.includes('device_type') && deviceTypes?.length > 0) {
            file.push([], ['Manufacturer'], ['Type', 'Count']);
            deviceTypes?.forEach((type) => file.push([type.label, type.count]));
        }
        if (tenant.WINDOWS_WIDGETS?.includes('dashboard_os_distribution') && osVersion?.length > 0) {
            file.push([], ['OS Distribution'], ['Version', 'Count']);
            osVersion?.forEach((type) => file.push([type.label, type.count]));
        }
        var csv = '';
        file.forEach(function (row) {
            csv += row.join(',');
            csv += '\n';
        });
        // document.write(csv);
        var a = document.createElement('a');
        a.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
        a.target = '_blank';
        a.download = 'Dashboard Report.csv';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        setLoading(false);
    };

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className={`page-content h-100 ${loading ? 'postion-relative mask' : ''}`}>
                <Container fluid>
                    <BreadCrumb
                        pageTitle="Windows Dashboard"
                        history={history}
                        homeLink="Dashboard"
                        hideLinks={true}
                        handleExport={handleExport}
                    />
                    <Row>
                        {tenant.WINDOWS_WIDGETS?.includes('dashboard_devices') && <DashboardWidgets windows={true} devices={devices} />}
                    </Row>
                    <Row>
                        {dashboardsData?.map(
                            (field, index) =>
                                tenant.WINDOWS_WIDGETS?.includes(field?.id) && (
                                    <Col xl={6} md={6} key={index}>
                                        <DashboardCharts details={field.details || []} field={field} />
                                    </Col>
                                )
                        )}
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default WindowsDashboard;
