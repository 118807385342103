/* eslint-disable max-lines */
import android from '../../../assets/images/android/png/android_image.png';
import ios from '../../../assets/images/apple/png/common/ios_image.png';

export const ALLOW = 'allow';
export const RESTRICT = 'restrict';
export const REQUIRED = 'required';
export const NOT_REQUIRED = 'notRequired';
export const dropdownOpt = {
    offlineOptions: [
        {
            label: 'Block Access',
            value: 'block_access',
            key: 'periodOfflineBeforeAccessCheck',
            type: 'minutes',
            max: 43799,
            isDisabled: true
        },
        {
            label: 'Wipe Data',
            value: 'wipe_dataa',
            key: 'periodOfflineBeforeWipeIsEnforced',
            type: 'minutes',
            max: 43799,
            isDisabled: true
        }
    ],
    miniosappversion: [
        { label: 'Wipe Data', value: 'wipe_data', key: 'minimumWipeAppVersion' },
        { label: 'Block Access', value: 'block_access', key: 'minimumRequiredAppVersion' },
        { label: 'Warn', value: 'warn', key: 'minimumWarningAppVersion' }
    ],
    minappVersion: [
        { label: 'Block Access', value: 'block_access', key: 'minimumRequiredAppVersion' },
        { label: 'Wipe Data', value: 'wipe_data', key: 'minimumWipeAppVersion' },
        { label: 'Warn', value: 'warn', key: 'minimumWarningAppVersion' }
    ],
    minOSversion: [
        { label: 'Block Access', value: 'block_access', key: 'minimumRequiredOsVersion' },
        { label: 'Warn', value: 'warn', key: 'minimumWarningOsVersion' }
    ],
    minPatchVersion: [
        { label: 'Warn', value: 'warn', key: 'minimumWarningPatchVersion' },
        { label: 'Block Access', value: 'block_access', key: 'minimumRequiredPatchVersion' },
        { label: 'Wipe Data', value: 'wipe_data', key: 'minimumWipePatchVersion' }
    ],
    minsdkversion: [
        { label: 'Block Access', value: 'block_access', key: 'minimumRequiredSdkVersion' },
        { label: 'Wipe Data', value: 'wipe_data', key: 'minimumWipeSdkVersion' }
    ],
    maxosversion: [
        { label: 'Wipe Data', value: 'wipe_data', key: 'maximumWipeOsVersion' },
        { label: 'Warn', value: 'warn', key: 'maximumWarningOsVersion' },
        { label: 'Block Access', value: 'block_access', key: 'maximumRequiredOsVersion' }
    ],
    miniosOSversion: [
        { label: 'Warn', value: 'warn', key: 'minimumWarningOsVersion' },
        { label: 'Block Access', value: 'block_access', key: 'minimumRequiredOsVersion' },
        { label: 'Wipe Data', value: 'wipe_data', key: 'minimumWipeOsVersion' }
    ]
};
export const options = {
    labelOptions: [
        {
            label: 'Offline Grace Period (Minutes)',
            inputValue: 'offlineInput',
            value: 'offline',
            displayLength: 2,
            mandatory: true,
            labelDropdownValue: { label: 'Offline Grace Period', value: 'offline' },
            dropDownValue: 'offlineDropdown',
            dropdownoptions: dropdownOpt.offlineOptions
        },
        {
            label: 'Offline Grace Period (Days)',
            inputValue: 'offlineInputWipe',
            value: 'offline',
            displayLength: 2,
            mandatory: true,
            labelDropdownValue: { label: 'Offline Grace Period', value: 'offline' },
            dropDownValue: 'offlineDropddownWipe',
            dropdownoptions: dropdownOpt.offlineOptions
        },
        {
            label: 'Min App Version',
            inputValue: 'minAppVersionInput',
            placeHolder: 'Version (Ex:1.2 or 11.4)',
            value: 'minappversion',
            mandatory: true,
            displayLength: 2,
            labelDropdownValue: { label: 'Min App Version', value: 'minappversion' },
            dropDownValue: 'minAppVersionDropdown',
            dropdownoptions: dropdownOpt.minappVersion
        }
    ],
    ioslabelOptions: [
        {
            label: 'Offline Grace Period (Minutes)',
            inputValue: 'offlineInput',
            value: 'offline',
            displayLength: 2,
            mandatory: true,
            labelDropdownValue: { label: 'Offline Grace Period', value: 'offline' },
            dropDownValue: 'offlineDropdown',
            dropdownoptions: dropdownOpt.offlineOptions
        },
        {
            label: 'Offline Grace Period (Days)',
            inputValue: 'offlineInputWipe',
            value: 'offline',
            displayLength: 2,
            mandatory: true,
            labelDropdownValue: { label: 'Offline Grace Period', value: 'offline' },
            dropDownValue: 'offlineDropddownWipe',
            dropdownoptions: dropdownOpt.offlineOptions
        },
        {
            label: 'Min App Version',
            inputValue: 'minAppVersionInput',
            placeHolder: 'Version (Ex:1.2 or 11.4)',
            value: 'minappversion',
            mandatory: true,
            displayLength: 2,
            labelDropdownValue: { label: 'Min App Version', value: 'minappversion' },
            dropDownValue: 'minAppVersionDropdown',
            dropdownoptions: dropdownOpt.miniosappversion
        },
        {
            label: 'Min SDK Version',
            inputValue: 'minSDKVersionInputBlock',
            mandatory: true,
            value: 'minsdkversion',
            labelDropdownValue: { label: 'Min Sdk Version', value: 'minsdkversion' },
            placeHolder: 'Version (Ex:1.2 or 11.4)',
            dropDownValue: 'minSDKVersionDropdownBlock',
            dropdownoptions: dropdownOpt.minsdkversion
        }
    ],
    deviceLabelOptions: [
        {
            label: 'Min OS Version',
            placeHolder: 'Version (Ex:1.2 or 11.4)',
            value: 'minosversion',
            inputValue: 'minOSVersionInput',
            labelDropdownValue: { label: 'Min OS Version', value: 'minosversion' },
            dropDownValue: 'minOSVersionDropdown',
            dropdownoptions: dropdownOpt.minOSversion
        },
        {
            label: 'Min Patch Version',
            inputValue: 'minPatchVersionInput',
            type: 'date',
            value: 'minpatchversion',
            labelDropdownValue: { label: 'Min Patch Version', value: 'minpatchversion' },
            dropDownValue: 'minPatchVersionDropdown',
            dropdownoptions: dropdownOpt.minPatchVersion
        }
    ],
    iosdeviceLabelOptions: [
        {
            label: 'Maximum OS Version',
            inputValue: 'mxOsVersionInputreq',
            mandatory: true,
            value: 'maxosversion',
            labelDropdownValue: { label: 'Max OS Version', value: 'maxosversion' },
            placeHolder: 'Version (Ex:1.2 or 11.4)',
            dropDownValue: 'mxOsVersionDropdownreq',
            dropdownoptions: dropdownOpt?.maxosversion
        },
        {
            label: 'Minimum OS Version',
            inputValue: 'minOsVersionInputReq',
            mandatory: true,
            value: 'minosversion',
            labelDropdownValue: { label: 'Min OS Version', value: 'minosversion' },
            placeHolder: 'Version (Ex:1.2 or 11.4)',
            dropDownValue: 'minOsVersionDropdownReq',
            dropdownoptions: dropdownOpt.miniosOSversion
        }
    ],
    allowedOutboundDataTransferDestinations: [
        { label: 'All', value: 'allApps' },
        { label: 'Managed apps', value: 'managedApps' },
        { label: 'Blocked', value: 'none' }
    ],
    allowedOutboundClipboardSharingLevel: [
        { label: 'All', value: 'allApps' },
        { label: 'Managed apps with paste', value: 'managedAppsWithPasteIn' },
        { label: 'Managed apps', value: 'managedApps' },
        { label: 'Blocked', value: 'blocked' }
    ],
    allowedDataStorageLocations: [
        { label: 'OneDrive', value: 'oneDriveForBusiness' },
        { label: 'SharePoint', value: 'sharePoint' },
        { label: 'Box', value: 'box' },
        { label: 'Local Storage', value: 'localStorage' },
        { label: 'Photo Library', value: 'photoLibrary' }
    ],
    dialerRestrictionLevel: [
        { label: 'Any dailer App', value: 'allApps' },
        { label: 'Specific dailer App', value: 'customApp' },
        { label: 'Do not transfer this data between apps', value: 'blocked' }
    ],
    protectedMessagingRedirectAppType: [
        { label: 'Any Messaging App', value: 'allApps' },
        { label: 'None, do not transfer this data between apps', value: 'customApp' },
        { label: 'Specific Messaging App', value: 'blocked' }
    ],
    allowedInboundDataTransferSources: [
        { label: 'All Apps', value: 'allApps' },
        { label: 'None', value: 'none' },
        { label: 'Policy Managed Apps', value: 'managedApps' }
    ],
    allowedDataIngestionLocations: [
        { label: 'OneDrive', value: 'oneDriveForBusiness' },
        { label: 'SharePoint', value: 'sharePoint' },
        { label: 'Camera', value: 'camera' },
        { label: 'Photo Library', value: 'photoLibrary' }
    ],
    managedBrowser: [
        { label: 'Microsoft Edge', value: 'microsoftEdge' },
        { label: 'Unmanaged browser', value: 'custom' },
        { label: 'Any app', value: 'notConfigured' }
    ],
    notificationRestriction: [
        { label: 'Allow', value: ALLOW },
        { label: 'Restrict', value: 'block' },
        { label: 'Restrict organization Data', value: 'blockOrganizationalData' }
    ],
    minimumPinLength: [
        { label: '4', value: 4 },
        { label: '6', value: 6 },
        { label: '8', value: 8 }
    ],
    offlineDropdown: [
        {
            label: 'Block Access',
            value: 'block_access',
            key: 'periodOfflineBeforeAccessCheck',
            type: 'minutes',
            max: 43799
        }
    ],
    appActionIfMaximumPinRetriesExceeded: [
        { label: 'Reset PIN', value: 'block' },
        { label: 'Wipe Data', value: 'wipe' }
    ],
    offlineDropddownWipe: [{ label: 'Wipe Data', value: 'wipe_data', key: 'periodOfflineBeforeWipeIsEnforced', type: 'days' }],
    minAppVersionDropdown: [{ label: 'Block Access', value: 'block_access', key: 'minimumRequiredAppVersion' }],
    minAppVersionDropdownWarn: [{ label: 'Warn', value: 'warn', key: 'minimumWarningAppVersion' }],
    minOSVersionDropdown: [{ label: 'Block Access', value: 'block_access', key: 'minimumRequiredOsVersion' }],
    minOSVersionDropdownWarn: [{ label: 'Warn', value: 'warn', key: 'minimumWarningOsVersion' }],
    minPatchVersionDropdown: [{ label: 'Block Access', value: 'block_access', key: 'minimumRequiredPatchVersion' }],
    minPatchVersionDropdownWarn: [{ label: 'Warn', value: 'warn', key: 'minimumWarningPatchVersion' }],
    minAppVersionDropdowniosBlock: [{ label: 'Block Access', value: 'block_access', key: 'minimumRequiredAppVersion' }],
    minAppVersionDropdowniosWarn: [{ label: 'Warn', value: 'warn', key: 'minimumWarningAppVersion' }],
    minAppVersionDropdowniosWipe: [{ label: 'Wipe Data', value: 'wipe_data', key: 'minimumWipeAppVersion' }],
    minSDKVersionDropdowniosBlock: [{ label: 'Block Access', value: 'block_access', key: 'minimumRequiredSdkVersion' }],
    minSKDVersionDropdowniosWarn: [{ label: 'Warn', value: 'warn', key: 'minimumWarningSdkVersion' }],
    minSDKVersionDropdowniosWipe: [{ label: 'Wipe Data', value: 'wipe_data', key: 'minimumWipeSdkVersion' }],
    maximumWarningOsVersion: [{ label: 'Warn', value: 'warn', key: 'maximumWarningOsVersion' }],
    maximumWipeOsVersion: [{ label: 'Wipe Data', value: 'wipe_data', key: 'maximumWipeOsVersion' }],
    maximumRequiredOsVersion: [{ label: 'Block Access', value: 'block_access', key: 'maximumRequiredOsVersion' }],
    minimumWarningOsVersion: [{ label: 'Warn', value: 'warn', key: 'minimumWarningOsVersion' }],
    minimumWipeOsVersion: [{ label: 'Wipe Data', value: 'wipe_data', key: 'minimumWipeOsVersion' }],
    minimumRequiredOsVersion: [{ label: 'Block Access', value: 'block_access', key: 'minimumRequiredOsVersion' }],
    disableAccount: [
        { label: 'Block Access', value: 'block' },
        { label: 'Wipe Data', value: 'wipe' }
    ]
};

export const exp = new RegExp('^d+(.d+)*$');

export const PROTECTION_POLICY = [
    { Header: 'Name', accessor: 'name', customCell: true, fieldType: 'fieldClickable' },
    // { Header: 'Platform', accessor: 'platform' },
    { Header: 'Apps', accessor: 'appsCount' },
    // { Header: 'Assigned Group', accessor: 'assignedGroup' },
    { Header: 'Created Date', accessor: 'createdDate' },
    { Header: 'Modified Date', accessor: 'modifiedDate' },
    { Header: 'Actions', accessor: 'actions', customCell: true, fieldType: 'actions', delete: true }
];

export const TT_PROTECTION_POLICY = [
    { Header: 'Name', accessor: 'name', customCell: true, fieldType: 'fieldClickable' },
    { Header: 'Apps', accessor: 'appsCount' },
    { Header: 'Modified By', accessor: 'modifiedBy' },
    { Header: 'Modified Date', accessor: 'modifiedDate' },
    { Header: 'Actions', accessor: 'actions', customCell: true, fieldType: 'actions', delete: true }
];

export const radioField = {
    inputType: 'radios',
    value: 'appsType',
    code: true,
    radios: [
        { label: 'All Apps', value: 'appsType', val: 'allApps' },
        { label: 'Specific Apps', value: 'appsType', val: 'selectedPublicApps' },
        { label: 'Microsoft Apps', value: 'appsType', val: 'allMicrosoftApps' }
    ]
};

export const dataProtectionSchema = {
    android: {
        dataTransfer: [
            {
                label: 'Allow Save As',
                value: 'saveAsBlocked',
                helpText: 'Controls user access on Save As while saving files',
                inputType: 'radios',
                radios: [
                    { label: 'Allow', val: ALLOW },
                    { label: 'Restrict', val: RESTRICT }
                ]
            },
            {
                label: 'Allow Data Backup',
                value: 'dataBackupBlocked',
                helpText: 'Controls user access on backing up data',
                inputType: 'radios',
                radios: [
                    { label: 'Allow', val: ALLOW },
                    { label: 'Restrict', val: RESTRICT }
                ]
            },
            {
                label: 'Allow printing organization  data',
                value: 'printBlocked',
                helpText: 'Controls user access on printing data',
                inputType: 'radios',
                radios: [
                    { label: 'Allow', val: ALLOW },
                    { label: 'Restrict', val: RESTRICT }
                ]
            },
            {
                label: 'Allow the app to share data with other apps',
                value: 'allowedOutboundDataTransferDestinations',
                helpText: 'Allow app data from being shared with other apps',
                inputType: 'select',
                customCol: true,
                options: options.allowedOutboundDataTransferDestinations
            },
            {
                label: 'Allow the app to receive data from other apps',
                value: 'allowedInboundDataTransferSources',
                helpText: 'Allow the app to receive data from other apps',
                inputType: 'select',
                customCol: true,
                options: options.allowedOutboundDataTransferDestinations
            },
            {
                label: 'Allow copy and paste between apps',
                value: 'allowedOutboundClipboardSharingLevel',
                helpText: 'Controls user access on copying data to other apps',
                inputType: 'select',
                mandatory: true,
                customCol: true,
                options: options.allowedOutboundClipboardSharingLevel
            },
            {
                label: 'Allow user to save copies to selected services',
                value: 'allowedDataStorageLocations',
                inputType: 'select',
                isMulti: true,
                customCol: true,
                multipleCheck: ['allowedOutboundDataTransferDestinations', 'saveAsBlocked'],
                showKey: 'value',
                showValue: 'managedApps',
                radVal: 'restrict',
                helpText:
                    'Controls user access on saving copies to selected services apart from the ones that are blocked.  Selecting no services will prevent users from saving a copy of organization data.',
                options: options.allowedDataStorageLocations
            },
            {
                label: 'Allow sync app contacts with native contacts app',
                value: 'contactSyncBlocked',
                helpText: 'Controls if app contacts should be synced with native contacts',
                inputType: 'radios',
                radios: [
                    { label: 'Allow', val: ALLOW },
                    { label: 'Restrict', val: RESTRICT }
                ]
            }
        ],
        compliance: [
            {
                label: 'Require device to be compliant',
                value: 'deviceComplianceRequired',
                helpText: 'Specify if device needs to be compliant in accordance with compliance policies',
                inputType: 'radios',
                radios: [
                    { label: 'Require', val: REQUIRED },
                    { label: 'Not Required', val: NOT_REQUIRED }
                ]
            }
            /*
             * {
             *     label: 'Allow usage of rooted or jailbroken devices',
             *     value: 'allowUsage',
             *     helpText: 'Allow app data from being shared with other apps',
             *     inputType: 'radios',
             *     radios: [
             *         { label: REQUIRED, val: REQUIRED },
             *         { label: 'Not Require', val: NOT_REQUIRED }
             *     ]
             * }
             */
        ],
        encryption: [
            {
                label: 'Encrypt app data',
                value: 'encryptAppData',
                helpText: 'Secure application information by encrypting data',
                inputType: 'radios',
                radios: [
                    { label: 'Require', val: REQUIRED },
                    { label: 'Not Required', val: NOT_REQUIRED }
                ]
            },
            {
                label: 'Allow App Encryption If Device Encrypted',
                value: 'disableAppEncryptionIfDeviceEncryptionIsEnabled',
                helpText: 'Encrypt app data only if the device is already encrypted',
                inputType: 'radios',
                radios: [
                    { label: 'Allow', val: ALLOW },
                    { label: 'Restrict', val: RESTRICT }
                ]
            },
            /*
             * {
             *     label: 'Allow Encrypt data when the device is locked',
             *     value: 'lockedDeviceEncryptData',
             *     helpText: 'Allow app data from being shared with other apps',
             *     inputType: 'radios',
             *     radios: [
             *         { label: 'Allow', val: ALLOW },
             *         { label: 'Restrict', val: RESTRICT }
             *     ]
             * },
             */
            {
                label: 'Organization Credentials Required',
                value: 'organizationalCredentialsRequired',
                helpText: 'Controls if organization credentials are required to access apps',
                inputType: 'radios',
                radios: [
                    { label: 'Allow', val: ALLOW },
                    { label: 'Restrict', val: RESTRICT }
                ]
            }
        ],
        network: [
            /*
             * {
             *     label: 'Require Wi-Fi',
             *     value: 'requireWifi',
             *     helpText: 'Wi-Fi Access',
             *     inputType: 'radios',
             *     radios: [
             *         { label: 'Require', val: REQUIRED },
             *         { label: 'Not Required', val: NOT_REQUIRED }
             *     ]
             * }
             */
            {
                label: 'Managed browser',
                value: 'managedBrowser',
                helpText: 'This browser is controlled by Tectoro MDM to enforce security policies.',
                inputType: 'radios',
                radios: [
                    { label: 'Any', val: 'notConfigured' },
                    { label: 'Microsoft Edge', val: 'microsoftEdge' },
                    { label: 'Custom', val: 'noConfig' }
                ]
            },
            {
                label: 'Custom Managed Browser Pacakge ID',
                value: 'customBrowserPackageId',
                showOn: 'managedBrowser',
                mandatory: true,
                showValue: 'noConfig',
                inputType: 'input'
            },
            {
                label: 'Custom Manged Browser Package name',
                value: 'customBrowserDisplayName',
                inputType: 'input',
                showOn: 'managedBrowser',
                mandatory: true,
                showValue: 'noConfig'
            }
        ],
        userExperience: [
            /*
             * {
             *     label: 'Allow Google voice assistants',
             *     value: 'allowVoiceAssistant',
             *     helpText: 'Controls user access on using Google voice assistants',
             *     inputType: 'radios',
             *     radios: [
             *         { label: 'Allow', val: ALLOW },
             *         { label: 'Restrict', val: RESTRICT }
             *     ]
             * },
             */
            {
                label: 'Allow Screen capture',
                value: 'screenCaptureBlocked',
                helpText: 'Controls user access to capture screen',
                inputType: 'radios',
                radios: [
                    { label: 'Allow', val: ALLOW },
                    { label: 'Restrict', val: RESTRICT }
                ]
            }
        ]
    },
    ios: {
        dataTransfer: [
            {
                label: 'Backup Organization data to iTunes and iCloud',
                value: 'dataBackupBlocked',
                helpText: 'Controls user access on backing up data',
                inputType: 'radios',
                radios: [
                    { label: 'Allow', val: ALLOW },
                    { label: 'Restrict', val: RESTRICT }
                ]
            },
            /*
             * {
             *     label: 'Allow sync app contacts with native contacts app',
             *     value: 'contactSyncBlocked',
             *     helpText: 'Controls if app contacts should be synced with native contacts',
             *     inputType: 'radios',
             *     radios: [
             *         { label: 'Allow', val: ALLOW },
             *         { label: 'Restrict', val: RESTRICT }
             *     ]
             * },
             */
            {
                label: 'Send organization data to other apps',
                value: 'allowedOutboundDataTransferDestinations',
                helpText: 'Controls user access on sending organization data to other apps',
                inputType: 'select',
                customCol: true,
                options: options.allowedOutboundDataTransferDestinations
            },
            /*
             * {
             *     label: 'Select apps to exempt',
             *     value: 'exemptedAppProtocols',
             *     inputType: 'tableObject',
             *     helpText: 'Controls user access on exempting apps to send organization data',
             *     tableHeaders: ['Name', 'Package'],
             *     tableContent: [
             *         { inputType: 'input', value: 'appName', customCol: 12 },
             *         { inputType: 'input', value: 'appPackage', customCol: 12 }
             *     ]
             * },
             * {
             *     label: 'Select universal links to exempt',
             *     value: 'exemptedUniversalLinks',
             *     showHeader: true,
             *     val: 'exemptedLinks',
             *     helpText: 'Controls user access to universal links on unmanaged applications',
             *     inputType: 'tableInput',
             *     tableHeaders: ['URL'],
             *     tableContent: [{ inputType: 'input', value: 'universalURL', customCol: 12 }]
             * },
             * {
             *     label: 'Select managed universal links',
             *     value: 'managedUniversalLinks',
             *     val: 'universalLinks',
             *     showHeader: true,
             *     helpText: 'Controls user access to exempting managed universal send organization data',
             *     inputType: 'tableInput',
             *     tableHeaders: ['URL'],
             *     tableContent: [{ inputType: 'input', value: 'managedURL', customCol: 12 }]
             * },
             */
            {
                label: 'Save copies of organization data',
                value: 'saveAsBlocked',
                inputType: 'radios',
                helpText: 'Controls user access on saving copies of organization data to new location',
                radios: [
                    { label: 'Allow', val: ALLOW },
                    { label: 'Restrict', val: RESTRICT }
                ]
            },
            {
                label: 'Allow user to save copies to selected services',
                value: 'allowedDataStorageLocations',
                inputType: 'select',
                multipleCheck: ['allowedOutboundDataTransferDestinations', 'dataBackupBlocked'],
                showKey: 'value',
                showValue: 'managedApps',
                radVal: 'restrict',
                isMulti: true,
                customCol: true,
                helpText:
                    'Controls user access on saving copies to selected services apart from the ones that are blocked.  Selecting no services will prevent users from saving a copy of organization data.',
                options: options.allowedDataStorageLocations
            },
            /*
             * {
             *     label: 'Transfer telecommunication data to',
             *     value: 'dialerRestrictionLevel',
             *     inputType: 'select',
             *     customCol: true,
             *     helpText:
             *         'when a user selects a hyperlinked phone number in an app, a dialer app will open with the phone number prepopulated and ready to call. For this setting, choose how to handle this type of content transfer when its initiated from a policy-managed app. Additional steps may be necessary in order for this setting to take effect. First, verify that tel and telprompt have been removed from the Select apps to exempt list. Then, ensure the application is using a newer version of Intune SDK (Version 12.7.0+).',
             *     options: [
             *         { label: 'Any dailer App', value: 'allApps' },
             *         { label: 'Specific dailer App', value: 'customApp' },
             *         { label: 'Do not transfer this data between apps', value: 'blocked' }
             *     ]
             * },
             * {
             *     label: 'Dialer App URL Scheme',
             *     value: 'dialerAppUrlScheme',
             *     inputType: 'input',
             *     mandatory: true,
             *     helpText: 'Add specific dialer app',
             *     showOn: 'dialerRestrictionLevel',
             *     showKey: 'value',
             *     showValue: 'customApp'
             * },
             * {
             *     label: 'Transfer messaging data to',
             *     value: 'protectedMessagingRedirectAppType',
             *     inputType: 'select',
             *     customCol: true,
             *     helpText:
             *         'Typically, when a user selects a hyperlinked messaging link in an app, a messaging app will open with the phone number prepopulated and ready to send. For this setting, choose how to handle this type of content transfer when its initiated from a policy-managed app. Additional steps may be necessary in order for this setting to take effect. First, verify that sms has been removed from the Select apps to exempt list. Then, ensure the application is using a newer version of Intune SDK (Version > 19.0.0).',
             *     options: [
             *         { label: 'Any Messaging App', value: 'allApps' },
             *         { label: 'None, do not transfer this data between apps', value: 'customApp' },
             *         { label: 'Specific Messaging App', value: 'blocked' }
             *     ]
             * },
             * {
             *     label: 'Messaging App URL Scheme',
             *     value: 'messagingAppUrlScheme',
             *     inputType: 'input',
             *     helpText: 'Add specific messaging app',
             *     showOn: 'protectedMessagingRedirectAppType',
             *     showKey: 'value',
             *     showValue: 'customApp',
             *     mandatory: true
             * },
             */
            {
                label: 'Receive data from other apps',
                value: 'allowedInboundDataTransferSources',
                helpText: 'Specifies receiving data from other apps',
                inputType: 'select',
                customCol: true,
                options: options.allowedInboundDataTransferSources
            },
            /*
             * {
             *     label: 'Open data into Organization documents',
             *     value: 'blockDataIngestionIntoOrganizationDocuments',
             *     inputType: 'radios',
             *     helpText: 'Controls user access to open the organization documents and sharing data',
             *     radios: [
             *         { label: 'Allow', val: ALLOW },
             *         { label: 'Restrict', val: RESTRICT }
             *     ]
             * },
             */
            /*
             * {
             *     label: 'Allow users to open data from selected services',
             *     value: 'allowedDataIngestionLocations',
             *     inputType: 'select',
             *     isMulti: true,
             *     customCol: true,
             *     helpText:
             *         'Select the application storage services users can open data from. All other services are blocked. Selecting no services will prevent users from opening data.',
             *     options: [
             *         { label: 'OneDrive', value: 'oneDriveForBusiness' },
             *         { label: 'SharePoint', value: 'sharePoint' },
             *         { label: 'Camera', value: 'camera' },
             *         { label: 'Photo Library', value: 'photoLibrary' }
             *     ]
             * },
             */
            {
                label: 'Restrict cut, copy, and paste between other apps',
                value: 'allowedOutboundClipboardSharingLevel',
                helpText: 'Controls user access to cut, copy and paste data between apps',
                inputType: 'select',
                customCol: true,
                options: options.allowedOutboundClipboardSharingLevel
            },
            /*
             * {
             *     label: 'Cut and copy character limit for any app',
             *     value: 'allowedOutboundClipboardSharingExceptionLength',
             *     inputType: 'input',
             *     type: 'number',
             *     helpText: 'Controls user access on no. of characters to be cut and copied'
             * },
             */
            {
                label: 'Allow printing organization data',
                value: 'printBlocked',
                inputType: 'radios',
                helpText: 'Controls user access on printing data',
                radios: [
                    { label: 'Allow', val: ALLOW },
                    { label: 'Restrict', val: RESTRICT }
                ]
            }
            /*
             * {
             *     label: 'Sync policy managed app data with native apps or add-ins',
             *     value: 'syncPolicyManagedAppDataWithNativeApps',
             *     inputType: 'radios',
             *     helpText: 'Controls if app contacts/calendar/widgets should be synced with native apps',
             *     radios: [
             *         { label: 'Allow', val: ALLOW },
             *         { label: 'Restrict', val: RESTRICT }
             *     ]
             * },
             * {
             *     label: 'Third party keyboards',
             *     value: 'thirdPartyKeyboardsBlocked',
             *     inputType: 'radios',
             *     helpText: 'Restricts user access on use of third party keyboards',
             *     radios: [
             *         { label: 'Allow', val: ALLOW },
             *         { label: 'Restrict', val: RESTRICT }
             *     ]
             * }
             */
        ],
        complaince: [
            {
                label: 'Require device to be compliant',
                value: 'deviceComplianceRequired',
                helpText: 'Display name of the profile - will be shown on the device',
                inputType: 'radios',
                radios: [
                    { label: 'Require', val: REQUIRED },
                    { label: 'Not Required', val: NOT_REQUIRED }
                ]
            }
        ],
        encryption: [
            /*
             * {
             *     label: 'Encrypt organization data',
             *     value: 'appDataEncryptionType',
             *     helpText: 'Secure applications information by encrypting data',
             *     inputType: 'radios',
             *     radios: [
             *         { label: 'Required', val: 'whenDeviceLocked' },
             *         { label: 'Not Required', val: 'useDeviceSettings' }
             *     ]
             * }
             */
        ],
        functionality: [
            /*
             * {
             *     label: 'Restrict web content transfer with other apps',
             *     value: 'managedBrowser',
             *     helpText: 'Specify the applications to open web links',
             *     inputType: 'select',
             *     customCol: true,
             *     options: options.managedBrowser
             * },
             * {
             *     label: 'Unmanaged browser protocol',
             *     value: 'customBrowserProtocol',
             *     helpText:
             *         'Enter the protocol for a single unmanaged browser. Note: Include only the protocol prefix. If your browser requires links of the form "mybrowser://www.microsoft.com", enter "mybrowser".',
             *     inputType: 'input',
             *     mandatory: true
             * },
             */
            {
                label: 'Organization data notifications',
                value: 'notificationRestriction',
                helpText: 'Controls user access to app notifications',
                inputType: 'select',
                customCol: true,
                mandatory: true,
                options: options.notificationRestriction
            }
        ]
    }
};

export const ACCESS_REQUIREMENT_SCHEMA = {
    android: {
        pinSettings: [
            {
                label: 'Require PIN for access',
                value: 'pinRequired',
                helpText: 'Configure to enforce PIN',
                inputType: 'radios',
                radios: [
                    { label: 'Require', value: 'pinRequired', val: REQUIRED },
                    { label: 'Not Required', value: 'pinRequired', val: NOT_REQUIRED }
                ]
            },
            {
                label: 'PIN Type',
                value: 'pinCharacterSet',
                helpText: 'Select the PIN type',
                inputType: 'radios',
                mandatory: true,
                showValue: REQUIRED,
                showOn: 'pinRequired',
                radios: [
                    { label: 'Numeric', val: 'numeric', value: 'numeric' },
                    { label: 'Passcode', val: 'alphanumericAndSymbol', value: 'alphanumericAndSymbol' }
                ]
            },
            {
                label: 'Allow Fingerprint instead of PIN(Android 6.0 and above)',
                value: 'fingerprintBlocked',
                helpText: 'Controls if user is allowed to use fingerprint instead of PIN',
                inputType: 'radios',
                mandatory: true,
                showValue: REQUIRED,
                showOn: 'pinRequired',
                radios: [
                    { label: 'Allow', val: ALLOW },
                    { label: 'Restrict', val: RESTRICT }
                ]
            },
            {
                label: 'Allow Simple PIN',
                value: 'simplePinBlocked',
                helpText:
                    'Controls if user is allowed to set simple PIN. Blocking simple PIN of type Passcode requires the passcode to have at least one number, one letter, and one special character.',
                inputType: 'radios',
                showValue: REQUIRED,
                showOn: 'pinRequired',
                radios: [
                    { label: 'Allow', val: ALLOW },
                    { label: 'Restrict', val: RESTRICT }
                ],
                mandatory: true
            },
            {
                label: 'Maximum Attempts allowed for entering wrong PIN',
                value: 'maximumPinRetries',
                helpText: 'Specify the number of wrong PIN attempts',
                inputType: 'input',
                showValue: REQUIRED,
                showOn: 'pinRequired',
                type: 'number'
            },
            {
                label: 'Minimum PIN Length',
                value: 'minimumPinLength',
                customCol: true,
                helpText: 'Specify the min length of PIN',
                inputType: 'select',
                showValue: REQUIRED,
                showOn: 'pinRequired',
                options: options.minimumPinLength
            },
            {
                label: 'PIN Expiration Duration (in days)',
                value: 'periodBeforePinReset',
                helpText: 'Specify the number of days before a PIN must be changed',
                inputType: 'input',
                showValue: REQUIRED,
                showOn: 'pinRequired',
                type: 'number'
            },
            {
                label: 'App PIN when device PIN is set',
                value: 'disableAppPinIfDevicePinIsSet',
                helpText: 'Controls if user has to set PIN to access apps irrespective of device PIN',
                inputType: 'radios',
                showValue: REQUIRED,
                showOn: 'pinRequired',
                radios: [
                    { label: 'Require', val: REQUIRED },
                    { label: 'Not Required', val: NOT_REQUIRED }
                ]
            }
        ],
        mfa: [
            {
                label: 'Text message to phone',
                value: 'textMessageToPhone',
                helpText: 'User receives message to access apps',
                inputType: 'radios',
                radios: [
                    { label: 'Require', val: REQUIRED },
                    { label: 'Not Required', val: NOT_REQUIRED }
                ]
            },
            {
                label: 'Call to phone',
                value: 'callToPhone',
                helpText: 'User receives call to access apps',
                inputType: 'radios',
                radios: [
                    { label: 'Require', val: REQUIRED },
                    { label: 'Not Required', val: NOT_REQUIRED }
                ]
            },
            {
                label: 'Require corporate credentials for access',
                value: 'requireCorporateCreds',
                helpText: 'Specify if corporate credentials are required',
                inputType: 'radios',
                radios: [
                    { label: 'Require', val: REQUIRED },
                    { label: 'Not Required', val: NOT_REQUIRED }
                ]
            }
        ]
    },
    ios: {
        pinSettings: [
            {
                label: 'Require PIN for access',
                value: 'pinRequired',
                helpText: 'Configure to enforce PIN',
                inputType: 'radios',
                radios: [
                    { label: 'Require', val: REQUIRED },
                    { label: 'Not Required', val: NOT_REQUIRED }
                ]
            },
            {
                label: 'PIN Type',
                value: 'pinCharacterSet',
                helpText: 'Select the PIN type',
                inputType: 'radios',
                showValue: REQUIRED,
                showOn: 'pinRequired',
                mandatory: true,
                radios: [
                    { label: 'Numeric', val: 'numeric', value: 'numeric' },
                    { label: 'Passcode', val: 'alphanumericAndSymbol', value: 'alphanumericAndSymbol' }
                ]
            },
            {
                label: 'Allow Simple PIN',
                value: 'simplePinBlocked',
                showValue: REQUIRED,
                showOn: 'pinRequired',
                mandatory: true,
                helpText:
                    'Controls if user is allowed to set simple PIN. Blocking simple PIN of type Passcode requires the passcode to have at least one number, one letter, and one special character.',
                inputType: 'radios',
                radios: [
                    { label: 'Allow', val: ALLOW },
                    { label: 'Restrict', val: RESTRICT }
                ]
            },
            /*
             * {
             *     label: 'Minimum PIN Length',
             *     value: 'minimumPinLength',
             *     conditionalMandatory: 'pinRequired',
             *     mandatoryValue: REQUIRED,
             *     showValue: REQUIRED,
             *     showOn: 'pinRequired',
             *     mandatory: true,
             *     customCol: true,
             *     helpText: 'Specify the min length of PIN',
             *     inputType: 'select',
             *     options: [
             *         { label: '4', value: 4 },
             *         { label: '6', value: 6 },
             *         { label: '8', value: 8 }
             *     ]
             * },
             */
            {
                label: 'Allow Touch ID instead of PIN',
                value: 'fingerprintBlocked',
                helpText: 'Controls if user is allowed to use fingerprint instead of PIN',
                inputType: 'radios',
                showValue: REQUIRED,
                showOn: 'pinRequired',
                mandatory: true,
                radios: [
                    { label: 'Allow', val: ALLOW },
                    { label: 'Restrict', val: RESTRICT }
                ]
            },
            /*
             * {
             *     label: 'Override biometrics with PIN after timeout',
             *     value: 'overrideBiometrics',
             *     helpText:
             *         'If required, depending on the timeout (minutes of inactivity), a PIN prompt will override biometric prompts. If this timeout value is not met, the biometric prompt will continue to show. This timeout value should be greater than the value specified under Recheck the access requirements after (minutes of inactivity). On iOS/iPadOS, this feature requires the app to have Intune SDK version 8.1.1 or above.',
             *     inputType: 'radios',
             *     conditionalMandatory: 'pinRequired',
             *     mandatoryValue: REQUIRED,
             *     showValue: REQUIRED,
             *     showOn: 'pinRequired',
             *     mandatory: true,
             *     radios: [
             *         { label: 'Require', val: REQUIRED },
             *         { label: 'Not Required', val: NOT_REQUIRED }
             *     ]
             * },
             */
            {
                label: 'Timeout (minutes of inactivity)',
                value: 'pinRequiredInsteadOfBiometricTimeout',
                inputType: 'input',
                type: 'number',
                mandatory: true,
                showOn: 'overrideBiometrics',
                showValue: REQUIRED
            },
            /*
             * {
             *     label: 'Face ID instead of PIN for access (iOS 11+/iPadOS)',
             *     value: 'faceIdBlocked',
             *     conditionalMandatory: 'pinRequired',
             *     mandatoryValue: REQUIRED,
             *     showValue: REQUIRED,
             *     showOn: 'pinRequired',
             *     mandatory: true,
             *     helpText:
             *         'Face ID uses facial recognition technology to authenticate users on iOS/iPadOS devices. Intune calls the LocalAuthentication API to authenticate users using Face ID. If allowed, Face ID must be used to access the app on a Face ID capable device.',
             *     inputType: 'radios',
             *     radios: [
             *         { label: 'Allow', val: ALLOW },
             *         { label: 'Restrict', val: RESTRICT }
             *     ]
             * },
             */
            {
                label: 'PIN Expiration Duration (in days)',
                value: 'periodBeforePinReset',
                showValue: REQUIRED,
                showOn: 'pinRequired',
                mandatory: true,
                helpText: 'Specify the number of days before a PIN must be changed',
                inputType: 'input',
                type: 'number'
            }
            /*
             * {
             *     label: 'App PIN when device PIN is set',
             *     value: 'disableAppPinIfDevicePinIsSet',
             *     conditionalMandatory: 'pinRequired',
             *     mandatoryValue: REQUIRED,
             *     showValue: REQUIRED,
             *     showOn: 'pinRequired',
             *     mandatory: true,
             *     helpText: 'Controls if user has to set PIN to access apps irrespective of device PIN',
             *     inputType: 'radios',
             *     radios: [
             *         { label: 'Require', val: REQUIRED },
             *         { label: 'Not Required', val: NOT_REQUIRED }
             *     ]
             * }
             */
        ],
        mfa: [
            {
                label: 'Work or school account credentials for access',
                value: 'organizationalCredentialsRequired',
                inputType: 'radios',
                helpText: 'Specify if corporate work or school account credentials are required',
                radios: [
                    { label: 'Allow', val: ALLOW },
                    { label: 'Restrict', val: RESTRICT }
                ]
            }
        ]
    }
};

export const CONDITIONAL_LAUNCH = {
    android: {
        app: [
            {
                inputType: 'conditional',
                value: 'selectone',
                label: 'Select one',
                options: options?.labelOptions
            },
            {
                inputType: 'conditionss',
                fields: [
                    /*
                     * {
                     *     label: 'Maximum Attempts Allowed For Entering Wrong Pin',
                     *     inputValue: 'pinMaxRetries',
                     *     type: 'number',
                     *     dropDownValue: 'maxAttemptsDropdown',
                     *     options: [
                     *         { label: 'Reset PIN', value: 'reset_pin' },
                     *         { label: 'Wipe Data', value: 'wipe_data' }
                     *     ]
                     * },
                     */
                    {
                        label: 'Offline Grace Period (minutes)',
                        inputValue: 'offlineInput',
                        type: 'number',
                        mandatory: true,
                        placeHolder: 'Enter Grace period',
                        dropDownValue: 'offlineDropdown',
                        options: options.offlineDropdown
                    },
                    {
                        label: 'Offline Grace Period (days)',
                        inputValue: 'offlineInputWipe',
                        type: 'number',
                        mandatory: true,
                        placeHolder: 'Enter Grace period',
                        dropDownValue: 'offlineDropddownWipe',
                        options: options.offlineDropddownWipe
                    },
                    {
                        label: 'Min App Version',
                        inputValue: 'minAppVersionInput',
                        placeHolder: 'Version (Ex: 1.2 or 11.4)',
                        dropDownValue: 'minAppVersionDropdown',
                        options: options.minAppVersionDropdown
                    },
                    {
                        label: 'Min App Version',
                        inputValue: 'minAppVersionInputWarn',
                        placeHolder: 'Version (Ex: 1.2 or 11.4)',
                        dropDownValue: 'minAppVersionDropdownWarn',
                        options: options.minAppVersionDropdownWarn
                    }
                ]
            }
        ],
        device: [
            {
                inputType: 'conditional',
                value: 'selectone',
                label: 'Select one',
                options: options?.deviceLabelOptions
            },

            {
                inputType: 'conditionss',
                fields: [
                    /*
                     * {
                     *     label: 'Jailbroken/Rooted Devices',
                     *     inputValue: 'jailBrokenInput',
                     *     dropDownValue: 'jailBrokenDropdown',
                     *     options: [
                     *         { label: 'Block Access', value: 'block_access' },
                     *         { label: 'Wipe Data', value: 'wipe_data' }
                     *     ]
                     * },
                     */
                    /*
                     * {
                     *     label: 'Min OS Version',
                     *     placeHolder: 'Version (Ex:1.2 or 11.4)',
                     *     inputValue: 'minOSVersionInput',
                     *     dropDownValue: 'minOSVersionDropdown',
                     *     options: options.minOSVersionDropdown
                     * },
                     * {
                     *     label: 'Min OS Version',
                     *     placeHolder: 'Version (Ex:1.2 or 11.4)',
                     *     inputValue: 'minOSVersionInputWarn',
                     *     dropDownValue: 'minOSVersionDropdownWarn',
                     *     options: options.minOSVersionDropdownWarn
                     * },
                     */
                    /*
                     * {
                     *     label: 'Max OS Version',
                     *     inputValue: 'maxOSVersionInput',
                     *     dropDownValue: 'maxOSVersionDropdown',
                     *     options: [
                     *         { label: 'Block Access', value: 'block_access' },
                     *         { label: 'Wipe Data', value: 'wipe_data' },
                     *         { label: 'Warn', value: 'warn' }
                     *     ]
                     * },
                     */
                    {
                        label: 'Min Patch Version',
                        inputValue: 'minPatchVersionInput',
                        type: 'date',
                        dropDownValue: 'minPatchVersionDropdown',
                        options: options.minPatchVersionDropdown
                    },
                    {
                        label: 'Min Patch Version',
                        inputValue: 'minPatchVersionInputWarn',
                        type: 'date',
                        dropDownValue: 'minPatchVersionDropdownWarn',
                        options: options.minPatchVersionDropdownWarn
                    }
                    /*
                     * {
                     *     label: 'Device manufacturer(s)',
                     *     inputValue: 'deviceManufacturerInput',
                     *     dropDownValue: 'deviceManufacturerDropdown',
                     *     options: [
                     *         { label: 'Allow specified (Block non-specified) ', value: 'block' },
                     *         { label: 'Allow specified (Wipe non-specified)', value: 'wipe' }
                     *     ]
                     * },
                     * {
                     *     label: 'Play integrity verdict',
                     *     inputValue: 'playIntegrityInput',
                     *     dropDownValue: 'playIntegrityDropdown',
                     *     options: [
                     *         { label: 'Block Access', value: 'block_access' },
                     *         { label: 'Wipe Data', value: 'wipe_data' },
                     *         { label: 'Warn', value: 'warn' }
                     *     ]
                     * },
                     * {
                     *     label: 'Require threat scan on apps',
                     *     inputValue: 'requireThreatScanInput',
                     *     dropDownValue: 'requireThreatScanDropdown',
                     *     options: [
                     *         { label: 'Block Access', value: 'block_access' },
                     *         { label: 'Warn', value: 'warn' }
                     *     ]
                     * },
                     * {
                     *     label: 'Play Integrity verdict evaluation type',
                     *     inputValue: 'playIntegrityEvaluationInput',
                     *     dropDownValue: 'playIntegrityEvaluationDropdown',
                     *     options: [
                     *         { label: 'Block Access', value: 'block_access' },
                     *         { label: 'Warn', value: 'warn' }
                     *     ]
                     * },
                     * {
                     *     label: 'Max allowed device threat level',
                     *     inputValue: 'maxAllowedThreatLevelInput',
                     *     inputMax: 365,
                     *     dropDownValue: 'maxAllowedThreatLevelDropdown',
                     *     options: [
                     *         { label: 'Block Access', value: 'block_access' },
                     *         { label: 'Wipe Data', value: 'wipe_data' },
                     *         { label: 'Warn', value: 'warn' }
                     *     ]
                     * },
                     * {
                     *     label: 'Primary MTD (Mobile Threat Defense) Service',
                     *     inputValue: 'primaryMTDInput',
                     *     inputMax: 365,
                     *     dropDownValue: 'primaryMTDDropdown',
                     *     options: [
                     *         { label: 'Block Access', value: 'block_access' },
                     *         { label: 'Wipe Data', value: 'wipe_data' },
                     *         { label: 'Warn', value: 'warn' }
                     *     ]
                     * }
                     */
                ]
            }
        ]
    },
    ios: {
        app: [
            {
                inputType: 'conditional',
                value: 'selectone',
                label: 'Select one',
                options: options?.ioslabelOptions
            },
            {
                inputType: 'conditionss',
                fields: [
                    /*
                     * {
                     *     label: 'Maximum Attempts Allowed For Entering Wrong Pin',
                     *     inputValue: 'maximumPinRetries',
                     *     type: 'number',
                     *     dropDownValue: 'appActionIfMaximumPinRetriesExceeded',
                     *     options: [
                     *         { label: 'Reset PIN', value: 'block' },
                     *         { label: 'Wipe Data', value: 'wipe' }
                     *     ]
                     * },
                     */
                    {
                        label: 'Offline Grace Period (minutes)',
                        inputValue: 'offlineInput',
                        type: 'number',
                        mandatory: true,
                        placeHolder: 'Enter Grace period',
                        dropDownValue: 'offlineDropdown',
                        options: options.offlineDropdown
                    },
                    {
                        label: 'Offline Grace Period (days)',
                        inputValue: 'offlineInputWipe',
                        type: 'number',
                        mandatory: true,
                        placeHolder: 'Enter Grace period',
                        dropDownValue: 'offlineDropddownWipe',
                        options: options.offlineDropddownWipe
                    },
                    {
                        label: 'Min App Version',
                        inputValue: 'minAppVersionInput',
                        placeHolder: 'Version (Ex:1.2 or 11.4)',
                        mandatory: true,
                        dropDownValue: 'minAppVersionDropdown',
                        options: options.minAppVersionDropdowniosBlock
                    },
                    {
                        label: 'Min App Version',
                        inputValue: 'minAppVersionInputWarn',
                        placeHolder: 'Version (Ex: 1.2 or 11.4)',
                        mandatory: true,
                        dropDownValue: 'minAppVersionDropdownWarn',
                        options: options.minAppVersionDropdowniosWarn
                    },
                    {
                        label: 'Min App Version',
                        inputValue: 'minAppVersionInputWipe',
                        mandatory: true,
                        placeHolder: 'Version (Ex: 1.2 or 11.4)',
                        dropDownValue: 'minAppVersionDropdownWipe',
                        options: options.minAppVersionDropdowniosWipe
                    },
                    {
                        label: 'Min SDK Version',
                        inputValue: 'minSDKVersionInputBlock',
                        mandatory: true,
                        placeHolder: 'Version (Ex:1.2 or 11.4)',
                        dropDownValue: 'minSDKVersionDropdownBlock',
                        options: options.minSDKVersionDropdowniosBlock
                    },
                    /*
                     * {
                     *     label: 'Min SDK Version',
                     *     inputValue: 'minSDKVersionInputWarn',
                     *     mandatory: true,
                     *     placeHolder: 'Version (Ex:1.2 or 11.4)',
                     *     dropDownValue: 'minSDKVersionDropdownWarn',
                     *     options: options.minSKDVersionDropdowniosWarn
                     * },
                     */
                    {
                        label: 'Min SDK Version',
                        inputValue: 'minSDKVersionInputWipe',
                        mandatory: true,
                        placeHolder: 'Version (Ex:1.2 or 11.4)',
                        dropDownValue: 'minSDKVersionDropdownWipe',
                        options: options.minSDKVersionDropdowniosWipe
                    }
                    /*
                     * {
                     *     label: 'Disabled Account',
                     *     dropDownValue: 'disabledAccountDropdown',
                     *     mandatory: true,
                     *     value: 'appActionIfUnableToAuthenticateUser',
                     *     options: options.disableAccount
                     * }
                     */
                ]
            }
        ],
        device: [
            {
                inputType: 'conditional',
                value: 'selectone',
                label: 'Select one',
                options: options?.iosdeviceLabelOptions
            },
            {
                inputType: 'conditionss',
                fields: [
                    {
                        label: 'Maximum OS Version',
                        inputValue: 'mxOsVersionInputWipe',
                        mandatory: true,
                        placeHolder: 'Version (Ex:1.2 or 11.4)',
                        dropDownValue: 'mxOsVersionDropdownWipe',
                        options: options.maximumWipeOsVersion
                    },
                    {
                        label: 'Maximum OS Version',
                        mandatory: true,
                        inputValue: 'mxOsVersionInputWarn',
                        placeHolder: 'Version (Ex:1.2 or 11.4)',
                        dropDownValue: 'mxOsVersionDropdownWarn',
                        options: options.maximumWarningOsVersion
                    },
                    {
                        label: 'Maximum OS Version',
                        inputValue: 'mxOsVersionInputreq',
                        mandatory: true,
                        placeHolder: 'Version (Ex:1.2 or 11.4)',
                        dropDownValue: 'mxOsVersionDropdownreq',
                        options: options.maximumRequiredOsVersion
                    },
                    {
                        label: 'Minimum OS Version',
                        inputValue: 'minOsVersionInputWarn',
                        mandatory: true,
                        placeHolder: 'Version (Ex:1.2 or 11.4)',
                        dropDownValue: 'minOsVersionDropdownWarn',
                        options: options.minimumWarningOsVersion
                    },
                    {
                        label: 'Minimum OS Version',
                        inputValue: 'minOsVersionInputWipe',
                        mandatory: true,
                        placeHolder: 'Version (Ex:1.2 or 11.4)',
                        dropDownValue: 'minOsVersionDropdownWipe',
                        options: options.minimumWipeOsVersion
                    },
                    {
                        label: 'Minimum OS Version',
                        inputValue: 'minOsVersionInputReq',
                        mandatory: true,
                        placeHolder: 'Version (Ex:1.2 or 11.4)',
                        dropDownValue: 'minOsVersionDropdownReq',
                        options: options.minimumRequiredOsVersion
                    }
                ]
            }
        ]
    }
};

export const EMPTY_OBJ = {
    appsType: 'allApps',
    saveAsBlocked: ALLOW,
    dataBackupBlocked: ALLOW,
    printBlocked: ALLOW,
    allowedOutboundDataTransferDestinations: { label: 'All', value: 'allApps' },
    allowedOutboundClipboardSharingLevel: { label: 'All', value: 'allApps' },
    contactSyncBlocked: ALLOW,
    deviceComplianceRequired: REQUIRED,
    encryptAppData: REQUIRED,
    disableAppEncryptionIfDeviceEncryptionIsEnabled: ALLOW,
    organizationalCredentialsRequired: ALLOW,
    managedBrowser: 'notConfigured',
    allowVoiceAssistant: ALLOW,
    screenCaptureBlocked: ALLOW,
    pinRequired: REQUIRED,
    pinCharacterSet: 'numeric',
    pinType: 'numeric',
    fingerprintBlocked: ALLOW,
    simplePinBlocked: ALLOW,
    disableAppPinIfDevicePinIsSet: REQUIRED,
    minimumPinLength: { label: '4', value: 4 },
    copyPasteBlocked: ALLOW,
    // restrictedIps: 'specific',
    allowedIps: 'All',
    overrideBiometrics: NOT_REQUIRED,
    faceIDBlocked: ALLOW,
    sendOrgData: { label: 'All Apps', value: '' },
    textMessagesToPhone: REQUIRED,
    callToPhone: REQUIRED,
    requireCorporateCredentialsAccess: REQUIRED,
    blockDataIngestionIntoOrganizationDocuments: ALLOW,
    syncPolicyManagedAppDataWithNativeApps: ALLOW,
    thirdPartyKeyboardsBlocked: ALLOW,
    appDataEncryptionType: 'whenDeviceLocked',
    faceIdBlocked: ALLOW,
    mfaRequired: REQUIRED,
    allowedInboundDataTransferSources: { label: 'All Apps', value: 'allApps' },
    offlineDropdown: {
        label: 'Block Access',
        value: 'block_access',
        key: 'periodOfflineBeforeAccessCheck',
        type: 'minutes',
        max: 43799,
        isDisabled: true
    },

    offlineDropddownWipe: {
        label: 'Wipe Data',
        value: 'wipe_dataa',
        key: 'periodOfflineBeforeWipeIsEnforced',
        type: 'minutes',
        max: 43799,
        isDisabled: true
    },
    minAppVersionDropdown: undefined,
    minAppVersionDropdownWarn: undefined,
    minAppVersionDropdownWipe: undefined,
    minOSVersionDropdown: undefined,
    minOSVersionDropdownWarn: undefined,
    minPatchVersionDropdown: undefined,
    minPatchVersionDropdownWarn: undefined,
    minSDKVersionDropdownBlock: undefined,
    minSDKVersionDropdownWarn: options.minSKDVersionDropdowniosWarn[0],
    minSDKVersionDropdownWipe: undefined,
    mxOsVersionDropdownWipe: undefined,
    mxOsVersionDropdownWarn: undefined,
    mxOsVersionDropdownreq: undefined,
    minOsVersionDropdownWarn: undefined,
    minOsVersionDropdownWipe: undefined,
    minOsVersionDropdownReq: undefined,
    offlineInput: 1440,
    offlineInputWipe: 90,
    allowAccess: ALLOW,
    isTrustedAllow: 'trusted',
    isTrustedRestrict: 'trusted',
    notificationRestriction: options.notificationRestriction?.[0],
    shareAppDataWithOtherApps: ALLOW,
    requireAppVersion: NOT_REQUIRED,
    unlistedIPs: ALLOW
};

export const policiesArray = [
    {
        img: android,
        label: 'Android',
        id: 'android',
        desc: 'Click here to apply App Protection policies on Android apps.'
    },
    {
        img: ios,
        label: 'iOS/iPadOS',
        id: 'ios',
        desc: 'Click here to apply App Protection policies on iOS/iPadOS apps'
    }
];

export const groupsSchema = [
    {
        Header: 'checkbox',
        customHeader: true,
        headerType: 'checkbox',
        customCell: true,
        fieldType: 'checkbox',
        accessor: 'checked',
        checkALlTooltip: 'It will selects all the records',
        thClass: 'width-50 max-width-50 min-width-50 d-flex align-items-center',
        className: 'width-50 max-width-50',
        width: 50,
        maxWidth: 50,
        minWidth: 50
    },
    { Header: 'Group', accessor: 'name', sortable: true, width: 150, minWidth: 150, maxWidth: 150 }
    // { Header: 'Group Members', accessor: 'members', sortable: false, width: 150, minWidth: 150, maxWidth: 150 }
];

export const tectoroDataProSchema = {
    dataTransfer: [
        {
            label: 'Allow app data from being shared with other apps',
            value: 'shareAppDataWithOtherApps',
            helpText: 'Allow app data from being shared with other apps',
            inputType: 'radios',
            radios: [
                { label: 'Allow', val: ALLOW },
                { label: 'Restrict', val: RESTRICT }
            ],
            hide: ['android']
        },
        {
            label: 'Allow Copy & Paste',
            value: 'copyPasteBlocked',
            helpText: 'Controls Copy & Paste',
            inputType: 'radios',
            radios: [
                { label: 'Allow', val: ALLOW },
                { label: 'Restrict', val: RESTRICT }
            ],
            hide: ['ios']
        },
        {
            label: 'Allow sync app contacts with native contacts app',
            value: 'contactSyncBlocked',
            helpText: 'Allow app data from being shared with other apps',
            inputType: 'radios',
            radios: [
                { label: 'Allow', val: ALLOW },
                { label: 'Restrict', val: RESTRICT }
            ]
        }
    ],
    appVersion: [
        {
            label: 'Require App Version',
            value: 'requireAppVersion',
            helpText: 'Choose wheather the app version is required or not',
            inputType: 'radios',
            radios: [
                { label: 'Require', val: REQUIRED },
                { label: 'Not Required', val: NOT_REQUIRED }
            ]
        },
        {
            label: 'Require',
            value: REQUIRED,
            helpText: 'Select the app version requirement',
            customCol: true,
            inputType: 'select',
            options: [
                { label: 'Min Version', value: 'minVersion' },
                { label: 'Max Version', value: 'maxVersion' }
            ]
        }
    ],
    compliance: [
        {
            label: 'Require device to be compliant',
            value: 'deviceComplianceRequired',
            helpText: 'Specify if device needs to be compliant in accordance with compliance policies',
            inputType: 'radios',
            radios: [
                { label: 'Require', val: REQUIRED },
                { label: 'Not Required', val: NOT_REQUIRED }
            ]
        }
    ],
    userExperience: [
        {
            label: 'Allow Screen capture',
            value: 'screenCaptureBlocked',
            helpText: 'Controls user access to capture screen',
            inputType: 'radios',
            radios: [
                { label: 'Allow', val: ALLOW },
                { label: 'Restrict', val: RESTRICT }
            ]
        }
    ],
    network: [
        {
            allowedLabel: 'Allowed IP Address',
            regex: new RegExp(/^[0-9.\b]+$/),
            allowedValue: 'allowedIPs',
            inputType: 'networkip',
            allowedipName: 'allowedName',
            allowedVal: 'allowedIP',
            blockedLabel: 'Blocked IP Address',
            blockedValue: 'blockedIPs',
            blockedipName: 'blockedName',
            blockedVal: 'blockedIP'
        },
        {
            label: 'Unlisted IP Address',
            value: 'unlistedIPs',
            inputType: 'radios',
            radios: [
                { label: 'Allow', val: ALLOW },
                { label: 'Restrict', val: RESTRICT }
            ]
        }
    ]
};

export const tectoroAccessReqSchema = {
    pinSettings: [
        {
            label: 'Require PIN for access',
            value: 'pinRequired',
            helpText: 'Configure to enforce PIN',
            inputType: 'radios',
            clearFields: ['pinType', 'fingerprintBlocked', 'simplePinBlocked', 'minimumPinLength'],
            clearValues: {
                pinType: 'numeric',
                fingerprintBlocked: ALLOW,
                simplePinBlocked: ALLOW,
                minimumPinLength: { label: '4', value: 4 }
            },
            radios: [
                { label: 'Require', val: REQUIRED },
                { label: 'Not Required', val: NOT_REQUIRED }
            ]
        },
        {
            label: 'PIN Type',
            value: 'pinType',
            helpText: 'Select the PIN Type',
            mandatory: true,
            inputType: 'radios',
            showValue: REQUIRED,
            showOn: 'pinRequired',
            radios: [
                { label: 'Numeric', val: 'numeric', value: 0 },
                { label: 'Passcode', val: 'passcode', value: 1 }
            ]
        },
        {
            label: 'Allow Fingerprint instead of PIN(Android 6.0 and above)',
            value: 'fingerprintBlocked',
            helpText: 'Controls if user is allowed to use fingerprint instead of PIN',
            inputType: 'radios',
            showValue: REQUIRED,
            mandatory: true,
            showOn: 'pinRequired',
            radios: [
                { label: 'Allow', val: ALLOW },
                { label: 'Restrict', val: RESTRICT }
            ]
        },
        {
            label: 'Allow Simple PIN',
            value: 'simplePinBlocked',
            helpText:
                'Controls if user is allowed to set simple PIN. Blocking simple PIN of type Passcode requires the passcode to have at least one number, one letter, and one special character.',
            inputType: 'radios',
            showValue: REQUIRED,
            showOn: 'pinRequired',
            mandatory: true,
            radios: [
                { label: 'Allow', val: ALLOW },
                { label: 'Restrict', val: RESTRICT }
            ]
        },
        /*
         * {
         *     label: 'Maximum Attempts allowed for entering wrong PIN',
         *     value: 'maxPinAttempts',
         *     inputType: 'input',
         *     regex: new RegExp(/^[0-9\b]+$/),
         *     helpText: 'Enter the maximum number of wrong pin attempts'
         * },
         */
        {
            label: 'Minimum PIN Length',
            value: 'minimumPinLength',
            customCol: true,
            helpText: 'Specify the min length of PIN',
            inputType: 'select',
            showValue: REQUIRED,
            mandatory: true,
            showOn: 'pinRequired',
            options: [
                { label: '4', value: 4 },
                { label: '6', value: 6 },
                { label: '8', value: 8 }
            ]
        }
    ]
};

export const tectoroConditionalLaunch = {
    appConditions: [
        {
            inputType: 'conditions',
            fields: [
                {
                    label: 'Maximum Attempts Allowed For Entering Wrong Pin',
                    inputValue: 'maximumPinRetries',
                    type: 'text',
                    dropDownValue: 'maxAttemptsDropdown',
                    isClearable: true,
                    customHandle: true,
                    regex: '^[0-9]+$',
                    minValue: 1,
                    maxValue: 6,
                    options: [
                        { label: 'Reset PIN', value: 'reset_pin' },
                        { label: 'Wipe Data', value: 'wipe_data' }
                    ]
                },
                {
                    label: 'Offline Grace Period',
                    inputValue: 'offlineInput',
                    type: 'text',
                    dropDownValue: 'offlineDropdown',
                    isClearable: true,
                    customHandle: true,
                    minValue: 1,
                    maxValue: 999,
                    regex: '^[0-9]+$',
                    options: [
                        { label: 'Block Access', value: 'block_access', key: 'periodOfflineBeforeAccessCheck' },
                        { label: 'Wipe Data', value: 'wipe_data', key: 'periodOfflineBeforeWipeIsEnforced' }
                    ]
                },
                {
                    label: 'Min OS Version',
                    inputValue: 'minOsVertionInput',
                    dropDownValue: 'minOsVersionDropdown',
                    type: 'text',
                    isClearable: true,
                    customHandle: true,
                    regex: '^(?!.*\\.\\.)[0-9.]+$',
                    options: [
                        { label: 'Block Access', value: 'block_access', key: 'periodOfflineBeforeAccessCheck' },
                        { label: 'Wipe Data', value: 'wipe_data', key: 'periodOfflineBeforeWipeIsEnforced' }
                    ]
                },
                {
                    label: 'Min App Version',
                    inputValue: 'minAppVersionInput',
                    dropDownValue: 'minAppVersionDropdown',
                    type: 'text',
                    isClearable: true,
                    customHandle: true,
                    regex: '^(?!.*\\.\\.)[0-9.]+$',
                    options: [
                        { label: 'Block Access', value: 'block_access', key: 'minimumRequiredAppVersion' },
                        { label: 'Wipe Data', value: 'wipe_data' },
                        { label: 'Warn', value: 'warn', key: 'minimumWarningAppVersion' }
                    ]
                }
            ]
        }
    ]
};

export const ACCESS_REQ_SCHEMA = [
    {
        label: 'Allow Access',
        value: 'allowAccess',
        helpText: 'Allow access',
        inputType: 'radios',
        radios: [
            { label: 'Allow', val: ALLOW },
            { label: 'Restrict', val: RESTRICT }
        ]
    },
    /*
     * {
     *     label: 'MFA Required',
     *     value: 'mfaRequired',
     *     helpText: 'Controls multi factor authentication',
     *     inputType: 'radios',
     *     disabled: true,
     *     showOn: 'allowAccess',
     *     showValue: ALLOW,
     *     radios: [
     *         { label: 'Require', val: REQUIRED },
     *         { label: 'Not Required', val: NOT_REQUIRED }
     *     ]
     * },
     */
    {
        label: 'Check device to be complaint.',
        value: 'compliantDevice',
        helpText: 'Specify if device needs to be compliant in accordance with compliance policies',
        inputType: 'radios',
        showOn: 'allowAccess',
        showValue: ALLOW,
        radios: [
            { label: 'Require', val: REQUIRED },
            { label: 'Not Required', val: NOT_REQUIRED }
        ]
    }
    /*
     * {
     *     label: 'Text message to phone',
     *     value: 'textMessagesToPhone',
     *     helpText: 'User receives message to access apps',
     *     inputType: 'radios',
     *     radios: [
     *         { label: 'Require', val: REQUIRED },
     *         { label: 'Not Required', val: NOT_REQUIRED }
     *     ]
     * },
     * {
     *     label: 'Call to phone',
     *     value: 'callToPhone',
     *     helpText: 'User receives call to access apps',
     *     inputType: 'radios',
     *     radios: [
     *         { label: 'Require', val: REQUIRED },
     *         { label: 'Not Required', val: NOT_REQUIRED }
     *     ]
     * },
     * {
     *     label: 'Require corporate credentials for access',
     *     value: 'requireCorporateCredentialsAccess',
     *     helpText: 'Specify if corporate credentials are required',
     *     inputType: 'radios',
     *     radios: [
     *         { label: 'Require', val: REQUIRED },
     *         { label: 'Not Required', val: NOT_REQUIRED }
     *     ]
     * }
     */
];

export const DATA_PROTECT_SCHEMA = [
    {
        label: 'Allowed IP Addresses',
        value: 'allowedIps',
        helpText: 'Allowing trusted IPs restricts access to specified IP addresses only.',
        inputType: 'radios',
        noMargin: true,
        radios: [
            { label: 'Any', val: 'All' },
            // { label: 'All Trusted', val: 'AllTrusted' },
            { label: 'Specific', val: 'specific' }
        ]
    },
    /*
     * {
     *     label: 'Trusted IP’s level',
     *     value: 'trustedLevel',
     *     inputType: 'radios',
     *     helpText: 'Controls the range of IP addresses',
     *     noMargin: true,
     *     radios: [
     *         { label: 'Any', val: 'All' },
     *         { label: 'All Trusted', val: 'AllTrusted' },
     *         { label: 'Specific', val: 'specific' }
     *     ]
     * },
     */
    {
        value: 'allowedLocs',
        val: 'trustedAddress',
        inputType: 'ipTable',
        nameValue: 'ipNameAllow',
        ipValue: 'ipsAllow',
        trustValue: 'isTrustedAllow',
        // eslint-disable-next-line max-lines
        colSize: 12,
        showHeader: true,
        allowed: 'allowed',
        showOn: 'allowedIps',
        showValue: 'specific',
        editNameValue: 'allowedEditName',
        editIpValue: 'allowedEditIp',
        editTrustValue: 'allowedEditTrust'
    }
    /*
     * {
     *     label: 'Restricted IP Addresses',
     *     value: 'restrictedIps',
     *     helpText: 'Allowing untrusted IP means permitting access from any IP address, increasing security risks.',
     *     inputType: 'radios',
     *     disabled: true,
     *     noMargin: true,
     *     radios: [
     *         // { label: 'All Trusted', val: 'AllTrusted' },
     *         { label: 'Specific', val: 'specific' }
     *     ]
     * },
     */
    /*
     * {
     *     label: 'Untrusted IP’s level',
     *     value: 'untrustedLevel',
     *     inputType: 'radios',
     *     helpText: 'Controls the range of IP addresses',
     *     noMargin: true,
     *     radios: [
     *         { label: 'Any', val: 'All' },
     *         { label: 'All Trusted', val: 'AllTrusted' },
     *         { label: 'Specific', val: 'specific' }
     *     ]
     * },
     */
    /*
     * {
     *     value: 'notAllowedLocs',
     *     val: 'untrustedAddress',
     *     inputType: 'ipTable',
     *     nameValue: 'trustedNameRestrict',
     *     ipValue: 'ipRestrict',
     *     trustValue: 'isTrustedRestrict',
     *     colSize: 12,
     *     showHeader: true,
     *     allowed: 'restricted',
     *     showOn: 'restrictedIps',
     *     showValue: 'specific',
     *     editNameValue: 'restrictedEditName',
     *     editIpValue: 'restrictedEditIp',
     *     editTrustValue: 'restrictedEditTrust'
     * }
     */
];
