import React, { useCallback, useEffect, useState } from 'react';
import Loader from '../../../Components/Common/DotsLoader';
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Container, Input, Label, Row, Table, UncontrolledTooltip } from 'reactstrap';
import Pagination from '../../../Components/Common/Pagination/Pagination';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import OffcanvasModal from '../../../Components/Common/OffcanvasModal';
import { useFormik } from 'formik';
import {
    convertUTCtoIST,
    diffTwoDatesHrsMintsSeconds,
    DownloadBunnyFile,
    getFormTypeAndRecordId,
    Tooltip
} from '../../../Components/Common/Util';
import NoRecordsFound from '../../../Components/Common/NoRecordsFound';
import Select from 'react-select';
import * as Yup from 'yup';
import {
    iosCardDetails,
    EnrollmentProcess,
    enrollOptionsTab,
    expirationOptions,
    securityOptions,
    IosEnrollSecurity,
    iosEnrollSteps
} from './EnrollmentSchemas';
import { toast } from 'react-toastify';
import { useEnv } from '../../../envContext';
import { APIClient } from '../../../helpers/api_helper';
import * as url from '../../../helpers/url_helper';
import * as domains from '../../../helpers/domain_helper';
import EllipsisToolTip from '../../../Components/Common/Tooltip/Tooltip';
import toastMessages from '../../../common/messages/toastMessages';
import { useHistory } from 'react-router-dom';
import { platform } from 'process';

const IosEnrollmentToken = () => {
    document.title = 'iOS Enrollment Token';
    const [loading, setLoading] = useState(false);
    const [showEnrollModal, setShowEnrollModal] = useState(false);
    const [policyData, setPolicyData] = useState();
    const [mode, setMode] = useState('add');
    const [enrollDetails, setEnrollDetails] = useState([]);
    const formTypeAndId = getFormTypeAndRecordId(window.location.hash);
    const [selectedCard, setSelectedCard] = useState();
    const formType = formTypeAndId['formType'];
    const recordID = formTypeAndId['recordID'];
    const [showShareModal, setShowShareModal] = useState(false);
    const [activePage, setActivePage] = useState(1);
    const [pageSize, setPageSize] = useState(30);
    const [totalRecords, setTotalRecords] = useState(0);
    const [qrString, setQrString] = useState('');
    const [urlString, setUrlString] = useState('');
    const [canvasLoader, setCanvasLoader] = useState(false);
    const [searchParams, setSearchParams] = useState({ page: 1, size: 30 });
    const urlconf = useEnv();
    const api = new APIClient();
    const history = useHistory();

    useEffect(() => {
        handlePromise();
    }, []);

    const handlePromise = () => {
        setLoading(true);
        const getPolicies = new Promise((resolve, reject) => {
            api.get(url.POLICIES + '/' + recordID, '', domains.IOS_V1)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp?.data) resolve(resp.data);
                    else reject('Policy failed.');
                })
                .catch((err) => reject('Policy failed.'));
        });

        Promise.allSettled([getPolicies])
            .then((result) => {
                setLoading(false);
                if (result[0].status === 'fulfilled') {
                    setPolicyData(result[0]?.value);
                    getEnrollData({ ...searchParams, policy: result[0]?.value?.code });
                }
            })
            .catch((_err) => setLoading(false));
    };

    const getEnrollData = (params) => {
        setLoading(true);
        api.get(url.TV_ENROLLMENT, params, domains.IOS_V1)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp?.status === 'success') {
                    setLoading(false);
                    (resp?.data || []).forEach((ele) => {
                        ele.name = ele.name ? ele.name : ele.modifiedby;
                        ele.securityOptions = ele?.authtype;
                        ele.pin = ele?.authconfig?.pin;
                        ele.modifiedDate = ele.modifieddate ? convertUTCtoIST(ele.modifieddate, true) : '_';
                        ele.security = securityOptions?.find((option) => option?.val === ele.authtype)?.label;
                        ele.endDate = ele.expiration ? convertUTCtoIST(ele.expiration, true) : '_';
                        const { totalDaysNoAbs } = ele?.expiration
                            ? diffTwoDatesHrsMintsSeconds(new Date(convertUTCtoIST(ele?.expiration, true)), new Date())
                            : 0;
                        ele.expiresOn = totalDaysNoAbs + 1;
                        ele.badgeClass = totalDaysNoAbs + 1 <= 0 ? 'danger' : totalDaysNoAbs + 1 <= 15 ? 'warning' : 'success';
                    });
                    setEnrollDetails((resp?.data || [])?.reverse());
                    setTotalRecords(resp?.totalRecords);
                }
            })
            .catch((_err) => setLoading(false));
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: selectedCard?.name ? selectedCard.name : undefined,
            securityOptions: selectedCard?.securityOptions ? selectedCard?.securityOptions : 'NO_AUTH',
            pin: selectedCard?.pin ? selectedCard?.pin : undefined,
            expiration: selectedCard?.expiration ? expirationOptions?.find((ele) => ele.value === selectedCard?.expiration) : undefined,
            networkType: selectedCard?.conditions?.network?.length > 0 ? 'specific' : 'any',
            ipAddress: selectedCard?.conditions?.network ? selectedCard?.conditions?.network : undefined
        },
        validationSchema: Yup.object({
            Email: Yup.string()
                .email('Enter a valid Email ID')
                .matches('^[a-zA-Z0-9._%±]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$', 'Enter a valid Email ID')
        }),

        onSubmit: (values) => {
            let dataObj = {
                policy: policyData?.code,
                name: values?.name,
                authtype: values?.securityOptions,
                authconfig: values?.securityOptions === 'STATIC_PIN' ? { pin: values?.pin } : {},
                conditions: {
                    // networkType: values?.network,
                    network: values?.networkType?.toLocaleLowerCase() === 'specific' ? values?.ipAddress : []
                },
                platformConfig: {}
            };

            if (mode === 'add') {
                dataObj.days = values?.expiration?.value;
            }

            setEnrollDetails([...enrollDetails, dataObj]);
            createAndUpdate(dataObj);
            validation.resetForm();
            setShowEnrollModal(false);
        }
    });

    const createAndUpdate = (dataObj) => {
        setLoading(true);
        let prepareUrl;
        if (mode === 'add') prepareUrl = api.create(url.TV_ENROLLMENT, dataObj, false, domains.IOS_V1);
        else prepareUrl = api.patch(url.TV_ENROLLMENT + '/' + selectedCard?._id, dataObj, false, domains.IOS_V1);

        prepareUrl
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    setLoading(false);
                    setShowEnrollModal(false);
                    getEnrollData({ policy: policyData?.code });
                    toast.success(mode === 'add' ? toastMessages.AccessCodeGenerate : toastMessages.AccessCodeUpdated);
                }
            })
            .catch((err) => setLoading(false));
    };

    const radioButton = (field) => {
        return (
            <Row>
                {field?.label && (
                    <Col sm={12} className={field?.label ? 'mt-2' : ''}>
                        <Label className="mb-2 fw-semibold fs-14">{field?.label}</Label>
                        <div>
                            {field.helpText && (
                                <div className={`text-muted fs-12 word-wrap-break${field.noMargin ? '' : 'mb-2'}`}>{field.helpText}</div>
                            )}
                        </div>
                    </Col>
                )}

                <Col xs={12} sm={12} md={12} lg={12} xl={12} className={`mx-2 mt-1 ${field.noMargin ? '' : 'mb-2'}`}>
                    <Row>
                        {field?.radios?.map((ele, ind) => {
                            return (
                                <Col
                                    xs={field.customCol ? field.customCol : 12}
                                    sm={field.customCol ? field.customCol : 12}
                                    md={field.customCol ? field.customCol : 12}
                                    lg={field.customCol ? field.customCol : 12}
                                    xl={field.customCol ? field.customCol : 12}
                                    key={ind}
                                    className="form-check form-check-inline  mb-2"
                                >
                                    <Input
                                        className="form-check-input"
                                        type="radio"
                                        id={field.value}
                                        name={field.value}
                                        value={ele.val}
                                        onBlur={validation.handleBlur}
                                        disabled={mode === 'view'}
                                        onChange={(e) => {
                                            validation.values?.securityOptions !== 'STATIC_PIN'
                                                ? validation.setValues({ ...validation.values, pin: undefined })
                                                : validation.values?.networkType !== 'specific'
                                                ? validation.setValues({ ...validation.values, ipAddress: [] })
                                                : '';
                                            validation.handleChange(e);
                                        }}
                                        checked={validation.values[field.value] === ele.val}
                                    />
                                    <Label className="mb-0 d-flex">{ele.label}</Label>
                                    {ele.toolTip && <div className="fs-13 text-muted">{ele?.toolTip}</div>}
                                </Col>
                            );
                        })}
                    </Row>
                </Col>
            </Row>
        );
    };

    const inputComponent = (field, index) => {
        const handleNumberChnage = (e, fieldd) => {
            let pattern = new RegExp(/^[0-9]*$/);
            let result = pattern.test(e.target.value);
            if (
                (result && (e.target.value?.toString()?.length ? e.target.value?.toString()?.length <= fieldd.maxLength : true)) ||
                e.target.value === ''
            )
                validation.handleChange(e);
        };

        return (
            <>
                {field?.label && (
                    <Col md={12} sm={12} className="d-flex align-items-center justify-content-between">
                        <Label className="form-check-label fw-medium d-flex align-items-center mb-0">
                            {field.label}
                            {field.mandatory && mode !== 'view' ? (
                                <span className="red-color ps-1">*</span>
                            ) : (
                                <span className="mx-1"> :</span>
                            )}
                        </Label>
                    </Col>
                )}

                <Col md={10} sm={10} className={`mb-5 ${field?.label ? 'mt-2' : ''}`}>
                    <div className={'input-group '}>
                        <div className="d-flex align-items-center w-100">
                            <Input
                                name={field.value}
                                id={field.value}
                                className={`form-control ${field.class ? field.class : 'w-100'}`}
                                placeholder={`Enter ${field.label ? field?.label : field?.placeHolder}`}
                                // type={field.type}
                                disabled={mode === 'view'}
                                maxLength={field.maxLength}
                                onKeyDown={(e) => handleKeyDown(e)}
                                validate={{ required: { value: true } }}
                                onChange={(e) => {
                                    field.type === 'number' ? handleNumberChnage(e, field) : validation.handleChange(e);
                                }}
                                value={validation.values?.[field.value] || ''}
                            />
                        </div>
                    </div>
                </Col>
            </>
        );
    };

    const selectComponent = (field) => {
        const { totalDaysNoAbs } = selectedCard?.expiration
            ? diffTwoDatesHrsMintsSeconds(new Date(convertUTCtoIST(selectedCard?.expiration, true)), new Date())
            : 0;

        return (
            <Row className={`mb-3 pb-3 mt-3 ${field?.customBorder && 'border-bottom-dashed border-light'}`}>
                <Col
                    md={field?.customCol ? 6 : 12}
                    xl={field?.customCol ? 5 : 12}
                    lg={field.customCol ? 5 : 12}
                    className="d-flex align-items-center"
                >
                    <Label className="form-label d-flex align-items-center fw-medium mb-0 fs-14">
                        {field.label}
                        {field?.mandatory && mode === 'add' ? <span className="red-color ps-1">*</span> : <span className="mx-1"> :</span>}
                    </Label>
                </Col>
                <div>
                    <Col md={field.customCol ? 6 : 12} xl={field.customCol ? 5 : 12} lg={field.customCol ? 5 : 12}>
                        {field.helpText && <div className="text-muted  mb-2 word-wrap-break">{field.helpText}</div>}
                    </Col>
                </div>
                <Col md={field.customCol ? 6 : 10} xl={field.customCol ? 5 : 10} lg={field.customCol ? 5 : 10}>
                    {mode === 'view' || mode === 'edit' ? (
                        <Input
                            name={field.value}
                            id={field.value}
                            className={`form-control ${field.class ? field.class : 'w-100'}`}
                            type={field.type}
                            disabled={true}
                            value={totalDaysNoAbs >= 0 ? `${totalDaysNoAbs + 1} ${totalDaysNoAbs === 0 ? 'day' : 'days'}` : 0}
                        />
                    ) : (
                        <Select
                            getOptionValue={(option) => option.value}
                            getOptionLabel={(option) => option.label}
                            isMulti={field.isMulti ? true : false}
                            isClearable={field.isMulti ? true : false}
                            id={field.label}
                            name={field.value}
                            isDisabled={mode === 'view'}
                            options={field.options}
                            placeholder={`Select ${field.label}`}
                            onBlur={() => validation.handleBlur({ target: { name: field.value } })}
                            onChange={(selectedOption) => {
                                validation.handleChange({ target: { name: field.value, value: selectedOption } });
                            }}
                            value={validation?.values[field.value] || ''}
                            isSearchable={true}
                            noOptionsMessage={() => 'No data found'}
                        />
                    )}
                </Col>
            </Row>
        );
    };

    const handleKeyDown = (e) => {
        if (e.keyCode === 13) e.preventDefault();
    };

    const handleEnrollPageChange = (pageNumber, pgSize) => {
        setActivePage(pageNumber);
        setSearchParams({ ...searchParams, page: pageNumber });
        getEnrollData({ code: policyData?.code });
    };

    const tableInput = (field) => {
        const handleAdd = () => {
            if (validation.values[field.val]?.endsWith('.') || validation.values[field.val]?.split('.')?.length < 4) {
                toast.error(toastMessages.invalidIP);
                return;
            }

            if (validation.values[field.value]?.includes(validation.values[field.val])) {
                toast.error(field?.toastmsg);
                return;
            }

            let values = [...(validation.values[field.value] || [])];
            if (field.max ? values?.length <= field.max : true) values.push(validation.values[field.val]);
            validation.setValues({ ...validation.values, [field.value]: values, [field.val]: '' });
        };

        const handleDelete = (ind) => {
            let values = [...(validation.values[field.value] || [])];
            values.splice(ind, 1);
            validation.setValues({ ...validation.values, [field.value]: values.length > 0 ? values : '', [field.val]: '' });
        };

        const handleInputChange = (e) => {
            const regex = new RegExp(field?.regex);
            const valid = regex.test(e.target.value);
            if ((valid && e.target.value.charAt(0) !== '.') || e.target.value === '') validation.handleChange(e);
        };

        return (
            <React.Fragment>
                <Row>
                    <Col sm={12} xs={12} md={10} lg={10} xl={10}>
                        <Label className="mb-0 fw-medium d-flex align-items-center">
                            {field.label}
                            {field?.mandatory && <span className="red-color ps-1">*</span>}
                        </Label>
                        {field.helpText && <div className="text-muted font-size-12 mb-2 word-wrap-break ">{field.helpText}</div>}
                        <Table bordered={validation.values?.[field.value]?.length === 1}>
                            <tbody>
                                {(field.max && validation.values[field.value]?.length
                                    ? validation.values[field.value]?.length < field.max
                                    : true) && formType !== 'view' ? (
                                    <tr>
                                        <td className={`${validation.values?.[field.value]?.length === 1 ? '' : 'border-none px-0'}`}>
                                            <div className="d-flex align-items-center gap-2">
                                                <Input
                                                    name={field?.val}
                                                    id={field?.val}
                                                    className="form-control"
                                                    autoComplete="new-password"
                                                    placeholder={`Enter ${field?.placeHolder ? field?.placeHolder : field.label}`}
                                                    type={field.type}
                                                    validate={{ required: { value: true } }}
                                                    onChange={(e) => handleInputChange(e)}
                                                    min={field.minValue}
                                                    max={field.maxValue}
                                                    maxLength={field.maxLength}
                                                    onBlur={validation?.handleBlur}
                                                    onKeyDown={(e) => handleKeyDown(e)}
                                                    value={validation?.values[field?.val] || ''}
                                                    invalid={
                                                        validation?.touched?.[field?.val] && validation?.errors?.[field?.val] ? true : false
                                                    }
                                                />
                                                <Button
                                                    color="success"
                                                    outline
                                                    size="sm"
                                                    disabled={
                                                        !validation.values?.[field?.val] ||
                                                        validation.values?.[field?.val] === '' ||
                                                        Object.keys(validation.errors)?.length > 0
                                                    }
                                                    onClick={handleAdd}
                                                >
                                                    <i className="ri-check-line fs-16" />
                                                </Button>
                                            </div>
                                        </td>
                                    </tr>
                                ) : !validation.values[field.value]?.length ? (
                                    '–'
                                ) : (
                                    ''
                                )}
                                {(validation.values?.[field.value] || [])?.map((value, ind) => (
                                    <tr key={ind}>
                                        <td>
                                            <div className="d-flex align-items-center justify-content-between">
                                                {value}
                                                {formType !== 'view' && (
                                                    <Button color="danger" outline size="sm" onClick={() => handleDelete(ind)}>
                                                        <i className="ri-delete-bin-6-line fs-16" />
                                                    </Button>
                                                )}
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </React.Fragment>
        );
    };

    const copyToClipboard = async (val, toastmsg) => {
        navigator.clipboard.writeText(val).then(() => {
            toast.success(`${toastmsg} Copied Successfully.`);
        });
    };

    const dynamicComponent = (field, index) => {
        switch (field?.inputType) {
            case 'radio':
                return radioButton(field);
            case 'input':
                return inputComponent(field);
            case 'select':
                return selectComponent(field);
            case 'tableInput':
                return tableInput(field);
            case 'heading':
                return <div className="fs-14 mt-2 fw-semibold">{field?.label}</div>;
            case 'border':
                return <div className="my-2 border-bottom"></div>;
            case 'note':
                return (
                    <div className="fw-medium fs-12 badge-soft-warning p-2 text-black my-5">
                        <span className="fw-semibold fs-13"> {field?.label}</span> {field?.helptext}
                    </div>
                );
        }
    };

    const handleShowOn = (field) => {
        let flag = true;
        flag = field.showKey ? flag && validation.values[field.showKey] === field.showVal : flag;
        return flag;
    };

    const customCancel = () => {
        setShowEnrollModal(false);
        setSelectedCard('');
        setMode('add');
        validation.resetForm();
    };

    useEffect(() => {
        if (showEnrollModal && mode === 'view' && selectedCard) {
            setCanvasLoader(true);
            api.get(url.POLICIES + '/url/' + selectedCard?._id, '', domains.IOS_V1).then((resp) => {
                setUrlString(resp?.data);
            });
            api.get(url.POLICIES + '/qrcode/' + selectedCard?._id, '', domains.IOS_V1).then((resp) => {
                setQrString(resp?.data);
            });
            setCanvasLoader(false);
        }
    }, [showEnrollModal, mode, selectedCard]);

    const handleDownload = (fileUrl, fileName, type) => {
        DownloadBunnyFile(fileUrl, fileName, setLoading);
    };

    const handleEnrollModalBody = () => {
        return mode === 'view' ? (
            <React.Fragment>
                <div className="d-flex align-items-center mb-2">
                    <Label className="p-0 m-0 fw-semibold">Policy :</Label>
                    <p className="p-0 m-0 ms-2 fw-normal">{policyData?.name}</p>
                </div>
                <div className="d-flex align-items-center mb-2">
                    <Label className="p-0 m-0 fw-semibold">Configuration Name :</Label>
                    <p className="p-0 m-0 ms-2 fw-normal">{selectedCard?.name}</p>
                </div>
                <div className="fs-14 fw-medium">Enrollment Options :</div>
                <div className="mt-1 fs-12 text-muted">
                    Below are the available enrollment options to simplify device setup. Users can select the method that offers the best
                    convenience.
                </div>

                <Row className="mb-2">
                    {enrollOptionsTab?.map((ele, ind) => {
                        return (
                            <Col sm={6} xs={6} md={6} lg={4} xl={4} key={ind} className="mt-2">
                                <div className="fw-medium fs-13">{ele?.label}</div>
                                <div className="fs-12 text-muted mt-1">{selectedCard?.[ele?.accessor]}</div>
                            </Col>
                        );
                    })}
                </Row>
                <Row className="mt-3">
                    {iosEnrollSteps
                        .filter((obj) => obj?.showOn?.includes(policyData?.platform))
                        ?.map((ele, ind) => {
                            return (
                                <Col sm={12} xs={12} md={12} lg={12} xl={12} key={ind} className="border rounded-3 mb-2 p-2">
                                    <Row className="d-flex align-items-center">
                                        <Col sm={3} md={3} lg={2} xl={2} className="me-3">
                                            <img
                                                className="border-"
                                                src={ele?.image ? ele.image : `data:image/png;base64,${qrString}`}
                                                alt={'alterimg'}
                                                width={60}
                                            />
                                        </Col>
                                        <Col sm={8} md={8} lg={9} xl={9}>
                                            <div className="fs-13 fw-medium d-flex align-items-center justify-content-between">
                                                <div>{ele?.label}</div>
                                                {/* {ele?.labelCopyIcon && (
                                                        <>
                                                            <i
                                                                id={`copy-${ind}`}
                                                                onClick={(e) => copyToClipboard(ele.label)}
                                                                className="ri-file-copy-line ms-2 fs-13 cursor-pointer link-primary"
                                                            ></i>
                                                            {Tooltip(`copy-${ind}`, 'Copy')}
                                                        </>
                                                    )} */}

                                                {ele?.download && (
                                                    <div
                                                        onClick={() =>
                                                            handleDownload(`data:image/png;base64,${qrString}`, ele?.label, 'logo')
                                                        }
                                                    >
                                                        <i className="ri-download-2-line text-primary fs-16 cursor-pointer me-5"></i>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="mt-1 text-muted fs-12">{ele?.helptext}</div>
                                            {ele?.label === 'URL' && (
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div className="word-ellipsis">
                                                        <a
                                                            className="text-decoration-underline "
                                                            target="_blank"
                                                            href={urlString}
                                                            rel="noreferrer"
                                                        >
                                                            {urlString}
                                                        </a>
                                                    </div>
                                                    <div>
                                                        <i
                                                            id={`tokencopy-${ind}`}
                                                            onClick={(e) => copyToClipboard(urlString, ele?.label)}
                                                            className="ri-file-copy-line ms-2 fs-13 cursor-pointer link-primary"
                                                        ></i>
                                                        {Tooltip(`tokencopy-${ind}`, 'Copy')}
                                                    </div>
                                                </div>
                                            )}
                                        </Col>
                                    </Row>
                                </Col>
                            );
                        })}
                </Row>
            </React.Fragment>
        ) : (
            <div className="mt-2">
                <div className="d-flex align-items-center mb-2">
                    <Label className="p-0 m-0 fw-semibold">Policy :</Label>
                    <p className="p-0 m-0 ms-2 fw-normal">{policyData?.name}</p>
                </div>
                {IosEnrollSecurity?.map((field, index) => {
                    return (
                        <React.Fragment key={index}>
                            {(field?.showKey ? handleShowOn(field) : true) && dynamicComponent(field)}
                        </React.Fragment>
                    );
                })}
            </div>
        );
    };

    const toggle = useCallback(async () => {
        setShowShareModal(false);
        setSelectedCard('');
    }, [showShareModal]);

    const hanldeEnrollShare = () => {
        setLoading(true);
        api.create(
            url.TV_ENROLLMENT + '/' + selectedCard?._id + '/share',
            { mail: validation.values?.['Emails'] },
            false,
            domains.ANDROID_V1
        )
            .then((resp) => {
                if (resp.status === 'success') {
                    setLoading(false);
                    toast.success(toastMessages.enrollConfigGen);
                    setShowShareModal(false);
                }
            })
            .catch((err) => setLoading(false));
    };

    const handleShareModalody = () => {
        return (
            <React.Fragment>
                <div className="fs-13 fw-medium">Enrollment Options :</div>
                <div className="mt-1 fs-12">
                    Below are the available enrollment options to simplify device setup. Users can select the method that offers the best
                    convenience.
                </div>

                <Row className="mb-2">
                    {enrollOptionsTab?.map((ele, ind) => {
                        return (
                            <Col sm={6} xs={6} md={6} lg={4} xl={4} key={ind} className="mt-2">
                                <div className="fw-medium fs-13">{ele?.label}</div>
                                <div className="fs-12 text-muted mt-1">{selectedCard?.[ele?.accessor]}</div>
                            </Col>
                        );
                    })}
                </Row>
                <Row className="mt-3">
                    {EnrollmentProcess?.map((ele, ind) => {
                        return (
                            <Col sm={12} xs={12} md={12} lg={12} xl={12} key={ind} className="border rounded-3 mb-2 p-2">
                                <div className="d-flex align-items-center">
                                    <div className="me-3">
                                        <img src={ele?.image} alt={'enrollmentImage'} width={60} />
                                    </div>
                                    <div>
                                        <div className="fs-13 fw-medium d-flex align-items-center">
                                            {ele?.label}
                                            {ele?.labelCopyIcon && (
                                                <>
                                                    <i
                                                        id={`copy-${ind}`}
                                                        onClick={(e) => copyToClipboard(ele.label)}
                                                        className="ri-file-copy-line ms-2 fs-13 cursor-pointer link-primary"
                                                    ></i>
                                                    {Tooltip(`copy-${ind}`, 'Copy')}
                                                </>
                                            )}
                                        </div>
                                        <div className="mt-1 text-muted fs-12">{ele?.helptext}</div>
                                        {ele?.copyIcon && (
                                            <div className="d-flex align-items-center">
                                                <span className="fs-13 fw-medium">{selectedCard?.enrollmentcode}</span>
                                                <i
                                                    id={`tokencopy-${ind}`}
                                                    onClick={(e) => copyToClipboard(selectedCard?.enrollmentcode, ele?.label)}
                                                    className="ri-file-copy-line ms-2 fs-13 cursor-pointer link-primary"
                                                ></i>
                                                {Tooltip(`tokencopy-${ind}`, 'Copy')}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </Col>
                        );
                    })}
                </Row>
                <div className="fs-13 fw-medium">Share :</div>
                <div className="mt-1 fs-12">
                    List down or bulk upload the email addresses of users to share enrollment methods for onboarding the device.
                </div>
                <div className="my-2">
                    {tableInput({
                        label: 'Email ID',
                        value: 'Emails',
                        val: 'Email',
                        mandatory: true,
                        maxLength: 50
                    })}
                </div>
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className={`page-content h-100 ${loading ? 'postion-relative mask' : ''}`}>
                <Container fluid>
                    <BreadCrumb
                        pageTitle="Enrollment Token"
                        history={history}
                        homeLink="Dashboard"
                        showBack={true}
                        backLink="iospolicies"
                    />
                    <Card>
                        <CardHeader className="card-height-100">
                            <div className="d-flex justify-content-between align-items-center fs-14 fw-semibold">
                                Enrollment Configuration History
                            </div>
                            <div className="padding-top-10 font-size-12 fw-medium text-muted">
                                Dispalys detailed record of all the created enrollment tokens for a policy used for device registration to
                                track a validity period and configured settings.A default card has been pre-configured for convinent use.
                            </div>
                        </CardHeader>
                        <CardBody>
                            <div className="d-flex align-items-center justify-content-end">
                                <Button
                                    color={'primary'}
                                    size={'md'}
                                    onClick={() => {
                                        setShowEnrollModal(true);
                                        setMode('add');
                                    }}
                                >
                                    + Create New
                                </Button>
                            </div>
                            <Row className="mt-2">
                                {enrollDetails?.length > 0 ? (
                                    enrollDetails?.map((data, ind) => {
                                        return (
                                            <Col key={ind} sm={12} xs={12} md={10} lg={6} xl={6} xxl={4}>
                                                <Card className="card-height-100 shadow-card border-1">
                                                    <CardHeader className="d-flex justify-content-between align-items-center">
                                                        <div className="fs-14 fw-medium">
                                                            {data?.name}
                                                            <span className={`mx-1 fs-10 p-1 rounded-3 badge-soft-${data?.badgeClass}`}>{`${
                                                                data.expiresOn <= 0
                                                                    ? 'Expired'
                                                                    : `Expires in ${data.expiresOn} ${data.expiresOn > 1 ? 'days' : 'day'}`
                                                            }`}</span>
                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <>
                                                                <i
                                                                    id={`edit-${ind}`}
                                                                    className="ri-edit-box-line fs-15 pe-2 text-secondary cursor-pointer"
                                                                    onClick={() => {
                                                                        setSelectedCard(data);
                                                                        setShowEnrollModal(true);
                                                                        setMode('edit');
                                                                    }}
                                                                />
                                                                {Tooltip(`edit-${ind}`, 'Edit')}
                                                            </>

                                                            {/* <i
                                                                id={`share-${ind}`}
                                                                className="ri-share-forward-fill fs-15 cursor-pointer text-primary"
                                                                onClick={() => {
                                                                    setSelectedCard(data);
                                                                    setShowShareModal(true);
                                                                }}
                                                            />
                                                            {Tooltip(`share-${ind}`, 'Share')} */}
                                                        </div>
                                                    </CardHeader>
                                                    <CardBody className="row pb-2">
                                                        {iosCardDetails?.map((ele, index) => {
                                                            return (
                                                                <Col sm={6} key={index}>
                                                                    <div className="fs-12 text-muted ">
                                                                        <EllipsisToolTip options={{ effect: 'solid', place: 'bottom' }}>
                                                                            {ele?.label}
                                                                        </EllipsisToolTip>
                                                                    </div>
                                                                    <div className="d-flex justify-content-start mt-1 mb-2 fw-medium fs-13">
                                                                        <EllipsisToolTip options={{ effect: 'solid', place: 'bottom' }}>
                                                                            {data?.[ele?.accessor]}
                                                                            {ele?.copyIcon && (
                                                                                <i
                                                                                    id={`copy-${ind}`}
                                                                                    onClick={(e) =>
                                                                                        copyToClipboard(data?.[ele?.accessor], ele.label)
                                                                                    }
                                                                                    className="ri-file-copy-line ms-2 fs-13 cursor-pointer link-primary"
                                                                                ></i>
                                                                            )}
                                                                        </EllipsisToolTip>
                                                                    </div>
                                                                </Col>
                                                            );
                                                        })}
                                                    </CardBody>
                                                    <CardFooter className="d-flex align-items-center justify-content-between">
                                                        <div className="fs-12">
                                                            Valid Till : <span className="fs-12 fw-medium"> {data?.endDate}</span>
                                                        </div>
                                                        <div
                                                            className="text-decoration-underline text-secondary fs-12 fw-medium cursor-pointer"
                                                            onClick={() => {
                                                                setShowEnrollModal(true);
                                                                setSelectedCard(data);
                                                                setMode('view');
                                                            }}
                                                        >
                                                            View Enrollment Options
                                                        </div>
                                                    </CardFooter>
                                                    <div
                                                        className={`progress progress-bar animated-progess rounded-bottom rounded-0 height-3 bg-${data?.badgeClass}`}
                                                    ></div>
                                                </Card>
                                            </Col>
                                        );
                                    })
                                ) : (
                                    <NoRecordsFound />
                                )}
                                {totalRecords > 30 && (
                                    <Pagination
                                        activePage={activePage}
                                        itemsCountPerPage={pageSize}
                                        totalItemsCount={totalRecords}
                                        pageRangeDisplayed={3}
                                        marginTopClass={'mt-1'}
                                        onChange={handleEnrollPageChange}
                                        hideFirstLastPages={false}
                                    />
                                )}
                            </Row>
                        </CardBody>
                    </Card>
                    <OffcanvasModal
                        direction="end"
                        toggle={showEnrollModal ? customCancel : toggle}
                        open={showEnrollModal || showShareModal}
                        hideSaveButton={showEnrollModal && mode === 'view'}
                        handleCloseClick={showEnrollModal ? customCancel : toggle}
                        OffcanvasModalID="enrollment"
                        bodyCSSClass="px-4 py-3"
                        saveDisabled={
                            showEnrollModal
                                ? (mode === 'add' && !validation.values?.expiration) ||
                                  !validation.values?.name ||
                                  validation.values?.name === '' ||
                                  (validation.values?.securityOptions === 'STATIC_PIN' && !validation.values?.pin) ||
                                  validation.values?.pin?.toString()?.length < 4 ||
                                  (validation.values?.networkType === 'specific'
                                      ? !validation.values?.ipAddress?.length || validation.values?.ipAddress?.length === 0
                                      : false)
                                : // ? !validation.values?.name
                                  !validation.values?.['Emails'] || validation.values?.['Emails'] === ''
                        }
                        handleSaveClick={showEnrollModal ? validation.handleSubmit : hanldeEnrollShare}
                        saveText={showEnrollModal ? (mode === 'add' ? 'Save' : 'Update') : 'Send'}
                        handleOffcanvasBody={() => (showEnrollModal ? handleEnrollModalBody() : handleShareModalody())}
                        modalClassName={'w-40'}
                        loading={canvasLoader}
                        offcanvasHeader={`${
                            showEnrollModal ? mode?.charAt(0)?.toUpperCase() + mode?.substr(1)?.toLowerCase() : ''
                        } Enrollment Configurations`}
                    />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default IosEnrollmentToken;
